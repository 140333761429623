import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
import FooterCopyright from "../../components/footer/FooterCopyright";
import FooterNewsletter from "../../components/footer/FooterNewsletter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { YouTube } from "@mui/icons-material";
import footerLogo from "../../assets/img/amyraa_logo.png";
import mixpanel from "mixpanel-browser";
import { Mixpanel } from "../../utils/mixpannel";
import { useSelector } from "react-redux";

// import TwitterIcon from "@mui/icons-material/Twitter";
import twitterIcon from "../../assets/icons/twitter.svg";
// import vivahLogo from "../../assets/img/vivha_logo.png";
// import vivahLogo from "../../assets/img/vivah_new_logo.png";
// import vivahName from "../../assets/img/vivah_name.png";
import VivahLogo from "../../assets/img/Vivah Logo.jpg";
import "./footerone.css";

const FooterOne = ({
  backgroundColorClass,
  spaceTopClass,
  spaceBottomClass,
  spaceLeftClass,
  spaceRightClass,
  containerClass,
  extraFooterClass,
  sideMenu,
}) => {
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);

  const mylanguage = useSelector((state) => state.language);

  useEffect(() => {
    console.log("footer render");

    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
    window.scrollTo(0, 0);
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  const ClickOnTwitter = () => {
    mixpanel.track("Share_FaceBook");
  };

  const ClickOnInstagram = () => {
    mixpanel.track("Share_Insta");
  };

  const ClickOnFacebook = () => {
    mixpanel.track("Share_Twitter");
  };

  const ShopHome = {
    English: "Shop",
    Hindi: "शॉप",
    Tamil: "ஷாப்",
    Telugu: "షాపు",
    French: "Magasin",
    Spanish: "Tienda",
  };

  const CollectionHome = {
    English: "Collection",
    Hindi: "कलेक्शन",
    Tamil: "சேகரிப்பு",
    Telugu: "సంగ్రహం",
    French: "Collection",
    Spanish: "Colección",
  };

  const AboutusHome = {
    English: "About Us",
    Hindi: "हमारे बारे में",
    Tamil: "எங்கள் பற்றி",
    Telugu: "మా గురించి",
    French: " À proposdenous",
    Spanish: "Sobrenosotros",
  };

  const ContactUsHome = {
    English: "Contact Us",
    Hindi: "हमसे संपर्क करें",
    Tamil: "தொடர்பு கொள்ளவும்",
    Telugu: "మనకు సంప్రదించండి",
    French: "Contactez-nous",
    Spanish: "Contáctenos",
  };

  const Contact = {
    English: "Contact",
    Hindi: "संपर्क",
    Spanish: "Contacto",
    Tamil: "தொடர்பு",
    Telugu: "సంప్రదించండి",
    French: "Contact",
  };

  const Address = {
    English: "Address",
    Hindi: "पता",
    Spanish: "Dirección",
    Tamil: "முகவரி",
    Telugu: "చిరునామా",
    French: "Adresse",
  };

  const FullAddress = {
    English:
      " 70, Ratnajyot Industrial Estate, 2nd Floor, Irla Lane, Vile Parle (W), Mumbai, Maharashtra 400056",
    Hindi:
      "१९४/ शेख मेमन स्ट्रीट, विपरीत महावीर मार्केट, जवेरी बाजार, मुंबई - ४००००२।",
    Spanish:
      "194/ Calle Sheikh Memon, frente al Mercado Mahavir, Zaveri Bazar, Mumbai - 400002.",
    Tamil:
      "194/ செய்க் மேமன் தெரு, எதிர்கால் மகாவீர் மார்க்கெட், ஜாவேரி பஜார், மும்பை - 400002.",
    Telugu:
      "194/ శేఖ్ మేమన్ స్ట్రీట్, అప్పు.మహావీర్ మార్కెట్, జవేరీ బజార్, ముంబయి - 400002.",
    French:
      "194/ Rue Sheikh Memon, en face du marché Mahavir, Zaveri Bazar, Mumbai - 400002.",
  };

  const DownloadAmyraaAppFrom = {
    English: "Download Amyraa App From",
    Hindi: "से एमिरा ऐप डाउनलोड करें",
    Spanish: "Descargar la aplicación Amyraa desde",
    Tamil: "அமைரா பயன்படுத்தும் செயலி இருந்து",
    Telugu: "నుండి Amyraa యాప్ డౌన్లోడ్ చేయండి",
    French: "Télécharger l'application Amyraa à partir de",
  };

  const PhoneNumber = {
    English: "Phone Number:",
    Hindi: "फ़ोन नंबर:",
    Spanish: "Número de teléfono:",
    Tamil: "தொலைபேசி எண்:",
    Telugu: "ఫోన్ నంబర్:",
    French: "Numéro de téléphone:",
  };

  const MobileNumber = {
    English: "Mobile Number:",
    Hindi: "मोबाइल नंबर:",
    Spanish: "Número de móvil:",
    Tamil: "மொபைல் எண்:",
    Telugu: "మొబైల్ నంబర్:",
    French: "Numéro de portable:",
  };

  const Copyright = {
    English: "Copyright 2023. Vivah Creations. All rights reserved.",
    Hindi: "कॉपीराइट 2023। विवाह सृष्टियाँ। सभी अधिकार सुरक्षित।",
    Spanish:
      "Derechos de autor 2023. Creaciones Vivah. Todos los derechos reservados.",
    Tamil:
      "பதிப்புரிமை 2023. விவா உருவங்கள். அனைத்து உரிமைகளும் பாதுகாக்கப்பட்டுள்ளன.",
    Telugu: "కాపీరైట్ 2023. వివా రచనలు. అన్ని హక్కులు సంరక్షితం.",
    French: "Droits d'auteur 2023. Créations Vivah. Tous droits réservés.",
  };
  const Blogs = {
    English: "Blogs",
  };

  const NewnavbarShop = ShopHome[mylanguage];
  const NewnavbarCollection = CollectionHome[mylanguage];
  const newAboutus = AboutusHome[mylanguage];
  const newContactus = ContactUsHome[mylanguage];
  const newContactHeading = Contact[mylanguage];
  const newAddressHeading = Address[mylanguage];
  const newFullAddress = FullAddress[mylanguage];
  const newDownloadAmyraaAppFrom = DownloadAmyraaAppFrom[mylanguage];
  const newPhoneNumber = PhoneNumber[mylanguage];
  const newMobileNumber = MobileNumber[mylanguage];
  const newCopyright = Copyright[mylanguage];
  const newBlogs = Blogs[mylanguage];

  const FAQ = {
    English: "FAQ's",
    Hindi: "सामान्य प्रश्न",
    Tamil: "கேள்விகள் பொது",
    Telugu: "సాధారణ ప్రశ్నలు",
    French: "Questions fréquemment posées",
    Spanish: "Preguntas",
  };

  const Shipping = {
    English: "Shipping",
    Hindi: "शिपिंग",
    Tamil: "டெலிவரி",
    Telugu: "పంపిణీ",
    French: "Expédition",
    Spanish: "Envío",
  };

  const ReturnRefundPolicy = {
    English: "Return & Refund Policy",
    Hindi: "वापसी और रिफंड नीति",
    Tamil: "திரும்பவும் & திரும்ப பணம்",
    Telugu: "తిరిగి & రిఫండ్ ధరణ",
    French: "Politique de retour et de remboursement",
    Spanish: "Política de devolución y reembolso",
  };

  const PrivacyPolicy = {
    English: "Privacy Policy	",
    Hindi: "गोपनीयता नीति",
    Tamil: "தனியுரிமை கொள்கை",
    Telugu: "గోప్యత విధానం",
    French: "Politique de confidentialité",
    Spanish: "Política de privacidad",
  };

  const TermsConditions = {
    English: "Terms & Conditions",
    Hindi: "नियम और शर्तें	",
    Tamil: "விதிமுறைகள் & நடவடிக்கைகள்",
    Telugu: "షరతులు & పథకాలు",
    French: "Termes et conditions",
    Spanish: "Términos y condiciones",
  };

  const QuickLinks = {
    English: "Quick Links",
    Hindi: "त्वरित  लिंक",
    Spanish: "Enlaces rápidos",
    Tamil: "விரைவு இணைப்புகள்",
    Telugu: "త్వరగా లింకులు",
    French: "Liens rapides",
  };

  const newFAQ = FAQ[mylanguage];
  const newShippig = Shipping[mylanguage];
  const newReturnRefundPolicy = ReturnRefundPolicy[mylanguage];
  const newPrivacyPolicy = PrivacyPolicy[mylanguage];
  const newTermsConditions = TermsConditions[mylanguage];
  const newQuickLinks = QuickLinks[mylanguage];

  return (
    <footer
      className={`footer-area ${
        backgroundColorClass ? backgroundColorClass : ""
      } ${spaceTopClass ? spaceTopClass : ""} ${
        spaceBottomClass ? spaceBottomClass : ""
      } ${extraFooterClass ? extraFooterClass : ""} ${
        spaceLeftClass ? spaceLeftClass : ""
      } ${spaceRightClass ? spaceRightClass : ""}`}
    >
      <div className={`${containerClass ? containerClass : "container"}`}>
        <div className="row">
          <div
            className={`${
              sideMenu
                ? "col-xl-3 col-sm-3 text-center"
                : "col-lg-2 col-sm-2 text-center p-0"
            }`}
          >
            {/* footer copyright */}

            <FooterCopyright footerLogo={footerLogo} spaceBottomClass="mb-30" />
            {/* <a href="https://www.facebook.com/vivahcreation/ " target="_blank">
              <FacebookIcon className="social-icons facebook-icon " onClick={ClickOnFacebook} />
            </a> */}
            <a
              style={{ marginLeft: "-19px" }}
              href="https://www.youtube.com/@AdminAmyraa"
              target="_blank"
            >
              <YouTube className="social-icons instagram-icon ml-30" />
            </a>
            <a
              style={{ marginLeft: "-6px" }}
              href="https://www.instagram.com/amyraa.app?igsh=MXhqdXA4dWRnd3VvZQ=="
              target="_blank"
            >
              <InstagramIcon
                className="social-icons instagram-icon ml-30"
                onClick={ClickOnInstagram}
              />
            </a>

            {/* <a href="https://twitter.com/vivah_creation" target="_blank" onClick={ClickOnTwitter}  > */}
            {/* <TwitterIcon className="social-icons twitter-icon ml-30" /> */}
            {/* <img
                src={twitterIcon}
                alt="Twitter Icon"
                className="social-icons twitter-icon ml-30"
                height={35}
                width={35}
              /> */}
            {/* </a> */}
          </div>

          <div
            className={`${
              sideMenu ? "col-xl-4 col-sm-4" : "col-lg-4 col-sm-4"
            }`}
          >
            <div
              className={`${
                sideMenu
                  ? "footer-widget mb-30 ml-95"
                  : "footer-widget mb-30 ml-50"
              }`}
            >
              <div className="footer-title">
                <h3>{newQuickLinks}</h3>
                <hr className="footer-lines" />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="footer-list  ml-40">
                  <ul>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/products"}>
                        {NewnavbarShop}
                      </Link>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/Collection"}>
                        {NewnavbarCollection}
                      </Link>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/contact"}>
                        {newContactus}
                      </Link>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/about"}>
                        {newAboutus}
                      </Link>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/bloglisting"}>
                        {newBlogs}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-6">
                <div className="footer-list">
                  <ul>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/FAQ"}>{newFAQ}</Link>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/Shipping"}>
                        {newShippig}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={process.env.PUBLIC_URL + "/Return_Refund_Policy"}
                      >
                        {newReturnRefundPolicy}
                      </Link>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/Privacy_Policy"}>
                        {newPrivacyPolicy}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={process.env.PUBLIC_URL + "/Terms_and_Conditions"}
                      >
                        {newTermsConditions}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${
              sideMenu ? "col-xl-4 col-sm-4" : "col-lg-3 col-sm-6"
            }`}
          >
            <div
              className={`${
                sideMenu
                  ? "footer-widget mb-30 ml-145"
                  : "footer-widget mb-30 ml-20 mr-20"
              }`}
            >
              <div className="footer-title">
                <h3>{newContactHeading}</h3>
                <hr className="footer-lines" />
              </div>
              <div className="footer-list">
                <h3>{newAddressHeading}</h3>
                <p>{newFullAddress}</p>
                {/* <h3 className="Custom_contact_nomargin">{newPhoneNumber}</h3>
                <p>+91-22-49735979</p> */}
                <h3 className="Custom_contact_nomargin">{newMobileNumber}</h3>
                <p>+91-9320838762</p>
              </div>
            </div>
          </div>
          <div
            className={`${
              sideMenu ? "col-xl-2 col-sm-8" : "col-lg-3 col-sm-6"
            }`}
          >
            {/* footer newsletter */}

            <h5 className="mt-0">{newDownloadAmyraaAppFrom}</h5>
            <hr className="footer-lines" />
            <div className="row">
              <div className="col-6">
                <a
                  href="https://apps.apple.com/in/app/amyraa/id6476032395"
                  target="_blank"
                >
                  <img
                    src="/assets/img/Download_APP_store.png"
                    className="img-thumbnail"
                    alt="App store logo"
                  />
                </a>
              </div>
              <div className="col-6">
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.amyra"
                >
                  <img
                    src="../../assets/img/amyra_playstore.png"
                    className="img-thumbnail"
                    alt="Play store logo"
                  />
                </a>
              </div>
            </div>

            {/* <div
              className="mt-50"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}>
              <a
                href="https://www.vivahcreation.net/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ width: "50%" }}>
                <img
                  src={VivahLogo}
                  className="img-thumbnail"
                  alt="Vivah Logo"
                  style={{ width: "100%", cursor: "pointer" }}
                />
              </a>
              <h5 style={{ marginTop: 20 }}>Powered by Vivah Creation</h5>
            </div> */}
            {/* <FooterNewsletter
              spaceBottomClass="mb-30"
              spaceLeftClass="ml-70"
              sideMenu={sideMenu}
            /> */}
          </div>
          <div
            className={`${
              sideMenu ? "col-xl-12 col-sm-12" : "col-lg-12 col-sm-12"
            }`}
          >
            {/* footer newsletter */}
            <hr />
            <h5 className="text-center">{newCopyright}</h5>

            {/* <FooterNewsletter
              spaceBottomClass="mb-30"
              spaceLeftClass="ml-70"
              sideMenu={sideMenu}
            /> */}
          </div>
        </div>
      </div>
      <button
        className={`scroll-top ${scroll > top ? "show" : ""}`}
        onClick={() => scrollToTop()}
      >
        <i className="fa fa-angle-double-up"></i>
      </button>
    </footer>
  );
};

FooterOne.propTypes = {
  backgroundColorClass: PropTypes.string,
  containerClass: PropTypes.string,
  extraFooterClass: PropTypes.string,
  sideMenu: PropTypes.bool,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  spaceRightClass: PropTypes.string,
};

export default FooterOne;
