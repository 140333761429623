export default function removeProducts(prds, key, value) {
  console.log("remove atr workig");
  let i = prds.length;
  while (i--) {
    if (
      prds[i] &&
      prds[i].hasOwnProperty(key) &&
      arguments.length > 2 &&
      prds[i][key] === value
    ) {
      prds.splice(i, 1);
      console.log("if working");
    }
  }
  console.log("remove atr arr", prds);
  return prds;
}

export const filterColors = {
  AP: ["#F9D6B8", "#68DBD6"],
  AQ: ["#68DBD6"],
  BG: ["#0083CD", "#00A05D"],
  BK: ["#424242"],
  BP: ["#0053B4", "#A701AA"],
  BR: ["#01A4FF", "#E0115F"],
  BU: ["#0072E6"],
  BW: ["#520000"],
  CP: ["#FAECCC"],
  CW: ["#FAECCC", "#FFFFFF"],
  fb: ["#008794", "#001B7A"],
  FP: ["#018895", "#FF06D7"],
  FZ: ["#008794"],
  GB: ["#D9D9D9", "#E11260"],
  GG: ["#00A224", "#E21361"],
  GP: ["#9800CD", "#00A05D"],
  GR: ["#157967"],
  GY: ["#D9D9D9"],
  HB: ["#006995"],
  HG: ["#438079"],
  HR: ["#FF5495"],
  LB: ["#8CB3FF"],
  LG: ["#A2FF99"],
  LP: ["#FF96F4"],
  LU: ["#F2A0FF"],
  MD: ["#657F3D"],
  ML: ["#FD0000", "#FF5495", "#F9AF20", "#008794", "#00A05D", "#FF5495"],
  MP: ["#79E6A3", "#9800CD"],
  MT: ["#00DEC7"],
  OB: [],
  OL: ["#536832"],
  OR: ["#ED8000"],
  OW: ["#FFFFE4"],
  PB: ["#FF06D7", "#1877F2"],
  PE: ["#68DBD6", "#C9CDCD"],
  PG: ["#FF06D7", "#00CB14"],
  PH: ["#FAD7B9"],
  PK: ["#FF06D7"],
  PL: [],
  PM: ["#FF06D7", "#00DEC7"],
  PP: ["#FF06D7", "#8800B8"],
  PR: ["#FF06D7", "#FF5495"],
  PU: ["#9800CD"],
  RA: ["#00504C"],
  RB: ["#E11260"],
  RG: ["#E11260", "#00A05D"],
  RM: ["#E11260", "#00DEC7"],
  RN: ["#C71C69", "#FF06D7"],
  RP: ["#FAD7B9", "#E11260"],
  WC: ["#67144A"],
  WG: ["#00A05D", "#FFFFFF"],
  WH: ["#FFFFFF"],
  WR: ["#FFFEFE", "#E11260"],
  YB: ["#1877F2", "#FFE814"],
  YG: ["#00A05D", "#F3F800"],
  YL: ["#FFF500"],
};

export const filterPlatings = {
  BG: ["#000000"],
  BL: ["#000000"],
  BR: ["#000000"],
  BW: ["#000000"],
  cr: ["#000000"],
  GJ: ["#000000"],
  GP: ["#000000"],
  MG: ["#000000"],
  NA: ["#000000"],
  RB: ["#000000"],
  RJ: ["#000000"],
  RO: ["#000000"],
  RW: ["#000000"],
  WH: ["#000000"],
  WR: ["#000000"],
};
