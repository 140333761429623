import { ActionTypes } from "../constants/action-type";

const initialState = {
  products: [],
  product: {},
  filters: [],
  filters_top: {
    color: [],
    plating: [],
    lowerPrice: "",
    upperPrice: "",
    sortby: "CREATEDTIME desc",
    catgFilter: [],
  },
  search: "",
};
export const ProductReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_PRODUCTS:
      return { ...state, products: payload };
    case ActionTypes.SET_PRODUCT:
      return { ...state, product: payload };
    case ActionTypes.SELECTED_FILTERS:
      return { ...state, filters: payload };
    case ActionTypes.SELECTED_TOP_FILTERS:
      return { ...state, filters_top: payload };
    case ActionTypes.SEARCH:
      return { ...state, search: payload };
    default:
      return state;
  }
};
