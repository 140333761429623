import { ActionTypes } from "../constants/action-type";

const initialState = {
  selectedProducts: [],
};
export const SelectAllReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SELECT_ALL:
      return { ...state, selectedProducts: payload };

    default:
      return state;
  }
};
