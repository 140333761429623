import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ProductListingSingle from "./ProductListingSingle";
import ApiLib from "../../utils/api";
import setProducts from "../../redux/actions/productAction";
import Loader from "../../helpers/Loader";
import { useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import getWishlist from "../../redux/actions/wishlistActions";
import mixpanel from "mixpanel-browser";

const ProductListingGrid = (props) => {
  const location = useLocation();
  const { selectedProducts, selectedProductsArr, checkAll } = props;

  console.log("search key", location.state.detail);

  const [allProducts, setAllProducts] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [wishlist, setWishlist] = useState([]);
  const { addToast } = useToasts();
  const API = new ApiLib();
  const filters_top = useSelector((state) => state.allProducts.filters_top);
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails);

  console.log(userDetails, "userDetailsInp");

  useEffect(() => {
    setPageCount(1);
    FetchAllProducts(1);
  }, [filters_top, location.state.detail]);

  useEffect(() => {
    if (pageCount > 1) {
      FetchMoreProducts(pageCount);
    }
  }, [pageCount]);

  function FetchMoreProducts(count) {
    let userID = userDetails?.user ? userDetails?.user?.ID : "";
    setLoading(true);
    API.FetchSearchProducts(count, filters_top, location.state.detail, userID)
      .then((res) => {
        console.log("fetch all products working");
        const products = res.data;
        setAllProducts([...allProducts, ...products]);
        dispatch(setProducts([...allProducts, ...products]));
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }

  function FetchAllProducts(count) {
    setLoading(true);
    let userID = userDetails?.user ? userDetails?.user?.ID : "";
    API.FetchSearchProducts(count, filters_top, location.state.detail, userID)
      .then((res) => {
        console.log("fetch all products working");
        const products = res.data;
        setAllProducts([...products]);
        dispatch(setProducts([...products]));
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }

  useEffect(() => {
    if (userDetails?.user?.ID) {
      FetchWishlist();
    }
  }, []);

  const FetchWishlist = () => {
    setLoading(false);
    API.GetUserWishlist(userDetails.user.ID)
      .then((res) => {
        console.log("wishlist==>", res.data);
        setWishlist(res.data);
        dispatch(getWishlist(res.data));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const DeleteFromWishlist = (DCODE) => {
    setLoading(true);
    API.DeleteWishlist(userDetails.user.ID, DCODE)
      .then((res) => {
        setLoading(false);
        console.log(res);
        addToast("Item removed from wishlist", {
          appearance: "success",
          autoDismiss: true,
        });
        FetchWishlist();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        addToast("Something went wrong", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };
  const AddToWishlist = (product) => {
    if (!userDetails?.user?.ID) {
      addToast("Kindly login to add item to wishlist ", {
        appearance: "warning",
        autoDismiss: true,
      });
      return;
    }
    setLoading(true);
    API.AddToWishlist(userDetails.user.ID, product.DCODE)
      .then((res) => {
        setLoading(false);
        console.log(res);
        //
        mixpanel.track("AddToWishList", { DesignId: JSON.stringify(product.DCODE) });  // MixPannel ka code

        //
        addToast("Item added to wishlist successfully ", {
          appearance: "success",
          autoDismiss: true,
        });
        FetchWishlist();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        addToast("Something went wrong", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : allProducts.length !== 0 ? (
        allProducts.map((product) => {
          return (
            <ProductListingSingle
              checkAll={checkAll}
              // sliderClassName={sliderClassName}
              // spaceBottomClass={spaceBottomClass}
              product={product}
              // currency={currency}
              // addToCart={addToCart}
              // addToWishlist={addToWishlist}
              // addToCompare={addToCompare}
              selectedProducts={selectedProducts}
              selectedProductsArr={selectedProductsArr}
              wishlistPrds={wishlist}
              addwishlist={(product) => AddToWishlist(product)}
              deletewishlist={(DCODE) => DeleteFromWishlist(DCODE)}
            />
          );
        })
      ) : (
        <h4 className="mx-auto">No Products Available</h4>
      )}

      {loading ? (
        <Loader />
      ) : allProducts.length >= 24 ? (
        <div className=" col-12 text-center ">
          <button
            onClick={() => setPageCount(pageCount + 1)}
            className=" ml-auto mr-auto Register-btn"
            style={{ background: "#14716b", color: "white", border: "none" }}>
            Show more
          </button>
        </div>
      ) : null}
    </Fragment>
  );
};

export default ProductListingGrid;
