// jshint esversion:6

import React from "react";
import "./loader.css";
import LoaderImg from "../assets/loader.gif";

const Loader = () => {
  return (
    <div class="loader-area no-printme">
      <div class="loader">
        <img src={LoaderImg} />
      </div>
    </div>
  );
};

export default Loader; 
