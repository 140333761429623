import React, { useEffect, useState, useCallback } from "react";
import HomeSingleProduct from "../../components/product/HomeSingleProduct";
import MyCollectionModal from "../../pages/shop-product/MyCollectionModal";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
// import { Lazy } from "swiper/dist/js/swiper.esm";
const responsive = {
  0: { items: 2 },
  568: { items: 4 },
  1024: { items: 4 },
};

const HomeProductsGrid = ({
  products,
  fetchMorePrds,
  wishlistPrds,
  deletewishlist,
  addwishlist,
}) => {
  const [thumbIndex, setThumbIndex] = useState(0);
  const [thumbAnimation, setThumbAnimation] = useState(false);
  const [thumbs, setThumbs] = useState();
  const [morePrd, setMorePrds] = useState([]);

  console.log("thumb", thumbs, thumbIndex, wishlistPrds);

  useEffect(() => {
    if (products.length) {
      let samePrd = products.length
        ? products.map((product, key) => {
            let wishlistPrd = wishlistPrds.find(
              (item) => item.DCODE == product.DCODE
            );
            console.log("wishlist prds");
            return (
              <HomeSingleProduct
                product={product}
                key={key}
                addwishlist={addwishlist}
                deletewishlist={deletewishlist}
                wishlistPrd={wishlistPrd}
              />
            );
          })
        : null;

      const thumbItems = (samePrd, [setThumbIndex, setThumbAnimation]) => {
        return samePrd.map((item, i) => (
          <div onClick={() => (setThumbIndex(i), setThumbAnimation(true))}>
            {item}
          </div>
        ));
      };
      setThumbs(thumbItems(samePrd, [setThumbIndex, setThumbAnimation]));
      setMorePrds(samePrd);
    }
    // eslint-disable-next-line
  }, [products, wishlistPrds]);
  const slideNext = () => {
    if (thumbIndex < thumbs.length - 5) {
      setThumbAnimation(true);
      setThumbIndex(thumbIndex + 1);
    } else {
      console.log("fetch more prds workimg");
      fetchMorePrds();
    }
  };

  const slidePrev = () => {
    if (thumbIndex > 0) {
      setThumbAnimation(true);
      if (thumbIndex == thumbs.length - 1) {
        setThumbIndex(thumbIndex - 5);
      } else {
        setThumbIndex(thumbIndex - 1);
      }
    }
  };

  return (
    <>
      <div className="swiper-slider-navigation-wrapper">
        <button
          className="swiper-button-prev ht-swiper-button-nav "
          style={{
            background: "transparent",
            border: "none",
          }}
          onClick={slidePrev}
        >
          <i
            className="pe-7s-angle-left slider-home-left left-arrow"
            style={{
              fontSize: "6em",
              color: "black",
            }}
          />
        </button>
        <button
          className="swiper-button-next ht-swiper-button-nav"
          style={{
            background: "transparent",
            border: "none",
          }}
          onClick={slideNext}
        >
          <i
            className="pe-7s-angle-right slider-home-right"
            style={{
              fontSize: "6em",
              color: "black",
            }}
          />
        </button>
      </div>
      <AliceCarousel
        activeIndex={thumbIndex}
        animationType="fadeout"
        animationDuration={400}
        mouseTracking
        items={morePrd}
        responsive={responsive}
        disableDotsControls
        disableButtonsControls
        // autoWidth
        // touchTracking={!thumbAnimation}
      />
    </>
  );
};

export default HomeProductsGrid;
