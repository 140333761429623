import React, { Fragment, useCallback, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { connect } from "react-redux";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import Table from "react-bootstrap/Table";
import "./myorder.css";
import DownloadIcon from "@mui/icons-material/Download";
import ArticleIcon from "@mui/icons-material/Article";
import { Link } from "react-router-dom";
import img5 from "../../assets/img/ringb.png";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ProgressBar from "./ProgressBar";

import ApiLib, { BillDeskApi } from "../../utils/api";
import { useSelector, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import CustomStepper from "./CustomStepper";
import whatsapp_btn from "../../assets/img/whatsapp_btn.png";
import Notify from "../../utils/Notify";
import addToCart from "../../redux/actions/cartActions";

// const SHIPPING_CHARGE = 99;

function OrderDetails(props) {
  //   const { pathname } = location;
  const [tab, setTab] = useState(1);
  const API = new ApiLib();
  const PAYMENTAPI = new BillDeskApi();
  const userDetails = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const [orderDetails, setOrderDetails] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [shippingChrg, setShippingChrg] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [showAlet, setShowAlet] = useState(false);
  const [reVerify, setReVerify] = useState(false);
  console.log("id", props.match.params.id);

  useEffect(() => {
    FetchOrderDetails();
    // eslint-disable-next-line
  }, []);

  const FetchShippingChrg = (total) => {
    setLoading(true);
    API.GetShippingChrg(userDetails.user.ID, props.match.params.id)
      .then((res) => {
        setLoading(false);
        if (res.data.length) {
          setShippingChrg(res.data[0].AMOUNT);
          let ship = Number(res.data[0].AMOUNT);
          setTotalAmount(total);
        } else {
          setTotalAmount(total);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const SHIPPING_CHARGE = parseFloat(shippingChrg);

  const createToast = (statusCode) => {
    if (statusCode == "0399") {
      Notify({
        alert: true,
        type: "error",
        title: "Transaction Failed",
      });
    } else if (statusCode == "0002") {
      Notify({
        alert: true,
        type: "info",
        title: "Status is pending. Please check after some time",
      });
      return;
    } else if (statusCode === "0300") {
      Notify({
        alert: true,
        type: "success",
        title: "Transaction Successfull",
      });
      GetCartDetails();
    }
  };

  const checkOrderStatus = useCallback(
    async (email, orderId, amyraaOrderId) => {
      let requestBody = {
        email,
        orderId,
        amyraaOrderId,
      };
      try {
        let { data } = await PAYMENTAPI.checkBdOrderStatus(requestBody);
        createToast(data.data.auth_status);
        if (data.data.auth_status == "0002") {
          reFetchOrderDetails();
        }
        if(data.data.auth_status=='0300'){
          SendMessageNotification(email,orderId)
        }
      } catch (error) {
        console.log(error?.response?.data.statusCode);
        if (error?.response?.data?.statusCode == 404) {
          Notify({
            alert: true,
            type: "error",
            title: "Transaction Failed",
          });
          GetCartDetails()
          return;
        }
        Notify({
          alert: true,
          type: "error",
          title: "Something went wrong",
        });
      }
    },
    []
  );

  const FetchOrderDetails = () => {
    setLoading(true);
    API.GetOrderDetails(userDetails.user.ID, props.match.params.id)
      .then((res) => {
        const { RAZSTATUS, BILLNO, RAZPAY1 } = res.data[0];
        if (
          RAZSTATUS !== "COMPLETED" &&
          RAZSTATUS !== "FAILED" &&
          RAZPAY1.length
        ) {
          checkOrderStatus(userDetails.user.EMAILID, BILLNO, RAZPAY1);
        }
        setOrderDetails(res.data);
        let orders = res.data;
        let total = 0;
        let disc = 0;
        orders.map((item) => {
          total = total + parseFloat(item.AMOUNT);
          disc += parseFloat(item?.DISCAMT);
        });
        console.log("TOTAL -> ", total);
        FetchShippingChrg(total);
        setLoading(false);
        setDiscount(disc);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const reFetchOrderDetails = () => {
    setLoading(true);
    API.GetOrderDetails(userDetails.user.ID, props.match.params.id)
      .then((res) => {
        setOrderDetails(res.data);
        let orders = res.data;
        let total = 0;
        let disc = 0;
        orders.map((item) => {
          total = total + parseFloat(item.AMOUNT);
          disc += parseFloat(item?.DISCAMT);
        });
        console.log("TOTAL -> ", total);
        FetchShippingChrg(total);
        setLoading(false);
        setDiscount(disc);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const SendMessageNotification = (email, result) => {
    setLoading(true);
    API.GetNotification(email, result)
      .then((notification) => {
      return
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const GetCartDetails = () => {
    setLoading(true);
    API.GetCart(userDetails.user.ID)
      .then((res) => {
        console.log("get cart", res);
        dispatch(addToCart(res.data));
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  console.log("TOTOTAL", orderDetails);
  let taxes = (3 / 100) * totalAmount;
  let totalPrice = Math.round(totalAmount + taxes + SHIPPING_CHARGE);

  
  return (
    <div>
      {/* <a href=" https://wa.me/9320838762">
<img  src={whatsapp_btn} className="whatsapp-img" alt="" />
</a> */}
      <MetaTags>
        <title>My Orders</title>
        <meta name="description" content="Product page." />
      </MetaTags>
      <div>
        {/* <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>
          Home
        </BreadcrumbsItem>
        <BreadcrumbsItem to={process.env.PUBLIC_URL}>My Orders</BreadcrumbsItem> */}
      </div>

      {orderDetails.length ? (
        <LayoutOne headerTop="visible">
          {/* breadcrumb */}
          {/* <Breadcrumb /> */}
          <div className="container box-shadow mt-4 p-4">
            <div className="row">
              <div className="col-8">
                <></>
                <div>
                  <h3 className=" font-weight-bold">Delivery Address</h3>
                  <h5 className="custom_address_name">
                    {orderDetails[0]?.FNAME}
                  </h5>
                  <p className="text-muted">
                    {orderDetails[0]?.PAR_ADD1} , {orderDetails[0]?.PAR_ADD2}{" "}
                    {orderDetails[0]?.PAR_ADD3} ,-{orderDetails[0]?.PIN}
                  </p>
                </div>
                <div className="pt-2">
                  <h5 className="title-text-bold">Phone Number</h5>
                  <h5 className="text-muted">{orderDetails[0]?.MOBNO} </h5>
                </div>
              </div>
              <div className="col-4 border-left custom_print_invoice">
                <h3 className="title-text-bold">More Actions</h3>
                <p className="title-text">
                  <DownloadIcon /> Download Invoice
                </p>
                <p className="title-text">
                  <ArticleIcon /> Credit Info
                </p>
                <div className="btn btn-light btn-outline-dark custom_print_button">
                  Print
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-4 p-4 box-shadow">
            <div className="row">
              <div className="col-lg-5 col-12">
                {orderDetails.length &&
                  orderDetails?.map((item) => {
                    return (
                      <div className="row mt-4">
                        <div className="col-5">
                          <img
                            className="img-fluid border-round"
                            src={item.TIMG}
                            alt=""
                          />
                        </div>

                        <div className="col-7">
                          <div className="cart-item-variation-2 custom_orderdetails_data">
                            <span className="font-weight-bold title-text custom_orderID_name">
                              Order Id: {item?.BILLNO}
                            </span>

                            <div>
                              <h3 className="title-text-bold">{item.INAME}</h3>
                              <span className="text-muted">
                                Design ID: {item.DCODE}
                              </span>

                              <span className="text-muted">Size: 10</span>

                              <div className="text-muted">
                                Weight: {item.WEIGHT} Gms
                              </div>

                              <div className="text-muted">
                                Quantity : {item.QUANTITY}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              {console.log("orderDetails", orderDetails)}
              <div className="col-lg-7 col-12  text-center">
                {/* <ProgressBar status={orderDetails[0].STATUS} /> */}
                <CustomStepper status={orderDetails[0].STATUS} />
                <hr />
                <h5 className="font-weight-bold title-text">
                  {" "}
                  Your Item has been Delivered
                </h5>

                <div className="container ">
                  <div className="row custom_totalorder_row">
                    <div className=" p-4 mt-4 col-8 box-shadow">
                      <div className="row">
                        <div className="col-8 ">
                          <h5 className="font-weight-bold">
                            Total Order Price
                          </h5>
                          <div className="bg-light mt-2 p-2">
                            <h5 className="font-weight-bold Custom_contact_nomargin">
                              <AccountBalanceWalletIcon />
                              Paid By Net Banking
                            </h5>
                          </div>
                        </div>
                        <div className="col-4 text-center">
                          <h5
                            className="font-weight-bold "
                            style={{ color: "#212529" }}
                          >
                            {totalPrice}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container mb-4">
                    <div className="row custom_paymentinfo">
                      <div className="col-lg-8 col-12  box-shadow p-4 mt-4 payment-information-pannel">
                        <h5 className=" custom_payment_information">
                          Payment Information
                        </h5>

                        <table className="table custom_table_data">
                          <tbody>
                            {orderDetails.map((item) => {
                              return (
                                <tr>
                                  <td>
                                    {item.QUANTITY}x {item.INAME}
                                  </td>
                                  <td>₹{item.TXVAL}</td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td>Discount</td>
                              <td>-₹{Math.round(discount)}</td>
                            </tr>
                            <tr>
                              <td>Taxes(GST:3%)</td>
                              <td>₹{Math.round((3 / 100) * totalAmount)}</td>
                            </tr>
                            <tr>
                              <td>Shipping</td>
                              <td>₹ {SHIPPING_CHARGE}</td>
                            </tr>

                            <tr>
                              <td className="font-weight-bold">Total Paid</td>
                              <td className="font-weight-bold">
                                ₹ {totalPrice}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br></br>
          <br></br>
        </LayoutOne>
      ) : null}
    </div>
  );
}

export default OrderDetails;
