import React from "react";
import img12 from "../../assets/img/img12.jpeg";
import img13 from "../../assets/img/img13.jpeg";
import img14 from "../../assets/img/img14.jpeg";
import img8 from "../../assets/img/amyraHome/stayinloop.webp";
import { useSelector } from "react-redux";



import "./homePage.css";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
function Connect() {

  const mylanguage = useSelector((state) => state.language);


  const ConnectHeading = {
    English:"Find out first about our launches, exclusive offers and private pop-ups.",
    Hindi: "सबसे पहले हमारे लॉन्च, विशेष ऑफ़र और निजी पॉप-अप के बारे में जानें। ",
    Tamil: "எங்கள் அறிமுகங்கள், அந்தரங்க சலுகைகள் மற்றும் தனியாகப் பாப்-அப்கள் பற்றிய முதல் பரிந்துரையைக் கண்டுபிடிக்கவும்.",
    Telugu:"మా ప్రయాణాలు, ప్రతిష్టాత్మక ఆఫర్లు మరియు ప్రైవేట్ పాప్-అప్స్ గురించి మొదటిగా తెలుసుకోండి.",
    French: "Soyez les premiers informés de nos lancements, offres exclusives et événements privés.",
    Spanish: "Descubre primero sobre nuestros lanzamientos, ofertas exclusivas y eventos privados.",

  }


  const StayInLoop = {
    English:"Stay in loop",
    Hindi: "लूप में रहें",
    Tamil:"வரிசையில் இருங்கள்",
    Telugu:"లూప్లో ఉండండి",
    French:"Rester dans la boucle",
    Spanish:"Mantente en el bucle",
  }

  const EmailPlaceholder = {
    English:"Enter your email or whatsapp number to subscribe to our newsletter",
    Hindi: "हमारे न्यूज़लेटर की सदस्यता लेने के लिए अपना ईमेल या व्हाट्सएप नंबर दर्ज करें",
    Tamil:"எங்கள் செய்திச்செய்தி சந்திக்க உங்கள் மின்னஞ்சல் அல்லது வாட்ஸ்அப் எண்ணை உள்ளிடவும்",
    Telugu:"మా వార్తాపత్రికకు చేరడానికి మీ ఇమెయిల్ లేదా వాట్సాప్ సంఖ్య నమోదు చేయండి",
    French:"Entrez votre adresse e-mail ou votre numéro WhatsApp pour vous abonner à notre newsletter",
    Spanish: "Ingrese su correo electrónico o número de WhatsApp para suscribirse a nuestro boletín",

  }

  const newStayInLoop = StayInLoop[mylanguage] || StayInLoop["English"];
  const NewConnectHeading = ConnectHeading[mylanguage] || ConnectHeading['English'];
  const NewEmailPlaceholder = EmailPlaceholder[mylanguage] || EmailPlaceholder[mylanguage]


  return (
    <div className="container-fluid px-0 ">
      <div className="custom_inLoop"  style={{ backgroundImage: `url(${img8})` , backgroundSize:"cover"}}>
        <div className="row" style={{  }}>
          <div className="col-8"></div>
          <div className="col-2 text-right connect-img">
            {/* <img src={img12} height="60%" /> */}
          </div>
          <div className="col-2">
            {/* <img src={img13} height="60%" /> */}
          </div>
        </div>
        <div className="row">
          <div className="col-7 pl-5 ml-5 mt-4 text-white h4 font-weight-normal mb-5 pb-5 stay-in-loop-section">
            <h2 className="text-white subscribe-text">{NewConnectHeading}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-5  pl-5 ml-5 text-white  mb-5 h1 stay-in-loop-main">
          <h1 class="text-green2">{newStayInLoop}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-8 pl-5 ml-5 text-white  mb-5">
            <input
            style={{color:'white !important'}}
              className="connect-input text-white w-60"
              placeholder={NewEmailPlaceholder}
            />
            <ArrowRightAltIcon sx={{fontSize:40}}className="arrow-icon"/>
          </div>
        </div>
        <div className="row" >
          <div className="col-8"></div>
          <div className="col-2 text-right connect-img">
            {/* <img src={img14}  height="60%" /> */}
          </div>
          <div className="col-2">
          </div>
        </div>
      </div>
    </div>
  );
}

export default Connect;
