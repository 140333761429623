import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import makeAnimated from "react-select/animated";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../axios";
import Apilib from "../../utils/api";

import Dropdown from "react-bootstrap/Dropdown";
import Checkbox from "@mui/material/Checkbox";
import { selectedTopFilters } from "../../redux/actions/productAction";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";

const ShopTopAction = ({ selectProduct, selectAll }) => {
  const dispatch = useDispatch();
  const [plating, setPlating] = useState([]);
  const [colors, setColors] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [filters, setFilters] = useState({
    color: [],
    plating: [],
    lowerPrice: "",
    upperPrice: "",
    sortby: "",
  });
  const filters_top = useSelector((state) => state.allProducts.filters_top);
  const userDetails = useSelector((state) => state.userDetails);
  const [value, setValue] = React.useState([400, 1500]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPirce] = useState(1000);
  const API = new Apilib();
  const [pricearr, setPriceArr] = useState([]);

  useEffect(() => {
    API.FetchTopFilters()
      .then((res) => {
        console.log("top filters ", res);
        const filters = res.data;
        filters.map((filter) => {
          if (filter.NAME == "Color") {
            setColors(filter.VALUE.split(","));
          } else if (filter.NAME == "Plating") {
            setPlating(filter.VALUE.split(","));
          } else if (filter.NAME == "Price") {
            const prices = filter.VALUE.split(",");
            setMaxPirce(Number(prices[1]));
            setMinPrice(Number(prices[0]));
            setValue([Number(prices[0]), Number(prices[1])]);
          }
        });
        // setMaterial([...new Set(materialArr)]);
        // setColors([...new Set(colorsArr)]);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      selectAll(allProducts, e.target.checked);
    } else {
      selectAll([], e.target.checked);
    }
  };

  const handleFilters = (e, value) => {
    let tempFilters = { ...filters_top };
    const filterCategory = e.target.name;
    console.log("checked", e.target.value);
    if (e.target.checked) {
      switch (filterCategory) {
        case "price":
          const price = e.target.value.split(",").map(Number);
          console.log("price arr -> ", price);
          // temppricearr = [...tempFilters.price, ...price];
          let arr = [...pricearr, ...price];
          console.log("number", arr);
          setPriceArr(arr);

          tempFilters.lowerPrice = Math.min(...arr);
          tempFilters.upperPrice = Math.max(...arr);

          // tempFilters.price = [...price, ...tempFilters.price].sort(function (
          //   a,
          //   b
          // ) {
          //   return a - b;
          // });

          break;
        case "color":
          tempFilters.color.push(e.target.value);
          break;
        case "plating":
          tempFilters.plating.push(e.target.value);

          break;

        case "material":
          tempFilters.sortby = value;

          break;
        default:
          break;
      }
    } else {
      switch (filterCategory) {
        case "price":
          const price = e.target.value.split(",").map(Number);
          let arr = pricearr;
          let index = arr.indexOf(price[0]);
          if (index > -1) {
            arr.splice(index, 1);
          }
          index = arr.indexOf(price[1]);
          if (index > -1) {
            arr.splice(index, 1);
          }

          // arr.filter((price) => price == price[0]);
          // arr.filter((price) => price == price[1]);
          console.log("number arr", arr);
          setPriceArr(arr);
          if (arr.length) {
            tempFilters.lowerPrice = Math.min(...arr);
            tempFilters.upperPrice = Math.max(...arr);
          } else {
            tempFilters.lowerPrice = "";
            tempFilters.upperPrice = "";
          }

          // const lowIndex = tempFilters.price.indexOf(price[0]);
          // const upperIndex = tempFilters.price.indexOf(price[1]);
          // console.log("color working", lowIndex, upperIndex);

          // tempFilters.price.splice(lowIndex, 1);
          // tempFilters.price.splice(upperIndex, 1);

          break;
        case "color":
          const colorIndex = tempFilters.color.indexOf(e.target.value);
          tempFilters.color.splice(colorIndex, 1);

          break;
        case "plating":
          const platingIndex = tempFilters.plating.indexOf(e.target.value);
          tempFilters.plating.splice(platingIndex, 1);

          break;
        default:
          break;
      }
    }

    dispatch(selectedTopFilters(tempFilters));
    setFilters(tempFilters);
    // console.log("color filter", tempFilters);
  };

  const handlePriceChange = (event, newValue) => {
    let tempFilters = { ...filters_top };
    tempFilters.lowerPrice = newValue[0];
    tempFilters.upperPrice = newValue[1];

    dispatch(selectedTopFilters(tempFilters));
    setFilters(tempFilters);
    setValue(newValue);
  };

  function handleSortby(value) {
    let tempFilters = { ...filters_top };

    if (value == "Default") {
      tempFilters.sortby = "";
    } else {
      tempFilters.sortby = value;
    }
    dispatch(selectedTopFilters(tempFilters));
    setFilters(tempFilters);
  }
  // function handleClearAllFilters() {
  //   dispatch(
  //     selectedTopFilters({
  //       color: [],
  //       plating: [],
  //       lowerPrice: "",
  //       upperPrice: "",
  //       sortby: "CREATEDTIME desc",
  //       catgFilter: [],
  //     })
  //   );
  // }

  function handleClearAllFilters() {
    dispatch(
      selectedTopFilters({
        color: [],
        plating: [],
        lowerPrice: "",
        upperPrice: "",
        sortby: "CREATEDTIME desc",
        catgFilter: [],
      })
    );
    setPriceArr([]);
    setFilters({
      color: [],
      plating: [],
      lowerPrice: "",
      upperPrice: "",
      sortby: "",
    });
  }
  useEffect(() => {
    return () => {
      console.log("cleaned up");
      handleClearAllFilters();
    };
  }, []);
  return (
    <div className="row">
      <div className="col-12 mt-4 p-2 ">
        <div className="row  justify-content-center">
          <div className="shop-top-bar mb-35 pl-2">
            {userDetails && userDetails?.user !== null ? (
              <div className="col-2 shop-select justify-content-center">
                <Dropdown>
                  <Dropdown.Toggle
                    style={{
                      background: "transparent",
                      color: "black",
                      border: "none",
                      boxShadow: "none",
                    }}>
                    Price
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-menu-price">
                    {/* <div className="row">
                    <div col="6">
                      <input></input>
                    </div>
                    <div col="6">
                      <input></input>
                    </div>
                  </div> */}
                    {/* <Box
                  sx={{ width: 275, marginTop: "30px", marginBottom: "5px" }}
                > */}
                    {/* <div className="row">
                  <input
                    key={Math.random()}
                    type="checkbox"
                    className="filter-input checkbox-color"
                    name="color"
                    value={[0, 500]}
                    onChange={(e) => handleFilters(e)}
                    // checked={filters.color.includes(splitColor[0].trim())}
                  />
                  <label className="filter-label">below 500</label>
                </div> */}
                    {/* <Slider
                    value={value}
                    sx={{ marginLeft: "20px" }}
                    onChangeCommitted={handlePriceChange}
                    valueLabelDisplay="on"
                    min={minPrice}
                    max={maxPrice}
                  />
                  <div className="row  ml-4 ">
                    <div className="col-5">
                      <h4>Rs {value[0]}</h4>
                    </div>
                    <div className="col-2">
                      <h4>-</h4>
                    </div>
                    <div className="col-5">
                      {" "}
                      <h4>Rs {value[1]}</h4>
                    </div>
                  </div> */}
                    {/* </Box> */}
                    <Dropdown.Item className="dropdown-item-1">
                      <div className="row">
                        <input
                          type="checkbox"
                          key={1}
                          className="filter-input checkbox-color"
                          name="price"
                          value="1,500"
                          onChange={(e) => handleFilters(e)}
                          checked={
                            pricearr.includes(1) && pricearr.includes(500)
                          }
                        />
                        <label className="filter-label">Below ₹ 500</label>
                      </div>
                    </Dropdown.Item>

                    <Dropdown.Item className="dropdown-item-1">
                      <div className="row">
                        <input
                          key={2}
                          type="checkbox"
                          className="filter-input"
                          name="price"
                          value="501,1000"
                          onChange={(e) => handleFilters(e)}
                          checked={
                            pricearr.includes(501) && pricearr.includes(1000)
                          }
                        />
                        <label className="filter-label">₹ 501 - ₹ 1000</label>
                      </div>
                    </Dropdown.Item>

                    <Dropdown.Item className="dropdown-item-1">
                      <div className="row">
                        <input
                          type="checkbox"
                          className="filter-input"
                          name="price"
                          value="1001,2000"
                          onChange={(e) => handleFilters(e)}
                          key={3}
                          checked={
                            pricearr.includes(1001) && pricearr.includes(2000)
                          }
                        />
                        <label className="filter-label">₹ 1001 - ₹ 2000</label>
                      </div>
                    </Dropdown.Item>

                    <Dropdown.Item className="dropdown-item-1">
                      <div className="row">
                        <input
                          type="checkbox"
                          className="filter-input"
                          name="price"
                          value="2001,3000"
                          onChange={(e) => handleFilters(e)}
                          key={4}
                          checked={
                            pricearr.includes(2001) && pricearr.includes(3000)
                          }
                        />
                        <label className="filter-label">₹ 2001 - ₹ 3000</label>
                      </div>
                    </Dropdown.Item>

                    <Dropdown.Item className="dropdown-item-1">
                      <div className="row">
                        <input
                          type="checkbox"
                          className="filter-input"
                          name="price"
                          value="3000,50000"
                          onChange={(e) => handleFilters(e)}
                          key={5}
                          checked={
                            pricearr.includes(3000) && pricearr.includes(50000)
                          }
                        />
                        <label className="filter-label">₹ 3000 AND ABOVE</label>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ) : null}
            <div className="col-3 shop-select justify-content-center">
              <Dropdown>
                <Dropdown.Toggle
                  style={{
                    background: "transparent",
                    color: "black",
                    border: "none",
                    boxShadow: "none",
                  }}>
                  Colors
                </Dropdown.Toggle>

                <Dropdown.Menu className="custom_colorfiltermenu">
                  {colors.map((color, index) => {
                    let splitColor = color.split("~");
                    console.log("split color", splitColor);
                    return (
                      <div className="custom_dropdow_list">
                        <Dropdown.Item className="dropdown-item-1">
                          <div className="row">
                            <input
                              key={Math.random()}
                              type="checkbox"
                              className="filter-input"
                              name="color"
                              value={splitColor[0].trim()}
                              onChange={(e) => handleFilters(e)}
                              checked={filters.color.includes(
                                splitColor[0].trim()
                              )}
                            />
                            <label className="filter-label">
                              {splitColor[1].trim()}
                            </label>
                          </div>
                        </Dropdown.Item>
                      </div>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>{" "}
            <div className="col-3 shop-select justify-content-center">
              <Dropdown>
                <Dropdown.Toggle
                  style={{
                    background: "transparent",
                    color: "black",
                    border: "none",
                    boxShadow: "none",
                  }}>
                  Plating
                </Dropdown.Toggle>

                <Dropdown.Menu className="custom_colorfiltermenu">
                  {plating.map((val) => {
                    let plating = val.split("~");

                    return (
                      <Dropdown.Item className="dropdown-item-1">
                        <div className="row">
                          <input
                            key={Math.random()}
                            type="checkbox"
                            className="filter-input"
                            name="plating"
                            value={plating[0].trim()}
                            onChange={(e) => handleFilters(e)}
                            checked={filters.plating.includes(
                              plating[0].trim()
                            )}
                          />
                          <label className="filter-label" value={val}>
                            {plating[1].trim()}
                          </label>
                        </div>
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>{" "}
            <div className="col-3 shop-select justify-content-center">
              <Dropdown>
                <Dropdown.Toggle
                  style={{
                    background: "transparent",
                    color: "black",
                    border: "none",
                    boxShadow: "none",
                  }}>
                  Sort By
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {/* <Dropdown.Item>Price -High to Low</Dropdown.Item>

                  <Dropdown.Item>Price - Low to High</Dropdown.Item> */}

                  <Dropdown.Item
                    onClick={() => handleSortby("CREATEDTIME desc")}
                    name="sortby">
                    Date - New to Old
                  </Dropdown.Item>

                  <Dropdown.Item
                    onClick={() => handleSortby("Createdtime asc")}>
                    Date - Old to New
                  </Dropdown.Item>

                  <Dropdown.Item onClick={() => handleSortby("Default")}>
                    Default
                  </Dropdown.Item>
                  {/* <Dropdown.Item>Popularity</Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div
              onClick={() => handleClearAllFilters()}
              // style={{ position: "absolute", right: "1em", bottom: "34px" }}
              className="custom_search_clearbtn btn col-3">
              {" "}
              <h3>Clear Filter</h3>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row justify-content-end" style={{ marginTop: 10 }}>
          <Checkbox
            edge="start"
            // checked={checked.indexOf(value) !== -1}
            tabIndex={-1}
            disableRipple
            size="small"
            className="checkbox-color"
            onChange={(e) => handleSelectAll(e)}
          />
          <p className="ml-2 h-5 select-all" onClick={() => selectProduct()}>
            Select All
          </p>
        </div> */}
    </div>
  );
};

ShopTopAction.propTypes = {
  getFilterSortParams: PropTypes.func,
  getLayout: PropTypes.func,
  productCount: PropTypes.number,
  sortedProductCount: PropTypes.number,
};

export default ShopTopAction;
