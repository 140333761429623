import { ActionTypes } from "../constants/action-type";

const initialState = {
  user: null,
};
export const UserInformationReducer = (state = initialState, { type, payload }) => {
  console.log("user payload Checkkaro", type , payload)
  switch (type) {
    case ActionTypes.SET_USERINFORMATION:
      return { ...state, user: payload };
    default:
      return state;
  }
};
