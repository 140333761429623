import { ActionTypes } from "../constants/action-type";

const initialState = {
  categoryData: {
    
  },
};
export const CategoryReducer = (state = initialState, { type, payload }) => {
  console.log("cart payload", type, payload);
  switch (type) {
    case ActionTypes.SELECTED_CATEGORY:
      return { ...state, categoryData: payload };
    default:
      return state;
  }
};
