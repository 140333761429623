 import { useState } from "react";
import {  IconButton } from "@material-ui/core";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import * as React from 'react';
import {NavLink} from 'react-router-dom';
import UserNameBtn from "./UserNameBtn";

import Drawer from '@mui/material/Drawer';
import { useSelector,useDispatch } from "react-redux";
import setUser from "../../redux/actions/userActions";
import {useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import LanguageModel from "../../components/header/sub-components/LanguageModel";


export default function TemporaryDrawer() {


  const mylanguage = useSelector((state) => state.language);


  const NavbarHome = {
    English:"Home",
Hindi: "होम",
Tamil: "வீடு",
Telugu: "ఇంటి",
French: "Accueil",
Spanish: "Inicio",
  }

  const ShopHome = {
English:"Shop",
Hindi: "शॉप",
Tamil: "ஷாப்",
Telugu: "షాపు",
French: "Magasin",
Spanish: "Tienda",
  }

  const CollectionHome = {
 English:"Collection",
 Hindi: "कलेक्शन",
 Tamil: "சேகரிப்பு",
 Telugu: "సంగ్రహం",
 French: "Collection",
 Spanish: "Colección",
  }

  const AboutusHome = {
    English:"About Us",
Hindi: "हमारे बारे में",
Tamil: "எங்கள் பற்றி",
Telugu: "మా గురించి",
French:" À proposdenous",
Spanish: "Sobrenosotros",
  }

  const ContactUsHome = {
English:"Contact Us",
Hindi: "हमसे संपर्क करें",
Tamil: "தொடர்பு கொள்ளவும்", 
Telugu: "మనకు సంప్రదించండి", 
French: "Contactez-nous",
Spanish: "Contáctenos",
  }

  const MyAccount = {
    English:'My Account',
Hindi: "माई अकाउंट",
Spanish: "Mi cuenta",
Tamil: "என் கணக்கு",
Telugu: "నా ఖాతా",
French: "Mon compte",

  }

  const My_Orders = {
    English:'My Orders',
    Hindi: "माई ऑर्डर्स",
Spanish: "Mis pedidos",
Tamil: "எனது ஆர்டர்கள்",
Telugu: "నా ఆర్డర్లు",
French: "Mes commandes",


  }


  const NewnavbarHome = NavbarHome[mylanguage] 
  const NewnavbarShop = ShopHome[mylanguage]
  const NewnavbarCollection = CollectionHome[mylanguage]
  const newAboutus = AboutusHome[mylanguage]
  const newContactus = ContactUsHome[mylanguage]
 const newMyAccount =  MyAccount[mylanguage]
 const newMy_Orders = My_Orders[mylanguage]


  
const userDetails = useSelector((state) => state?.userDetails?.user);
const [open,setOpen] = useState(false);
const dispatch = useDispatch();
const [authenticatedUser, setAuthenticatedUser] = useState(false);
const { addToast } = useToasts();
const history = useHistory();


function userLogout(e) {
  e.preventDefault();
  // setLoading(true);
  localStorage.removeItem("amyra_email");
  dispatch(setUser(null));
  setAuthenticatedUser(false);
  addToast("User logged out successfully", {
    appearance: "success",
    autoDismiss: true,
  });
  history.push("/");
  window.location.reload(true);
}

  return (
    <div>
     <IconButton onClick={() => setOpen(true)} >
        <MenuRoundedIcon/>
     </IconButton>

     <Drawer anchor={"right"} open={open} onClose={() => setOpen(false)}>
     {/* UserName */}
          <UserNameBtn/>
          <div className="drawer-div" >
          
          {/* <div style={{display:"flex",padding:"0px 10px"}} >
              <LanguageModel/>
          </div> */}
        
            <NavLink to="/" exact  activeClassName="active-link" >
              <p>{NewnavbarHome} </p>
            </NavLink>

            <NavLink to="/products" activeClassName="active-link" >
            <p>{NewnavbarShop} </p>
            </NavLink>

            <NavLink to="/collection" activeClassName="active-link">
            <p>{NewnavbarCollection}</p>
            </NavLink>

            <NavLink to="/about" activeClassName="active-link">
            <p>{newAboutus}</p>
            </NavLink>
            
            <NavLink to="/contact" activeClassName="active-link">
            <p>{newContactus}</p>
            </NavLink>

            {userDetails && <NavLink to="/account" activeClassName="active-link">
            <p>{newMyAccount}</p>
            </NavLink>}

            {userDetails && <NavLink to="/MyOrders" activeClassName="active-link">
            <p>{newMy_Orders}</p>
            </NavLink>}

            {userDetails && 
              <button className="logout-button" onClick={(e) => userLogout(e)} >Logout</button>
            }
          </div>

     </Drawer>
    </div>
  );
}