import React, { Fragment, useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../../layouts/LayoutOne";
import ShopTopbar from "./ShopTopbar";
import ProductListingProducts from "./ProductListingProducts";
import "./plp.css";
import removeProducts from "../../utils/constants";
import { useSelector, useDispatch } from "react-redux";
import { search } from "../../redux/actions/productAction";

const ProductListing = ({ location, products }) => {

  const [allProducts, setAllProducts] = useState(products);
  const [layout, setLayout] = useState("grid three-column");
  const [offset, setOffset] = useState(0);
  const [currentData, setCurrentData] = useState([]);
  const [selectProduct1, setSelectProduct] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const pageLimit = 15;
  const { pathname, state } = location;
  const loading = useSelector((state) => state.allProducts.loading);
  const productsList = useSelector((state) => state.allProducts.products);
  const dispatch = useDispatch();

  const getLayout = (layout) => {
    setLayout(layout);
  };

  

  console.log(state.detail, typeof state.detail, "NeedValue");
  const deleteProduct = (id) => {
    console.log("prdId=>", id);
    const remainingProducts = removeProducts(selectedProducts, "DCODE", id);
    console.log("remaining", remainingProducts);
    setSelectedProducts([...remainingProducts]);
    console.log("productsAfter deletion=>", selectedProducts);
  };
  useEffect(() => {
    //your code goes here
    return () => {
      //your cleanup code codes here
      dispatch(search(""));
    };
  }, [dispatch]);

  return (
    <Fragment>
      <MetaTags>
        <title>Amyraa | Shop Page</title>
        <meta
          name="description"
          content="Shop page of flone react minimalist eCommerce template."
        />
      </MetaTags>

      {/* <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>
        Search
      </BreadcrumbsItem> */}

      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        {/* <Breadcrumb /> */}

        <div className="shop-area pt-25 pb-25">
          <div className="container">
            {productsList.length ? (
              <div className="row ">
                <div className="col-12 text-right mr-4">
                  {productsList.length} Products
                </div>
              </div>
            ) : null}
            <div className="row">
              {/* <div className="col-lg-3 order-2 order-lg-1 border-filter">
                <ProductListingFilters
                  products={products}
                  // getSortParams={getSortParams}
                  sideSpaceClass="mr-30"
                />
              </div> */}
              <div className="col-lg-12 order-1 order-lg-2 scroll-max-products">
                {/* shop topbar default */}
                <div className="row justify-content-center">
                  {!state.detail == "" ? (
                    <>
                      <h2></h2>
                      <h2>{state.detail.toUpperCase()}</h2>
                    </>
                  ) : null}
                </div>
                {/* Search functionality bug solve by Syed */}
                {productsList && productsList.length > 0 ? (
                  <ShopTopbar
                    getLayout={getLayout}
                    products={products}
                    selectAll={(products, checked) => {
                      setSelectedProducts([...products]);
                      setSelectAll(checked);
                    }} // getFilterSortParams={getFilterSortParams}
                    // productCount={products.length}
                    // sortedProductCount={currentData.length}
                    selectProduct={() => setSelectProduct(true)}
                  />
                ) : null}
                {/* shop page content default */}
                <ProductListingProducts
                  layout={layout}
                  products={currentData}
                  selectedProducts={(products, checked) =>
                    checked
                      ? setSelectedProducts([...selectedProducts, products])
                      : deleteProduct(products.DCODE)
                  }
                  selectedProductsArr={selectedProducts}
                  checkAll={selectAll}
                />
                {/* {selectedProducts.length !== 0 ? (
                  <BottomBar
                    newSelectedProducts={(prdArr) =>
                      setSelectedProducts([...prdArr])
                    }
                    selectedProducts={selectedProducts}
                  />
                ) : null}{" "} */}
                {/* shop product pagination */}
                {/* <div className="pro-pagination-style text-center mt-30">
                  <Paginator
                    totalRecords={sortedProducts.length}
                    pageLimit={pageLimit}
                    pageNeighbours={2}
                    setOffset={setOffset}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageContainerClass="mb-0 mt-0"
                    pagePrevText="«"
                    pageNextText="»"
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default ProductListing;
