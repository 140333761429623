import React from "react";
import { Link } from "react-router-dom";
import MenuCart from "./sub-components/MenuCart";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useSelector } from "react-redux";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import mixpanel from "mixpanel-browser";


const IconGroup = ({
  currency,
  cartData,
  wishlistData,
  compareData,
  deleteFromCart,
  iconWhiteClass,
}) => {
  function MenuPopupState() {
    return (
      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <NotificationsNoneIcon
              className="header-icons"
              {...bindTrigger(popupState)}
            />

            <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={popupState.close}>Profile</MenuItem>
              <MenuItem onClick={popupState.close}>My account</MenuItem>
              <MenuItem onClick={popupState.close}>Logout</MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    );
  }

  // const [listenerActive, setListenerActive] = useState(false);
  // const [anchorEl, setAnchorEl] = React.useState(null);

  // const handleClick = (event) => {
  //   setAnchorEl(anchorEl ? null : event.currentTarget);
  // };

  // const open = Boolean(anchorEl);
  // const id = open ? "simple-popper" : undefined;
//
  const CheckOnCartButton = () => {
    mixpanel.track("ViewCart");  // MixPannel ka code
  }

  const ClickOnWishList = () => {
    mixpanel.track("Wishlist");  // MixPannel ka code
  }
//
  const handleClick = (e) => {
   // setListenerActive(true);
    // e.currentTarget.nextSibling.classList.toggle("active");
  CheckOnCartButton()
  const cart = e.currentTarget.nextSibling;
  cart.classList.toggle("active");
  // Remove the "active" class after 2 seconds
  // Bug Solve By Syed - If The Cart is empty 
  if(cartItems.length <= 0){
  setTimeout(() => {
    cart.classList.remove("active");
  }, 3000);
}
  };

  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add("active");
  };

  const cartItems = useSelector((state) => state.cart.cartItems);
  const wishlistItems = useSelector((state) => state.wishlist.wishlistItems);

  return (
    <div
    
      className={`header-right-wrap ${iconWhiteClass ? iconWhiteClass : ""}`}>
      
      <div className="same-style header-wishlist">
        <NotificationsNoneIcon className="header-icons" />
      </div>
      {/* <div className="same-style header-wishlist">
        <MenuPopupState className="header-icons" />
      </div> */}
      <div className="same-style header-wishlist">
        <Link to={process.env.PUBLIC_URL + "/wishlist"} >
          <FavoriteBorderOutlinedIcon className="header-icons" onClick={ClickOnWishList} />
          {/* <span className="count-style">
            {wishlistData && wishlistData.length ? wishlistData.length : 0}
          </span> */}
          <span class="count-style custom_count">{wishlistItems.length}</span>
        </Link>
      </div>

      <div className="same-style cart-wrap d-lg-block">
        <button
          className="icon-cart customnumbercart"
          onClick={(e) => handleClick(e)}>
          <LocalMallOutlinedIcon className="header-icons" />
          {/* <span className="count-style">
            {cartData && cartData.length ? cartData.length : 0}
          </span> */}
          <span class="count-style custom_count">{cartItems.length}</span>
        </button>

        {/* menu cart */}
        {/* <Popper id={id} open={open} anchorEl={anchorEl}> */}
        <MenuCart
          cartData={cartData}
          currency={currency}
          deleteFromCart={deleteFromCart}
          // listenerActive={listenerActive}
          // setListenerActive={setListenerActive}
        />
        {/* </Popper> */}
      </div>
      <div className="same-style cart-wrap d-block d-lg-none">
        <Link className="icon-cart" to={process.env.PUBLIC_URL + "/cart"}>
        
          {/* <i className="pe-7s-shopbag" /> */}
          {/* <span className="count-style"> */}
            {/* {cartData && cartData.length ? cartData.length : 0} */}
          {/* </span> */}

        </Link>
      </div>
      <div className="same-style mobile-off-canvas d-block d-lg-none">
        <button
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}>
          <i className="pe-7s-menu" />
        </button>
      </div>
    </div>
  );
};

export default IconGroup;
