// This Component Developed By Syed - That Contain Username inside drawer
import { findLastKey } from "lodash";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import IconGroup from "../../components/header/IconGroup";
import { useToasts } from "react-toast-notifications";
import { Link, useHistory } from "react-router-dom";
// import "./header.css";
import Loader from "../../helpers/Loader";
import { useSelector, useDispatch } from "react-redux";
import setUser from "../../redux/actions/userActions";
import { setLocale } from "yup";
import addToCart from "../../redux/actions/cartActions";
import ApiLib from "../../utils/api";
import NoProfile from "../../assets/img/no-profile.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import avatar from "../../assets/img/avatar.png";
import amyraaLogo from "../../assets/img/amyraa_logo.png";

const UserNameBtn = ({ borderStyle }) => {
  const [toggle, setToggle] = useState(false);
  const [authenticatedUser, setAuthenticatedUser] = useState(false);
  const userDetails = useSelector((state) => state?.userDetails?.user);
  const [bannerText, setBannerText] = useState("");
  const { addToast } = useToasts();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const API = new ApiLib();


  console.log(userDetails,"userdetaisluserdetails")

  useEffect(() => {
    userDetails && setAuthenticatedUser(true);
    console.log({ userDetails });
  }, [userDetails]);

  

  console.log(userDetails, "UserDetails");

  useEffect(() => {
    console.log("header top working");
    API.BannerText()
      .then((res) => {
        console.log("banner text", res);
        setBannerText(res.data[0].BANNERLINE);
      })
      .catch(() => {});
    //eslint-disable-next-line
  }, []);

  const GetCartDetails = () => {
    setLoading(true);
    API.GetCart(userDetails.ID)
      .then((res) => {
        console.log("get cart", res);
        dispatch(addToCart(res.data));
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    localStorage.getItem("amyra_email") && setAuthenticatedUser(true);
    // eslint-disable-next-line
  }, [localStorage]);

  function userLogout(e) {
    e.preventDefault();
    // setLoading(true);
    localStorage.removeItem("amyra_email");
    dispatch(setUser(null));
    setAuthenticatedUser(false);
    addToast("User logged out successfully", {
      appearance: "success",
      autoDismiss: true,
    });
    history.push("/");
    window.location.reload(true);

    // setLoading(false);
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <div
        className={`header-top-wap ${
          borderStyle === "fluid-border" ? "border-bottom" : ""
        }`}
      >

        {/* <div className="group-icons custom_group-icons">
          {authenticatedUser ? <IconGroup /> : null}{" "}
        </div> */}
        {authenticatedUser ? (
          <div
            className=""
            style={{
              display: "flex",
              borderLeft: "1px solid lightgray",
              paddingLeft: "5vw",
            }}
          >
            <div className="my-auto customusername username-in-drawer">
            <p> Hi,{userDetails?.FNAME}</p>
             
            </div>
            
            {/* <div
              className=" avatar dropdown user-profile"
              style={{ textAlign: "center !important" }}
            >
              <Link
                className="nav-link dropdown-toggle p-0"
                onClick={() => setToggle(!toggle)}
              >
                <img
                  style={{
                    // marginLeft: 10,
                    width: "60%",
                    height: 40,
                    marginLeft: 10,
                  }}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = amyraaLogo;
                  }}
                  src={userDetails?.USERIMG}
                  className="rounded-circle z-depth-0 img-responsive"
                  alt="avatar"
                />
              </Link>
              <div
                className={
                  toggle
                    ? "dropdown-menu dropdown-menu-lg-right dropdown show"
                    : "dropdown-menu dropdown-menu-lg-right dropdown"
                }
                aria-labelledby="navbarDropdownMenuLink-saju"
               > 

                <Link className="dropdown-item" to="/account">
                  My Account
                </Link>
                <Link className="dropdown-item" to="/MyOrders">
                  My Orders
                </Link>
                {/* <Link className="dropdown-item" to="/changepassword">
                  Change Password
                </Link> */}
                {/* <Link className="dropdown-item" onClick={(e) => userLogout(e)}>
                  Logout
                </Link> */}
                
              {/* </div> */}
            {/* </div> */ }

            
          </div>
        ) : (
          <div className="row" style={{ marginRight: -61 }}>
            {/* <div className="login-btn ">
              <Link to="/login">Login</Link>
            </div>
            <div className="Register-btn">
              <Link to="/register">Register</Link>
            </div> */}
          </div>
        )}
        {/* <UserDropdown/> */}
        <div></div>
      </div>
    </>
  );
};
export default UserNameBtn;
