import React, { useState } from 'react'
import { useEffect } from 'react';
import { MetaTags } from 'react-meta-tags';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { BillDeskApi } from '../../utils/api';
import { useLayoutEffect } from 'react';

const BillDeskSdk = () => {
    const PAYMENTAPI = new BillDeskApi();
    const [sdkLoaded, setSdkLoaded] = useState(false)
    const {merchId,authToken,bdOrderId}=useParams()
  
    useEffect(() => {
        if (sdkLoaded) {
          // SDK is already loaded, nothing to do
          return;
        }
    
        // Dynamically create script elements
        const scriptEsm = document.createElement("script");
        scriptEsm.src =
          "https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk/billdesksdk.esm.js";
        scriptEsm.type = "module";
    
        const scriptNomodule = document.createElement("script");
        scriptNomodule.src =
          "https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk/billdesksdk.js";
        scriptNomodule.setAttribute("nomodule", "");
    
        const scriptJquery = document.createElement("script");
        scriptJquery.src = "https://code.jquery.com/jquery-latest.min.js";
    
        // Dynamically create link element for CSS
        const linkCss = document.createElement("link");
        linkCss.href =
          "https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk/billdesksdk.css";
        linkCss.rel = "stylesheet";
    
        // Load scripts and CSS
        document.body.appendChild(scriptEsm);
        document.body.appendChild(scriptNomodule);
        document.body.appendChild(scriptJquery);
        document.head.appendChild(linkCss);
    
        // Set up onload handlers to mark the SDK as loaded
        scriptEsm.onload =
          scriptNomodule.onload =
          scriptJquery.onload =
            () => {
              setSdkLoaded(true);
            };
    
        return () => {
          // Cleanup if needed
          scriptEsm.onload = scriptNomodule.onload = scriptJquery.onload = null;
          document.body.removeChild(scriptEsm);
          document.body.removeChild(scriptNomodule);
          document.body.removeChild(scriptJquery);
          document.head.removeChild(linkCss);
        };
      }, [sdkLoaded]);

  
        const flowConfig = {
          merchantId: "VIVAHUAT",
          bdOrderId: "OA8Z19XTQBUGOB",
          authToken: "OToken 5a2a3e54bf7c16042267f077ad563a58c8321f85afcb27f28b5152a508298ac87087a3ba3c6d9d3697953e84357d39428c21268203fc4273ac9a6e807b925f125da20dfe45c3687b9a627058521fc0ccc0db828863f961ba46bc3ca70a45e3b848014a46f42632231a00f2238a2726929f847204295d3e7db88ba52156fa8be7b9e4637754a8e3925750547bdee6d04c151bedd1f28f06d344.4145535f55415431",
          childWindow: true,
          retryCount: 3,
          netBanking: {
            showPopularBanks: "Y",
          },
        };
    
        const responseHandler = (response) => {
          console.log("callback received status:", response.status);
          console.log("callback received response:", response.txnResponse);
        };
    
        const themeConfig = {
          sdkPrimaryColor: "#69068a",
          sdkAccentColor: "#cf5df5",
          sdkBackgroundColor: "#f2caff",
          sdkBannerColor: "#982cbb",
        };
    
        const config = {
          responseHandler,
          flowConfig,
          themeConfig,
          flowType: "payments",
        };
    
    

  return (
    <>
    <MetaTags>
    <title>Amyraa | Payment</title>
    <script
      type="module"
      src="https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk/billdesksdk.esm.js"
    ></script>
    <script
      nomodule=""
      src="https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk.js"
    ></script>
    <script src="https://code.jquery.com/jquery-latest.min.js"></script>
    <link
      href="https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk/billdesksdk.css"
      rel="stylesheet"
    ></link>
  </MetaTags>
    <form
            name="pgresponse"
            id="pgresponse"
            action=""
            method="POST"
            hidden
          >
            <input type="hidden" id="encodedres" name="encodedres" />
          </form>
    </>
  )
}

export default BillDeskSdk