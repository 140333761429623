import React, {useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import ProductDetailImage from "./ProductDetailImage";
import ProductDetailDescription from "./ProductDetailDescription";
import axios from "../../axios";
import { useSelector } from "react-redux";
import mixpanel from "mixpanel-browser";


const ProductDetailContent = (props) => {
  const productDetails = useSelector((state) => state.allProducts.product);
  const userDetails = useSelector((state) => state.userDetails);
  const { addToast } = useToasts();
  const baseDesign = props.props.match.params.BASEDESIGN;
  const dcode = props.props.match.params.DCODE;
  const [productInfo, setProductInfo] = useState();
  const [allVariant, setAllVariant] = useState([]);
  console.log("product info==> ", productInfo);
  useEffect(() => {
    FetchProductInfo();
    //eslint-disable-next-line
  }, [props]);
  const FetchProductInfo = () => {
    let userId;
    if (userDetails?.user?.ID) {
      userId = userDetails?.user?.ID;
      // Mix Pannel Code
      mixpanel.track("PDP_<ProductID>", { 
        DesignId: JSON.stringify(dcode),
        EmailID: JSON.stringify(userDetails.user.EMAILID)
      }); // Mix Panel Code
    } else {
      userId = "0";
    }
    let data = {
      basedesign: baseDesign,
      dcode: dcode,
      userid: userId,
    };
    data = JSON.stringify(data);
   
    console.log("userdetailsssss----> ", userDetails);
    axios
      .get(`getdesign.aspx?type=D&cnd=${[data]}`)
      .then((res) => {
        const product = res.data;
        setProductInfo(product[0]);
        setAllVariant(product);
        console.log("product-->", product);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  console.log("product", productInfo);
  return (
    <div
      className={`shop-area ${props.spaceTopClass ? props.spaceTopClass : ""} ${
        props.spaceBottomClass ? props.spaceBottomClass : ""
      }`}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-4">
            {/* product image gallery */}
            <ProductDetailImage product={productInfo} />
          </div>
          <div className="col-lg-8 col-md-8">
            {/* product description info */}

            <ProductDetailDescription
              variants={allVariant}
              productInfo={productInfo}
              changeProduct={(e, index) => {
                setProductInfo(allVariant[index]);
                console.log(
                  "product info 2",
                  allVariant[index],
                  allVariant,
                  index
                );
              }}
              // product={product}
              // discountedPrice={discountedPrice}
              // currency={currency}
              // finalDiscountedPrice={finalDiscountedPrice}
              // finalProductPrice={finalProductPrice}
              // cartItems={cartItems}
              // wishlistItem={wishlistItem}
              // compareItem={compareItem}
              // addToast={addToast}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailContent;
