import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useSelector, useDispatch } from "react-redux";
import ApiLib from "../../../utils/api";
import { setProduct } from "../../../redux/actions/productAction";
import addToCart from "../../../redux/actions/cartActions";
import SweetAlert from "react-bootstrap-sweetalert";
import mixpanel from "mixpanel-browser";


const MenuCart = ({
  cartData,
  currency,
  deleteFromCart,
  listenerActive,
  setListenerActive,
}) => {
  const userDetails = useSelector((state) => state.userDetails);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const [totalAmount, setTotalAmount] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [itemDetails, setItemDetails] = useState();
  const API = new ApiLib();
  const [deleteItem, setDeleteItem] = useState(false);
  const { addToast } = useToasts();
  const ref = useRef();

  const ProductSelected = (item) => {
    dispatch(setProduct(item));
    history.push("/product");
  };
  useEffect(() => {
    if (cartItems.length) {
      let total = 0;
      cartItems.map((item) => {
        total = total + Number(item.AMOUNT) + Number(item.DISCAMT);
      });
      setTotalAmount(Math.round(total));
      // console.log(cartItems.length,"hiiiiiiiiiii");
    }
  }, [cartItems]);

  console.log("cart items", cartItems);

  const DeleteCartItems = (ser_no) => {
    console.log(ser_no);
    setLoading(true);
    let data = {
      ser_no: itemDetails,
      userid: userDetails.user.ID,
    };
    API.DeleteCart(data)
      .then((res) => {
        setLoading(false);
        console.log(res);
        if (res.data[0].result == 1) {
          addToast("Item deleted from cart successfully ", {
            appearance: "success",
            autoDismiss: true,
          });
          setDeleteItem(false);
          GetCartDetails();
        } else {
          addToast("Something went wrong please try again", {
            appearance: "error",
            autoDismiss: true,
          });
          setDeleteItem(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        addToast("Something went wrong please try again", {
          appearance: "error",
          autoDismiss: true,
        });
        setDeleteItem(false);
      });
  };

  const GetCartDetails = () => {
    setLoading(true);
    API.GetCart(userDetails.user.ID)
      .then((res) => {
        console.log("get cart", res);
        dispatch(addToCart(res.data));
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     se(true);
  //   }, 500);
  // }, []);

  // console.log("e.target", listenerActive);


  const itemDeleteFromCart = (item) => {
    mixpanel.track("ItemDeleteFromCart", {DesignId: JSON.stringify(item)});  // MixPannel ka code
  }

  const ViewCart = () => {
    mixpanel.track("View Cart");
  }


  return (
    <div className="shopping-cart-content" ref={ref}>
      {deleteItem ? (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Confirm"
          confirmBtnBsStyle="danger"
          title="Are you sure you want to remove this product?"
          onConfirm={DeleteCartItems}
          onCancel={() => setDeleteItem(false)}
          focusCancelBtn
        ></SweetAlert>
      ) : null}
      <div
        style={{ textAlign: "right", userSelect: "none", cursor: "pointer" }}
        onClick={() => {
          console.log("currentRef", ref.current.classList.toggle("active"));
        }}
      >
        <div>X</div>
      </div>
      {/* {cartData && cartData.length > 0 ? ( */}
      {cartItems.length ? (
        <Fragment>
          <ul>
            {cartItems.map((item, key) => {
              return (
                <li className="single-shopping-cart" key={key}>
                  <div className="shopping-cart-img">
                    <Link
                      to={
                        process.env.PUBLIC_URL +
                        "/product/" +
                        item.BASEDESIGN +
                        "/" +
                        item.DCODE
                      }
                    >
                      <img alt="" src={item?.TIMG} className="img-fluid" />
                    </Link>
                  </div>
                  <div className="shopping-cart-title">
                    <h4>
                      <Link
                        to={
                          process.env.PUBLIC_URL +
                          "/product/" +
                          item.BASEDESIGN +
                          "/" +
                          item.DCODE
                        }
                      >
                        {" "}
                        {item.DCODE}
                      </Link>
                    </h4>
                    <h6>Qty: {item.QUANTITY} </h6>
                    <span>
                      ₹ {Math.round(Number(item.AMOUNT) + Number(item.DISCAMT))}{" "}
                    </span>
                    <div className="">
                      {/* <button onClick={() => deleteFromCart(single, addToast)}> */}
                      <button
                        className="custom_headerdeletebtn"
                        onClick={() => {
                          setDeleteItem(true);
                          setItemDetails(item.SER_NO);
                          itemDeleteFromCart(item.DCODE)
                        }}
                      >
                        {/* <i className="fa fa-times-circle" /> */}
                        Delete
                      </button>
                    </div>
                    {/* <div className="cart-item-variation">
                      <span>Size: 3 </span>
                    </div> */}
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="shopping-cart-total">
            <h4>
              Total : <span className="shop-total">₹ {totalAmount}</span>
            </h4>
          </div>
          <div className="shopping-cart-btn btn-hover text-center">
            <Link className="default-btn" to={process.env.PUBLIC_URL + "/cart"} onClick={ViewCart} >
              view cart
            </Link>
            <Link
              className="default-btn"
              to={process.env.PUBLIC_URL + "/checkout"}
            >
              checkout
            </Link>
          </div>
        </Fragment>
      ) : (
        <p className="text-center">Your cart is empty</p>
      )}
    </div>
  );
};

MenuCart.propTypes = {
  cartData: PropTypes.array,
  currency: PropTypes.object,
  deleteFromCart: PropTypes.func,
};

export default MenuCart;
