import React, { useState, useEffect } from "react";
import "./account.css";
import AddAddress from "./AddAddress";
import EditAddress from "./EditAddress";
import Apilib from "../../utils/api";
import { useSelector, useDispatch } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { useToasts } from "react-toast-notifications";
import mixpanel from "mixpanel-browser";


function ManageAddress() {
  const [openAddress, setOpenAddress] = useState(false);
  const [openEditAddress, setOpenEditAddress] = useState(false);
  const userDetails = useSelector((state) => state.userDetails);
  const [address, setAddress] = useState([]);
  const [deleteAddress, setDeleteAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState();
  const [addressDetails, setAddressDetails] = useState();
  const [deliveryAddress, setDeliveryAddress] = useState();
  const API = new Apilib();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  console.log("delivery add", deliveryAddress);
  useEffect(() => {
    FetchDeliveryAddress();
  }, []);

  const clickOnEditAddress = () => {
    mixpanel.track("Edit Address");
  }

  const ClickOnAddNewAddress = () => {
    mixpanel.track("AddNewAddress");
  }

  // useEffect(() => {
  //   if (deliveryAddress) {
  //     props.selectedAdd(deliveryAddress);
  //   }
  // }, [deliveryAddress]);

  const FetchDeliveryAddress = () => {
    setLoading(true);
    const userId = userDetails.user.ID;
    API.GetAllDeliveryAddress(userId)
      .then((res) => {
        console.log(res.data);
        setAddress(res.data);
        setDeliveryAddress(res.data[0]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // useEffect(() => {
  //   if (deliveryAddress) {
  //     props.selectedAdd(deliveryAddress);
  //   }
  // }, [deleteAddress]);

  const DeleteAddress = () => {
    setLoading(true);
    let data = [
      {
        USERID: selectedAddress.USERID,
        SER_NO: selectedAddress.SER_NO,
      },
    ];
    // data = JSON.stringify(data);
    API.DeleteAddress(data)
      .then((res) => {
        console.log(res);
        FetchDeliveryAddress();
        setDeleteAddress(false);
        addToast("Address deleted successfully", {
          appearance: "success",
          autoDismiss: true,
        });


        mixpanel.track("ManageAddress_Address_Deleted");  
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        addToast("Something went wrong please try again", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };
  return (
    <div>
      {deleteAddress ? (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Confirm"
          confirmBtnBsStyle="danger"
          title="Are you sure you want to delete this address ?"
          onConfirm={DeleteAddress}
          onCancel={() => setDeleteAddress(false)}
          focusCancelBtn></SweetAlert>
      ) : null}
      <AddAddress
        open={openAddress}
        Onclose={() => setOpenAddress(false)}
        fetchAddress={() => FetchDeliveryAddress()}
      />
      <EditAddress
        open={openEditAddress}
        Onclose={() => setOpenEditAddress(false)}
        address={selectedAddress}
        fetchAddress={() => FetchDeliveryAddress()}
      />
      <div className="ml-1 overflow-hidden">
        <div className="title-text-bold sidebar-title h2">
          Saved Addresses
          <button
            className="btn btn-background border-round text-white edit-btn  mx-4 btn-sm"
            onClick={() => {
              setOpenAddress(true)
              ClickOnAddNewAddress()
            }}>
            Add New Address
          </button>
        </div>
        <div className="container">
          {address.length != 0
            ? address.map((add) => {
                return (
                  <div className=" custom_address_rowGap  row box-shadow ">
                    <div className="container custom_address_myaccount">
                      <div className="col-12 font-weight-bold ">
                        <h4 className="font-weight-bold custon_addressName">
                          {add.FNAME}
                        </h4>
                      </div>
                      <div className="col-12 h6 text-muted">
                        {add?.PAR_ADD1} {add.PAR_ADD2} {add.PAR_ADD3}
                      </div>
                      <div className="col-12 h6 text-muted">
                        {add.CITY}-{add.PIN}
                      </div>
                      <div className="col-12 h6 text-muted">
                        {add.STATE},{add.COUNTRY}{" "}
                      </div>

                      <div
                        className="col-12 h6 text-muted"
                        style={{
                          display: "inline-flex",
                          justifyContent: "space-between",
                        }}>
                        Mobile-{add.MOBNO}
                        {/* {add.ALTMOBNO != "0" && add.ALTMOBNO != "" ? (
                          <>Alternate Mobile-{add.ALTMOBNO}</>
                        ) : null} */}
                        <div className=" custom_editanddeletebtn">
                          <button
                            className="Custom_addreddEditbtn"
                            onClick={() => {
                              setOpenEditAddress(true);
                              setSelectedAddress(add);
                              clickOnEditAddress();
                            }}>
                            Edit
                          </button>
                          <button
                            className="Custom_addreddDeletebtn"
                            onClick={() => {
                              setDeleteAddress(true);
                              setSelectedAddress(add);
                            }}>
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* <div className=" custom_editanddeletebtn border-top border-right col-6 ">
                      <button
                        className="btn bg-transparent title-text font-weight-bold w-100  border-none "
                        onClick={() => {
                          setOpenEditAddress(true);
                          setSelectedAddress(add);
                        }}
                      >
                        Edit
                      </button>
                    </div>
                    <div className="custom_editanddeletebtn border-top col-6  ">
                      <button
                        className="btn bg-transparent delete-btn font-weight-bold w-100  border-none "
                        onClick={() => {
                          setDeleteAddress(true);
                          setSelectedAddress(add);
                        }}
                      >
                        Remove
                      </button>
                    </div> */}
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
}

export default ManageAddress;
