import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import ShareIcon from "@mui/icons-material/Share";
import { useSelector, useDispatch } from "react-redux";
import ApiLib from "../../utils/api";
import Loader from "../../helpers/Loader";
import addToCart from "../../redux/actions/cartActions";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import MyCollectionModal from "../shop-product/MyCollectionModal";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Checkbox from "@mui/material/Checkbox";
import mixpanel from "mixpanel-browser";
import { setSelectAllAction } from "../../redux/actions/selectAllAction";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
  pb: 0,
};

const ProductListingSingle = ({
  setOpen,
  setdata,
  product,
  selectedProducts,
  selectedProductsArr,
  checkAll,
  wishlistPrds,
  deletewishlist,
  addwishlist,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const { addToast } = useToasts();
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const API = new ApiLib();
  const dispatch = useDispatch();
  const history = useHistory();
  const userDetails = useSelector((state) => state.userDetails);
  const userInformation = useSelector((state) => state.userDetails);

  const reduxstate = useSelector((state) => state);
  const [openCollectionModal, setOpenCollectionModal] = useState(false);
  const [createCollection, setCreateCollection] = useState(false);
  const [shareCollection, setShareCollection] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState();
  const handleOpenshareCollection = () => setShareCollection(true);
  const handleCloseshareCollection = () => setShareCollection(false);
  const { selectAll } = useSelector((state) => state);

  function productSeletion(e) {
    const isChecked = selectAll.selectedProducts.some(
      (_prd) => _prd.DCODE === product?.DCODE
    );
    if (isChecked) {
      const filteredArray = selectAll.selectedProducts.filter(
        (_prd) => _prd.DCODE !== product.DCODE
      );
      console.log("removing from array");
      dispatch(setSelectAllAction(filteredArray));
    } else {
      dispatch(setSelectAllAction([...selectAll.selectedProducts, product]));
    }
  }

  const handleClosecreateCollection = () => setCreateCollection(false);

  //

  // console.log(userDetails.user.ID,"DDDDDDD")
  const handleOpenUsermodeModal = () => {
    if (userDetails.user != null) {
      setdata(product);
      setOpen(true);
    } else {
      addToast("Kindly login to share a product", {
        appearance: "warning",
        autoDismiss: true,
      });
    }
  };
  //

  const capitalize = (string) => {
    if (string !== undefined && string !== "") {
      let str = "";
      str = string;
      let strArr = str.split(" ");
      for (let i = 0; i < strArr.length; i++) {
        strArr[i] = strArr[i].charAt(0).toUpperCase() + strArr[i].slice(1);
      }
      let finalStr = strArr.join(" ");
      return finalStr;
    }
    return "";
  };
  const [shareLink, setShareLink] = useState();
  useEffect(() => {
    if (product.BASEDESIGN) {
      CreateShareLink();
    }
    // eslint-disable-next-line
  }, [product.BASEDESIGN]);

  const CreateShareLink = () => {
    let userId =
      userInformation?.user !== null
        ? Buffer.from(`${userInformation?.user?.ID}` || "0").toString("base64")
        : Buffer.from("0").toString("base64");
    console.log(userId, "Checkits Value");
    let link = `https://catalogcollection.web.app/catalouge/product/${userId}/${product.BASEDESIGN}/${product.DCODE}`;
    setShareLink(link);
  };

  const ShareModal = () => {
    const userDetails = useSelector((state) => state.userDetails);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleWaClick = () => {
      let userPhone = userDetails.user.MOBNO;

      if (userPhone === "") return;

      let link = `https://wa.me/${userPhone}`;
      window.open(link, "_blank");
    };

    return (
      <Modal
        open={shareCollection}
        onClose={handleCloseshareCollection}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="share-on-whatsApp">
          <div className="row">
            <div className="col-8 text-left">
              <div className="row align-items-center mb-10">
                <h4
                  className="mr-3 font-weight-bold share-button sidebar-title custom_share_link_button"
                  onClick={handleWaClick}
                  style={{ marginBottom: 0 }}
                >
                  Share on Whatsapp
                </h4>
                <WhatsAppIcon
                  className="custom_icon_size icon-color pointer"
                  onClick={handleWaClick}
                />
              </div>
            </div>
            <hr />
            <div className=" col-4 text-right mt-n2 ">
              <CloseIcon
                className=" close-button"
                onClick={handleCloseshareCollection}
              />
            </div>
          </div>
          {/* <div className="row text-center mt-4 mb-4">
            <div className="col-3">
              <InstagramIcon className="icon-color" />
            </div>
            <div className="col-3">
              <EmailIcon className="icon-color" />
            </div>
            <div className="col-3">
              <FacebookIcon className="icon-color" />
            </div>
            <div className="col-3">
              <WhatsAppIcon
                className="custom_icon_size icon-color pointer"
                onClick={handleWaClick}
              />
            </div>
          </div> */}
          {/*  */}
          <div className="row">
            <div className="col-12 mb-2 title-text-bold">Product Link</div>
            <div className="col-10 pr-0 mb-3">
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={shareLink}
                placeholder="https://zjwel.com/ddf/dfdf/swd....."
              />
            </div>
            <div className="col-2  mt-2">
              <ContentCopyOutlinedIcon
                onClick={(event) => {
                  navigator.clipboard.writeText(shareLink);
                  handleClick(event);
                }}
                className="icon-color pointer"
              />
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Typography sx={{ p: 2 }}>{"Item link copied"}</Typography>
              </Popover>
            </div>
          </div>
        </Box>
      </Modal>
    );
  };

  // integration code from here

  const GetCartDetails = () => {
    setLoading(true);
    API.GetCart(userDetails.user.ID)
      .then((res) => {
        console.log("get cart", res);
        dispatch(addToCart(res.data));
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const AddToCart = (product) => {
    console.log("product", product);
    if (userDetails.user) {
      setLoading(true);
      let productDetails = {
        userid: userDetails.user.ID,
        dcode: product.DCODE,
        quantity: 1,
        rate:
          userDetails?.user?.RATEMODE === "2"
            ? product.SRATE1
            : userDetails?.user?.RATEMODE === "3"
            ? product.SRATE
            : product.SRATE1,
      };
      API.AddToCart(productDetails)
        .then((res) => {
          console.log("added to cart", res.data[0].result);

          if (res.data[0].result == 1) {
            addToast("Item added to cart successfully ", {
              appearance: "success",
              autoDismiss: true,
            });
            GetCartDetails();
          } else if (res.data[0].result == 2) {
            addToast("Item already present in cart", {
              appearance: "error",
              autoDismiss: true,
            });
          } else {
            addToast("Something went wrong please try again", {
              appearance: "error",
              autoDismiss: true,
            });
          }

          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          addToast("Something went wrong please try again", {
            appearance: "error",
            autoDismiss: true,
          });
        });
    } else {
      addToast("Kindly login to add this product to Cart", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const CreateModal = (props) => {
    const [collectionName, setCollectionName] = useState();
    const CreateCollection = () => {
      if (collectionName) {
        setLoading(true);
        const data = {
          USERID: userDetails.user.ID,
          COLL_NAME: collectionName,
        };
        API.SaveCollection(data)
          .then((res) => {
            console.log(res);
            addToast("New collection created successfully", {
              appearance: "success",
              autoDismiss: true,
            });
            props.onClose();
            setLoading(false);
            setOpenCollectionModal(true);

            //
            mixpanel.track("CreateCollection", {
              CollectionName: JSON.stringify(collectionName),
            }); // MixPannel ka code
            //
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            addToast("Something went wrong please try again", {
              appearance: "error",
              autoDismiss: true,
            });
          });
      }
    };

    return (
      <Modal
        open={createCollection}
        onClose={handleClosecreateCollection}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="row">
            <div className="col-10 text-left">
              <h3 className="mr-3 title-text-bold sidebar-title">
                Create Collection{" "}
              </h3>
            </div>
            <div className=" col-2 text-right mt-n2 ">
              <CloseIcon
                onClick={handleClosecreateCollection}
                className="close-button"
              />
            </div>{" "}
          </div>

          <div className="row">
            <div className="col-12 px-4 py-4 mb-3">
              <input
                type="text"
                // onChange={(e) => setCollectionName(e.target.value)}
                // Bug Solve By Syed - Create Collection
                onChange={(e) => {
                  const input = e.target.value.trim(); // Remove leading and trailing spaces
                  if (
                    input.length > 0 &&
                    !input.startsWith(".") &&
                    !input.startsWith(",") &&
                    !input.startsWith("@")
                  ) {
                    setCollectionName(input);
                  }
                }}
                // Bug Solve By Syed
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    CreateCollection();
                  }
                }}
                //
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Collection Name"
                autoFocus
              />
            </div>
          </div>
          <div
            className="row bg-black  text-center save-btn"
            style={{ marginLeft: "-33px", marginRight: "-33px" }}
          >
            <div
              className="col-12 px-3  share-button py-3 text-white btn-background"
              onClick={() => {
                CreateCollection();
              }}
            >
              Save
            </div>
          </div>
        </Box>
      </Modal>
    );
  };

  const OpenCollection = () => {
    if (userDetails.user != null) {
      setOpenCollectionModal(true);
    } else {
      addToast("Kindly login to view/add collection", {
        appearance: "warning",
        autoDismiss: true,
      });
      // Notify({
      //   alert: true,
      //   type: "warning",
      //   title: "Kindly login to view/add collection",
      // });
    }
  };
  return (
    <Fragment>
      <ShareModal />

      <MyCollectionModal
        open={openCollectionModal}
        close={() => setOpenCollectionModal(false)}
        product={product}
        openAddCollection={() => {
          setCreateCollection(true);
          setOpenCollectionModal(false);
        }}
        closeaAddCollection={() => setCreateCollection(false)}
      />
      <CreateModal
        onClose={() => setCreateCollection(false)}
        open={createCollection}
        openCollection={() => {
          setCreateCollection(false);
          setOpenCollectionModal(true);
        }}
      />
      {loading ? <Loader /> : null}
      <div className="col-xl-4 col-sm-6 col-6">
        <div className="product-wrap mb-4">
          <div className="product-img">
            <Link
              to={
                process.env.PUBLIC_URL +
                "/product/" +
                product.BASEDESIGN +
                "/" +
                product.DCODE
              }
              // onClick={() => ProductSelected()}
              // to={{
              //   pathname: `${process.env.PUBLIC_URL}product/${product?.DCODE} `,
              //   query: `${product?.DCODE}`,
              // }}
            >
              <img className="default-img" src={product?.DIMAGE} alt="" />
              {/* {product.image.length > 1 ? (
                <img
                  className="hover-img"
                  src={process.env.PUBLIC_URL + product.image[1]}
                  alt=""
                />
              ) : (
                ""
              )} */}
            </Link>
            <div className="product-img-badges-select">
              <span className="pink">
                <Checkbox
                  className="custom_checkboxon_shoppage"
                  onChange={(e) => productSeletion(e)}
                  checked={selectAll.selectedProducts.some(
                    (_prd) => _prd.DCODE === product?.DCODE
                  )}
                />

                {/* <input
                  type="checkbox"
                  style={{ width: 20, color: "#14716b" }}
                  className="checkbox-color"
                  onChange={(e) => productSeletion(e)}
                  checked={checked}
                /> */}
              </span>
            </div>

            <div
              // onClick={() => handleOpenshareCollection()}
              onClick={() => handleOpenUsermodeModal()}
              className="product-img-badges"
            >
              <span className="pink">
                <ShareIcon
                  className="color-green"
                  sx={{ color: "#14716b", cursor: "pointer" }}
                />
              </span>
            </div>

            <div className="product-action">
              <div className="pro-same-action pro-wishlist">
                {!wishlistPrds.find((item) => item.DCODE == product.DCODE) ? (
                  <button
                    // className={wishlistItem !== undefined ? "active" : ""}
                    // disabled={wishlistItem !== undefined}
                    // title={
                    //   wishlistItem !== undefined
                    //     ? "Added to wishlist"
                    //     : "Add to wishlist"
                    // }
                    onClick={() => addwishlist(product)}
                  >
                    <i className="pe-7s-like" />
                  </button>
                ) : (
                  <button
                    // className={wishlistItem !== undefined ? "active" : ""}
                    // disabled={wishlistItem !== undefined}
                    // title={
                    //   wishlistItem !== undefined
                    //     ? "Added to wishlist"
                    //     : "Add to wishlist"
                    // }
                    onClick={() => deletewishlist(product.DCODE)}
                  >
                    <FavoriteIcon className="icon-color-wishlist" />
                  </button>
                )}
              </div>
              <div className="pro-same-action pro-cart">
                <button
                  className="custom_addtocartbtnhome"
                  onClick={() => AddToCart(product)}
                  title="Add to cart"
                >
                  {" "}
                  <i className="pe-7s-cart"></i> Add to cart
                </button>
              </div>
              <div className="pro-same-action pro-quickview">
                <button
                  onClick={() => OpenCollection()}
                  title="Add To Collection"
                >
                  <LibraryAddOutlinedIcon />{" "}
                </button>
              </div>
            </div>
          </div>
          <div className="product-content text-center">
            <h3 style={{ color: "#3d4246", fontSize: "1.2em" }}>
              {capitalize(product?.INAME)}

              {/* {product?.INAME } */}
              {/* {product?.DCODE} */}
            </h3>
            {/* {product.rating && product.rating > 0 ? (
              <div className="product-rating">
                <Rating ratingValue={product.rating} />
              </div>
            ) : (
              ""
            )} */}
            {userDetails?.user !== null ? (
              userDetails?.user?.RATEMODE === "3" ? (
                <div className="product-price">
                  <h3
                    style={{
                      fontWeight: 600,
                      color: "#3d4246",
                      fontSize: "16px",
                    }}
                  >
                    {" "}
                    ₹ {product?.SRATE}
                  </h3>
                </div>
              ) : userDetails?.user?.RATEMODE === "2" ? (
                <div className="product-price">
                  <h3
                    style={{
                      fontWeight: 600,
                      color: "#3d4246",
                      fontSize: "16px",
                    }}
                  >
                    {" "}
                    ₹ {product?.SRATE1}
                  </h3>
                </div>
              ) : (
                <div className="product-price">
                  <h3
                    style={{
                      fontWeight: 600,
                      color: "#3d4246",
                      fontSize: "16px",
                    }}
                  >
                    {" "}
                    ₹ {product?.SRATE1}
                  </h3>
                </div>
              )
            ) : null}
          </div>
        </div>
        {/* <div className="shop-list-wrap mb-30">
          <div className="row">
            <div className="col-xl-4 col-md-5 col-sm-6">
              <div className="product-list-image-wrap">
                <div className="product-img">
                  <Link to={process.env.PUBLIC_URL + "/product/" + product.id}>
                    <img
                      className="default-img img-fluid"
                      src={process.env.PUBLIC_URL + product.image[0]}
                      alt=""
                    />
                    {product.image.length > 1 ? (
                      <img
                        className="hover-img img-fluid"
                        src={process.env.PUBLIC_URL + product.image[1]}
                        alt=""
                      />
                    ) : (
                      ""
                    )}
                  </Link>

                  <div className="product-img-badges">
                    <span className="pink">
                      <ShareOutlinedIcon />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-md-7 col-sm-6">
              <div className="shop-list-content">
                <h3>
                  <Link to={process.env.PUBLIC_URL + "/product/" + product.id}>
                    {product.name}
                  </Link>
                </h3>
                <div className="product-list-price">
                  {discountedPrice !== null ? (
                    <Fragment>
                      <span>
                        {currency.currencySymbol + finalDiscountedPrice}
                      </span>{" "}
                      <span className="old">
                        {currency.currencySymbol + finalProductPrice}
                      </span>
                    </Fragment>
                  ) : (
                    <span>{currency.currencySymbol + finalProductPrice} </span>
                  )}
                </div>
                {product.rating && product.rating > 0 ? (
                  <div className="rating-review">
                    <div className="product-list-rating">
                      <Rating ratingValue={product.rating} />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {product.shortDescription ? (
                  <p>{product.shortDescription}</p>
                ) : (
                  ""
                )}

                <div className="shop-list-actions d-flex align-items-center">
                  <div className="shop-list-btn btn-hover">
                    {product.affiliateLink ? (
                      <a
                        href={product.affiliateLink}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {" "}
                        Buy now{" "}
                      </a>
                    ) : product.variation && product.variation.length >= 1 ? (
                      <Link
                        to={`${process.env.PUBLIC_URL}/product/${product.id}`}
                      >
                        Select Option
                      </Link>
                    ) : product.stock && product.stock > 0 ? (
                      <button
                        onClick={() => addToCart(product, addToast)}
                        className={
                          cartItem !== undefined && cartItem.quantity > 0
                            ? "active"
                            : ""
                        }
                        disabled={
                          cartItem !== undefined && cartItem.quantity > 0
                        }
                        title={
                          cartItem !== undefined
                            ? "Added to cart"
                            : "Add to cart"
                        }
                      >
                        {" "}
                        <i className="pe-7s-cart"></i>{" "}
                        {cartItem !== undefined && cartItem.quantity > 0
                          ? "Added"
                          : "Add to cart"}
                      </button>
                    ) : (
                      <button disabled className="active">
                        Out of Stock
                      </button>
                    )}
                  </div>

                  <div className="shop-list-wishlist ml-10">
                    <button
                      className={wishlistItem !== undefined ? "active" : ""}
                      disabled={wishlistItem !== undefined}
                      title={
                        wishlistItem !== undefined
                          ? "Added to wishlist"
                          : "Add to wishlist"
                      }
                      onClick={() => addToWishlist(product, addToast)}
                    >
                      <i className="pe-7s-like" />
                    </button>
                  </div>
                  <div className="shop-list-compare ml-10">
                    <button
                      className={compareItem !== undefined ? "active" : ""}
                      disabled={compareItem !== undefined}
                      title={
                        compareItem !== undefined
                          ? "Added to compare"
                          : "Add to compare"
                      }
                      onClick={() => addToCompare(product, addToast)}
                    >
                      <i className="pe-7s-shuffle" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {/* product modal */}
    </Fragment>
  );
};

export default ProductListingSingle;
