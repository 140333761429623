import PropTypes from "prop-types";
import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../../layouts/LayoutOne";
import ProductDetailContent from "./ProductDetailContent";

const ProductDetail = (props) => {
  // const { pathname } = location;

  console.log("PProps", props);

  return (
    <Fragment>
      <MetaTags>
        <title>Product Page</title>
        <meta name="description" content="Product page." />
      </MetaTags>
      <div>
        {/* <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>
          Home
        </BreadcrumbsItem>
        <BreadcrumbsItem to={process.env.PUBLIC_URL + props.pathname}>
          Shop Product
        </BreadcrumbsItem> */}
      </div>
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        {/* <Breadcrumb /> */}

        {/* product description with image */}
        <ProductDetailContent
          spaceTopClass="pt-100"
          spaceBottomClass="pb-100"
          product={props.product}
          props={props}
        />

        {/* product description tab */}
      </LayoutOne>
    </Fragment>
  );
};

export default ProductDetail;
