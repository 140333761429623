import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Logo from "../../components/header/Logo";
import NavMenu from "../../components/header/NavMenu";
import SearchIcon from "@material-ui/icons/Search";
import HeaderTop from "../../components/header/HeaderTop";
import { Redirect } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import "./headerone.css";
import { useSelector, useDispatch } from "react-redux";
import { search } from "../../redux/actions/productAction";
import Apilib from "../../utils/api";
import amyraaLogo from "../../assets/img/amyraa_logo.png";
import TemporaryDrawer from "./drawer";
import { CloseOutlined } from "@mui/icons-material";

const HeaderOne = ({
  layout,
  top,
  borderStyle,
  headerPaddingClass,
  headerPositionClass,
  headerBgClass,
  location,
}) => {
  const [searchEnable, setSearchDisable] = useState(0);
  const dispatch = useDispatch();
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const history = useHistory();
  const searchText = useSelector((state) => state.allProducts.search);
  const userInfo = useSelector((state) => state);
  const [userDetails, setUserDetails] = useState();
  const [loading, setLoading] = useState(false);
  const API = new Apilib();

  console.log(userInfo?.userInformation?.user, "ChMainChecker");

  useEffect(() => {
    const header = document.querySelector(".sticky-bar");
    setHeaderTop(header.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    FetchUserDetails();
    //eslint-disable-next-line
  }, []);

  function FetchUserDetails() {
    setLoading(true);
    API.GetUserDetails(localStorage?.amyra_email)
      .then((res) => {
        console.log("user Details", res.data);
        let userInfo = { ...res.data[0] };
        setUserDetails(userInfo);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  const handleSearch = (e) => {
    dispatch(search(e));
  };

  const handleSearchSubmit = () => {
    if (searchText.trim() !== "") {
      history.push({
        pathname: "/searchResult",
        search: "?value=" + searchText,
        state: { detail: searchText },
      });
    }
  };

  const handleClearSearch = () => {
    dispatch(search(""));
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchSubmit();
    }
  };

  return (
    <>
      {redirect ? <Redirect push to="/somewhere/else" /> : null}
      <header
        className={`header-area clearfix ${
          headerBgClass ? headerBgClass : ""
        } ${headerPositionClass ? headerPositionClass : ""}`}
      >
        <div
          className={`${headerPaddingClass ? headerPaddingClass : ""} ${
            top === "visible" ? "d-lg-block" : ""
          } header-top-area ${
            borderStyle === "fluid-border" ? "border-none" : ""
          }`}
        >
          <div className={layout === "container-fluid" ? layout : "container"}>
            {/* header top */}
            <HeaderTop borderStyle={borderStyle} />
          </div>
        </div>

        <div
          className={` ${
            headerPaddingClass ? headerPaddingClass : ""
          } sticky-bar header-res-padding clearfix ${
            scroll > headerTop ? "stick" : ""
          }`}
        >
          <div className={layout === "container-fluid" ? layout : "container"}>
            <div className="row custom_push_header">
              <div className="col-xl-3 col-lg-3 col-md-6 col-4">
                {/* header logo */}

                {!userDetails?.USERIMG ? (
                  <Link to={process.env.PUBLIC_URL + "/"}>
                    <Logo
                      className="mainHeaderlogo"
                      imageUrl={amyraaLogo}
                      logoClass="logo"
                    />
                  </Link>
                ) : (
                  <Link to={process.env.PUBLIC_URL + "/"}>
                    <div>
                      <img
                        src={userDetails?.USERIMG}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = amyraaLogo;
                        }}
                        style={{ height: 75 }}
                        className="ml-1 logo-img"
                        alt="Business Logo"
                      />
                    </div>
                  </Link>
                )}
              </div>
              <div className="col-xl-3 col-lg-4 col-md-4 col-4">
                <div id="Search_bar_header">
                  <div style={{ position: "relative" }}>
                    <input
                      type="text"
                      placeholder="Search"
                      style={{
                        padding: "8px 82px 8px 20px", // Adjust padding as needed
                        border: "none", // Remove border
                        borderRadius: "4px",
                        fontSize: "14px",
                        outline: "none",
                        width: "100%",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Add shadow
                        backgroundColor: "#fff",
                      }}
                      value={searchText}
                      onChange={(e) => handleSearch(e.target.value)}
                      onKeyPress={handleKeyPress}
                    />
                    {searchText && (
                      <button
                        type="button"
                        style={{
                          position: "absolute",
                          right: "38px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          color: "#000",
                          border: "none",
                          borderRadius: "4px",
                          padding: "8px", // Adjust padding as needed
                          cursor: "pointer",
                          fontSize: "14px",
                          backgroundColor: "#fff",
                        }}
                        onClick={handleClearSearch}
                      >
                        <CloseOutlined />
                      </button>
                    )}
                    <button
                      type="button"
                      style={{
                        position: "absolute",
                        right: "8px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        color: "#000",
                        border: "none",
                        borderRadius: "4px",
                        padding: "8px", // Adjust padding as needed
                        cursor: "pointer",
                        fontSize: "14px",
                        backgroundColor: "#fff",
                      }}
                      onClick={handleSearchSubmit}
                    >
                      <SearchIcon />
                    </button>
                  </div>
                </div>
              </div>

              {/* Mobile Drawer - Syed */}
              <div className="mobile-drawer">
                <TemporaryDrawer />
              </div>

              <div className="col-xl-6 col-lg-5 d-none d-lg-block pull-right">
                {/* Nav menu */}
                <div className="pull-right">
                  <NavMenu />
                </div>
              </div>
            </div>
          </div>
          {/* mobile menu */}
          {/* <MobileMenu /> */}
        </div>
      </header>
    </>
  );
};

HeaderOne.propTypes = {
  borderStyle: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  layout: PropTypes.string,
  top: PropTypes.string,
};

export default HeaderOne;
