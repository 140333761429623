import React, { Fragment, useEffect, useState } from "react";
import ring1 from "../../assets/img/ring1.jpg";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import SweetAlert from "react-bootstrap-sweetalert";
import ApiLib from "../../utils/api";
import Loader from "../../helpers/Loader";
import { useToasts } from "react-toast-notifications";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const BlogComment = ({ item, FetchCollectionDesigns }) => {
  const [deleteDesign, setDeleteDesign] = useState(false);
  const [loading, setLoading] = useState(false);
  const API = new ApiLib();
  const { addToast } = useToasts();
  const userDetails = useSelector((state) => state.userDetails);

  // useEffect(() => {
  //   if (deleteDesign) {
  //     DeleteCollectionDesign();
  //   }
  // }, [deleteDesign]);
  // console.log(item.SRATE,item,userDetails,"HSratee")
  // console.log(item.SRATE1,"HSratee")

  const DeleteCollectionDesign = () => {
    setLoading(true);

    let data = {
      USERID: item.USERID,
      COLL_ID: Number(item.COLL_ID),
      DCODE: [item.DCODE],
    };
    API.DeleteCollectionDesign(data)
      .then((res) => {
        console.log(res);
        FetchCollectionDesigns();
        setDeleteDesign(false);
        setLoading(false);
        addToast("Item deleted successfully", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        addToast("Something went wrong please try again", {
          appearance: "error",
          autoDismiss: true,
        });
        setDeleteDesign(false);
      });
  };

  return (
    <Fragment>
      {loading ? <Loader /> : null}
      {deleteDesign ? (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Confirm"
          confirmBtnBsStyle="danger"
          title="Are you sure you want to remove this product from collection ?"
          onConfirm={DeleteCollectionDesign}
          onCancel={() => setDeleteDesign(false)}
          focusCancelBtn></SweetAlert>
      ) : null}

      <div className="blog-comment-wrapper  collection">
        <div className="single-comment-wrapper ">
          <div className="blog-comment-img">
            <img src={item.DIMAGE} alt="" className="border-round" />
          </div>
          <Link
            to={
              process.env.PUBLIC_URL +
              "/product/" +
              item.BASEDESIGN +
              "/" +
              item.DCODE
            }>
            <div className="blog-comment-content ">
              <h4>{item.INAME}</h4>
              <span className="text-muted">DesignId - {item.DCODE} </span>
              <span className="text-muted">Weight -{item.WEIGHT} Gms </span>

              {userDetails ? (
                userDetails?.user?.RATEMODE === "3" ? (
                  <h4 className="title-text">₹ {item.SRATE}</h4>
                ) : userDetails?.user?.RATEMODE === "2" ? (
                  <h4 className="title-text">₹ {item.SRATE1}</h4>
                )
                // Add By Syed
                : userDetails?.user?.RATEMODE === "" ? (
                  <h4 className="title-text">₹ {item.SRATE1}</h4>
                ) 
                // Syed
                : null
              ) : (
                <h4 className="title-text">₹ {item.SRATE1}</h4>
              ) }
            </div>
          </Link>

          <div className="custom_collection_button blog-comment-content w-100 text-right my-auto">
            <button onClick={() => setDeleteDesign(true)}>
              Delete <hr className="Custom_collection_hr" />{" "}
            </button>
            {/* <DeleteOutlineOutlinedIcon
              className="icon-color"
              onClick={() => setDeleteDesign(true)}
            /> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BlogComment;
