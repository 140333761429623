import React from "react";
import { NavLink } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
function SideBar(props) {

  return (
    <>
      <div  className={`sidebar-white navbar-fixed-left sidebar-mob ml-3 mr-3 ${props.sidebarOpen ? 'sidebar-open' : ''}`}>
       <div className="side-bar-cancel"  onClick={props.toggleSidebar}>
        <i  className="collection-icon"  class="ri-close-line"></i>
       </div>
        <ul
          className="nav navbar-nav custom_coolection_sidebarlast"
          style={{ backgroundColor: "#f5f5f5", color: "#000;" }}
        >
          <li className="Custom_collection_heading">Collection</li>
          {props.collection.length
            ? props.collection.map((item) => {
                return (
                  <li
                    className={
                      props.selectedCollection?.COLL_ID == item.COLL_ID
                        ? "link-hover font-fam pt-3 pb-3  border-bottom btn-background custom_Collection_backcolor"
                        : "link-hover font-fam pt-3 pb-3  border-bottom bg-white"
                    }
                    onClick={() => props.updateSelectedCollection(item)}
                  >
                    <div className="row">
                      <div className="col-10 custom_collection_a">

                        <NavLink
                          to="#"
                          className={
                            props.selectedCollection?.COLL_ID == item.COLL_ID
                              ? "a-link  text-white ml-4 "
                              : " text-black ml-4 "
                          }
                        >
                          {item.COLL_NAME}
                        </NavLink>
                      </div>
                      <div className="col-2 text-white p-0">
                        <ChevronRightIcon sx={{ fontSize: "2rem" }} />
                      </div>
                    </div>
                  </li>
                );
              })
            : null}
        </ul>
      </div>
    </>
  );
}

export default SideBar;
