import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import makeAnimated from "react-select/animated";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../axios";
import Apilib from "../../utils/api";
import Dropdown from "react-bootstrap/Dropdown";
import Checkbox from "@mui/material/Checkbox";
import { selectedTopFilters } from "../../redux/actions/productAction";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import { Splitscreen } from "@mui/icons-material";
import ProductListingSingle from "./ProductListingSingle";
import { filterColors, filterPlatings } from "../../utils/constants";
import mixpanel from "mixpanel-browser";
import { Mixpanel } from "../../utils/mixpannel";

import "./ShopTopAction.css";
import { setSelectAllAction } from "../../redux/actions/selectAllAction";

const ShopTopAction = ({
  toggleFilterbar,
  FilterOpen,
  selectProduct,
  selectAll,
  productsLength,
  setProductsLength,
  selectCheckbox,
  setSelectCheckbox,
}) => {
  const dispatch = useDispatch();
  const API = new Apilib();

  const [plating, setPlating] = useState([]);
  const [platingNames, setPlatingNames] = useState([]);
  const [platingFilters, setPlatingFilters] = useState([]);
  const [colors, setColors] = useState([]);
  const [colorNames, setColorNames] = useState([]);
  const [colorFilters, setColorFilters] = useState([]);
  const [, setAllProducts] = useState([]);
  const [colorDropdownVisible, setColorDropdownVisible] = useState(false);
  const [platingDropdownVisible, setPlatingDropdownVisible] = useState(false);
  const [value, setValue] = React.useState([400, 1500]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPirce] = useState(1000);
  const [filters, setFilters] = useState({
    color: [],
    plating: [],
    lowerPrice: "",
    upperPrice: "",
    sortby: "",
  });

  const filters_top = useSelector((state) => state.allProducts.filters_top);
  const products = useSelector((state) => state.allProducts.products);
  const productsList = useSelector((state) => state.allProducts.products);
  const userDetails = useSelector((state) => state.userDetails);
  const { selectedProducts } = useSelector((state) => state.selectAll);

  // DropDownBug
  const [dropdownStates, setDropdownStates] = useState({
    priceDropdown: false,
    colorDropdown: false,
    platingDropdown: false,
    sortByDropdown: false,
  });
  const handleDropdownToggle = (dropdownName) => {
    // Create a new object based on the current dropdownStates
    const newDropdownStates = { ...dropdownStates };
    // Toggle the state for the specified dropdown
    newDropdownStates[dropdownName] = !newDropdownStates[dropdownName];
    // Close all other dropdowns when opening one
    if (newDropdownStates[dropdownName]) {
      for (const key in newDropdownStates) {
        if (key !== dropdownName) {
          newDropdownStates[key] = false;
        }
      }
    }
    setDropdownStates(newDropdownStates);
  };

  useEffect(() => {
    let tempFilters = { ...filters, ...filters_top };
    setFilters(tempFilters);
  }, [filters_top]);

  useEffect(() => {
    API.FetchTopFilters()
      .then((res) => {
        console.log("top filters ", res);
        const filters = res.data;
        filters.map((filter) => {
          if (filter.NAME == "Color") {
            setColors(filter.VALUE.split(","));
          } else if (filter.NAME == "Plating") {
            console.log("PLATING -> ", filter.VALUE.split(","));
            setPlating(filter.VALUE.split(","));
          } else if (filter.NAME == "Price") {
            const prices = filter.VALUE.split(",");
            setMaxPirce(Number(prices[1]));
            setMinPrice(Number(prices[0]));
            setValue([Number(prices[0]), Number(prices[1])]);
          }
        });
        // setMaterial([...new Set(materialArr)]);
        // setColors([...new Set(colorsArr)]);
      })
      .catch((e) => {
        console.log(e);
      });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let splitColor = colors.map((color) =>
      color.split("~").map((row) => row.trim())
    );
    let localRows = [...Array(Math.ceil(splitColor.length / 4))];
    let localColorNames = localRows.map((row, index) =>
      splitColor.slice(index * 4, index * 4 + 4)
    );
    setColorNames(localColorNames);

    let splitPlating = plating.map((plat) =>
      plat.split("~").map((row) => row.trim())
    );
    let localRowsPlating = [...Array(Math.ceil(splitPlating.length / 3))];
    let localPlatingNames = localRowsPlating.map((row, index) =>
      splitPlating.slice(index * 3, index * 3 + 3)
    );
    console.log("PLATING -> ", localPlatingNames);
    setPlatingNames(localPlatingNames);
  }, [colors, plating]);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      selectAll(productsList, e.target.checked);

    } else {
      dispatch(setSelectAllAction([]));
      setSelectCheckbox(false);
    }
  };
  const [pricearr, setPriceArr] = useState([]);
  console.log("FINAL -> ", filters);

  const handleFilters = (e, value) => {
    let tempFilters = { ...filters_top };
    const filterCategory = e.target.name;
    console.log("checked", e.target.value);

    if (e.target.checked) {
      switch (filterCategory) {
        case "price":
          const price = e.target.value.split(",").map(Number);
          // temppricearr = [...tempFilters.price, ...price];
          let arr = [...pricearr, ...price];
          console.log("number", arr);
          setPriceArr(arr);

          tempFilters.lowerPrice = Math.min(...arr);
          tempFilters.upperPrice = Math.max(...arr);

          // tempFilters.price = [...price, ...tempFilters.price].sort(function (
          //   a,
          //   b
          // ) {
          //   return a - b;
          // });

          break;
        case "color":
          tempFilters.color = [...colorFilters];
          break;
        case "plating":
          tempFilters.plating = [...platingFilters];

          break;

        case "material":
          tempFilters.sortby = value;

          break;
        default:
          break;
      }
    } else {
      console.log(filterCategory, "filtercategory");

      switch (filterCategory) {
        case "price":
          const price = e.target.value.split(",").map(Number);
          let arr = pricearr;
          let index = arr.indexOf(price[0]);
          if (index > -1) {
            arr.splice(index, 1);
          }
          index = arr.indexOf(price[1]);
          if (index > -1) {
            arr.splice(index, 1);
          }

          // arr.filter((price) => price == price[0]);
          // arr.filter((price) => price == price[1]);
          console.log("number arr", arr);
          setPriceArr(arr);
          if (arr.length) {
            tempFilters.lowerPrice = Math.min(...arr);
            tempFilters.upperPrice = Math.max(...arr);
          } else {
            tempFilters.lowerPrice = "";
            tempFilters.upperPrice = "";
          }
          // if(arr.length === 0){
          //   setIsFilterSelected(false)
          // }

          // const lowIndex = tempFilters.price.indexOf(price[0]);
          // const upperIndex = tempFilters.price.indexOf(price[1]);
          // console.log("color working", lowIndex, upperIndex);

          // tempFilters.price.splice(lowIndex, 1);
          // tempFilters.price.splice(upperIndex, 1);

          break;
        case "color":
          tempFilters.color = [...colorFilters];
          console.log("temp color", tempFilters.color);
          break;
        case "plating":
          tempFilters.plating = [...platingFilters];
          break;
        default:
          break;
      }
    }

    dispatch(selectedTopFilters(tempFilters));
    setFilters(tempFilters);

    // console.log("color filter", tempFilters);
  };

  const handlePriceChange = (event, newValue) => {
    let tempFilters = { ...filters_top };
    tempFilters.lowerPrice = newValue[0];
    tempFilters.upperPrice = newValue[1];

    dispatch(selectedTopFilters(tempFilters));
    setFilters(tempFilters);
    setValue(newValue);
  };

  function handleSortby(value) {
    let tempFilters = { ...filters_top };

    if (value == "Default") {
      tempFilters.sortby = "";
    } else {
      tempFilters.sortby = value;
    }
    dispatch(selectedTopFilters(tempFilters));
    setFilters(tempFilters);
  }

  function handleClearAllFilters() {
    mixpanel.track("ClearFilter"); // mixpannel ka code
    dispatch(
      selectedTopFilters({
        color: [],
        plating: [],
        lowerPrice: "",
        upperPrice: "",
        sortby: "CREATEDTIME desc",
        catgFilter: [],
      })
    );
    setPriceArr([]);
    setColorFilters([]);
    setPlatingFilters([]);
    setFilters({
      color: [],
      plating: [],
      lowerPrice: "",
      upperPrice: "",
      sortby: "",
    });

    setProductsLength(30);
  }

  useEffect(() => {
    return () => {
      console.log("cleaned up");
      // handleClearAllFilters();
    };
    //eslint-disable-next-line
  }, []);

  return (
    <div className="row">
      <div className="col-10 p-2 ">
        <div className="row Custom_shop_FilterRow  justify-content-left">
          {/* <div className="shop-top-bar mb-35 pl-4"> */}
          {userDetails && userDetails?.user !== null ? (
            <div
              className="col-1 shop-select justify-content-center"
              style={{ marginRight: 30 }}
            >
              <Dropdown>
                <Dropdown.Toggle
                  style={{
                    background: "transparent",
                    color: "black",
                    border: "none",
                    boxShadow: "none",
                  }}
                >
                  Price
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-price">
                  {/* <div className="row">
                    <div col="6">
                      <input></input>
                    </div>
                    <div col="6">
                      <input></input>
                    </div>
                  </div> */}
                  {/* <Box
                  sx={{ width: 275, marginTop: "30px", marginBottom: "5px" }}
                > */}
                  {/* <div className="row">
                  <input
                    key={Math.random()}
                    type="checkbox"
                    className="filter-input checkbox-color"
                    name="color"
                    value={[0, 500]}
                    onChange={(e) => handleFilters(e)}
                    // checked={filters.color.includes(splitColor[0].trim())}
                  />
                  <label className="filter-label">below 500</label>
                </div> */}
                  {/* <Slider
                    value={value}
                    sx={{ marginLeft: "20px" }}
                    onChangeCommitted={handlePriceChange}
                    valueLabelDisplay="on"
                    min={minPrice}
                    max={maxPrice}
                  />
                  <div className="row  ml-4 ">
                    <div className="col-5">
                      <h4>Rs {value[0]}</h4>
                    </div>
                    <div className="col-2">
                      <h4>-</h4>
                    </div>
                    <div className="col-5">
                      {" "}
                      <h4>Rs {value[1]}</h4>
                    </div>
                  </div> */}
                  {/* </Box> */}
                  <Dropdown.Item className="dropdown-item-1">
                    <div className="row">
                      <input
                        type="checkbox"
                        key={1}
                        className="filter-input checkbox-color"
                        name="price"
                        value="1,500"
                        onChange={(e) => handleFilters(e)}
                        checked={pricearr.includes(1) && pricearr.includes(500)}
                      />
                      <label className="filter-label">Below ₹ 500</label>
                    </div>
                  </Dropdown.Item>

                  <Dropdown.Item className="dropdown-item-1">
                    <div className="row">
                      <input
                        key={2}
                        type="checkbox"
                        className="filter-input"
                        name="price"
                        value="501,1000"
                        onChange={(e) => handleFilters(e)}
                        checked={
                          pricearr.includes(501) && pricearr.includes(1000)
                        }
                      />
                      <label className="filter-label">₹ 501 - ₹ 1000</label>
                    </div>
                  </Dropdown.Item>

                  <Dropdown.Item className="dropdown-item-1">
                    <div className="row">
                      <input
                        type="checkbox"
                        className="filter-input"
                        name="price"
                        value="1001,2000"
                        onChange={(e) => handleFilters(e)}
                        key={3}
                        checked={
                          pricearr.includes(1001) && pricearr.includes(2000)
                        }
                      />
                      <label className="filter-label">₹ 1001 - ₹ 2000</label>
                    </div>
                  </Dropdown.Item>

                  <Dropdown.Item className="dropdown-item-1">
                    <div className="row">
                      <input
                        type="checkbox"
                        className="filter-input"
                        name="price"
                        value="2001,3000"
                        onChange={(e) => handleFilters(e)}
                        key={4}
                        checked={
                          pricearr.includes(2001) && pricearr.includes(3000)
                        }
                      />
                      <label className="filter-label">₹ 2001 - ₹ 3000</label>
                    </div>
                  </Dropdown.Item>

                  <Dropdown.Item className="dropdown-item-1">
                    <div className="row">
                      <input
                        type="checkbox"
                        className="filter-input"
                        name="price"
                        value="3000,50000"
                        onChange={(e) => handleFilters(e)}
                        key={5}
                        checked={
                          pricearr.includes(3000) && pricearr.includes(50000)
                        }
                      />
                      <label className="filter-label">₹ 3000 AND ABOVE</label>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : null}
          <div
            className="col-1 shop-select justify-content-center"
            style={{ marginRight: 40 }}
          >
            <Dropdown
              onToggle={(isOpen, e, metadata) => {
                if (
                  ["click", "rootClose"].some((ele) => ele === metadata.source)
                ) {
                  setColorDropdownVisible((pre) => !pre);
                }
              }}
              show={colorDropdownVisible}
            >
              <Dropdown.Toggle
                id="dropdown-autoclose-false"
                style={{
                  background: "transparent",
                  color: "black",
                  border: "none",
                  boxShadow: "none",
                }}
              >
                Colors
              </Dropdown.Toggle>
              <Dropdown.Menu
                style={{
                  width: "60vw",
                  position: "relative",
                  borderRadius: "10px",
                }}
                className="custom_colorfiltermenu colors_filter_menu_cards"
              >
                <div
                  style={{
                    height: "295px",
                    overflowY: "scroll",
                    marginBottom: "35px",
                  }}
                >
                  {colorNames.map((colorRow, index) => {
                    return (
                      <Dropdown.Item className="dropdown-item-1 hoverChange">
                        <div className="row mb-2">
                          {colorRow.map(([colorCode, colorLabel], index) => {
                            return (
                              <div
                                className="col-lg-3 col-6"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "15vw",
                                }}
                              >
                                <input
                                  key={Math.random()}
                                  type="checkbox"
                                  className="filter-input"
                                  name="color"
                                  value={colorCode}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    let temp = [];
                                    if (!colorFilters.includes(colorCode)) {
                                      temp = [...colorFilters, colorCode];
                                    } else {
                                      temp = colorFilters.filter(
                                        (code) => code !== colorCode
                                      );
                                    }
                                    console.log("FILTER -> ", temp);
                                    setColorFilters(temp);
                                  }}
                                  checked={colorFilters.includes(colorCode)}
                                />
                                <label className="filter-label">
                                  <div className="row">
                                    <div
                                      style={{
                                        marginLeft: "1.5vw",
                                        width: "22px",
                                        height: " 22px",
                                        borderRadius: "543.24px",
                                        border: "1px solid rgba(0, 0, 0, 0.15)",
                                        background:
                                          filterColors &&
                                          filterColors[colorCode]?.length === 1
                                            ? filterColors[colorCode][0]
                                            : `linear-gradient(125deg, ${filterColors[
                                                colorCode
                                              ]?.join(",")})`,
                                      }}
                                    />
                                    <div style={{ marginLeft: "0.5vw" }}>
                                      {colorLabel}
                                    </div>
                                  </div>
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </Dropdown.Item>
                    );
                  })}
                </div>
                <div
                  style={{
                    height: "50px",
                    boxShadow: "rgba(0, 0, 0, 0.05) 1px -18px 20px 0px",
                  }}
                >
                  <div
                    style={{
                      float: "right",
                      padding: "15px",
                    }}
                  >
                    <div
                      className="Custom_clearbtnsubclass btn"
                      style={{ marginRight: "10px" }}
                      onClick={() => setColorFilters([])}
                    >
                      {" "}
                      Clear Selection
                    </div>
                    <button
                      name="color"
                      className=" ml-auto mr-auto Register-btn"
                      style={{
                        background: "#14716b",
                        color: "white",
                        border: "none",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setColorDropdownVisible(false);
                        handleFilters(e);
                      }}
                    >
                      Show Result
                    </button>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>{" "}
          <div
            className="col-1 shop-select justify-content-center"
            style={{ marginRight: 40 }}
          >
            <Dropdown
              onToggle={(isOpen, e, metadata) => {
                if (
                  ["click", "rootClose"].some((ele) => ele === metadata.source)
                ) {
                  setPlatingDropdownVisible((pre) => !pre);
                }
              }}
              show={platingDropdownVisible}
            >
              <Dropdown.Toggle
                style={{
                  background: "transparent",
                  color: "black",
                  border: "none",
                  boxShadow: "none",
                }}
              >
                Plating
              </Dropdown.Toggle>
              <Dropdown.Menu
                style={{
                  width: "45vw",
                  position: "relative",
                  borderRadius: "10px",
                }}
                className="custom_colorfiltermenu custom_plating_card_menu"
              >
                <div
                  style={{
                    height: "210px",
                    overflowY: "scroll",
                    marginBottom: "35px",
                  }}
                >
                  {platingNames.map((platingRow, index) => {
                    return (
                      <Dropdown.Item className="dropdown-item-1 hoverChange">
                        <div className="row mb-2">
                          {platingRow.map(
                            ([platingCode, platingLabel], index) => {
                              return (
                                <div
                                  className="col-lg-4 col-6"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "15vw",
                                  }}
                                >
                                  <input
                                    key={Math.random()}
                                    type="checkbox"
                                    className="filter-input"
                                    name="color"
                                    value={platingCode}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      let temp = [];
                                      if (
                                        !platingFilters.includes(platingCode)
                                      ) {
                                        temp = [...platingFilters, platingCode];
                                      } else {
                                        temp = platingFilters.filter(
                                          (code) => code !== platingCode
                                        );
                                      }
                                      console.log("FILTER -> ", temp);
                                      setPlatingFilters(temp);
                                    }}
                                    checked={platingFilters.includes(
                                      platingCode
                                    )}
                                  />
                                  <label className="filter-label">
                                    <div className="row">
                                      {/* <div
                                        style={{
                                          marginLeft: "1vw",
                                          width: "22px",
                                          height: " 22px",
                                          borderRadius: "543.24px",
                                          border:
                                            "1px solid rgba(0, 0, 0, 0.15)",
                                          background:
                                            filterPlatings[platingCode]
                                              .length === 1
                                              ? filterPlatings[platingCode][0]
                                              : `linear-gradient(125deg, ${filterPlatings[
                                                  platingCode
                                                ].join(",")})`,
                                        }}
                                      /> */}
                                      <div style={{ marginLeft: "20px" }}>
                                        {platingLabel}
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </Dropdown.Item>
                    );
                  })}
                </div>
                <div
                  style={{
                    height: "50px",
                    boxShadow: "rgba(0, 0, 0, 0.05) 1px -18px 20px 0px",
                  }}
                >
                  <div
                    style={{
                      float: "right",
                      padding: "15px",
                    }}
                  >
                    <div
                      className="Custom_clearbtnsubclass btn"
                      style={{ marginRight: "10px" }}
                      onClick={() => setPlatingFilters([])}
                    >
                      {" "}
                      Clear Selection
                    </div>
                    <button
                      name="plating"
                      className=" ml-auto mr-auto Register-btn"
                      style={{
                        background: "#14716b",
                        color: "white",
                        border: "none",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setPlatingDropdownVisible(false);
                        handleFilters(e);
                      }}
                    >
                      Show Result
                    </button>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>{" "}
          <div
            className="col-1 shop-select justify-content-center"
            style={{ marginRight: 40 }}
          >
            <Dropdown>
              <Dropdown.Toggle
                style={{
                  background: "transparent",
                  color: "black",
                  border: "none",
                  boxShadow: "none",
                }}
              >
                Sort By
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {/* <Dropdown.Item>Price -High to Low</Dropdown.Item>

                  <Dropdown.Item>Price - Low to High</Dropdown.Item> */}

                <Dropdown.Item
                  onClick={() => handleSortby("CREATEDTIME desc")}
                  name="sortby"
                >
                  Date - New to Old
                </Dropdown.Item>

                <Dropdown.Item onClick={() => handleSortby("Createdtime asc")}>
                  Date - Old to New
                </Dropdown.Item>

                <Dropdown.Item onClick={() => handleSortby("Default")}>
                  Default
                </Dropdown.Item>
                {/* <Dropdown.Item>Popularity</Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {/* <div
            className="col-2 shop-select justify-content-center"
            style={{ marginRight: 40 }}
          >
            <Dropdown>
              <Dropdown.Toggle
                style={{
                  background: "transparent",
                  color: "black",
                  border: "none",
                  boxShadow: "none",
                }}
              >
                No of Products
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => setProductsLength(24)}
                  name="sortby"
                >
                  24
                </Dropdown.Item>

                <Dropdown.Item onClick={() => setProductsLength(50)}>
                  50
                </Dropdown.Item>

                <Dropdown.Item onClick={() => setProductsLength(75)}>
                  75
                </Dropdown.Item>

                <Dropdown.Item onClick={() => setProductsLength(100)}>
                  100
                </Dropdown.Item>

                <Dropdown.Item onClick={() => setProductsLength(150)}>
                  150
                </Dropdown.Item>

                <Dropdown.Item onClick={() => setProductsLength(200)}>
                  200
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div> */}
          {/* <div className="shop-select">
          <select
            onChange={(e) =>
              getFilterSortParams("filterSort", e.target.value)
            }
            className="topbar-dropdown"
          >
            <option value="default">Type</option>
            <option value="priceHighToLow">Price - High to Low</option>
            <option value="priceLowToHigh">Price - Low to High</option>
          </select>
        </div> */}
          {/* <div className="select-shoing-wrap">
      <div className="shop-select">
        <select
          onChange={(e) => getFilterSortParams("filterSort", e.target.value)}
        >
          <option value="default">Default</option>
          <option value="priceHighToLow">Price - High to Low</option>
          <option value="priceLowToHigh">Price - Low to High</option>
        </select>
      </div>

      <Select
        closeMenuOnSelect={false}
        components={animatedComponents}
        // defaultValue={[colourOptions[4], colourOptions[5]]}
        isMulti
        // options={colourOptions}
      />

      <p>
        Showing {sortedProductCount} of {productCount} result
      </p>
    </div>

    <div className="shop-tab">
      <button
        onClick={(e) => {
          getLayout("grid two-column");
          setActiveLayout(e);
        }}
      >
        <i className="fa fa-th-large" />
      </button>
      <button
        onClick={(e) => {
          getLayout("grid three-column");
          setActiveLayout(e);
        }}
      >
        <i className="fa fa-th" />
      </button>
      <button
        onClick={(e) => {
          getLayout("list");
          setActiveLayout(e);
        }}
      >
        <i className="fa fa-list-ul" />
      </button>
    </div> */}
          {/* </div> */}
          <div className="col-2 shop-select Custom_shop_btn ">
            <Checkbox
              edge="start"
              disabled={!products?.length}
              checked={
                selectedProducts.length &&
                selectedProducts?.length === productsList?.length
              }
              tabIndex={-1}
              disableRipple
              size="small"
              className="checkbox-color"
              onChange={(e) => handleSelectAll(e)}
            />
            <p className="select-all">Select All</p>
          </div>
          <div className="col-2 shop-select justify-content-center Custom_clearall_btn">
            <button
              className="Custom_clearbtnsubclass btn"
              onClick={() =>
                filters.color.length > 0 ||
                filters.plating.length > 0 ||
                filters.catgFilter.length > 0 ||
                filters.lowerPrice ||
                filters.upperPrice ||
                filters.sortby !== "CREATEDTIME desc"
                  ? handleClearAllFilters()
                  : null
              }
            >
              {" "}
              Clear Filter
            </button>

            {/* <div className="Custom_product_count">
            {productsList.length} Products
            </div> */}
          </div>
          {/* Syed */}
          <div className="filter-btn">
            <button onClick={toggleFilterbar}>Categories</button>
          </div>
        </div>
      </div>
    </div>
  );
};

ShopTopAction.propTypes = {
  getFilterSortParams: PropTypes.func,
  getLayout: PropTypes.func,
  productCount: PropTypes.number,
  sortedProductCount: PropTypes.number,
};

export default ShopTopAction;
