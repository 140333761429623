import { ActionTypes } from "../constants/action-type";

//add to cart
export default function addToCart(item_info) {
  return {
    type: ActionTypes.ADD_TO_CART,
    payload: item_info,
  };
}
//decrease from cart
// export const decreaseQuantity = (item, addToast) => {
//   return (dispatch) => {
//     if (addToast) {
//       addToast("Item Decremented From Cart", {
//         appearance: "warning",
//         autoDismiss: true,
//       });
//     }
//     dispatch({ type: DECREASE_QUANTITY, payload: item });
//   };
// };
//delete from cart
// export const deleteFromCart = (item, addToast) => {
//   return (dispatch) => {
//     if (addToast) {
//       addToast("Removed From Cart", { appearance: "error", autoDismiss: true });
//     }
//     dispatch({ type: DELETE_FROM_CART, payload: item });
//   };
// };
// //delete all from cart
// export const deleteAllFromCart = (addToast) => {
//   return (dispatch) => {
//     if (addToast) {
//       addToast("Removed All From Cart", {
//         appearance: "error",
//         autoDismiss: true,
//       });
//     }
//     dispatch({ type: DELETE_ALL_FROM_CART });
//   };
// };

// // get stock of cart item
// export const cartItemStock = (item, color, size) => {
//   if (item.stock) {
//     return item.stock;
//   } else {
//     return item.variation
//       .filter((single) => single.color === color)[0]
//       ?.size.filter((single) => single.name === size)[0].stock;
//   }
// };
