import React from "react";
// import FontAwesomeIcon from "@fortawesome/fontawesome-svg-core"
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import "./progressBar.css";
function ProgressBar() {
  return (
    <div>
      <div className="container px-1 px-md-4 pb-5 mx-auto">
        <div className="card">
          <div className="row d-flex justify-content-center">
            <div className="col-12">
              <ul id="progressbar" className="text-center custom_progressbar">
                <li className="active step0"></li>
                <li className="active step0"></li>
                <li className="active step0"></li>
                <li className="active step0"></li>
                <li className="step0"></li>
              </ul>
            </div>
          </div>
          <div className="row justify-content-between top">
            <div className="row d-flex icon-content">
              {" "}
              <img className="icon" src="https://i.imgur.com/9nnc9Et.png" />
              {/* <FontAwesomeIcon icon="fa-brands fa-first-order" /> */}
              {/* <BookmarkBorderOutlinedIcon /> */}
              <div className="d-flex flex-column">
                <p className="font-weight-bold">
                  Order
                  <br />
                  Processed
                </p>
              </div>
            </div>
            <div className="row d-flex icon-content">
              {" "}
              <img className="icon" src="https://i.imgur.com/u1AzR7w.png" />
              <div className="d-flex flex-column">
                <p className="font-weight-bold">
                  Order
                  <br />
                  En Route
                </p>
              </div>
            </div>
            <div className="row d-flex icon-content">
              {" "}
              <img className="icon" src="https://i.imgur.com/TkPm63y.png" />
              <div className="d-flex flex-column">
                <p className="font-weight-bold">
                  Order
                  <br />
                  Shipped
                </p>
              </div>
            </div>
            <div className="row d-flex icon-content">
              {" "}
              <img className="icon" src="https://i.imgur.com/HdsziHP.png" />
              <div className="d-flex flex-column">
                <p className="font-weight-bold">
                  Order
                  <br />
                  Delivered
                </p>
              </div>
            </div>
            <div className="row d-flex icon-content">
              {" "}
              <img className="icon" src="https://i.imgur.com/HdsziHP.png" />
              <div className="d-flex flex-column">
                <p className="font-weight-bold">
                  Payment
                  <br />
                  Complete
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProgressBar;
