import React from "react";
import { NavLink } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import mixpanel from "mixpanel-browser";
import { Mixpanel } from "../../utils/mixpannel";


const ClickOnManangeAddress = () => {
  mixpanel.track("MyAccount_ManangeAddress");
}
const ClickOnPersonalDetails = () => {
  mixpanel.track("MyAccount_PersonalDetails");
}
const ClickOnBusinessDetails = () => {
  mixpanel.track("MyAccount_BusinessDetails");
}


function SideBar(props) {
  console.log("sidebar props", props);
  return (
    <>
      <div className="navbar-fixed-left sidebar-mob ml-3 mr-3 sidebar-white custon_sidebar_myaccount myaccountside-bar-white">
        <ul
          className="nav navbar-nav custom_myaccount_onactive"
          // style={{ backgroundColor: "#f5f5f5", color: "#000;" }}
        >
          <li className="link-hover font-fam pt-3 pb-3 btn-background border-bottom">
            <div className="row">
              <div className="col-10 custom_myaccount_divalign text-center ">
                <NavLink to="#" className="a-link  text-white ml-4 h5 ">
                  My Account
                </NavLink>
              </div>
              {/* <div className="col-2 text-white p-0">
                <ChevronRightIcon sx={{ fontSize: "2rem" }} />
              </div> */}
            </div>
          </li>
          <li
            className={
              props.sidebar == 1
                ? "link-hover active-link  font-fam pt-3 pb-3 border-bottom"
                : "link-hover bg-white font-fam pt-3 pb-3 border-bottom"
            }
          >
            <div className="row">
              <div
                className="col-10 custom_myaccount_divalign text-center"
                onClick={() => props.setSidebarValue(1)}
              >
                <NavLink
                  to="#"
                  className={
                    props.sidebar == 1
                      ? "active-link ml-4 h6"
                      : "sidebar-title  ml-4 h6"
                  }
                  onClick={ClickOnPersonalDetails}
                >
                  Personal Details
                </NavLink>
              </div>
              <div className="col-2 p-0">
                <ChevronRightIcon sx={{ fontSize: "2rem" }} />
              </div>
            </div>
          </li>
          <li
            className={
              props.sidebar == 2
                ? "link-hover active-link  font-fam pt-3 pb-3 border-bottom"
                : "link-hover bg-white font-fam pt-3 pb-3 border-bottom"
            }
          >
            <div className="row">
              <div
                className="col-10 custom_myaccount_divalign text-center"
                onClick={() => props.setSidebarValue(2)}
              >
                <NavLink
                  to="#"
                  className={
                    props.sidebar == 2
                      ? "active-link ml-4 h6"
                      : "sidebar-title  ml-4 h6"
                  }
                  onClick={ClickOnBusinessDetails}
                >
                  Business Details
                </NavLink>
              </div>
              <div className="col-2 p-0">
                <ChevronRightIcon sx={{ fontSize: "2rem" }} />
              </div>
            </div>
          </li>
          {/* <li className="link-hover  bg-white font-fam pt-3 pb-3 border-bottom">
            <div className="row">
              <div className="col-10 custom_myaccount_divalign text-center" onClick={()=>props.setSidebarValue(3)}>
                <NavLink to="#" className=" sidebar-title ml-4 h6">
                  Additional details
                </NavLink>
              </div>
              <div className="col-2 p-0">
                <ChevronRightIcon sx={{ fontSize: "2rem" }} />
              </div>
            </div>
          </li> */}
          {/* <li className="link-hover  bg-white font-fam pt-3 pb-3 border-bottom">
            <div className="row">
              <div className="col-10 custom_myaccount_divalign text-center" onClick={()=>props.setSidebarValue(4)}>
                <NavLink to="#" className=" sidebar-title ml-4 h6">
                  Bank Details
                </NavLink>
              </div>
              <div className="col-2 p-0">
                <ChevronRightIcon sx={{ fontSize: "2rem" }} />
              </div>
            </div>
          </li> */}
          {/* <hr className="sidebar-hr" />
              <li className="link-hover font-fam">
                <NavLink to="/size" className="a-link">
                  SAVED SIZES
                </NavLink>
              </li> */}
          <li
            className={
              props.sidebar == 5
                ? "link-hover active-link  font-fam pt-3 pb-3 border-bottom"
                : "link-hover bg-white font-fam pt-3 pb-3 border-bottom"
            }
          >
            <div className="row">
              <div
                className="col-10 custom_myaccount_divalign text-center"
                onClick={() => props.setSidebarValue(5)}
              >
                <NavLink
                  to="#"
                  className={
                    props.sidebar == 5
                      ? "active-link ml-4 h6"
                      : "sidebar-title  ml-4 h6"
                  }
                  onClick={ClickOnManangeAddress}
                >
                  Manage Address
                </NavLink>
              </div>
              <div className="col-2 p-0">
                <ChevronRightIcon sx={{ fontSize: "2rem" }} />
              </div>
            </div>
          </li>
{/* User Mode is Hidden */}
          {/* <li
            className={
              props.sidebar == 6
                ? "link-hover active-link  font-fam pt-3 pb-3 border-bottom"
                : "link-hover bg-white font-fam pt-3 pb-3 border-bottom"
            }
          >
            <div className="row">
              <div
                className="col-10 custom_myaccount_divalign text-center"
                onClick={() => props.setSidebarValue(6)}
              >
                <NavLink
                  to="#"
                  className={
                    props.sidebar == 6
                      ? "active-link ml-4 h6"
                      : "sidebar-title  ml-4 h6"
                  }
                >
                  User Mode
                </NavLink>
              </div>
              <div className="col-2 p-0">
                <ChevronRightIcon sx={{ fontSize: "2rem" }} />
              </div>
            </div>
          </li> */}

          <li
            className={
              props.sidebar == 7
                ? "link-hover active-link  font-fam pt-3 pb-3 border-bottom cust_myaccount_botborder"
                : "link-hover bg-white font-fam pt-3 pb-3 border-bottom cust_myaccount_botborder"
            }
          >
            <div className="row">
              <div
                className="col-10 custom_myaccount_divalign text-center"
                onClick={() => props.setSidebarValue(7)}
              >
                <NavLink
                  to="#"
                  className={
                    props.sidebar == 7
                      ? "active-link ml-4 h6"
                      : "sidebar-title  ml-4 h6"
                  }
                >
                  Credit Info
                </NavLink>
              </div>
              <div className="col-2 p-0">
                <ChevronRightIcon sx={{ fontSize: "2rem" }} />
              </div>
            </div>
          </li>
          {/* <li className="link-hover bg-white font-fam pt-3 pb-3 border-bottom">
            <div className="row">
              <div className="col-10 custom_myaccount_divalign text-center" onClick={()=>props.setSidebarValue(6)}>
                <NavLink to="/myorders" className="a-link sidebar-title ml-4 h6">
                  My Orders
                </NavLink>
              </div>
              <div className="col-2 p-0">
                <ChevronRightIcon sx={{ fontSize: "2rem" }} />
              </div>
            </div>
          </li> */}
        </ul>
      </div>
    </>
  );
}

export default SideBar;
