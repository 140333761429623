import { ActionTypes } from "../constants/action-type";



export const  userInformation=(userInformation)=> {
  console.log("userCheckForData" , userInformation)
  return {
    type: ActionTypes.SET_USERINFORMATION,
    payload: userInformation,
  };
}
