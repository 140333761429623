import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import "./account.css";
import SideBar from "../account/Sidebar";
import PersonalDetails from "./PersonalDetails";
import BankDetails from "./BankDetails";
import AdditionalDetails from "./AdditionalDetails";
import BusinessDetails from "./BusinessDetails";
import ManageAddress from "./ManageAddress";
import CreditInfo from "./CreditInfo";
import UserMode from "./UserMode";
const Account = ({ location }) => {
  const { pathname } = location;
  const { addToast } = useToasts();
  const [sidebar, setSidebar] = useState(1);
  return (
    <Fragment>
      <MetaTags>
        <title>Amyraa | My Account</title>
        <meta
          name="description"
          content="Compare page of flone react minimalist eCommerce template."
        />
      </MetaTags>
      {/* <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>
        My Account
      </BreadcrumbsItem> */}
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        {/* <Breadcrumb /> */}
        <div className="compare-main-area pt-90 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-3 myAccount-ColBar">
                <SideBar
                  setSidebarValue={(val) => setSidebar(val)}
                  sidebar={sidebar}
                />
              </div>
              <div className="col-9 sidebar-whitee pt-5 pb-5">
                {sidebar == 1 ? (
                  <PersonalDetails />
                ) : sidebar == 2 ? (
                  <BusinessDetails />
                ) : sidebar == 3 ? (
                  <AdditionalDetails />
                ) : sidebar == 4 ? (
                  <BankDetails />
                ) : sidebar == 5 ? (
                  <ManageAddress />
                ) : sidebar == 6 ? (
                  <UserMode />
                ) : (
                  <div className="row">
                    <div className="col-lg-8 col-12">
                      <CreditInfo />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default Account;
