import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./account.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useToasts } from "react-toast-notifications";
import Apilib from "../../utils/api";
import Loader from "../../helpers/Loader";
// import PercentRoundedIcon from '@mui/icons-material/PercentRounded';
import AddIcon from "@mui/icons-material/Add";
import setUser from "../../redux/actions/userActions";
import Radio from "@mui/material/Radio";

export default function UserMode() {
  const [userDetails, setUserDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [userMode, setUserMode] = useState(2);
  const [markupPrice, setMarkupPrice] = useState(1);
  const [addedPrice, setAddedPrice] = useState("");
  const { addToast } = useToasts();
  const API = new Apilib();
  const dispatch = useDispatch();

  useEffect(() => {
    FetchUserDetails();
    //eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   setAddedPrice("");
  //   setMarkupPrice(1);
  // }, [userMode]);

  function FetchUserDetails() {
    setLoading(true);

    API.GetUserDetails(localStorage?.amyra_email)
      .then((res) => {
        console.log("user Details", res.data);
        let userInfo = { ...res.data[0] };
        // dispatch(setUserDetails(userDetails));
        setUserDetails(userInfo);
        setUserMode(userInfo.RATEMODE);
        setMarkupPrice(userInfo.RATETYPE);
        setAddedPrice(userInfo.RATEVALUE);
        setLoading(false);
        dispatch(setUser(userInfo));
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }

  function SubmitUserMode() {
    if (userMode == 3 && !addedPrice) {
      addToast("Markup price is required", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    setLoading(true);
    let priceType;
    let newPrice;
    if (userMode == 3) {
      priceType = markupPrice;
      newPrice = addedPrice;
    } else {
      priceType = "";
      newPrice = "";
    }
    // if (priceType == 1 && newPrice > 99) {
    //   addToast("Invalid Markup Price", {
    //     appearance: "error",
    //     autoDismiss: true,
    //   });
    //   setLoading(false);
    //   return;
    // }
    API.ChangeUserMode(userDetails.ID, userMode, priceType, newPrice)
      .then((res) => {
        FetchUserDetails();
        setLoading(false);
        addToast("User mode updated successfully", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }

  return (
    <div>
      {loading ? <Loader /> : null}
      <div className="ml-5 overflow-hidden">
        <div className="title-text-bold sidebar-title h2">
          User Mode
          {/* <button
            className="btn btn-background border-round text-white edit-btn mx-4 btn-sm"
            onClick={() => setEditField(!editField)}
          >
            {editField ? "Edit" : "Cancel"}
          </button> */}
        </div>
        <hr />
        <div className="mt-2 mr-4 pr-4">
          <form>
            <div className="row font-weight-bold text-black ">
              <div className="col-12">
                <div
                  className="custom_business_radio"
                  style={{ justifyContent: "left" }}
                >
                  <Radio
                    onClick={() => setUserMode(2)}
                    checked={userMode == 2}
                  />
                  {/* <input
                    type="radio"
                    onClick={() => setUserMode(2)}
                    checked={userMode == 2}
                  /> */}
                  <label style={{ marginTop: 10 }}>Show base price</label>
                  <Radio
                    onClick={() => setUserMode(3)}
                    checked={userMode == 3}
                  />
                  {/* <input
                    type="radio"
                    onClick={() => setUserMode(3)}
                    checked={userMode == 3}
                  /> */}
                  <label style={{ marginTop: 10 }}>Show mark-up price </label>
                  <Radio
                    onClick={() => setUserMode(1)}
                    checked={userMode == 1}
                  />
                  {/* <input
                    type="radio"
                    onClick={() => setUserMode(1)}
                    checked={userMode == 1}
                  /> */}
                  <label style={{ marginTop: 10 }}>
                    Do not show base price
                  </label>
                </div>
              </div>

              {userMode == 3 ? (
                <div className="col-12">
                  <div
                    className="custom_business_radio"
                    style={{ justifyContent: "left", fontWeight: 500 }}
                  >
                    <Radio
                      onClick={() => setMarkupPrice(1)}
                      checked={markupPrice == 1}
                    />
                    {/* <input
                      type="radio"
                      onClick={() => setMarkupPrice(1)}
                      checked={markupPrice == 1}
                    /> */}
                    <label style={{ marginTop: 10 }}>Percentage </label>
                    <Radio
                      onClick={() => setMarkupPrice(2)}
                      checked={markupPrice == 2}
                    />
                    {/* <input
                      type="radio"
                      onClick={() => setMarkupPrice(2)}
                      checked={markupPrice == 2}
                    /> */}
                    <label style={{ marginTop: 10 }}>Add fix amount</label>
                  </div>
                </div>
              ) : null}

              {userMode == 3 && markupPrice != "" ? (
                <div className="col-6 mt-4" style={{ display: "flex" }}>
                  <input
                    type="number"
                    style={{ width: "60%" }}
                    class="form-control "
                    placeholder={
                      markupPrice == 1
                        ? "Enter mark-up percent"
                        : "Enter mark-up price"
                    }
                    onChange={(e) => setAddedPrice(e.target.value)}
                    value={addedPrice}
                  />

                  {markupPrice == 1 ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                      style={{ marginLeft: 10, width: "4%" }}
                    >
                      <path d="M374.6 73.39c-12.5-12.5-32.75-12.5-45.25 0l-320 320c-12.5 12.5-12.5 32.75 0 45.25C15.63 444.9 23.81 448 32 448s16.38-3.125 22.62-9.375l320-320C387.1 106.1 387.1 85.89 374.6 73.39zM64 192c35.3 0 64-28.72 64-64S99.3 64.01 64 64.01S0 92.73 0 128S28.7 192 64 192zM320 320c-35.3 0-64 28.72-64 64s28.7 64 64 64s64-28.72 64-64S355.3 320 320 320z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                      style={{ marginLeft: 10, width: "4%" }}
                    >
                      <path d="M.0022 64C.0022 46.33 14.33 32 32 32H288C305.7 32 320 46.33 320 64C320 81.67 305.7 96 288 96H231.8C241.4 110.4 248.5 126.6 252.4 144H288C305.7 144 320 158.3 320 176C320 193.7 305.7 208 288 208H252.4C239.2 266.3 190.5 311.2 130.3 318.9L274.6 421.1C288.1 432.2 292.3 452.2 282 466.6C271.8 480.1 251.8 484.3 237.4 474L13.4 314C2.083 305.1-2.716 291.5 1.529 278.2C5.774 264.1 18.09 256 32 256H112C144.8 256 173 236.3 185.3 208H32C14.33 208 .0022 193.7 .0022 176C.0022 158.3 14.33 144 32 144H185.3C173 115.7 144.8 96 112 96H32C14.33 96 .0022 81.67 .0022 64V64z" />
                    </svg>
                  )}
                </div>
              ) : null}
              <div
                className={
                  userMode != 3
                    ? "col-6 mt-4 text-right"
                    : "col-4 mt-4 text-right"
                }
              >
                <div
                  style={{ backgroundColor: " #14716b" }}
                  className="custom_search_clearbtn-1 btn col-3 mr-4 save-button-modal"
                  onClick={() => SubmitUserMode()}
                >
                  {" "}
                  <h4>Save</h4>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
