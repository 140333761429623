import React from "react";
import FeatureIconTwo from "../../wrappers/feature-icon/FeatureIconTwo";
import { useSelector } from "react-redux";








function WhyUs() {

  const mylanguage = useSelector((state) => state.language)


  const why = {
    English: "Why",
    Hindi: " क्यों",
    Spanish: "Por",
    French: "Pourquoi",
    Tamil: "ஏன",
    Telugu: "ఎందుకు",
    }
    
    const us = {
    English: "Us",
    Hindi:  "हम",
    Spanish: "nosotros",
    French: "nous",
    Tamil: "எங்களை",
    Telugu: "మేము",
    }
    const WhyLanguage = why[mylanguage]
const usLanguage = us[mylanguage]




  return (
    <>
      <div class=" display-flex justify-content-center">
        <h1 class="text-black text-center text-title">{WhyLanguage}</h1>
        <h1 class="text-green pt-1 pb-4">{usLanguage}</h1>
      </div>
      <div className="container">
        <FeatureIconTwo spaceTopClass="pt-20" />
        {/* <FeatureIconTwo  /> */}
      </div>
    </>
  );
}

export default WhyUs;
