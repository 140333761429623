import React, { useState } from 'react';
import { Modal, Radio, Box, Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { Typography } from '@material-ui/core';
import Popover from "@mui/material/Popover";
import { useSelector } from "react-redux";
import { useEffect } from 'react';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import mixpanel from "mixpanel-browser";


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width:"70%",
    transform: "translate(-50%, -50%)",
  };

export default function SharePriceModal({show,onClose,data}) {
  // const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userMode, setUserMode] = useState(2); // Initialize as per your requirement
  const [markupPrice, setMarkupPrice] = useState(1); // Initialize as per your requirement
  const [addedPrice, setAddedPrice] = useState('');
  const [shareLink, setShareLink] = useState();
  const [finalShareLink, setFinalShareLink] = useState(null);
  const userDetails = useSelector((state) => state.userDetails);


console.log(addedPrice,"addedprice")

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
 
  console.log("data_data_data",data)
  console.log("dataaa_dataaaa",userDetails)

// const [open, setOpen] = useState(false);
//   const handleOpen = () => {
//     setOpen(true);
//     // Additional logic when modal opens
//   };
  // const handleClose = () => {
  //   setmodalOpen(false);
  //   // Additional logic when modal closes
  // };
  
  useEffect(() => {
    if (data) {
      CreateShareLink();
    }
    setUserMode(2);
    setMarkupPrice(1);
    setAddedPrice("");
    setFinalShareLink("");
    // eslint-disable-next-line
  }, [data]);



  const CreateShareLink = () => {
    let userId = Buffer.from(`${userDetails.user.ID}-3`).toString("base64");
    let base_design = data.BASEDESIGN;
    let dcode = data.DCODE;
    // let link = `https://catalogcollection.web.app/catalouge/product/${userId}/${base_design}/${dcode}/`;
    let link =  `https://catalogcollection.web.app/catalouge/product/${userId}/${base_design}/${dcode}/` ;
    setShareLink(link);
  };
  

  // 
  const formatProductDetails = () => {
    const productName = data.INAME;
    const category = data.CATEGORY;
    const subCategory = data.SCATG;
    const weight = data.WEIGHT;
    const productLink = finalShareLink;
    // Create a formatted string with product details
    const productInfo = `Product Name: ${productName}\nCategory: ${category}\nSub Category: ${subCategory}\nWeight: ${weight}gms\nLink: ${productLink}`;
    return productInfo;
  }
  // 
  const handleWhatsAppShare = () => {
    const productInfo = formatProductDetails();
    const encodedInfo = encodeURIComponent(productInfo);
    const whatsappURL = `https://api.whatsapp.com/send?text=${encodedInfo}`;
    window.open(whatsappURL, '_blank');
  };
  // 

  const handleSubmit = () => {
    // Logic for saving data goes here
    const rateMode = userMode;
    const rateType = markupPrice;
    const rateValue = addedPrice;
    const str = `${rateMode}@${rateType}@${rateValue}`;
    const encodedStr = Buffer.from(str).toString("base64");
    setFinalShareLink(shareLink  + encodedStr + "/0");
    mixpanel.track("UserShareSingleProduct", { DesignId: JSON.stringify(data.DCODE) });  // MixPannel ka code
  };


  return (
    // <div>
    //   <Modal open={Open} onClose={handleClose}>
    //     <Box sx={style} className="ml-5 overflow-hidden">
    //       <hr />
    //       <div style={{background:"white",width:"",padding:"20px"}}>
    //       <div className="mt-2 mr-4 pr-4">
    //       <CloseIcon  onClick={handleClose} style={{ float: 'right', cursor: 'pointer'}}  />
    //       <form>
    //         <div className="row font-weight-bold text-black ">
    //           <div className="col-12" style={{marginTop:"20px"}} >
    //             <div
    //               className="custom_business_radio"
    //               style={{ justifyContent: "left" }}
    //             >
    //               <Radio
    //                 onClick={() => setUserMode(2)}
    //                 checked={userMode == 2}
    //               />
    //               {/* <input
    //                 type="radio"
    //                 onClick={() => setUserMode(2)}
    //                 checked={userMode == 2}
    //               /> */}
    //               <label style={{ marginTop: 10 }}>Show base price</label>
    //               <Radio
    //                 onClick={() => setUserMode(3)}
    //                 checked={userMode == 3}
    //               />
    //               {/* <input
    //                 type="radio"
    //                 onClick={() => setUserMode(3)}
    //                 checked={userMode == 3}
    //               /> */}
    //               <label style={{ marginTop: 10 }}>Show mark-up price </label>
    //               <Radio
    //                 onClick={() => setUserMode(1)}
    //                 checked={userMode == 1}
    //               />
    //               {/* <input
    //                 type="radio"
    //                 onClick={() => setUserMode(1)}
    //                 checked={userMode == 1}
    //               /> */}
    //               <label style={{ marginTop: 10 }}>
    //                 Do not show base price
    //               </label>
    //             </div>
    //           </div>

    //           {userMode == 3 ? (
    //             <div className="col-12">
    //               <div
    //                 className="custom_business_radio"
    //                 style={{ justifyContent: "left", fontWeight: 500 }}
    //               >
    //                 <Radio
    //                   onClick={() => setMarkupPrice(1)}
    //                   checked={markupPrice == 1}
    //                 />
    //                 {/* <input
    //                   type="radio"
    //                   onClick={() => setMarkupPrice(1)}
    //                   checked={markupPrice == 1}
    //                 /> */}
    //                 <label style={{ marginTop: 10 }}>Percentage </label>
    //                 <Radio
    //                   onClick={() => setMarkupPrice(2)}
    //                   checked={markupPrice == 2}
    //                 />
    //                 {/* <input
    //                   type="radio"
    //                   onClick={() => setMarkupPrice(2)}
    //                   checked={markupPrice == 2}
    //                 /> */}
    //                 <label style={{ marginTop: 10 }}>Add fix amount</label>
    //               </div>
    //             </div>
    //           ) : null}

    //           {userMode == 3 && markupPrice != "" ? (
    //             <div className="col-6 mt-4" style={{ display: "flex" }}>
    //               <input
    //                 type="number"
    //                 style={{ width: "60%" }}
    //                 class="form-control "
    //                 placeholder={
    //                   markupPrice == 1
    //                     ? "Enter mark-up percent"
    //                     : "Enter mark-up price"
    //                 }
    //                 onChange={(e) => setAddedPrice(e.target.value)}
    //                 value={addedPrice}
    //               />

    //               {markupPrice == 1 ? (
    //                 <svg
    //                   xmlns="http://www.w3.org/2000/svg"
    //                   viewBox="0 0 384 512"
    //                   style={{ marginLeft: 10, width: "4%" }}
    //                 >
    //                   <path d="M374.6 73.39c-12.5-12.5-32.75-12.5-45.25 0l-320 320c-12.5 12.5-12.5 32.75 0 45.25C15.63 444.9 23.81 448 32 448s16.38-3.125 22.62-9.375l320-320C387.1 106.1 387.1 85.89 374.6 73.39zM64 192c35.3 0 64-28.72 64-64S99.3 64.01 64 64.01S0 92.73 0 128S28.7 192 64 192zM320 320c-35.3 0-64 28.72-64 64s28.7 64 64 64s64-28.72 64-64S355.3 320 320 320z" />
    //                 </svg>
    //               ) : (
    //                 <svg
    //                   xmlns="http://www.w3.org/2000/svg"
    //                   viewBox="0 0 320 512"
    //                   style={{ marginLeft: 10, width: "4%" }}
    //                 >
    //                   <path d="M.0022 64C.0022 46.33 14.33 32 32 32H288C305.7 32 320 46.33 320 64C320 81.67 305.7 96 288 96H231.8C241.4 110.4 248.5 126.6 252.4 144H288C305.7 144 320 158.3 320 176C320 193.7 305.7 208 288 208H252.4C239.2 266.3 190.5 311.2 130.3 318.9L274.6 421.1C288.1 432.2 292.3 452.2 282 466.6C271.8 480.1 251.8 484.3 237.4 474L13.4 314C2.083 305.1-2.716 291.5 1.529 278.2C5.774 264.1 18.09 256 32 256H112C144.8 256 173 236.3 185.3 208H32C14.33 208 .0022 193.7 .0022 176C.0022 158.3 14.33 144 32 144H185.3C173 115.7 144.8 96 112 96H32C14.33 96 .0022 81.67 .0022 64V64z" />
    //                 </svg>
    //               )}
    //             </div>
    //           ) : null}
    //           <div
    //             className={
    //               userMode != 3
    //                 ? "col-6 mt-4 text-right"
    //                 : "col-4 mt-4 text-right"
    //             }
    //           >
    //             <div
    //               style={{ backgroundColor: " #14716b" }}
    //               className="custom_search_clearbtn-1 btn col-3 mr-4 save-button-modal"
    //               onClick={() => handleSubmit()}
    //             >
    //               {" "}
    //               <h4>Save</h4>
    //             </div>
    //           </div>
    //         </div>
    //       </form>

    //       {finalShareLink && (
    //       <div className="row mt-3">
    //         <div className="col-12 mb-2">Catalog Link</div>
    //         <div className="col-10 pr-0 mb-3">
    //           <input
    //             type="text"
    //             class="form-control"
    //             id="exampleInputEmail1"
    //             aria-describedby="emailHelp"
    //             value={finalShareLink}
    //             placeholder="https://catalog.amyra.com/..."
    //             disabled={true}
    //           />
    //         </div>
    //         <div className="col-2  mt-2">
    //           <ContentCopyOutlinedIcon
    //             aria-describedby={id}
    //             onClick={(event) => {
    //               navigator.clipboard.writeText(finalShareLink);
    //               handleClick(event);
    //             }}
    //             className="pointer"
    //           />
    //           <Popover
    //             id={id}
    //             open={open}
    //             anchorEl={anchorEl}
    //             onClose={handleClose}
    //             anchorOrigin={{
    //               vertical: "bottom",
    //               horizontal: "left",
    //             }}>
    //             <Typography sx={{ p: 2 }}>Catalog link copied</Typography>
    //           </Popover>
    //         </div>
    //       </div>
    //     )}

    //     </div>
    //     </div>
    //     </Box>
    //   </Modal>
    // </div>
    <Dialog open={show} onClose={onClose} fullWidth maxWidth="md">
    <div
      className="row"
      style={{
        justifyContent: "space-between",
        width: "100%",
        paddingRight: "7px",
        paddingTop: "10px",
      }}>
      <div />
      <div style={{ cursor: "pointer" }} onClick={onClose}>
        <CloseIcon/>
      </div>
    </div>
    <Box style={{ padding: 20, paddingTop: 5 }}>
      <form>
        <div className="row font-weight-bold text-black ">
          <div className="col-12">
            <div
              className="custom_business_radio"
              style={{ justifyContent: "left" }}>
              <Radio
                onClick={() => setUserMode(2)}
                checked={userMode === 2}
              />
              <label>Show base price</label>
              <Radio
                onClick={() => setUserMode(3)}
                checked={userMode === 3}
              />
              <label>Show mark-up price </label>
              <Radio
                onClick={() => setUserMode(1)}
                checked={userMode === 1}
              />
              <label>Do not show base price</label>
            </div>
          </div>
          {userMode === 3 ? (
            <div className="col-12">
              <div
                className="custom_business_radio"
                style={{ justifyContent: "left", fontWeight: 500 }}>
                <Radio
                  onClick={() => setMarkupPrice(1)}
                  checked={markupPrice === 1}
                />
                <label>Percentage </label>
                <Radio
                  onClick={() => setMarkupPrice(2)}
                  checked={markupPrice === 2}
                />
                <label>Add fix amount</label>
              </div>
            </div>
          ) : null}

          {userMode === 3 && markupPrice !== "" ? (
            <div className="col-6 mt-4" style={{ display: "flex" }}>
              <input
                type="number"
                style={{ width: "60%" }}
                class="form-control "
                placeholder={
                  markupPrice === 1
                    ? "Enter mark-up percent"
                    : "Enter mark-up price"
                }
// onChange={(e) => setAddedPrice(e.target.value)}
                // Bug Solve By Syed -  Dont accept negative value
                
                onChange={(e) => {
    const inputValue = parseInt(e.target.value);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setAddedPrice(inputValue);
    } else {
      setAddedPrice(''); // Reset addedPrice if the input is invalid
    }
  }}
         

value={addedPrice}
              />

              {markupPrice === 1 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                  style={{ marginLeft: 10, width: "4%" }}>
                  <path d="M374.6 73.39c-12.5-12.5-32.75-12.5-45.25 0l-320 320c-12.5 12.5-12.5 32.75 0 45.25C15.63 444.9 23.81 448 32 448s16.38-3.125 22.62-9.375l320-320C387.1 106.1 387.1 85.89 374.6 73.39zM64 192c35.3 0 64-28.72 64-64S99.3 64.01 64 64.01S0 92.73 0 128S28.7 192 64 192zM320 320c-35.3 0-64 28.72-64 64s28.7 64 64 64s64-28.72 64-64S355.3 320 320 320z" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  style={{ marginLeft: 10, width: "4%" }}>
                  <path d="M.0022 64C.0022 46.33 14.33 32 32 32H288C305.7 32 320 46.33 320 64C320 81.67 305.7 96 288 96H231.8C241.4 110.4 248.5 126.6 252.4 144H288C305.7 144 320 158.3 320 176C320 193.7 305.7 208 288 208H252.4C239.2 266.3 190.5 311.2 130.3 318.9L274.6 421.1C288.1 432.2 292.3 452.2 282 466.6C271.8 480.1 251.8 484.3 237.4 474L13.4 314C2.083 305.1-2.716 291.5 1.529 278.2C5.774 264.1 18.09 256 32 256H112C144.8 256 173 236.3 185.3 208H32C14.33 208 .0022 193.7 .0022 176C.0022 158.3 14.33 144 32 144H185.3C173 115.7 144.8 96 112 96H32C14.33 96 .0022 81.67 .0022 64V64z" />
                </svg>
              )}
            </div>
          ) : null}
          <div
            className={
              userMode !== 3
                ? "col-6 mt-4 text-right"
                : "col-4 mt-4 text-right"
            }>
            <div
              style={{ backgroundColor: " #14716b" }}
              className="custom_search_clearbtn-1 btn col-3 mr-4 save-button-modal"
              onClick={() => handleSubmit()}>
              {" "}
              <h4>Save</h4>
            </div>
          </div>
        </div>
      </form>

      {finalShareLink && (
        <div className="row mt-3">
          <div className="col-12 mb-2">Catalog Link</div>
          <div className="col-10 pr-0 mb-3">
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={finalShareLink}
              placeholder="https://catalog.amyra.com/..."
              disabled={true}
            />
          </div>
          <div className="col-2  mt-2">
            <ContentCopyOutlinedIcon
              aria-describedby={id}
              onClick={(event) => {
                navigator.clipboard.writeText(finalShareLink);
                handleClick(event);
              }}
              className="pointer"
            />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}>
              <Typography sx={{ p: 2 }}>Catalog link copied</Typography>
            </Popover>

          <WhatsAppIcon
                  className="custom_icon_size icon-color pointer"
                  onClick={handleWhatsAppShare}
                />
          </div>

          
       

        </div>
      )}
    </Box>
  </Dialog>
  );
}
