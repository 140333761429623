import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import demoImage from "../../assets/img/amyra-faded-logo2.png";
import "./pdp.css";
import ReactImageMagnify from "react-image-magnify";

const ProductDetailImage = ({ product }) => {
  const [gallerySwiper, getGallerySwiper] = useState(null);
  const [thumbnailSwiper, getThumbnailSwiper] = useState(null);

  // effect for swiper slider synchronize
  useEffect(() => {
    if (
      gallerySwiper !== null &&
      gallerySwiper.controller &&
      thumbnailSwiper !== null &&
      thumbnailSwiper.controller
    ) {
      gallerySwiper.controller.control = thumbnailSwiper;
      thumbnailSwiper.controller.control = gallerySwiper;
    }
  }, [gallerySwiper, thumbnailSwiper]);

  // swiper slider settings
  const gallerySwiperParams = {
    getSwiper: getGallerySwiper,
    spaceBetween: 10,
    loopedSlides: 4,
    loop: true,
    effect: "fade",
  };

  const thumbnailSwiperParams = {
    getSwiper: getThumbnailSwiper,
    spaceBetween: 10,
    slidesPerView: 4,
    loopedSlides: 4,
    touchRatio: 0.2,
    freeMode: true,
    loop: true,
    slideToClickedSlide: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    renderPrevButton: () => (
      <button className="swiper-button-prev ht-swiper-button-nav">
        <i className="pe-7s-angle-left" />
      </button>
    ),
    renderNextButton: () => (
      <button className="swiper-button-next ht-swiper-button-nav">
        <i className="pe-7s-angle-right" />
      </button>
    ),
  };
  const [sliderImages, setSliderImages] = useState([]);

  useEffect(() => {
    if (product) {
      const imageArr = [];
      imageArr.push(product.DIMAGE);
      imageArr.push(product.DIMAGE2);
      imageArr.push(product.DIMAGE3);
      imageArr.push(product.DIMAGE4);
      // imageArr.push(product.DIMAGE4);
      setSliderImages(imageArr);
    }
  }, [product]);

  const ImgProps = {
    width: 400,
    borderRadius: "10px",
    height: 400,
    zoomWidth: 600,
    img: "https://vivahc.azurewebsites.net/AMYRA/IMAGES/DESIGN/HHVFR00017GPWH.jpg",
  };

  console.log("img props", ImgProps);

  return (
    <Fragment>
      <div className="product-large-image-wrapper">
        {/* {product.discount || product.new ? (
          <div className="product-img-badges">
            {product.discount ? (
              <span className="pink">-{product.discount}%</span>
            ) : (
              ""
            )}
            {product.new ? <span className="purple">New</span> : ""}
          </div>
        ) : (
          ""
        )} */}
        {/* <LightgalleryProvider>
          <Swiper {...gallerySwiperParams}>
            {product.image &&
              product.image.map((single, key) => {
                return (
                  <div key={key}>
                    <LightgalleryItem
                      group="any"
                      src={process.env.PUBLIC_URL + single}
                    >
                      <button>
                        <i className="pe-7s-expand1"></i>
                      </button>
                    </LightgalleryItem>
                    <div className="single-image">
                      <img
                        src={process.env.PUBLIC_URL + single}
                         
                        alt=""
                      />
                    </div>
                  </div>
                );
              })}
          </Swiper>
        </LightgalleryProvider> */}
        {product?.DIMGYESNO == "True" ? (
          <LightgalleryProvider>
            <div className="image-zoom">
              <div>
                <LightgalleryItem group="any" src={product?.DIMAGE}>
                  <button>
                    <i className="pe-7s-expand1"></i>
                  </button>
                </LightgalleryItem>
              </div>
              <ReactImageMagnify
                imageClassName="single-image"
                enlargedImageContainerClassName	="single-image"
                enlargedImageContainerStyle={{
                  zIndex: 999,
                  backgroundColor: "white",
                  marginLeft: 50,
                }}
                enlargedImageContainerDimensions={{
                  width: "150%",
                  height: "100%",
                }}
                {...{
                  smallImage: {
                    alt: "Wristwatch by Ted Baker London",
                    isFluidWidth: true,
                    src: product?.DIMAGE,
                  },
                  largeImage: {
                    src: product?.DIMAGE,
                    width: 1400,
                    height: 1800,
                  },
                }}
              />
              {/* <ReactImageZoom
                {...ImgProps}
                className="img-zoom"
                zoomLensStyle="opacity: 0.4;
                background-color: gray;
                width: 80.6452px !important;
                height: 53.7634px !important;
                position: absolute; "
                zoomStyle="z-index:999 ; background-color:white"
              /> */}
            </div>
          </LightgalleryProvider>
        ) : (
          <LightgalleryProvider>
            <div>
              <LightgalleryItem group="any" src={demoImage}>
                <button>
                  <i className="pe-7s-expand1"></i>
                </button>
              </LightgalleryItem>
              <div className="single-image">
                <img src={demoImage} alt="" />
              </div>
            </div>
          </LightgalleryProvider>
        )}
      </div>

      {/* <div className="product-small-image-wrapper mt-15 ">
        <Swiper {...thumbnailSwiperParams}>
          {sliderImages.map((single, key) => {
            return (
              <div key={key} className="prd-swiper-slide">
                <div className="single-image">
                  <img
                    src={product?.DIMGYESNO == "True" ? single : demoImage}
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            );
          })}
        </Swiper>
      </div> */}
    </Fragment>
  );
};

ProductDetailImage.propTypes = {
  product: PropTypes.object,
};

export default ProductDetailImage;
