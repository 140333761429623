import React from "react";
import neckPieceImg from "../../assets/img/home_categories/Necklace.png";
import banglesImg from "../../assets/img/home_categories/Bangles.png";
import earRingImg from "../../assets/img/home_categories/Earrings.png";
import fingerRingImg from "../../assets/img/home_categories/Finger ring.png";
import accessoriesImg from "../../assets/img/home_categories/accessories.png";
import kadaImg from "../../assets/img/home_categories/Kada.png";
import malaSetImg from "../../assets/img/home_categories/Mala set.png";
import mangalsutraImg from "../../assets/img/home_categories/mangalsutra.png";
import malaImg from "../../assets/img/home_categories/mala.png";
import hairAccessoriesImg from "../../assets/img/home_categories/hair Accessories.png";
import pendantSetImg from "../../assets/img/home_categories/pendant set.png";
import topsImg from "../../assets/img/home_categories/Tops.png";
import ApiLib from "../../utils/api";
import CategoryAction from "../../redux/actions/CategoryAction";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { find, pullAllWith, isEqual } from "lodash";
import { selectedTopFilters } from "../../redux/actions/productAction";
import mixpanel from "mixpanel-browser";


function Category({ DataTrasfer }) {
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [selectedSubID, setSelectedSubID] = useState();
  const dispatch = useDispatch();
  const API = new ApiLib();
  const history = useHistory();
  const filterState = useSelector((state) => state);
  const filters_top = useSelector((state) => state.allProducts.filters_top);
  console.log(filterState.CategoryReducer, selectedSubID, "filterStateData");

  useEffect(() => {
    FetchCategory();
    //eslint-disable-next-line
  }, []);

  console.log(filterState, "filterState");

  const FetchCategory = () => {
    setLoading(true);
    console.log("fetch category working");

    API.CategoryList()
      .then((res) => {
        console.log("new category", res.data);
        setCategory(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const Categories = [
    {
      SCATG: "NECK PIECES",
      SCATGID: "368",
      CNT: "417",
      IMG: neckPieceImg,
      catgid: "214",
      scatgid: "368",
      FILTERATT: [
        "AD/KUNDAN-475",
        "AD-310",
        "ANTIQUE-311",
        "KUNDAN-313",
        "MAZAK-314",
      ],
    },
    {
      SCATG: "BANGLES",
      SCATGID: "359",
      CNT: "100",
      IMG: banglesImg,
      catgid: "214",
      scatgid: "359",
      FILTERATT: ["AD-310", "ANTIQUE-311", "KUNDAN-313", "RAJWADI-315"],
    },
    {
      SCATG: "EARRINGS",
      SCATGID: "361",
      CNT: "423",
      IMG: earRingImg,
      catgid: "214",
      scatgid: "361",
      FILTERATT: ["AD/KUNDAN-475", "AD-310", "ANTIQUE-311", "KUNDAN-313"],
    },
    {
      SCATG: "FINGER RING",
      SCATGID: "362",
      CNT: "149",
      IMG: fingerRingImg,
      catgid: "214",
      scatgid: "362",
      FILTERATT: [
        "AD/KUNDAN-475",
        "AD-310",
        "ANTIQUE-311",
        "KUNDAN-313",
        "FUSION -312",
      ],
    },
    {
      SCATG: "ACCESSORIES",
      SCATGID: "358",
      CNT: "43",
      IMG: accessoriesImg,
      catgid: "214",
      scatgid: "358",
      FILTERATT: [
        "AD-310",
        "ANTIQUE-311",
        "HAATHPAAN-501",
        "KUNDAN-313",
        "NATH (NOSE RING)-506",
        "PAYAL (ANKLET)-510",
        "WAIST BELT-504",
        "WATCH-502",
      ],
    },
    {
      SCATG: "KADA",
      SCATGID: "499",
      CNT: "298",
      IMG: kadaImg,
      catgid: "214",
      scatgid: "499",
      FILTERATT: ["AD/KUNDAN-475", "AD-310", "ANTIQUE-311", "KUNDAN-313"],
    },
    {
      SCATG: "MALA SET",
      SCATGID: "366",
      CNT: "61",
      IMG: malaSetImg,
      catgid: "214",
      scatgid: "366",
      FILTERATT: ["AD-310", "ANTIQUE-311", "KUNDAN-313", " RAJWADI-315"],
    },
    {
      SCATG: "MANGALSUTRA",
      SCATGID: "367",
      CNT: "31",
      IMG: mangalsutraImg,
      catgid: "214",
      scatgid: "367",
      FILTERATT: ["AD-310", "ANTIQUE-311", "KUNDAN-313"],
    },
    {
      SCATG: "MALA",
      SCATGID: "497",
      CNT: "61",
      IMG: malaImg,
      catgid: "214",
      scatgid: "497",
      FILTERATT: ["ANTIQUE-311", "KUNDAN-313"],
    },
    {
      SCATG: "HAIR ACCESSORIES",
      SCATGID: "363",
      CNT: "72",
      IMG: hairAccessoriesImg,
      catgid: "214",
      scatgid: "363",
      FILTERATT: [
        "AD-310",
        "ANTIQUE-311",
        "KUNDAN-313",
        "MATHA PATTI (DAMINI)-511",
        "SHEESHPHOOL-507",
        "TIKKA-508",
      ],
    },
    {
      SCATG: "PENDANT SET",
      SCATGID: "369",
      CNT: "140",
      IMG: pendantSetImg,
      catgid: "214",
      scatgid: "369",
      FILTERATT: ["AD-310", "ANTIQUE-311", "KUNDAN-313"],
    },
    {
      SCATG: "TOPS",
      SCATGID: "371",
      CNT: "75",
      IMG: topsImg,
      catgid: "214",
      scatgid: "371",
      FILTERATT: [
        "AD-310",
        "ANTIQUE-311",
        "FUSION -312",
        "KUNDAN-313",
        "SARVOSKI-423",
      ],
    },
  ];

  // const handleFilters = (filterId, category) => {
  //   console.log(filterId, category , "FilterIDCheck");
  //   let id = filterId.split("-")[1];
  //   const idObj = {
  //     catgid: category.CATGID,
  //     scatgid: category.SCATGID,
  //     filterid: id,
  //   };
  //   const tempFilters = { ...filters_top };

  //   if (!find(filterData, idObj)) {
  //     setFilterData([...filterData, idObj]);
  //     tempFilters.catgFilter = [...filterData, idObj];
  //     dispatch(selectedTopFilters(tempFilters));
  //   } else {
  //     let idArr = filterData;
  //     pullAllWith(idArr, [idObj], isEqual);
  //     setFilterData([...idArr]);
  //     tempFilters.catgFilter = [...idArr];
  //     dispatch(selectedTopFilters(tempFilters));
  //   }
  // };

  const dataToParent = (filter, category) => {
    console.log(filter, category, "FilterIDCheck789");
    // [
    //   { catgid: "214", scatgid: "358", filterid: "310" },
    //   { catgid: "214", scatgid: "358", filterid: "311" },
    //   { catgid: "214", scatgid: "358", filterid: "313" },
    // ];

    let idObj =
      category &&
      category?.FILTERATT?.map((filter) => {
        return {
          catgid: category.catgid,
          scatgid: category.SCATGID,
          filterid: filter.split("-")[1],
        };
      });

    console.log(idObj, "FilterIDCheck123");

    const tempFilters = { ...filters_top };

    console.log(tempFilters, "FilterIDCheck2");

    if (!find(filterData, ...idObj)) {
      setFilterData([...filterData, ...idObj]);
      tempFilters.catgFilter = [...filterData, ...idObj];
      dispatch(selectedTopFilters(tempFilters));
    } else {
      let idArr = filterData;
      pullAllWith(idArr, [...idObj], isEqual);
      setFilterData([...idArr]);
      tempFilters.catgFilter = [...idArr];
      dispatch(selectedTopFilters(tempFilters));
    }

    // let id = filter.split("-")[1];
    // console.log(id, "FilterIDCheck1")
    // const idObj = {
    //   catgid: category.CATGID,
    //   scatgid: category.SCATGID,
    //   filterid: id,
    // }
  };

  function selectedCategory(index, catg) {
    console.log(index, catg, "CheckIndex");

    dispatch(CategoryAction(catg));

    let filterID = catg?.FILTERATT;
    const newfilterID =
      filterID &&
      filterID.map((value) => {
        const parts = value.split("-");
        return parts[parts.length - 1];
      });

    console.log(newfilterID, "filterID");
    const idArray = [];

    newfilterID &&
      newfilterID.forEach((id) => {
        const idObj = {
          catgid: catg.catgid,
          scatgid: catg.SCATGID,
          filterid: id,
        };
        idArray.push(idObj);
      });

    console.log(idArray, "filterID1");

    // history.push("/products");
  }


  const ViewAllCollection = () => {
    setShowMore(!showMore)
    mixpanel.track("ViewAllCategory");

  }

  // const dataToParent = (index, catg) => {
  //   console.log(catg, "CategoryData1254 ");
  //   DataTrasfer(catg);
  // };

  return (
    <div className="product-area hm6-section-padding ">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            {Categories.length ? (
              <div className="product-wrap-4 mb-20">
                <Link to={process.env.PUBLIC_URL + "/products"}>
                  <img
                    src={Categories[0].IMG}
                    style={{ width: "100%" }}
                    alt=""
                    onClick={() => {
                      dataToParent(Categories[0]?.SCATG, Categories[0]);
                    }}
                  />
                </Link>
                <div className="product-content-4 text-center position-1">
                  <h1 className="text-white category-title">
                    {Categories[0].SCATG}
                  </h1>
                  <div className="price-4 price-4-center">
                    <Link to={process.env.PUBLIC_URL + "/products"}>
                      <div
                        onClick={() => {
                          dataToParent(Categories[0]?.SCATG, Categories[0]);
                        }}
                        className="h5 category_link text-white"
                      >{`Shop Now > `}</div>
                    </Link>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-lg-6">
            <div className="row custom_layout_cat">
              <div className="col-lg-12">
                <div className="row custom_big">
                  {Categories.length > 1
                    ? Categories.map((catg, index) => {
                        if (index > 0 && index <= 4) {
                          return (
                            <div className="col-lg-6 mb-20 custom_Main_card">
                              <div className="product-wrap-4 custom_cat_card">
                                {" "}
                                <img
                                  src={catg.IMG}
                                  style={{ width: "100%" }}
                                  alt=""
                                />
                                <div
                                  onClick={() => {
                                    selectedCategory(index, catg);
                                    dataToParent(index, catg);
                                  }}
                                  className="product-content-4 text-center position-1"
                                >
                                  <h2 className="text-white category-title text-left ml-4">
                                    {catg.SCATG}
                                  </h2>
                                  <div className="price-4 ml-4 text-left">
                                    <Link
                                      to={process.env.PUBLIC_URL + "/products"}
                                    >
                                      <span className="text-white category_link">{`Shop Now >`}</span>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })
                    : null}

                  {/* <div className="col-lg-6">
                <div className="product-wrap-4 mb-20">
                  <Link to={process.env.PUBLIC_URL + "/products"}>
                    <img src={ringb} alt="" />
                  </Link>
                  <div className="product-content-4 text-center position-1 text-right">
                    <h2 className="text-white category-title text-left ml-4">
                      Rings
                    </h2>
                    <div className="price-4   ml-4 text-left">
                      <span className="text-white category_link">{`Shop Now >`}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="product-wrap-4 mb-20">
                  <Link to={process.env.PUBLIC_URL + "/products"}>
                    <img src={bangle} alt="" />
                  </Link>
                  <div className="product-content-4 product-content-center position-1">
                    <h4 className="text-white text-left ml-4">Bangles</h4>
                    <div className="price-4 text-left ml-4">
                      <span className="text-white category_link">{`Shop Now >`}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="product-wrap-4 mb-20">
                  <Link to={process.env.PUBLIC_URL + "/products"}>
                    <img src={braclet} alt="" />
                  </Link>
                  <div className="product-content-4 product-content-center position-1 ">
                    <h4 className="text-white text-left ml-4">Bracelets</h4>
                    <div className="price-4 text-left ml-4">
                      <span className="text-white category_link">{`Shop Now >`}</span>
                    </div>
                  </div>
                </div>
              </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          {showMore && Categories.length > 5
            ? Categories.map((catg, index) => {
                if (index > 4) {
                  return (
                    <div className="col-lg-3">
                      <div
                        onClick={() => {
                          selectedCategory(index, catg);
                          dataToParent(index, catg);
                        }}
                        className="product-wrap-4 mb-20"
                      >
                        <img src={catg.IMG} style={{ width: "100%" }} alt="" />

                        <div className="product-content-4 text-center position-1">
                          <h2 className="text-white category-title text-left ml-4">
                            {catg.SCATG}
                          </h2>
                          <div className="price-4 ml-4 text-left">
                            <Link to={process.env.PUBLIC_URL + "/products"}>
                              <span className="text-white category_link">{`Shop Now >`}</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })
            : null}
        </div>
        {showMore ? (
          <div
            className="row customShow justify-content-center"
            onClick={() => setShowMore(!showMore)}
          >
            <p>Show Less</p>
          </div>
        ) : (
          <div
            className="row customShow justify-content-center"
            onClick={ViewAllCollection}
          >
            <p>View All Categories</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Category;
