import React, { Fragment, useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import ApiLib from "../../utils/api";
import { useToasts } from "react-toast-notifications";
import Loader from "../../helpers/Loader";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
  pb: 0,
};

const EditModal = (props) => {
  const [collectionName, setCollectionName] = useState();
  const API = new ApiLib();
  const userDetails = useSelector((state) => state.userDetails);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  useEffect(() => {
    console.log("props", props);
    setCollectionName(props?.selectedCollection?.COLL_NAME);
  }, [props]);

  const UpdateCollection = () => {
    if (collectionName) {
      setLoading(true);
      const data = {
        USERID: userDetails.user.ID,
        COLL_NAME: collectionName,
        COLL_ID: Number(props.selectedCollection.COLL_ID),
      };
      API.UpdateCollectionName(data)
        .then((res) => {
          setLoading(false);
          console.log(res);
          props.GetAllCollection();
          props.onClose();
          addToast("Collection updated successfully ", {
            appearance: "success",
            autoDismiss: true,
          });
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          addToast("Something went wrong please try again", {
            appearance: "error",
            autoDismiss: true,
          });
        });
    }
  };
  return (
    <>
      {loading ? <Loader /> : null}

      <Modal
        open={props.editCollection}
        onClose={props.editCollection}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="Edit-Collection-Box-Modal">
          <div className="row">
            <div className="col-10 text-left">
              <h3 className="mr-3 title-text-bold sidebar-title">
                Edit Collection{" "}
              </h3>
            </div>
            <div className=" col-2 text-right mt-n2 ">
              <CloseIcon
                onClick={() => props.onClose()}
                className="close-button"
              />
            </div>{" "}
          </div>

          <div className="row">
            <div className="col-12 px-4 py-4 mb-3">
              <input
                type="text"
                onChange={(e) => setCollectionName(e.target.value)}
                value={collectionName}
                // Bug Solve By Syed
          onKeyDown={(e) => {
        if (e.key === "Enter") {
          UpdateCollection();
        }
      }}
      //
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Collection Name"
              />
            </div>
          </div>
          <div
            className="row bg-black  text-center save-btn"
            style={{ marginLeft: "-33px", marginRight: "-33px" }}
          >
            <div
              className="col-12 px-3 py-3 share-button text-white btn-background"
              onClick={() => {
                UpdateCollection();
              }}
            >
              Update Collection
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};
export default EditModal;
