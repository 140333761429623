// *** IMPORTANT ***
// Any Changes in this file will not be reflected. Contact Developer.  #GitIgnoredFile
import axios from "../axios";

export default class Api {
  //Product listing page and Product detail page

  FetchCategories() {
    return axios.get("Getdesign.aspx?type=u");
  }
  FetchTopFilters() {
    return axios.get("Getdesign.aspx?type=m");
  }

  FetchProducts(pageNo, filter_top, userDetails, productsLength) {
    let userid = userDetails?.user?.ID ? userDetails?.user?.ID : "";
    const filter = filter_top;
    console.log("fiter plating", filter, userDetails);
    const ids =
      filter.catgFilter.length !== 0 ? JSON.stringify(filter.catgFilter) : "";
    return axios.get(
      "Getdesignnew.aspx?sortby=" +
        filter.sortby +
        "&pageno=" +
        pageNo +
        "&rows=" +
        productsLength +
        "&filter=" +
        ids +
        "&plating=" +
        filter.plating.join(",") +
        "&color=" +
        filter.color.join(",") +
        "&material=" +
        "&priceupper=" +
        filter.upperPrice +
        "&pricelow=" +
        filter.lowerPrice +
        "&userid=" +
        userid
    );
  }
  FetchFilteredProducts(filterIds, pageNo, filterTop) {
    const filter = filterTop;
    const ids = filterIds.length !== 0 ? JSON.stringify(filterIds) : "";
    const upperPrice = filter.price.length
      ? filter.price[filter.price.length - 1]
      : "";
    const lowerPrice = filter.price.length ? filter.price[0] : "";
    console.log("api filterid", filterIds, pageNo, filterTop);

    return axios.get(
      "Getdesignnew.aspx?sortby=CREATEDTIME desc&pageno=" +
        pageNo +
        "&rows=30&filter=" +
        ids +
        "&color=" +
        filter.color.join(",") +
        "&material=" +
        "&plating=" +
        filter.plating.join(",") +
        "&priceupper=" +
        upperPrice +
        "&pricelow=" +
        lowerPrice
    );
  }

  // UserRegistrationEmail
  UserRegistrationEmail(email, password) {
    return axios.post(
      `https://api-bridge.amyraa.app/api/v1/email/userRegistration`,
      { email, password }
    );
  }
  //

  //User Authentication

  RegisterUser(userdata) {
    return axios.post(
      `Signup.aspx?fname=${userdata.fname}&bname=${userdata.bname}&emailid=${userdata.emailid}&password=${userdata.password}&mobno=${userdata.mobno}`
    );
  }

  Login(user) {
    return axios.post(
      `SignIn.aspx?signin=${user.emailid}&passwd=${user.password}`
    );
  }

  LoginWithOtp(mobno, otp = "") {
    return axios.get(`LoginWithOtp.aspx?mobno=${mobno}&otp=${otp}`);
  }

  ForgotPassword(mobno) {
    return axios.get(`ForgotPasswd.aspx?Mobno=${mobno}`);
  }

  GetNotification(email, billNo) {
    return axios.post(`https://api-bridge.amyraa.app/api/v1/email`, {
      email,
      billNo,
    });
  }

  ChangePassword(userDetails) {
    return axios.post(
      `ChangePasswd.aspx?mobno=${userDetails.mobno}&type=1&otp=${userDetails.otp}&passwd=${userDetails.password}`
      // `ChangePasswd.aspx?email=${userDetails.emailid}&type=${userDetails.type}&passwd=${userDetails.new}&oldpasswd=${userDetails.old}`
    );
  }

  // User & Bussiness Details

  GetUserDetails(emailid) {
    return axios.post(
      `https://vivahc.azurewebsites.net/AMYRA/GETUSER.aspx?cnd=${emailid}`
    );
  }

  UpdateUserDetails(type, details) {
    return axios.post(
      `https://vivahc.azurewebsites.net/AMYRA/UpdateUser.aspx?type=${type}&data=${details}`
    );
  }

  //CART APIS

  AddToCart(data) {
    let itemDetails = JSON.stringify(data);
    return axios.post(
      `https://vivahc.azurewebsites.net/AMYRA/savecart.aspx`,
      itemDetails
    );
  }

  UpdateCart(data) {
    let itemDetails = JSON.stringify(data);

    return axios.post(
      `https://vivahc.azurewebsites.net/AMYRA/updatecart.aspx`,
      itemDetails
    );
  }

  GetCart(userId) {
    return axios.post(
      `https://vivahc.azurewebsites.net/AMYRA/getcart.aspx?userid=${userId}`
    );
  }

  DeleteCart(data) {
    let itemDetails = JSON.stringify(data);

    return axios.post(
      `https://vivahc.azurewebsites.net/AMYRA/deletecart.aspx?data=${[
        itemDetails,
      ]}`
    );
  }

  ClearCart(data) {
    let itemDetails = JSON.stringify(data);

    return axios.post(
      `https://vivahc.azurewebsites.net/AMYRA/deletecart.aspx?data=${[
        itemDetails,
      ]}`
    );
  }

  //Checkout address

  AddDeliveryAddress(data) {
    let addressDetails = JSON.stringify(data);

    return axios.post(
      `https://vivahc.azurewebsites.net/AMYRA/SaveDelAddress.aspx?data=${[
        addressDetails,
      ]}`
    );
  }
  UpdateDeliveryAddress(data) {
    let addressDetails = JSON.stringify(data);

    return axios.post(
      `https://vivahc.azurewebsites.net/AMYRA/UpdateDelAddress.aspx?data=${[
        addressDetails,
      ]}`
    );
  }

  DeleteAddress(data) {
    let addressDetails = JSON.stringify(data);

    return axios.post(
      `https://vivahc.azurewebsites.net/AMYRA/DeleteDelAddress.aspx?data=${[
        addressDetails,
      ]}`
    );
  }

  GetAllDeliveryAddress(userId) {
    return axios.post(
      `https://vivahc.azurewebsites.net/AMYRA/GetUserDelAddress.aspx?userid=${userId}&ser_no=0`
    );
  }
  GetUserDeliveryAddress(userId, ser_no) {
    return axios.post(
      `https://vivahc.azurewebsites.net/AMYRA/GetUserDelAddress.aspx?userid=${userId}&ser_no=${ser_no}`
    );
  }

  SaveOrder(details) {
    return axios.post(
      `https://vivahc.azurewebsites.net/AMYRA/saveorder.aspx?userid=${details.userId}&narration=${details.narration}&razpayid=${details.razId}&shipid=${details.shipId}&shipamt=${details.shipAmt}&gstamt=${details.gstamt}&gsttax=3&delid=${details.delId}&crdays=${details.crdays}&shipmode=${details.shipMode}&shiptype=${details.shipType}`
    );
  }

  //Collection apis

  SaveCollection(data) {
    let collectionDetails = JSON.stringify(data);

    return axios.post(
      `https://vivahc.azurewebsites.net/AMYRA/SaveCollection.aspx`,
      collectionDetails
    );
  }
  GetAllCollection(userId) {
    return axios.post(
      `https://vivahc.azurewebsites.net/AMYRA/GetUserCollection.aspx?userid=${userId}&type=summary&coll_id=`
    );
  }
  AddToCollection(data) {
    let collectionDetails = JSON.stringify(data);

    return axios.post(
      `https://vivahc.azurewebsites.net/AMYRA/SaveCollection.aspx?data=${[
        collectionDetails,
      ]}`
    );
  }

  GetDetailCollection(userId, coll_id) {
    return axios.post(
      `https://vivahc.azurewebsites.net/AMYRA/GetUserCollection.aspx?userid=${userId}&type=detail&coll_id=${coll_id}`
    );
  }

  DeleteCollection(data) {
    let collectionDetails = JSON.stringify(data);

    return axios.post(
      `https://vivahc.azurewebsites.net/AMYRA/DeleteFullCollection.aspx`,
      collectionDetails
    );
  }
  DeleteCollectionDesign(data) {
    let collectionDetails = JSON.stringify(data);

    return axios.post(
      `https://vivahc.azurewebsites.net/AMYRA/DeleteCollection.aspx`,
      collectionDetails
    );
  }
  UpdateCollection(data) {
    let collectionDetails = JSON.stringify(data);

    return axios.post(
      `https://vivahc.azurewebsites.net/AMYRA/UpdateCollection.aspx`,
      collectionDetails
    );
  }
  UpdateCollectionName(data) {
    let collectionDetails = JSON.stringify(data);

    return axios.post(
      `https://vivahc.azurewebsites.net/AMYRA/UpdateCollName.aspx`,
      collectionDetails
    );
  }
  // My Orders

  GetAllOrders(userId) {
    return axios.post(
      `https://vivahc.azurewebsites.net/AMYRA/getordersumm.aspx?userid=${userId}`
    );
  }
  GetOrderDetails(userId, billNo) {
    return axios.post(
      `https://vivahc.azurewebsites.net/AMYRA/getorderdet.aspx?userid=${userId}&billno=${billNo}&option=detail`
    );
  }
  GetShippingChrg(userId, billNo) {
    return axios.post(
      `https://vivahc.azurewebsites.net/AMYRA/getorderdet.aspx?userid=${userId}&billno=${billNo}&option=SHIP`
    );
  }
  GetStoreShippingChrg(userId, custId, billNo) {
    return axios.post(
      `https://vivahc.azurewebsites.net/AMYRA/getorderdet.aspx?userid=${userId}&custid=${custId}&billno=${billNo}&option=SHIP`
    );
  }

  //Home Apis

  Banner(userType) {
    return axios.post(
      `https://vivahc.azurewebsites.net/amyra/getdesign.aspx?type=${userType} `
    );
  }

  NewArrival(pageCount, userId) {
    return axios.post(
      `https://vivahc.azurewebsites.net/amyra/GetNewArrival.aspx?userid=${userId}&pageno=${pageCount}&rows=20`
    );
  }

  MostPopular(pageCount, userId) {
    return axios.post(
      `https://vivahc.azurewebsites.net/amyra/getMostPopular.aspx?userid=${userId}&pageno=${pageCount}&rows=20&totcnt=`
    );
  }

  ComboList() {
    return axios.post(
      `https://vivahc.azurewebsites.net/amyra/getdesign.aspx?type=3`
    );
  }

  CategoryList() {
    return axios.post(
      `https://vivahc.azurewebsites.net/amyra/getdesign.aspx?type=C`
    );
  }

  UploadLogo(data) {
    return axios.post(
      `https://vivahc.azurewebsites.net/AMYRA/UploadLogo.aspx`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  DeleteLogo(userId) {
    return axios.get("DeleteLogo.aspx?userid=" + userId);
  }
  //Search products

  FetchSearchProducts(pageNo, filter_top, searchtext = "", userid) {
    const filter = filter_top;
    const ids =
      filter.catgFilter.length !== 0 ? JSON.stringify(filter.catgFilter) : "";

    return axios.get(
      "Getdesignnew.aspx?sortby=" +
        filter.sortby +
        "&pageno=" +
        pageNo +
        "&rows=30&filter=" +
        ids +
        "&color=" +
        filter.color.join(",") +
        "&plating=" +
        filter.plating.join(",") +
        "&material=" +
        "&priceupper=" +
        filter.upperPrice +
        "&pricelow=" +
        filter.lowerPrice +
        "&searchtext=" +
        searchtext +
        "&userid=" +
        userid
    );
  }

  // Wishlist
  GetUserWishlist(userid) {
    return axios.post(
      `https://vivahc.azurewebsites.net/AMYRA/GetWishList.aspx?userid=${userid}`
    );
  }

  DeleteWishlist(userid, DCODE) {
    return axios.get(
      `https://vivahc.azurewebsites.net/AMYRA/UpdateWishList.aspx?type=2&userid=${userid}&dcode=${DCODE}`
    );
  }
  AddToWishlist(userid, DCODE) {
    return axios.get(
      `https://vivahc.azurewebsites.net/AMYRA/UpdateWishList.aspx?type=1&userid=${userid}&dcode=${DCODE}`
    );
  }
  ClearWishlist(userid) {
    return axios.get(
      `https://vivahc.azurewebsites.net/AMYRA/UpdateWishList.aspx?type=3&userid=${userid}&dcode=`
    );
  }

  // DeleteMyAccount
  deleteMyAccount(email) {
    return axios.post(
      `https://api-bridge.amyraa.app/api/v1/email/userAccountDeletion`,
      email
    );
  }

  // DeleteMyAccount
  deleteMyAccount(email) {
    return axios.post(
      `https://api-bridge.amyraa.app/api/v1/email/userAccountDeletion`,
      email
    );
  }

  // User Mode

  ChangeUserMode(userid, userMode, rateType = "", rateValue = "") {
    return axios.get(
      `https://vivahc.azurewebsites.net/AMYRA/UpdateRateMode.aspx?userid=${userid}&ratemode=${userMode}&ratetype=${rateType}&ratevalue=${rateValue}`
    );
  }
  // banner
  BannerText() {
    return axios.get(
      `https://vivahc.azurewebsites.net/AMYRA/GetDesign.aspx?type=10`
    );
  }

  // customer orders
  GetCustomerOrders(userId) {
    return axios.get(
      `https://vivahc.azurewebsites.net/AMYRA/GetCustOrderSumm.aspx?cust_code=&userId=${userId}`
    );
  }
  GetCustomerOrderDetails(userId, billNo, cust_code) {
    return axios.post(
      `https://vivahc.azurewebsites.net/AMYRA/GetCustOrderDet.aspx?userid=${userId}&cust_code=${cust_code}&billno=${billNo}`
    );
  }
}

export class BillDeskApi {
  // create bill desk order
  createBdOrder(email, orderId, amount) {
    return axios.post(
      `https://api-bridge.amyraa.app/api/v1/payment/orders/create`,
      { email, orderId, amount }
    );
  }

  checkBdOrderStatus(reqBody) {
    return axios.post(
      `https://api-bridge.amyraa.app/api/v1/payment/orders/status`,
      reqBody
    );
  }

  repopulateCart(email, orderId, amyraaOrderId) {
    return axios.post(
      `https://api-bridge.amyraa.app/api/v1/payment/orders/forceAddToCart`,
      { email, orderId, amyraaOrderId }
    );
  }
}

export class ShipmentApi {
  getDomesticShippingPrice(
    userEmail,
    pickupPostcode,
    deliveryPostcode,
    weight,
    shipBy
  ) {
    return axios.get(
      `https://api-bridge.amyraa.app/api/v1/shipment/getShippingPrice?userEmail=${userEmail}&orderType=domestic&pickupPostcode=${pickupPostcode}&deliveryPostcode=${deliveryPostcode}&weight=${weight}&shipBy=${shipBy}`
      // `http://localhost:3001/api/v1/shipment/getShippingPrice?userEmail=${userEmail}&orderType=domestic&pickupPostcode=${pickupPostcode}&deliveryPostcode=${deliveryPostcode}&weight=${weight}&shipBy=${shipBy}`
    );
  }

  getInernationalShippingPrice(userEmail, weight, deliveryCountry) {
    return axios.get(
      `https://api-bridge.amyraa.app/api/v1/shipment/getShippingPrice?userEmail=${userEmail}&orderType=international&weight=${weight}&deliveryCountry=${deliveryCountry}`
      // `http://localhost:3001/api/v1/shipment/getShippingPrice?userEmail=${userEmail}&orderType=international&weight=${weight}&deliveryCountry=${deliveryCountry}`
    );
  }

  getShipmentAvailableCountries(userEmail) {
    return axios.get(
      `https://api-bridge.amyraa.app/api/v1/shipment/getAllCountries?email=${userEmail}`
    );
  }
}
