import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../../layouts/LayoutOne";
import HomePageSlider from "../../wrappers/hero-slider/HomePageSlider";
import HomeProducts from "../../wrappers/product/HomeProducts";
import Category from "./Category";
import HowWeWork from "./HowWeWork";
import CustomerReviews from "./CustomerReviews";
// import OurPartners from "./OurPartners";
import Connect from "./Connect";
import WhyUs from "./WhyUs";
import BrandLogoSliderOne from "../../wrappers/brand-logo/BrandLogoSliderOne";
import ApiLib from "../../utils/api";
import { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useSelector, useDispatch } from "react-redux";
import getWishlist from "../../redux/actions/wishlistActions";
import whatsapp_btn from "../../assets/img/whatsapp_btn.png";
import WhatsAppBotComp from "../../components/WhatsAppBot/YourMainComponent";
import mixpanel from "mixpanel-browser";
import { useMediaQuery } from "@mui/material";  // Import useMediaQuery


const Home = () => {
  const [loading, setLoading] = useState(false);
  const [newArrival, setNewArrival] = useState([]);
  const [newArrivalPage, setNewArrivalPage] = useState(0);
  const [mostPopular, setMostPopular] = useState([]);
  const [mostPopularPage, setMostPopularPage] = useState(0);
  const [wishlist, setWishlist] = useState([]);
  const API = new ApiLib();
  const userDetails = useSelector((state) => state.userDetails);
  const mylanguage = useSelector((state) => state.language);
  const price = useSelector((state) => state.price);
  const { addToast } = useToasts();
  const [dataFromChild, setDataFromChild] = useState({});
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width:600px)');  // Determine if it's a mobile view


  const setSelectedSubID = (data) => {
    setDataFromChild(data);
    console.log(data, "dataFromChild");
  };

  useEffect(() => {
    FetchNewArrival();
    FetchMostPopular();
    console.log("home details", userDetails?.user?.ID);
    // eslint-disable-next-line
  }, []);

  console.log(price, "123123Currency");

  useEffect(() => {
    if (userDetails?.user?.ID) {
      console.log("home details wishlist woking", userDetails?.user?.ID);
      FetchWishlist();
      FetchMostPopular();
      FetchNewArrival();
    }
    // eslint-disable-next-line
  }, [userDetails]);

  const FetchNewArrival = () => {
    setLoading(true);
    console.log("fetch new arrival working", userDetails);
    let pageCount = newArrivalPage + 1;
    API.NewArrival(pageCount, userDetails?.user?.ID || "0")
      .then((res) => {
        console.log("new arrival", res.data);
        setNewArrival([...newArrival, ...res.data]);
        setLoading(false);
        setNewArrivalPage(pageCount);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const FetchMostPopular = () => {
    setLoading(true);
    console.log("fetch most popular working");
    let pageCount = mostPopularPage + 1;

    API.MostPopular(pageCount, userDetails?.user?.ID || "0")
      .then((res) => {
        console.log("most popular", res.data);
        setMostPopular([...mostPopular, ...res.data]);
        setLoading(false);
        setMostPopularPage(pageCount);
      })
      .catch((err) => {
        console.log("most popular =>", err);
        console.log(err);
        setLoading(false);
      });
  };

  const FetchWishlist = () => {
    setLoading(false);
    API.GetUserWishlist(userDetails.user.ID)
      .then((res) => {
        console.log("wishlist==>", res.data);
        setWishlist(res.data);
        dispatch(getWishlist(res.data));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const DeleteFromWishlist = (DCODE) => {
    setLoading(true);
    API.DeleteWishlist(userDetails.user.ID, DCODE)
      .then((res) => {
        setLoading(false);
        console.log(res);
        addToast("Item removed from wishlist", {
          appearance: "success",
          autoDismiss: true,
        });
        FetchWishlist();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        addToast("Something went wrong", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const AddToWishlist = (product) => {
    if (!userDetails?.user?.ID) {
      addToast("Kindly login to add item to wishlist ", {
        appearance: "warning",
        autoDismiss: true,
      });
      return;
    }
    setLoading(true);
    API.AddToWishlist(userDetails.user.ID, product.DCODE)
      .then((res) => {
        setLoading(false);
        console.log(res);

        //
        mixpanel.track("AddToWishList", { DesignId: JSON.stringify(product.DCODE) });  // MixPannel ka code
        //

        addToast("Item added to wishlist successfully ", {
          appearance: "success",
          autoDismiss: true,
        });
        FetchWishlist();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        addToast("Something went wrong", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const New = {
    English: "New",
    Hindi: "नई",
    Tamil: "புதிய",
    Telugu: "కొత్త",
    French: "Nouvelles",
    Spanish: "Nuevas",
  };

  const Arrivals = {
    English: "Arrivals",
    Hindi: "आगमन",
    Tamil: "வரவுகள்",
    Telugu: "రాకలు",
    French: "Arrivées",
    Spanish: "Llegadas",
  };

  const NewLanguage = New[mylanguage];
  const ArrivalsLanguage = Arrivals[mylanguage];

  const Most = {
    English: "Most",
    Hindi: "सबसे",
    Tamil: "மிகவும்",
    Telugu: "అత్యంత",
    French: "Le Plus",
    Spanish: "Más",
  };

  const Popular = {
    English: "Popular",
    Hindi: "लोकप्रिय",
    Tamil: "பிரபலமான",
    Telugu: "ప్రజాదరణ",
    French: "Populaire",
    Spanish: "Popular",
  };

  const MostLanguage = Most[mylanguage];
  const PopularLanguage = Popular[mylanguage];

  const Our = {
    English: "Our",
    Hindi: "हमारे",
    Tamil: "எங்கள்",
    Telugu: "మా",
    French: "Nos",
    Spanish: "Nuestras",
  };

  const Categories = {
    English: "Categories",
    Hindi: "श्रेणियाँ",
    Tamil: "வகைகள்",
    Telugu: "వర్గాలు",
    French: "Catégories",
    Spanish: "Categorías",
  };

  const partners = {
    Hindi: "भागीदार",
    English: "Partners",
    Tamil: "கூட்டாளிகள",
    Spanish: "Socios",
    Telugu: "భాగస్వాములు",
    French: "Partenaires",
  };

  const OurLanguage = Our[mylanguage];
  const CategoriesLanguage = Categories[mylanguage];
  const partnerslanguage = partners[mylanguage];

  const fontSizeStyle = isMobile ? { fontSize: '30px' } : mylanguage === 'Hindi' || mylanguage === 'Tamil' || mylanguage === 'Telugu' ? { fontSize: '50px' } : { fontSize: '62px' };

  return (
    <Fragment>
      <MetaTags>
        <title>Amyraa | Home</title>
        <meta name="description" content="Home ." />
      </MetaTags>
      <LayoutOne headerContainerClass="container-fluid" headerPaddingClass="header-padding-2" headerTop="visible">
        {/* hero slider */}
        <HomePageSlider />
        {/* product grid */}
        <HomeProducts
          category="fashion"
          spaceBottomClass="pb-50"
          spaceTopClass="pt-50"
          title={<span style={fontSizeStyle}>{NewLanguage}</span>}
          title2={<span style={fontSizeStyle}>{ArrivalsLanguage}</span>}
          products={newArrival}
          fetchMorePrds={() => FetchNewArrival()}
          wishlistPrds={wishlist}
          addwishlist={(product) => AddToWishlist(product)}
          deletewishlist={(DCODE) => DeleteFromWishlist(DCODE)}
        />
        {/* <div>
          <a href=" https://wa.me/9320838762">
            <img src={whatsapp_btn} className="whatsapp-img" alt="" />
          </a>
        </div> */}
        <HomeProducts
          category="fashion"
          spaceBottomClass="pb-50"
          spaceTopClass="pt-50"
          title={<span style={fontSizeStyle}>{MostLanguage}</span>}
          title2={<span style={fontSizeStyle}>{PopularLanguage}</span>}
          products={mostPopular}
          fetchMorePrds={() => FetchMostPopular()}
          wishlistPrds={wishlist}
          addwishlist={(product) => AddToWishlist(product)}
          deletewishlist={(DCODE) => DeleteFromWishlist(DCODE)}
        />
        {/* <Combo /> */}
        {/* <NewProductGrid category="accessories" limit={10} /> */}
        {/* blog featured */}

        <div className=" display-flex justify-content-center">
          <h1 className="text-black text-center text-title" style={fontSizeStyle}>{OurLanguage}</h1>
          <h1 className="text-green pt-1 pb-4" style={fontSizeStyle}>{CategoriesLanguage}</h1>
        </div>
        <div className="display-flex text-center">
          <hr className="hr-white" />
        </div>
        <Category DataTrasfer={setSelectedSubID} />
        <HowWeWork />
        <WhyUs />
        <CustomerReviews />
        {/* <OurPartners /> */}
        <div className="display-flex justify-content-center">
          <h1 className="text-black text-center text-title" style={fontSizeStyle}>{OurLanguage}</h1>
          <h1 className="text-green pt-1 pb-4" style={fontSizeStyle}>{partnerslanguage}</h1>
        </div>
        <BrandLogoSliderOne spaceBottomClass="pb-70 pt-70" />
        <Connect />
      </LayoutOne>

      <WhatsAppBotComp />
    </Fragment>
  );
};

export default Home;
