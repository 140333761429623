import React from "react";
import img11 from "../../assets/img/amyraHome/whatpeoplesay.webp";
import img12 from "../../assets/img/c2.png";
import Swiper from "react-id-swiper";
import { useSelector } from "react-redux";


function CustomerReviews() {


  const mylanguage = useSelector((state) => state.language);


  const params = {
    // shouldSwiperUpdate: true,
    // effect: "fade",
    // speed: 1000,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    watchSlidesVisibility: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    renderPrevButton: () => (
      <button
        className="swiper-button-prev ht-swiper-button-nav"
        style={{ left: -30 , fontSize:40}}
      >
        <i className="pe-7s-angle-left font-weight-bold text-dark " />
      </button>
    ),
    renderNextButton: () => (
      <button
        className="swiper-button-next ht-swiper-button-nav"
        style={{ right: -30 , fontSize:40 }}
      >
        <i className="pe-7s-angle-right font-weight-bold text-dark " />
      </button>
    ),
  };


  const FirstReview = {
    English:{
      FirstReviews:"I have used jewellery of all kinds from many other places, but my search for affordable and elegant jewellery ends with Amyraa. Great variety, great quality, and great packaging. Strongly recommend Amyraa’s products to all!",
      ReviewerName:"Rashi Shah",
    },
    Hindi:{
      FirstReviews:"मैंने कई अन्य स्थानों से हर प्रकार के आभूषण का उपयोग किया है, लेकिन सस्ते और शानदार आभूषण की तलाश अमीरा के साथ समाप्त होती है। शानदार विविधता, उत्कृष्ट गुणवत्ता, और शानदार पैकेजिंग। सभी को अमीरा के उत्पादों की मजबूत अनुशंसा करता हूं!",
      ReviewerName:"राशि शाह",
    },
    Telugu:{
      FirstReviews:"నేను చాలా ఇతర స్థలాలు నుండి అనేక రకాల జ్వెలరీ ఉపయోగించాను, కానీ నా అమీరా తో కనిపిస్తున్న అధికారిక మరియు శానదార జ్వెలరీని కోసం నా శోధన ముగిసేలా ఉంది. గొప్ప వివిధత, మహాన్ గుణం, మరియు మహాన్ ప్యాకేజింగ్. అన్నికి అమీరా ఉత్పత్తుల మాజీగా సిఫార్సు చేస్తున్నాను!",
      ReviewerName:"రాశి శాహ్",
    },
    Tamil:{
      FirstReviews:"நான் பல போல் மற்ற இடங்களில் வேறுபடும் வகைகளில் மணியகங்களை பயன்படுத்தி வந்துவிட்டேன், ஆனால் எனது மிகச் சம்பளமான மற்றும் மகிழ்ச்சியான மணியகம் அமையாராவின் மூலம் முடிந்துவிடும். மிகவும் வெற்றிகரமான வகைப்பாடு, மிகவும் தரமான தரம், மற்றும் மிகவும் பெரிய தோற்றம். அனைவருக்கும் அமீராவின் உற்பத்திகளை வல்லுறுதி செய்கிறேன்!",
      ReviewerName:"ராசி ஷா",
    },
    French:{
      FirstReviews:"J'ai utilisé des bijoux de toutes sortes venant de nombreux autres endroits, mais ma recherche de bijoux abordables et élégants se termine avec Amyraa. Grande variété, grande qualité et super emballage. Je recommande vivement les produits d'Amyraa à tous !",
      ReviewerName:"Rashi Shah",
    },
    Spanish:{
      FirstReviews:"He utilizado joyas de todo tipo de muchos otros lugares, pero mi búsqueda de joyas asequibles y elegantes termina con Amyraa. Gran variedad, gran calidad y gran empaque. ¡Recomiendo encarecidamente los productos de Amyraa a todos!",
      ReviewerName:"Rashi Shah",
    },

  }

  const SecondReview = {
    English:{
      SecondReviews:"Amyraa has become my go-to destination for my personal jewellery, and I am thoroughly satisfied with my experience. Besides, they also make for ideal gifts. They are affordable and classy, exactly what you look for in a gift. The quality of the products makes Amyraa stand out from the other jewellery companies selling similar products and I’d recommend their products to everyone looking to try something refreshing.",
      SecondName:"Mita Shetty",
    },
    Hindi:{
      SecondReviews:"आमिरा मेरे व्यक्तिगत आभूषण के लिए मेरी पसंदीदा गंतव्य बन गया है, और मैं अपने अनुभव से पूरी तरह संतुष्ट हूं। साथ ही, वे आदर्श उपहार भी बनाते हैं। वे सस्ते और क्लासी हैं, जो आप उपहार में खोजते हैं। उत्पादों की गुणवत्ता अन्य आभूषण कंपनियों को बेचती है जो समान उत्पाद बेचते हैं, और मैं उनके उत्पादों की सिफारिश करूँगा सभी को जो कुछ नया करने की कोशिश कर रहे हैं।",
      SecondName:"मीता शेट्टी",
    },
    Telugu:{
      SecondReviews:"ఆమీరా నా వ్యక్తిగత ఆభరణాల కోసం నా ముఖ్య గమన స్థలం అయింది, మరియు నా అనుభవంతో నేను పూర్తిగా తృప్తినచ్చాను. పోతకుండా, వారు సర్వోత్తమ ఉపహారాలు కూడా చేస్తున్నారు. వీలు సౌజన్యంగా మరియు క్లాసిగా ఉన్నాయి, మనం ఉపహారంలో ఎటువంటికి కనుగొన్నాము. ఉత్పత్తుల గుణము ఇతర ఆభరణ కంపెనీలను ఉత్పాదించే ఉత్పత్తులకు వేరు కల్పించింది, మరియు నేను అన్యాయంగా ప్రతిపాదించిన ఉత్పత్తులను అనుసరించే వారికి నా సిఫారసు చేస్తాను.",
      SecondName:"మీతా షెట్టి",
    },
    Tamil:{
      SecondReviews:"ஆமிரா என் தனிப்பட்ட ஜெவரி படைப்புகளுக்கு என் தேனிய செல்வமான கருவியாக உள்ளது, மேலும் எனது அனுபவம் மூலம் நான் முழுமையாக பரிதாபித்துக் கொண்டிருக்கிறேன். போதுமான பரிசுகளாக அவர்கள் தயாரிக்கின்றனர். அவர்கள் மதிப்பீட்டு பொருள்கள் பிற ஜெவரி நிறுவனங்களை விட விசாரிக்கின்றன, மேலும் எனக்கு ஒரு புதிய முயற்சியை முயற்சிக்க உதவுகின்றன.",
      SecondName:"மீதா ஷெட்டி",
    },
    French:{
      SecondReviews:"Amyraa est devenu ma destination privilégiée pour mes bijoux personnels, et je suis entièrement satisfait de mon expérience. De plus, ils sont également parfaits pour les cadeaux. Ils sont abordables et élégants, exactement ce que vous recherchez dans un cadeau. La qualité des produits fait d'Amyraa un acteur incontournable parmi les autres entreprises de bijoux vendant des produits similaires, et je recommanderais leurs produits à tous ceux qui cherchent à essayer quelque chose de rafraîchissant.",
      SecondName:"Mita Shetty",
    },
    Spanish:{
      SecondReviews:"Amyraa se ha convertido en mi destino preferido para mis joyas personales, y estoy completamente satisfecho con mi experiencia. Además, también son ideales para regalos. Son asequibles y elegantes, exactamente lo que buscas en un regalo. La calidad de los productos hace que Amyraa se destaque entre las demás empresas de joyería que venden productos similares, y recomendaría sus productos a todos los que busquen probar algo refrescante",
      SecondName:"Mita Shetty",
    },
  }

 
        
  const ThirdReview = {
    English:{
      ThirdReviews:"Amyraa has a great variety of all kinds of jewellery. I ordered jewellery for an upcoming occasion and the quality was truly superior and I also got the delivery on time. Love all the products offered.",
       ThirdName:"Roshni Kotwal",
    },
    Hindi:{
      ThirdReviews: "आमिरा में हर प्रकार के ज्वेलरी का एक बड़ा विविधता है। मैंने आगामी अवसर के लिए आभूषण आदेश किया और गुणवत्ता वास्तव में उत्कृष्ट थी और मुझे समय पर भी वितरण मिला। सभी प्रस्तुत की गई उत्पादों को पसंद किया।",
      ThirdName: "रोशनी कोटवाल"
    },
    Tamil:{
        ThirdReviews: "ஆமிராவில் எல்லா வகையான பாடக்கான சிறந்த வகை உள்ளது. வரம்புத்தனமாக ஆக்கப்பட்ட ஒரு நல்ல வரைபடத்தில் ஆ஭ரணங்களை நான் வாங்கினேன் மேலும் கொண்டுவர முடியாது என்று நான் உறுதியாக நினைவுக்குப் பெற்றேன். கொண்டுவர முடியாது என்று நான் உறுதியாக நினைவுக்குப் பெற்றேன். வாய்ப்பு பற்றி என்னைத் தெரிந்துகொள்ளும் எல்லா உத்பந்தங்களையும் காத்திருக்கிறேன்.",
        ThirdName: "ரோஷ்ணி கோட்வால்"
      },
      Telugu:{
          ThirdReviews: "ఆమీరాలో అన్ని రకాల ఆభరణాల వివిధమైన వివరాలు ఉన్నాయి. తరువాత సంఘటన కోసం ఆభూషణాలను ఆర్డర్ చేసాను మరియు గుణము నిజముగా మించిపోయింది మరియు నాకు సమయంలో కూడా సరఫరా చేసినారు. ఆలస్యం నాన్నాకు కూడా అనుకూలంగా ఉన్నాయి.",
          ThirdName: "రోష్ణి కోట్వాల్"
        },
        French:{
            ThirdReviews: "Amyraa propose une grande variété de tous types de bijoux. J'ai commandé des bijoux pour une occasion à venir et la qualité était vraiment supérieure et j'ai également reçu la livraison à temps. J'aime tous les produits proposés.",
            ThirdName: "Roshni Kotwal"
          },
          Spanish:{
            
              ThirdReviews: "Amyraa tiene una gran variedad de todo tipo de joyas. Pedí joyas para una ocasión próxima y la calidad fue realmente superior y también recibí la entrega a tiempo. Me encantan todos los productos ofrecidos.",
              ThirdName: "Roshni Kotwal"
            }
            
          }
          
        
          const FourthReview = {
            English:{
              FourthReviews:"AI ordered jewellery for my wife for our anniversary, and I am extremely satisfied with the quality of the products. The designs perfectly fit the occasion and the vibrance really stands out. Will continue shopping at Amyraa!",
               FourthName:"Sanjay Gupta",
            },
            Hindi: {
              FourthReviews: "मैंने हमारी सालगिरह के लिए अपनी पत्नी के लिए आभूषण ऑर्डर किया, और मैं उत्पादों की गुणवत्ता से पूरी तरह संतुष्ट हूं। डिज़ाइन बिल्कुल अवसर के लिए उपयुक्त हैं और जोश वास्तव में प्रकट होता है। Amyraa पर खरीदारी जारी रहेगी!",
              FourthName: "संजय गुप्ता"
            },
            Telugu:{
                FourthReviews: "ముఖ్యంగా మా వార్షికోత్సవానికి నా భార్యకు ఆభరణాలను ఆర్డర్ చేసాను, మరియు నాకు ఉత్పాదాల గుణముతో అత్యంత సంతోషం ఉంది. డిజైన్‌లు అవసరంపై పూర్తిగా అనుకూలంగా ఉన్నాయి మరియు వెండి విజయం వాస్తవంగా ప్రకటించుకుంది. Amyraa లో కొనుగోలు ముందుకు తిరిగి పోతాను!",
                FourthName: "సంజయ్", 
            },
            Spanish:{
                FourthReviews: "Pedí joyas para mi esposa para nuestro aniversario, y estoy extremadamente satisfecho con la calidad de los productos. Los diseños se adaptan perfectamente a la ocasión y la viveza realmente se destaca. ¡Seguiré comprando en Amyraa!",
                FourthName: "Sanjay Gupta"    
            },
            French:
              {
                FourthReviews: "J'ai commandé des bijoux pour ma femme pour notre anniversaire, et je suis extrêmement satisfait de la qualité des produits. Les designs correspondent parfaitement à l'occasion et la vivacité se démarque vraiment. Je continuerai à faire mes achats chez Amyraa !",
                FourthName: "Sanjay Gupta"   
            },
            Tamil:{
                FourthReviews: "என் தம்பதிக்கு என் திருநாள் பரிந்துரைக்க ஆ஭ரணங்களை ஆர்டர் செய்துகொண்டேன், மற்றும் உத்பந்தங்களின் தரம் மிகவும் மிகமாக முழுமையாக பெற்றேன். வடிவங்கள் சரியானவை அனைத்து நிகழ்ச்சிகளுக்கும் பூரணமாக பொருந்துகின்றன மற்றும் வெளிச்சம் மெய்ப்பொருள் மிகவும் முக்கியமாக உள்ளது. Amyraa யில் வாங்க continuesஸ் தொடரும்!",
                FourthName: "சஞ்சய் குப்தா"
              }
          
          }



  const { FirstReviews: FirstReviewer, ReviewerName: FirstReviewerName } = FirstReview[mylanguage] || FirstReview["English"];
  const { SecondReviews: SecondReviewer, SecondName: SecondReviewerName } = SecondReview[mylanguage] || SecondReview["English"];
  const { ThirdReviews: ThirdReviews, ThirdName: ThirdName } = ThirdReview[mylanguage] || ThirdReview["English"];
  const { FourthReviews: FourthReviews, FourthName: FourthName } = FourthReview[mylanguage] || FourthReview["English"];


const what = {
Hindi: "लोग",
English: "What",
Tamil: "என்ன" ,
Spanish: "Qué",
Telugu: "ఏమి" ,
French: "Quoi",
}
const peopleSay = {
Hindi: "क्या कहते हैं ",
English: "People Say",
Tamil: "மக்கள் சொல்கிறார்கள்",
Spanish: "La Gente Dice",
Telugu: "ప్రజలు అంటున్నారు",
French: "Les Gens Disent",
}

const WhatLanguage = what[mylanguage]
  const peopleSaylanguage = peopleSay[mylanguage]

  return (
    <div className="container Custom_whatpeoplesay">
      <div
        style={{
          backgroundImage: `url(${img11})`,
          backgroundSize: "cover",
          paddingBlock: 125,
          borderRadius: 18,
          marginLeft: 10,
          marginRight: 10,
        }}
        className="Custom_peopleSaymain"
      >
        <div className="container">
          <div className="row " style={{ width: "50%" }}>
            <div className="text-white font-weight-normal mb-5 mt-n5 pt-n3 display-flex">
              <h1 className="text-white text-title">{WhatLanguage}</h1>
              <h1 className="text-green2">{peopleSaylanguage}</h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row ">
            <div className="bg-white box-shadow p-4" style={{ width: "40%" }}>
              <div className="slider-area">
                <div className="slider-active nav-style-1">
                  <Swiper {...params}>
                    <div className="text-center py-3">
                      <div>
                        {/* <img src={img12} className="rounded" /> */}
                      </div>
                      <div className="cutomer-review font-weight-normal py-3 ">
                       {FirstReviewer}
                      </div>
                      <div className="h3 py-3 ">- {FirstReviewerName}</div>
                    </div>
                    <div className="text-center py-3">
                      <div>
                        {/* <img src={img12} className="rounded" /> */}
                      </div>
                      <div className="cutomer-review font-weight-normal py-3">
                       {SecondReviewer}
                      </div>
                      <div className="h3 py-3">- {SecondReviewerName}</div>
                    </div>
                    <div className="text-center py-3">
                      <div>
                        {/* <img src={img12} className="rounded" /> */}
                      </div>
                      <div className="cutomer-review font-weight-normal py-3">
                        {ThirdReviews}
                      </div>
                      <div className="h3 py-3">- {ThirdName}</div>
                    </div>
                    <div className="text-center py-3">
                      <div>
                        {/* <img src={img12} className="rounded" /> */}
                      </div>
                      <div className="cutomer-review font-weight-normal py-3">
                      {FourthReviews}
                      </div>
                      <div className="h3 py-3">- {FourthName}</div>
                    </div>
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerReviews;
