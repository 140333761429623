import React from "react";
import how_we_work from "../../assets/img/amyraHome/howwework.webp";
import { useSelector } from "react-redux";



function HowWeWork() {

  const mylanguage = useSelector((state) => state.language)


  const textContent = {
    English: {
      sectionHeader: "We do things differently at Amyraa",
      sectionText:"We do things differently at Amyraa. At Amyraa, we only use the finest materials to make our products, combined with skilled craftsmanship, to finally deliver products that exude elegance. Register with us, choose from a vast variety of exclusive products and get them delivered to your doorstep. When you sell products with Amyraa, all you have to do is create a profile on our platform, place an order and we do the rest.",
    },
    Hindi: {
      sectionHeader: "हम अमायरा में चीजें अलग तरीके से करते हैं। ",
      sectionText:"अमायरा में, हम अपने उत्पादों को बनाने के लिए केवल बेहतरीन सामग्रियों का उपयोग करते हैं, कुशल शिल्प कौशल के साथ मिलकर, अंत में ऐसे उत्पाद वितरित करते हैं जो सुंदरता प्रदान करते हैं। हमारे साथ पंजीकरण करें, विभिन्न प्रकार के विशिष्ट उत्पादों में से चुनें और उन्हें अपने दरवाजे पर पहुंचाएं। जब आप अमायरा के साथ उत्पाद बेचते हैं, तो आपको बस हमारे प्लेटफॉर्म पर एक प्रोफ़ाइल बनानी होती है, ऑर्डर देना होता है और बाकी काम हम करते हैं।",
    },
    Spanish: {
      sectionHeader: "En Amyraa hacemos las cosas de manera diferente.",
      sectionText:"En Amyraa, solo utilizamos los mejores materiales para fabricar nuestros productos, combinados con habilidades artesanales, para finalmente entregar productos que desprenden elegancia. Regístrese con nosotros, elija entre una gran variedad de productos exclusivos y recíbalos en su puerta. Cuando vende productos con Amyraa, todo lo que tiene que hacer es crear un perfil en nuestra plataforma, realizar un pedido y nosotros hacemos el resto.",
    },
    Tamil: {
      sectionHeader: "அமைராவில் நாங்கள் விஷேடமாக செயல்படுகிறோம்.",
      sectionText:"அம்ய்ராவில், எங்களது உற்பத்திகளை உருவாக்குவதுக்கு மட்டும் சிறந்த பொருள்களை பயன்படுத்துகிறோம், நுழைவாயிலும் உழைப்பின் கையொப்பத்துடன் கலைகளை இணைக்கின்றன, கட்டற்ற சிறுவனியுடன் இருந்து உற்பத்திகள் வழங்கப்படும் வழிமுறைகளை அடிப்படையில் தருகின்றோம். எங்களுடன் பதிவு செய்ய, எங்கள் மொத்தத்தில் உதவி பெற, எங்களுடன் உற்பத்திகளை விற்கும்போது, உங்கள் கட்டணத்தை கொண்டு, மேலும் எங்களது பெயர் சேர்க்கை செய்ய வேண்டிய பின்னர், மேலும் எங்களது உள்ளமைவில் பதிவு செய்வதேன்.",
    },
    Telugu: {
      sectionHeader: "అమ్య్రాలో వారు విభిన్నంగా చేయుతున్నారు.",
      sectionText: "అమ్య్రాలో, మన ఉత్పత్తులను తయారు చేయడానికి మాత్రమే మెరుగుపరచే ఉత్కృష్ట సామగ్రిని ఉపయోగిస్తాము, కుశల కౌశలతో కలిగిన విద్యాబోధతో తొలిసారిగా ఉత్పత్తులు చేయబడుతున్నాయి. మన తో నమోదు చేయండి, మన ప్లాట్‌ఫారంలోని నిర్దిష్ట ఉత్పత్తుల వల్ల ఎంచుకోండి మరియు మీ దగ్గరకు అవసరమైనవిని పొందండి. అమ్య్రాతో ఉత్పత్తులను అమ్మించడం వలన మీరు మా ప్లాట్‌ఫారంలో ప్రొఫైల్‌ను సృష్టించాలి, ఒక ఆర్డర్‌ను పెట్టాలి మరియు మేము ఇతరం చేస్తాం.",
    },
    French: {
      sectionHeader: "Nous faisons les choses différemment chez Amyraa.",
      sectionText: "Chez Amyraa, nous utilisons uniquement les meilleurs matériaux pour fabriquer nos produits, associés à un savoir-faire artisanal, pour enfin livrer des produits qui respirent l'élégance. Inscrivez-vous chez nous, choisissez parmi une grande variété de produits exclusifs et faites-les livrer chez vous. Lorsque vous vendez des produits avec Amyraa, tout ce que vous avez à faire est de créer un profil sur notre plateforme, passer une commande et nous faisons le reste.",
    },
  };


  const { sectionHeader, sectionText } = textContent[mylanguage];


  const How = {
Hindi: "हम", 
English: "How",
Tamil: "நாங்கள்",
Spanish: "Cómo",
Telugu: "మేము", 
French: "Comment",
  }

  const WeWork = {
Hindi: "कैसे काम करते हैं",
English: "We Work",
Tamil: "எப்படி",
Spanish: "Trabajamos",
Telugu: "ఎలా పని చేస్తాము",
French: "Nous Travaillons",
  }

  const HowLanguage = How[mylanguage]
  const WeWorkLanguage = WeWork[mylanguage]

  

  
  return (
    <section
      id="how_we_work"
      style={{
        backgroundImage: `url(${how_we_work})`,
        backgroundSize: "cover",
      }}
    >
      <div className="container mb-5">
        <div className="row justify-content-center mb-5"></div>
        <div className="row">
          <div className="col-6 my-auto work-text">
            <div className="text-white font-weight-bold display-flex">
              <h1 className="text-white text-title">{HowLanguage}</h1>
              <h1 className="text-green2">{WeWorkLanguage}</h1>
            </div>
            <div>
              <hr className="hr-white" />
              <h4 className="text-white font-weight-bold">
              {sectionHeader}
                {/* We do things differently at Amyraa */}
              </h4>
            </div>
            <div className="text-white mt-3">
              {sectionText}
            </div>
            <div className="text-white mt-1"> </div>
            {/* <div className=" text-white font-weight-bold mt-5">
              <button className="button">
                <span>Know More </span>
              </button>
            </div> */}
          </div>
          <div className="col-4 text-center ">
            <div className="how-we-work-section">
              {/* <img src={img6} className="image-how-we-work img-thumbnail"></img> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowWeWork;
