import PropTypes from "prop-types";
import React from "react";
import ProductListingGrid from "./ProductListingGrid";

const ProductListingProducts = ({
  products,
  layout,
  selectedProducts,
  selectedProductsArr,
  checkAll,
  productsLength,
  setProductsLength,
}) => {
  return (
    <div className="shop-bottom-area mt-35">
      <div className={`row ${layout ? layout : ""}`}>
        <ProductListingGrid
          products={products}
          checkAll={checkAll}
          selectedProductsArr={selectedProductsArr}
          selectedProducts={selectedProducts}
          spaceBottomClass="mb-25"
          productsLength={productsLength}
          setProductsLength={setProductsLength}
        />
      </div>
    </div>
  );
};

ProductListingProducts.propTypes = {
  layout: PropTypes.string,
  products: PropTypes.array,
};

export default ProductListingProducts;
