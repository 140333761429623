import React, { useState } from 'react';
import { Button, TextField, Typography, Container, Grid, Checkbox, FormControlLabel, Card, CardContent } from '@mui/material';
import amyraaLogo from '../../assets/img/amyraa_logo.png'
import Apilib from "../../utils/api";
import { useToasts } from "react-toast-notifications";
import Loader from '../../helpers/Loader';


const DeleteAccountPage = () => {
  const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  const [confirmChecked, setConfirmChecked] = useState(false);

  const API = new Apilib();
  const [loading, setLoading] = useState(false);

  const { addToast } = useToasts();
  const DeleteAddress = (email) => {
    const data = {email:email}

    setLoading(true);


    API.deleteMyAccount(data)
      .then((res) => {
        console.log("resresres", res);
        setLoading(false);
         addToast("Your request submitted successfully", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((e) => {
        console.log(e,"eroororoorr");
        setLoading(false);
        addToast("Invalid Email", {
          appearance: "error",
          autoDismiss: true,
        });
    
      });
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  // const handlePasswordChange = (event) => {
  //   setPassword(event.target.value);
  // };

  const handleConfirmCheckedChange = (event) => {
    setConfirmChecked(event.target.checked);
  };

  const handleConfirmDelete = () => {
    // Check if the checkbox is checked before confirming the delete
    if (confirmChecked) {
      // Add logic here to handle the delete operation
      console.log(email);
      setLoading(true);
      DeleteAddress(email)
      // console.log('Password:', password);
      // You can make an API call or perform any other actions here
    } else {
      alert('Please confirm the delete by checking the checkbox.');
    }
  };

  const cardStyle = {
    maxWidth: 400,
    margin: 'auto',
    marginTop: '20vh', // Adjust the marginTop to center vertically
    padding: 20,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    overflow: 'hidden',
  };

  const boxStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60vh', // Optional: Set the height to the viewport height for full-screen centering
  
  };

  const typographyStyle = {
    marginBottom: '20px', // Adjust the margin as needed
    fontWeight: 'bold', // Make the text more bold
    fontFamily: 'Poppins, sans-serif', // Set the font to Poppins
  };

  const buttonStyle = {
    background: '#218f7e', // Set the background color to #218f7e
    color: 'white', // Set the text color to white
  };

  return (
    <Container component="main" maxWidth="xs">
      <Card style={cardStyle}>
        <CardContent>
          <div style={boxStyle}>
          <img src={amyraaLogo} width="170px" alt="" />

            <Typography component="h1" variant="h5" style={typographyStyle}>
              Delete Account
            </Typography>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={confirmChecked}
                        onChange={handleConfirmCheckedChange}
                        color="primary"
                      />
                    }
                    label="I am sure I want to delete my account."
                    style={{ fontFamily: 'Poppins, sans-serif' }} // Set the font to Poppins
                  />
                </Grid>
              </Grid>
              <Button
                type="button"
                fullWidth
                variant="contained"
                style={buttonStyle} // Apply the button styles
                onClick={handleConfirmDelete}
              >
                Confirm Delete
              </Button>
            </form>
          </div>
        </CardContent>
      </Card>
      {loading && <Loader />}
    </Container>
  );
};

export default DeleteAccountPage;
