import { ActionTypes } from "../constants/action-type";

export default function setProducts(products) {
  return {
    type: ActionTypes.SET_PRODUCTS,
    payload: products,
  };
}
export function selectedFilters(filters) {
  return {
    type: ActionTypes.SELECTED_FILTERS,
    payload: filters,
  };
}
export function selectedTopFilters(filters) {
  return {
    type: ActionTypes.SELECTED_TOP_FILTERS,
    payload: filters,
  };
}
export function setProduct(product) {
  return {
    type: ActionTypes.SET_PRODUCT,
    payload: product,
  };
}


export function search(val) {
  return {
    type: ActionTypes.SEARCH,
    payload: val,
  };
}
