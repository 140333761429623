import React, { useState, useEffect } from "react";
// import Modal from "@mui/material/Modal";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useToasts } from "react-toast-notifications";
import Loader from "../../helpers/Loader";
import mixpanel from "mixpanel-browser";
import Apilib, { ShipmentApi } from "../../utils/api";
import { State, City } from "country-state-city"; // Import state and city functions



function AddAddress(props) {

   //
   const [selectedCountry, setSelectedCountry] = useState(""); // State to store the selected country
   const [selectedstate, setSelectedState] = useState(""); // State to store the selected country
 
   const [states, setStates] = useState([]);
   const [cities, setCities] = useState([]);

   console.log(states,"statesstates")
   //

  const userDetails = useSelector((state) => state.userDetails);
  //
  const SHIPMENTAPI = new ShipmentApi();
  const [availableCountries, setAvailableCountries] = useState({}); 
  //
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  const userDetailsInformation = useSelector((state) => state?.userInformation?.user);

  const API = new Apilib();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      USERID: userDetails.user.ID,
      FNAME: "",
      EMAILID: "",
      MOBNO: "",
      ALTMOBNO: "",
      PIN: "",
      AREA: "",
      PAR_ADD1: "",
      PAR_ADD2: "",
      PAR_ADD3: "",
      CITY: "",
      STATE: "",
      COUNTRY: ""
    },
    validationSchema: Yup.object({
      FNAME: Yup.string()
        .required()
        .min(3)
        .matches(/^([\w]{3,})+(\s+([\w\s]{3,})+)?$/i, "Invalid name")
        .label("Full Name"),
      EMAILID: Yup.string()
        .required()
        .min(3)
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Invalid email id"
        )
        .label("Email Id"),
      MOBNO: Yup.string()
        .required()
        .matches(/^[0-9]+$/, "Invalid mobile no.")
        .min(7)
        .max(15)
        .label("Mobile no"),
      ALTMOBNO: Yup.string()
        .matches(/^[0-9]+$/, "Invalid mobile no.")
        .min(7)
        .max(15)
        .label("Alternate no"),
      AREA: Yup.string().required().min(0).label("Area"),
  //     AREA: Yup.string()
  // .required()
  // .matches(/^\s*\S+(?:\s+\S+)*$/, 'Area cannot be blank or contain only spaces')
  // .label("Area"),

      PIN: Yup.string()
        .required()
        .matches(/^[0-9]+$/, "Invalid pincode")
        .min(0)
        .max(6)
        .label("Pincode"),
      PAR_ADD1: Yup.string()
        .required()
        .min(0)
        .label("Address"),
      PAR_ADD2: Yup.string().min(0).label("ADDRESS 2"),
      PAR_ADD3: Yup.string().min(0).label("ADDRESS 3"),
      // CITY: Yup.string().required().min(0).label("City"),
      CITY: Yup.string()
  .required()
  .matches(/^\s*\S+(?:\s+\S+)*$/, 'City cannot be blank or contain only spaces')
  .label("City"),

      // COUNTRY: Yup.string().required().min(0).label("Country"),
      COUNTRY: Yup.string()
  .required()
  .matches(/^\s*\S+(?:\s+\S+)*$/, 'Country cannot be blank or contain only spaces')
  .label("Country"),

      // STATE: Yup.string().required().min(0).label("State"),
      STATE: Yup.string()
  .required()
  .matches(/^\s*\S+(?:\s+\S+)*$/, 'State cannot be blank or contain only spaces')
  .label("State"),

    }),
    onSubmit: (values) => {
      Submit();
    },
  });


  const getAvailableCountries = async () => {
    setLoading(true)
    try {
      let { data } = await SHIPMENTAPI.getShipmentAvailableCountries(userDetailsInformation.EMAILID);
      setAvailableCountries(data?.data);
      // formik.setFieldValue('COUNTRY',data?.data[0].name)
      console.log(data,"datadatadata")
    } catch (error) {
      console.log(error);
    }
    setLoading(false)
  };

  useEffect(() => {
    getAvailableCountries();
  }, []);

  const Submit = () => {
    if (!formik.isValid) return;
    setLoading(true);
    const data = { ...formik.values };
    API.AddDeliveryAddress(data)
      .then((res) => {
        addToast("Address added successfully", {
          appearance: "success",
          autoDismiss: true,
        });
        //
        mixpanel.track("AddressAdded");
        //
        //
        formik.resetForm();
        //
        props.fetchAddress();
        props.Onclose();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        addToast("Something went wrong please try again", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const handleCountryChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex]; // Get the selected option element
    const isoCode = selectedOption.getAttribute("data-isocode"); // Extract the ISO code attribute
    setSelectedCountry({
      name: e.target.value,
      isoCode: isoCode
    }); // Update selected country state with country name and ISO code
    formik.handleChange(e); // Call formik's handleChange method to update form values
  };
  
  console.log(selectedCountry.isoCode,"SelectedCountry")

  const handleStateChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex]; // Get the selected option element
    const isoCode = selectedOption.getAttribute("state-isoCode"); // Extract the ISO code attribute
    setSelectedState({
      name: e.target.value,
      isoCode: isoCode
    }); // Update selected country state with country name and ISO code
    formik.handleChange(e); // Call formik's handleChange method to update form values
  };

  console.log(selectedstate.isoCode,"selectedstate")

  useEffect(() => {
    // Fetch states when the country changes
    if (selectedCountry.isoCode) {
      const countryStates = State.getStatesOfCountry(selectedCountry.isoCode);
      setStates(countryStates);
    }
  }, [selectedCountry.isoCode]);

  console.log(states,"statestate");

  useEffect(() => {
    // Fetch states when the country changes
    if (selectedCountry.isoCode,selectedstate.isoCode) {
      const StateCities = City.getCitiesOfState(selectedCountry.isoCode,selectedstate.isoCode);
      setCities(StateCities);
    }
  }, [selectedstate.isoCode,selectedCountry.isoCode]);

  console.log(cities,"citiescities");

  return (
    <>
      {loading ? <Loader /> : null}
      <Modal
        show={props.open}
        onHide={() => props.Onclose()}
        className="custom_arrow_bigger"
      // dialogClassName="address-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="sidebar-title">Add New Address</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="mt-4 mx-4 Custom_modalBody_form">
            <form onSubmit={formik.handleSubmit}>
              <div className="row font-weight-bold text-black  ">
                <div className="col-6">
                  <label>Name</label>
                  <span
                    style={{ color: "red", fontWeight: "bold" }}
                    class="required"
                  >
                    *
                  </span>
                  <input
                    type="text"
                    placeholder="Enter your full name"
                    class="form-control "
                    name="FNAME"
                    onChange={formik.handleChange}
                    value={formik.values.FNAME || ""}
                    onBlur={formik.handleBlur}
                  />
                  <span className="form-text text-danger login-error">
                    {formik.touched.FNAME && formik.errors.FNAME}
                  </span>
                </div>
                <div className="col-6">
                  <label>Email-ID </label>
                  <span
                    style={{ color: "red", fontWeight: "bold" }}
                    class="required"
                  >
                    *
                  </span>
                  <input
                    type="text"
                    class="form-control "
                    placeholder=" Enter your email-ID"
                    name="EMAILID"
                    onChange={formik.handleChange}
                    value={formik.values.EMAILID || ""}
                    onBlur={formik.handleBlur}
                  />
                  <span className="form-text text-danger login-error">
                    {formik.touched.EMAILID && formik.errors.EMAILID}
                  </span>
                </div>
                <div className="col-6  ">
                  <label>Mobile No</label>
                  <span
                    style={{ color: "red", fontWeight: "bold" }}
                    class="required"
                  >
                    *
                  </span>
                  {/* <ReactPhoneInput
                    inputExtraProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                    defaultCountry={"sg"}
                    value={this.state.phone}
                    onChange={this.handleOnChange}
                  /> */}
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter your mobile number"
                    name="MOBNO"
                    onChange={formik.handleChange}
                    value={formik.values.MOBNO || ""}
                    onBlur={formik.handleBlur}
                  />
                  <span className="form-text text-danger login-error">
                    {formik.touched.MOBNO && formik.errors.MOBNO}
                  </span>
                </div>
                <div className="col-6  ">
                  <label>Alternate Phone Number</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter your alternate number"
                    name="ALTMOBNO"
                    onChange={formik.handleChange}
                    value={formik.values.ALTMOBNO || ""}
                    onBlur={formik.handleBlur}
                  />
                  <span className="form-text text-danger login-error">
                    {formik.touched.ALTMOBNO && formik.errors.ALTMOBNO}
                  </span>
                </div>
                <div className="col-12  ">
                  <label>Address 1</label>
                  <span
                    style={{ color: "red", fontWeight: "bold" }}
                    class="required"
                  >
                    *
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    name="PAR_ADD1"
                    onChange={formik.handleChange}
                    value={formik.values.PAR_ADD1 || ""}
                    onBlur={formik.handleBlur}
                    placeholder="Enter your address"
                  />
                  <span className="form-text text-danger login-error">
                    {formik.touched.PAR_ADD1 && formik.errors.PAR_ADD1}
                  </span>
                </div>
                {/* <div className="col-12  ">
                  <label>Address 2</label>
                  <input
                    type="text"
                    class="form-control"
                    name="PAR_ADD2"
                    onChange={formik.handleChange}
                    value={formik.values.PAR_ADD2 || ""}
                    onBlur={formik.handleBlur}
                    placeholder="Enter your alternate address"
                  />
                  <span className="form-text text-danger login-error">
                    {formik.touched.PAR_ADD2 && formik.errors.PAR_ADD2}
                  </span>
                </div>
                <div className="col-12  ">
                  <label>Address 3</label>
                  <input
                    type="text"
                    class="form-control"
                    name="PAR_ADD3"
                    onChange={formik.handleChange}
                    value={formik.values.PAR_ADD3 || ""}
                    onBlur={formik.handleBlur}
                    placeholder="Enter your alternate address"
                  />
                  <span className="form-text text-danger login-error">
                    {formik.touched.PAR_ADD3 && formik.errors.PAR_ADD3}
                  </span>
                </div> */}
                <div className="col-6  ">
                  <label>Area</label>
                  <span
                    style={{ color: "red", fontWeight: "bold" }}
                    class="required"
                  >
                    *
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter your area"
                    name="AREA"
                    onChange={formik.handleChange}
                    value={formik.values.AREA || ""}
                    onBlur={formik.handleBlur}
                  />
                  <span className="form-text text-danger login-error">
                    {formik.touched.AREA && formik.errors.AREA}
                  </span>
                </div>
                <div className="col-6  ">
                  <label>Pincode</label>
                  <span
                    style={{ color: "red", fontWeight: "bold" }}
                    class="required"
                  >
                    *
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter your pincode"
                    name="PIN"
                    onChange={formik.handleChange}
                    value={formik.values.PIN || ""}
                    onBlur={formik.handleBlur}
                  />
                  <span className="form-text text-danger login-error">
                    {formik.touched.PIN && formik.errors.PIN}
                  </span>
                </div>

                <div className="col-12 col-md-6 ">
                    <label>Country</label>
                    <span
                      style={{ color: "red", fontWeight: "bold" }}
                      class="required"
                    >
                      *
                    </span>
                    {/* <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Country"
                      name="COUNTRY"
                      onChange={formik.handleChange}
                      value={formik.values.COUNTRY || ""}
                      onBlur={formik.handleBlur}
                    /> */}
                    <select
                      name="COUNTRY"
                      id="countries"
                      class="form-control"
                      placeholder="Enter Your Country"
                      // onChange={formik.handleChange}
                      onChange={handleCountryChange} // Call handleCountryChange when the country is changed
                      value={selectedCountry} // Use selectedCountry state as the value
                      // value={formik.values.COUNTRY}
                      onBlur={formik.handleBlur}
                      defaultValue={formik?.values.COUNTRY}
                    >
                      <option hidden value>
            {" "}
            {selectedCountry.name ? selectedCountry.name : "Select Country"}
          </option>

                      {availableCountries.length &&
                        availableCountries.map((_country) => (
                          <option key={_country.id} value={_country.name}  data-isocode={_country.iso_code_2}  >
                            {_country.name}
                          </option>
                        ))}
                    </select>
                    <span className="form-text text-danger login-error">
                      {formik.touched.COUNTRY && formik.errors.COUNTRY}
                    </span>
                  </div>
                 

                  <div className="col-12 col-md-6">
                    <label>State</label>
                    <span
                      style={{ color: "red", fontWeight: "bold" }}
                      class="required"
                    >
                      *
                    </span>
                    <select
    className="form-control"
    name="STATE"
    onChange={handleStateChange}
    value={selectedstate}
    onBlur={formik.handleBlur}
  >
    <option value="">  {selectedstate.name ? selectedstate.name : "Select State"}</option>
    {states.map((state) => (
      <option key={state.isoCode} value={state.name} state-isoCode={state.isoCode} >
        {state.name}
      </option>
    ))}
  </select>
                    <span className="form-text text-danger login-error">
                      {formik.touched.STATE && formik.errors.STATE}
                    </span>
                  </div>

                
                  <div className="col-12 col-md-6">
  <label>City</label>
  <span style={{ color: "red", fontWeight: "bold" }} class="required">
    *
  </span>
  <select
    className="form-control"
    name="CITY"
    onChange={formik.handleChange}
    value={formik.values.CITY}
    onBlur={formik.handleBlur}
  >
    <option value="">Select City</option>
    {cities.map((city) => (
      <option key={city.name} value={city.name}>
        {city.name}
      </option>
    ))}
  </select>
  <span className="form-text text-danger login-error">
    {formik.touched.CITY && formik.errors.CITY}
  </span>
</div>
              </div>
              <div className="row text-center">
                <div className=" mb-4  w-100">
                  <button
                    className="btn border-round text-white btn-background"
                    // onClick={() => props.addAddress()}
                    type="submit"
                  >
                    Submit
                  </button>
                  <div
                    className="btn title-text border-round border-green  mx-4"
                    onClick={() => props.Onclose()}
                  >
                    Close
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddAddress;
