import { ActionTypes } from "../constants/action-type";

const initialState = {
  wishlistItems: [],
};
export const WishlistReducer = (state = initialState, { type, payload }) => {
  console.log("wishlist payload", type, payload);
  switch (type) {
    case ActionTypes.GET_WISHLIST:
      return { ...state, wishlistItems: payload };
    default:
      return state;
  }
};
