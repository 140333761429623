import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import addToCart from "../redux/actions/cartActions";
import ApiLib from "../utils/api";
import getWishlist from "../redux/actions/wishlistActions";
const ScrollToTop = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const API = new ApiLib();
  const userDetails = useSelector((state) => state.userDetails.user);

  const GetCartDetails = () => {
    setLoading(true);
    API.GetCart(userDetails.ID)
      .then((res) => {
        console.log("get cart", res);
        dispatch(addToCart(res.data));
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const GetWishlistDetails = () => {
    setLoading(true);
    API.GetUserWishlist(userDetails?.ID)
      .then((res) => {
        console.log("get wishlist", res);
        dispatch(getWishlist(res.data));
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    console.log("scroll to top working");
    if (userDetails) {
      GetCartDetails();
      GetWishlistDetails();
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return props.children;
};

export default withRouter(ScrollToTop);
