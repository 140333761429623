import mixpanel from 'mixpanel-browser';
mixpanel.init('8dc442bbde294cd15c35ec89e2f727af', {debug: true, track_pageview: true, persistence: 'localStorage'});
// Set this to a unique identifier for the user performing the event.
// Track an event. It can be anything, but in this example, we're tracking a Sign Up event.
let actions = {
    track: (name, props) => {
        mixpanel.track(name, props);
       },
    identify:(id) => {
        mixpanel.identify(id);
    }   
}
export let Mixpanel = actions;