import React, { useState, useEffect } from "react";
import IconGroup from "../../components/header/IconGroup";
import { useToasts } from "react-toast-notifications";
import { Link, useHistory } from "react-router-dom";
import "./header.css";
import Loader from "../../helpers/Loader";
import { useSelector, useDispatch } from "react-redux";
import setUser from "../../redux/actions/userActions";
import addToCart from "../../redux/actions/cartActions";
import ApiLib from "../../utils/api";
import amyraaLogo from "../../assets/img/amyraa_logo.png";
import mixpanel from "mixpanel-browser";

import CurrencyModal from "./CurrencyModal";
import LanguageModel from "./sub-components/LanguageModel";

const HeaderTop = ({ borderStyle }) => {
  const [toggle, setToggle] = useState(false);
  const [authenticatedUser, setAuthenticatedUser] = useState(false);
  const userDetails = useSelector((state) => state?.userDetails?.user);
  const [bannerText, setBannerText] = useState("");
  const { addToast } = useToasts();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const API = new ApiLib();

  const mylanguage = useSelector((state) => state.language);

  let isRegisteredWithMixPanel = localStorage.getItem(
    "IsRegisteredWithMixPanel"
  );

  // Log the retrieved item to the console
  console.log(isRegisteredWithMixPanel, "isRegisteredWithMixPanel");

  useEffect(() => {
    userDetails && setAuthenticatedUser(true);

    localStorage.setItem("userDetails", JSON.stringify(userDetails));

    console.log(userDetails, "hhheeellllooooo");
  }, [userDetails]);

  console.log(authenticatedUser, "authenticatedUserauthenticatedUser");

  useEffect(() => {
    console.log("header top working");
    API.BannerText()
      .then((res) => {
        console.log("banner text", res);
        setBannerText(res.data[0].BANNERLINE);
      })
      .catch(() => {});
    //eslint-disable-next-line
  }, []);

  const GetCartDetails = () => {
    setLoading(true);
    API.GetCart(userDetails.ID)
      .then((res) => {
        console.log("get cart", res);
        dispatch(addToCart(res.data));
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  //
  const clickOnMyOrders = () => {
    mixpanel.track("ClickOnMyOrder"); // MixPannel code
  };
  const clickOnMyAccount = () => {
    mixpanel.track("ClickOnMyAccount"); // MixPannel code
  };
  //

  useEffect(() => {
    localStorage.getItem("amyra_email") && setAuthenticatedUser(true);
    // eslint-disable-next-line
  }, [localStorage]);

  function userLogout(e) {
    e.preventDefault();
    // setLoading(true);
    localStorage.removeItem("amyra_email");
    dispatch(setUser(null));
    setAuthenticatedUser(false);
    addToast("User logged out successfully", {
      appearance: "success",
      autoDismiss: true,
    });

    mixpanel.track("Logout", { Logout: JSON.stringify(userDetails.emailid) }); // MixPannel ka code

    history.push("/");
    window.location.reload(true);

    // setLoading(false);
  }

  const NewBannerText = {
    English: "SEE YOU AT IIFJAS FROM SEP21ST-24TH, STALL NO.T-16/17",
    Hindi: "21-24 सितंबर तक आईआईएफजेएएस में मिलते हैं, स्टॉल नंबर टी-16/17",
    Telugu: "సెప్టెంబరు 21-24, స్టాల్ నెం. T-16/17 నుండి IIFJASలో కలుద్దాం",
    Tamil: "செப்டம்பர் 21 முதல் 24 வரை IIFJAS இல் சந்திப்போம், ஸ்டால் எண்.T-16/17",
    French: "RENDEZ-VOUS À L'IIFJAS DU 21 AU 24 SEPTEMBRE, STALL NO.T-16/17",
    Spanish: "NOS VEMOS EN IIFJAS DEL 21 AL 24 DE SEPTIEMBRE, STAND NO.T-16/17",
  };

  const BannerTextt = NewBannerText[mylanguage];

  return (
    <>
      {loading ? <Loader /> : null}
      <div
        className={`header-top-wap ${
          borderStyle === "fluid-border" ? "border-bottom" : ""
        }`}
      >
        <div
          className="header-offer"
          style={{ borderRight: "1px solid lightgray" }}
        >
          {/* <marquee id="slider-text">{bannerText}</marquee> - - Backend Marquee   */}
          <marquee id="slider-text">{bannerText}</marquee>
        </div>
        <div className="group-icons custom_group-icons">
          {authenticatedUser ? <IconGroup /> : null}{" "}
        </div>
        {authenticatedUser ? (
          <div
            className="customusername-section"
            style={{
              display: "flex",
              borderLeft: "1px solid lightgray",
              paddingLeft: "1.5vw",
            }}
          >
            <div className="my-auto customusername">
              Hi,{userDetails?.FNAME}
            </div>
            <div
              className=" avatar dropdown user-profile"
              style={{ textAlign: "center !important" }}
            >
              <Link
                className="nav-link dropdown-toggle p-0"
                onClick={() => setToggle(!toggle)}
              >
                <img
                  style={{
                    // marginLeft: 10,
                    width: "60%",
                    height: 40,
                    marginLeft: 10,
                  }}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = amyraaLogo;
                  }}
                  src={userDetails?.USERIMG}
                  className="rounded-circle z-depth-0 img-responsive"
                  alt="avatar"
                />
              </Link>
              <div
                className={
                  toggle
                    ? "dropdown-menu dropdown-menu-lg-right dropdown show"
                    : "dropdown-menu dropdown-menu-lg-right dropdown"
                }
                // aria-labelledby="navbarDropdownMenuLink-saju"
              >
                <Link
                  className="dropdown-item"
                  to="/account"
                  onClick={clickOnMyAccount}
                >
                  My Account
                </Link>
                <Link
                  className="dropdown-item"
                  to="/MyOrders"
                  onClick={clickOnMyOrders}
                >
                  My Orders
                </Link>
                {/* <Link className="dropdown-item" to="/changepassword">
                  Change Password
                </Link> */}
                <Link className="dropdown-item" onClick={(e) => userLogout(e)}>
                  Logout
                </Link>
              </div>
            </div>

            {/* <CurrencyModal/> */}
            {/* <LanguageModel/> */}
          </div>
        ) : (
          <div className="row" style={{ marginRight: -61 }}>
            <div className="login-btn ">
              <Link to="/login">Login / Register</Link>
            </div>
            {/* <div className="Register-btn">
              <Link to="/register">Register</Link>
            </div> */}
          </div>
        )}
        {/* <UserDropdown/> */}
        <div></div>
      </div>
    </>
  );
};
export default HeaderTop;
