import React, { Fragment, useState, useEffect } from "react";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { Link } from "react-router-dom";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CloseIcon from "@mui/icons-material/Close";
// import "react-multi-carousel/lib/styles.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { setProduct } from "../../redux/actions/productAction";
import { useSelector, useDispatch } from "react-redux";
import ApiLib from "../../utils/api";
import addToCart from "../../redux/actions/cartActions";
import { useToasts } from "react-toast-notifications";
import MyCollectionModal from "./MyCollectionModal";
import Notify from "../../utils/Notify";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Loader from "../../helpers/Loader";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import demoImage from "../../assets/img/amyra-faded-logo2.png";
import axios from "axios";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import whatsapp_btn from "../../assets/img/whatsapp_btn.png";
import SharePriceModal from "../productListing/SharePriceModal";
import mixpanel from "mixpanel-browser";
import { Mixpanel } from "../../utils/mixpannel";


import "./pdp.css";
import getWishlist from "../../redux/actions/wishlistActions";
// import CreateCollection from "./CreateCollection";

const responsive = {
  0: { items: 2 },
  568: { items: 5 },
  1024: { items: 5 },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
  pb: 0,
};
const ProductDetailDescription = ({ productInfo, variants, changeProduct }) => {
  const [shareCollection, setShareCollection] = useState(false);
  const [addCollection, setAddCollection] = useState(false);
  const [openCollectionModal, setOpenCollectionModal] = useState(false);
  const [createCollection, setCreateCollection] = useState(false);
  const [wishlist, setWishlist] = useState(false);
  const handleOpenshareCollection = () => setShareCollection(true);
  const handleCloseshareCollection = () => setShareCollection(false);
  const handleOpenaddCollection = () => setAddCollection(true);
  const handleCloseaddCollection = () => setAddCollection(false);
  const handleOpencreateCollection = () => {
    setAddCollection(false);
    setCreateCollection(true);
  };
  const handleClosecreateCollection = () => setCreateCollection(false);
  const [loading, setLoading] = useState(false);
  const API = new ApiLib();
  console.log("API", typeof API);
  const userDetails = useSelector((state) => state.userDetails);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const dispatch = useDispatch();
  const [quantityCount, setQuantityCount] = useState(1);
  const { addToast } = useToasts();


  const [modalOpen,setmodalOpen] = useState(false);
  const [shareData,setShareData] = useState(null);
// 

const [showUserModeModal, setShowUserModeModal] = useState(false);
const [productquantityAvilable,setProductquantityAvilable] = useState(1);
const [ProductQUALITY,setProductQUALITY] = useState(cartItems[0]?.QUANTITY);


const handleOpenUsermodeModal = () => {
  if(userDetails.user != null){
  setShareData(productInfo)
  setShowUserModeModal(true)
  }else{
    addToast("Kindly login to share a product", {
      appearance: "warning",
      autoDismiss: true,
    });
  }
};

  useEffect(() => {
    setProductquantityAvilable(productInfo?.BALQTY)
    if (userDetails?.user?.ID && productInfo) {
      FetchWishlist();
    }
    // eslint-disable-next-line
  }, [productInfo]);

  console.log(productquantityAvilable,"productquantityAvilable");

  const FetchWishlist = () => {
    setLoading(false);
    API.GetUserWishlist(userDetails.user.ID)
      .then((res) => {
        console.log("wishlist==>", res.data, productInfo);
        let wishlistArr = res.data;
        let isPresent = wishlistArr.find(
          (item) => item?.DCODE == productInfo?.DCODE
        );
        console.log("ispresent", isPresent);
        setWishlist(isPresent);
        setLoading(false);
        dispatch(getWishlist(res.data));
      })
      .catch((err) => {
        console.log("wishlist err", err);
        setLoading(false);
      });
  };
  const DeleteFromWishlist = (DCODE) => {
    setLoading(true);
    API.DeleteWishlist(userDetails.user.ID, DCODE)
      .then((res) => {
        setLoading(false);
        console.log(res);
        addToast("Item removed from wishlist", {
          appearance: "success",
          autoDismiss: true,
        });
        FetchWishlist();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        addToast("Something went wrong", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const [shareLink, setShareLink] = useState();
  useEffect(() => {
    if (productInfo?.BASEDESIGN) {
      CreateShareLink();
    }
    // eslint-disable-next-line
  }, [productInfo?.BASEDESIGN]);

  const CreateShareLink = () => {
    let userId =
      userDetails?.user !== null
        ? Buffer.from(`${userDetails?.user?.ID}` || "0").toString("base64")
        : Buffer.from("0").toString("base64");
    console.log(userId, "Checkits Value");
    let link = `https://catalogcollection.web.app/catalouge/product/${userId}/${productInfo?.BASEDESIGN}/${productInfo?.DCODE}`;
    setShareLink(link);
  };

  const ShareModal = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleWaClick = () => {
      let userPhone = userDetails.user.MOBNO;

      if (userPhone === "") return;

      let link = `https://wa.me/${userPhone}`;
      window.open(link, "_blank");
    };

    return (
      <Modal
        open={shareCollection}
        onClose={handleCloseshareCollection}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style} className="share-on-whatsApp">
          <div className="row">
            <div className="col-8 text-left">
              <div className="row align-items-center mb-10">
                <h4
                  className="mr-3 font-weight-bold share-button sidebar-title custom_share_link_button"
                  onClick={handleWaClick}
                  style={{ marginBottom: 0 }}>
                  Share on Whatsapp
                </h4>
                <WhatsAppIcon
                  className="custom_icon_size icon-color pointer"
                  onClick={handleWaClick}
                />
              </div>
            </div>
            <hr />

            <div className=" col-4 text-right mt-n2 ">
              <CloseIcon
                className=" close-button"
                onClick={handleCloseshareCollection}
              />
            </div>
          </div>
          {/* <div className="row text-center mt-4 mb-4">
            <div className="col-3">
              <InstagramIcon className="icon-color" />
            </div>
            <div className="col-3">
              <EmailIcon className="icon-color" />
            </div>
            <div className="col-3">
              <FacebookIcon className="icon-color" />
            </div>
            <div className="col-3">
              <WhatsAppIcon
                className="custom_icon_size icon-color pointer"
                onClick={handleWaClick}
              />
            </div>
          </div> */}
          <div className="row">
            <div className="col-12 mb-2 title-text-bold">Product Link</div>
            <div className="col-10 pr-0 mb-3">
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="https://zjwel.com/ddf/dfdf/swd....."
                value={shareLink}
                disabled
              />
            </div>
            <div className="col-2  mt-2">
              <ContentCopyOutlinedIcon
                className="icon-color pointer"
                onClick={(event) => {
                  navigator.clipboard.writeText(shareLink);
                  handleClick(event);
                }}
              />
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                closeAfterTransition>
                <Typography sx={{ p: 2 }}>
                  {/* {props.type == 1
                    ? "Collection link copied"
                    : "Catalog link copied"} */}
                  Product link copied
                </Typography>
              </Popover>
            </div>
          </div>
        </Box>
      </Modal>
    );
  };

  const CreateModal = (props) => {
    const [collectionName, setCollectionName] = useState();
    const CreateCollection = () => {
      if (collectionName) {
        setLoading(true);
        const data = {
          USERID: userDetails.user.ID,
          COLL_NAME: collectionName,
        };
        API.SaveCollection(data)
          .then((res) => {
            console.log(res);
            addToast("New collection created successfully", {
              appearance: "success",
              autoDismiss: true,
            });
            props.onClose();
            setOpenCollectionModal(true);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            addToast("Something went wrong please try again", {
              appearance: "error",
              autoDismiss: true,
            });
            setLoading(false);
          });
      }
    };
    return (
      <Modal
        open={createCollection}
        onClose={handleClosecreateCollection}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div className="row">
            <div className="col-10 text-left">
              <h3 className="mr-3 title-text-bold sidebar-title">
                Create Collection{" "}
              </h3>
            </div>
            <div className=" col-2 text-right mt-n2 ">
              <CloseIcon
                onClick={handleClosecreateCollection}
                className="close-button"
              />
            </div>{" "}
          </div>

          <div className="row">
            <div className="col-12 px-4 py-4 mb-3">
              <input
                type="text"
                onChange={(e) => setCollectionName(e.target.value)}
                // Bug Solve By Syed
          onKeyDown={(e) => {
        if (e.key === "Enter") {
          CreateCollection();
        }
      }}
      //
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Collection Name"
                autoFocus
              />
            </div>
          </div>
          <div
            className="row bg-black  text-center save-btn"
            style={{ marginLeft: "-33px", marginRight: "-33px" }}>
            <div
              className="col-12 px-3 py-3 share-button text-white btn-background"
              onClick={() => {
                CreateCollection();
              }}>
              Save
            </div>
          </div>
        </Box>
      </Modal>
    );
  };

  const [checked, setChecked] = React.useState([1]);
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const [thumbIndex, setThumbIndex] = useState(0);
  const [thumbAnimation, setThumbAnimation] = useState(false);
  const [thumbs, setThumbs] = useState();
  const [morePrd, setMorePrds] = useState([]);

  const setNewProduct = (product) => {
    dispatch(setProduct(product));
  };
  useEffect(() => {
    if (!variants.length) return;
    console.log("variants==>", variants);
    let samePrd = variants.map((item, index) => {
      return (
        // <Link
        //   to={
        //     process.env.PUBLIC_URL +
        //     "/product/" +
        //     item.BASEDESIGN +
        //     "/" +
        //     item.DCODE
        //   }
        // >
        <Tooltip
          key={item.index}
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          title={
            <>
              <h4>{item.DCODE}</h4>
              {userDetails?.user !== null ? (
                userDetails?.user?.RATEMODE === "3" ? (
                  <h5>₹ {item.SRATE}</h5>
                ) : userDetails?.user?.RATEMODE === "2" ? (
                  <h5>₹ {item.SRATE1}</h5>
                ) : (
                  <h5>₹ {item.SRATE1}</h5>
                )
              ) : null}
            </>
          }>
          <img
            // src={CheckImage(item.DIMAGE) ? item.DIMAGE : demoImage}
            src={item.DIMAGE}
            alt="..."
            height="100"
            width="100"
            // className="px-1 border-round"
            onClick={(e) => changeProduct(e, index)}
            className={
              item.DCODE == productInfo.DCODE
                ? "more-prd-img border-round"
                : "px-1 border-round"
            }
          />
        </Tooltip>
        // </Link>
      );
    });
    const thumbItems = (samePrd, [setThumbIndex, setThumbAnimation]) => {
      return samePrd.map((item, i) => (
        <div onClick={() => (setThumbIndex(i), setThumbAnimation(true))}>
          {item}
        </div>
      ));
    };
    setThumbs(thumbItems(samePrd, [setThumbIndex, setThumbAnimation]));
    setMorePrds(samePrd);
    // eslint-disable-next-line
  }, [variants, productInfo]);

  const slideNext = () => {
    if (thumbIndex < thumbs.length - 5) {
      setThumbAnimation(true);
      setThumbIndex(thumbIndex + 1);
    }
  };

  const slidePrev = () => {
    if (thumbIndex > 0) {
      setThumbAnimation(true);
      if (thumbIndex == thumbs.length - 1) {
        setThumbIndex(thumbIndex - 5);
      } else {
        setThumbIndex(thumbIndex - 1);
      }
    }
  };

  const GetCartDetails = () => {
    setLoading(true);
    API.GetCart(userDetails.user.ID)
      .then((res) => {
        console.log("getcartgetcart", res);
        setProductQUALITY(res.data[0].QUANTITY)
        dispatch(addToCart(res.data));
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const UpdateCart = (item) => {
    console.log(ProductQUALITY,"productquantityAvilable")
    console.log(productquantityAvilable,"productquantityAvilable")
    setLoading(true);
    if (ProductQUALITY >= productquantityAvilable) {
      addToast("Maximum quantity reached", {
        appearance: "error",
        autoDismiss: true,
      });
      setLoading(false);
      return;
    }
    let data = {
      ser_no: item.SER_NO,
      userid: userDetails.user.ID,
      dcode: item.DCODE,
      quantity: Number(item.QUANTITY) + quantityCount,
      rate: item.RATE,
    };
    API.UpdateCart(data)
      .then((res) => {
        console.log(res);
        if (res.data[0].result == 1) {
          addToast("Cart updated successfully ", {
            appearance: "success",
            autoDismiss: true,
          });
          GetCartDetails();
        } else {
          addToast("Something went wrong please try again", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        addToast("Something went wrong please try again", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const AddToCart = (product) => {
    console.log("product", product);
    if (userDetails.user) {
      setLoading(true);
      // if item already present in cart then update cart will work
      let isPresent = cartItems.find((item) => {
        return item.DCODE == productInfo.DCODE;
      });
      console.log("ispresent", isPresent);
      if (isPresent) {
        UpdateCart(isPresent);
      } else {
        let productDetails = {
          userid: userDetails.user.ID,
          dcode: product.DCODE,
          quantity: quantityCount,
          rate: product.SRATE1,
        };
        API.AddToCart(productDetails)
          .then((res) => {
            console.log("added to cart");
            if (res.data[0].result == 1) {
              addToast("Item added to cart successfully ", {
                appearance: "success",
                autoDismiss: true,
              });
              //
              mixpanel.track("AddToCart_PDP", { DesignId: JSON.stringify(product.DCODE) });  // MixPannel ka code
              //
              GetCartDetails();
            } else if (res.data[0].result == 2) {
              addToast("Item already present in cart", {
                appearance: "error",
                autoDismiss: true,
              });
            } else {
              addToast("Something went wrong please try again", {
                appearance: "error",
                autoDismiss: true,
              });
            }
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            addToast("Something went wrong please try again", {
              appearance: "error",
              autoDismiss: true,
            });
          });
      }
    } else {
      addToast("Kindly login to add this product to Cart", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  console.log(thumbIndex);

  const OpenCollection = () => {
    if (userDetails.user != null) {
      setOpenCollectionModal(true);
    } else {
      addToast("Kindly login to view/add collection", {
        appearance: "warning",
        autoDismiss: true,
      });
      // Notify({
      //   alert: true,
      //   type: "warning",
      //   title: "Kindly login to view/add collection",
      // });
    }
  };

  const AddToWishlist = (product) => {
    if (!userDetails?.user?.ID) {
      addToast("Kindly login to add item to wishlist ", {
        appearance: "warning",
        autoDismiss: true,
      });
      return;
    }
    setLoading(true);
    API.AddToWishlist(userDetails.user.ID, product.DCODE)
      .then((res) => {
        setLoading(false);
        console.log(res);
        //
        mixpanel.track("AddToWishList", { DesignId: JSON.stringify(product.DCODE) });  // MixPannel ka code
        //
        addToast("Item added to wishlist successfully ", {
          appearance: "success",
          autoDismiss: true,
        });
        FetchWishlist();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        addToast("Something went wrong", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };
  function CheckImage(path) {
    axios
      .get(path)
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }
  return (
    <>
      {/* <a href=" https://wa.me/9320838762">
        <img src={whatsapp_btn} className="whatsapp-img" alt="" />
      </a> */}
      {loading ? <Loader /> : null}

      {/* <ShareModal /> */}
      <SharePriceModal
    onClose={() => setShowUserModeModal(false)}
    show={showUserModeModal}
    data={shareData} 
      />

      <MyCollectionModal
        open={openCollectionModal}
        close={() => setOpenCollectionModal(false)}
        product={productInfo}
        openAddCollection={() => {
          setCreateCollection(true);
          setOpenCollectionModal(false);
        }}
        closeaAddCollection={() => setCreateCollection(false)}
      />
      <CreateModal
        onClose={() => setCreateCollection(false)}
        open={createCollection}
        openCollection={() => {
          setCreateCollection(false);
          setOpenCollectionModal(true);
        }}
      />
      <div className="product-details-content ml-70">
        <div className="row ml-1"></div>
        <div>
          <div className="display-flex">
            <h2 className="title-text-bold title-text">{productInfo?.INAME}</h2>

            <div className="ml-3">
              <ShareOutlinedIcon
                className="share-button icon-color"
                // onClick={() => handleOpenshareCollection()}
                onClick={() => handleOpenUsermodeModal()}
              />
            </div>
          </div>
          <hr />

          <h5 className="text-muted my-3">
            Design Id-
            <span className="text-dark title-text-bold">
              {productInfo?.DCODE}
            </span>
          </h5>
        </div>
        {productInfo?.PRD_DESC ? (
          <div className="pro-details-list">
            <p className="text-muted">
              {productInfo?.PRD_DESC.replace(/<br>/g, " ")}
            </p>
          </div>
        ) : (
          <div className="pro-details-list">
            <p className="text-muted"> </p>
          </div>
        )}
        <div className="pb-3 mb-3">
          {/* <h5 className="text-muted my-3">Colors</h5> */}
          {/* <AliceCarousel
              // activeIndex={thumbIndex}
              width="60%"
              disableDotsControls
              disableButtonsControls
              items={items}
              // mouseTracking={false}
              // onSlideChanged={syncThumbs}
              // touchTracking={!mainAnimation}

              disableDotsControls
              disableButtonsControls
            /> */}
          <div className="row">
            <div className="col-12">
              <div className="row">
                {morePrd.length > 5 ? (
                  <div
                    className="col-1 my-auto product-details-carousal-arrow-left"
                    onClick={() => slidePrev()}
                    // style={{ marginRight: "-5%" }}
                  >
                    {" "}
                    <KeyboardArrowLeftIcon
                      style={{ fontSize: 40, marginLeft: 10 }}
                    />
                  </div>
                ) : null}
                <div className="col-10 product-details-carousel">
                  <AliceCarousel
                    activeIndex={thumbIndex}
                    animationType="fadeout"
                    animationDuration={400}
                    mouseTracking
                    items={morePrd}
                    responsive={responsive}
                    disableDotsControls
                    disableButtonsControls
                    autoWidth
                    // touchTracking={!thumbAnimation}
                  />
                </div>
                {morePrd.length > 5 ? (
                  <div className="col-1 my-auto product-details-carousal-arrow-right" style={{ marginLeft: "-5%" }}>
                    {" "}
                    <ChevronRightIcon
                      style={{ fontSize: 40 }}
                      onClick={() => slideNext()}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {/* <div className="btn-prev" onClick={slidePrev}>
              &lang;
            </div>
            <div className="btn-next" onClick={slideNext}>
              &rang;
            </div> */}

          {/* <div className="row">
            <div className="my-auto">
              {" "}
              <ArrowLeftIcon />
            </div>
            <AliceCarousel
              mouseTracking
              items={items}
              responsive={responsive}
            />

            {variants.map((item) => {
              return (
                <img
                  src={item.DIMAGE}
                  onClick={() => selectedProduct()}
                  alt="..."
                  height="100"
                  width="100"
                  className={
                    productInfo.DCODE == item.DCODE
                      ? "p-2 ml-2 single-image"
                      : "p-2 ml-2 "
                  }
                />
              );
            })}

            <div className="my-auto">
              {" "}
              <ArrowRightIcon />
            </div>
          </div> */}
        </div>

        <div>
          <h5 className="text-muted my-3">
            Weight-
            <span className="text-dark title-text-bold">
              {productInfo?.WEIGHT} gms
            </span>
          </h5>
        </div>
        <div>
          <h5 className="text-muted my-3">
            Available Quantity -
            <span className="text-dark title-text-bold">
              {" "}
              {productInfo?.BALQTY}
            </span>
          </h5>
        </div>

        <div>
          <h5 className="text-muted my-3">
            Dimensions-
            <span className="text-dark title-text-bold">
              {" "}
              {productInfo?.DIMEN}
            </span>
          </h5>
        </div>
        {productInfo?.SZ == null || productInfo?.SZ == "" ? null : (
          <div>
            <h5 className="text-muted my-3">
              Size-
              <span className="text-dark title-text-bold">
                {" "}
                {productInfo?.SZ}
              </span>
            </h5>
          </div>
        )}

        <div className="product-details-price pt-4">
          <span className="title-text title-text-bold">
            {userDetails && userDetails?.user?.RATEMODE !== "1" ? (
              <span className="title-text title-text-bold">
                {userDetails?.user !== null ? "₹" : null}
              </span>
            ) : null}{" "}
            {userDetails.user !== null
              ? userDetails?.user?.RATEMODE === "3"
                ? productInfo?.SRATE
                : userDetails?.user?.RATEMODE === "2"
                ? productInfo?.SRATE1
                : productInfo?.SRATE1
              : null}
          </span>{" "}
          {/* <span className="old title-text-bold">₹ 9500</span> */}
        </div>

        {/* {product.variation ? (
          <div className="pro-details-size-color">
            <div className="pro-details-size">
              <span className="text-muted">Select Size</span>
              <div className="pro-details-size-content">
                {product.variation &&
                  product.variation.map((single) => {
                    return single.color === selectedProductColor
                      ? [2.5, 3.5, 4, 8].map((singleSize, key) => {
                          return (
                            <label
                              className={`pro-details-size-content--single border-round`}
                              key={key}
                            >
                              <input
                                type="radio"
                                value={singleSize}
                                checked={singleSize === 3.5 ? "checked" : ""}
                                onChange={() => {
                                  setSelectedProductSize(singleSize);
                                  setProductStock(singleSize);
                                  setQuantityCount(1);
                                }}
                              />
                              <span className="size-name">{singleSize}</span>
                            </label>
                          );
                        })
                      : "";
                  })}
              </div>
            </div>
          </div>
        ) : (
          ""
        )} */}
        <div className="pro-details-quality">
          <div className="cart-plus-minus">
            <button
              onClick={() =>
                setQuantityCount(quantityCount > 1 ? quantityCount - 1 : 1)
              }
              className="dec qtybutton">
              -
            </button>
            <input
              className="cart-plus-minus-box"
              type="text"
              value={quantityCount}
              readOnly
            />
            <button
              onClick={() =>
                setQuantityCount(
                  quantityCount < Number(productInfo.BALQTY)
                    ? quantityCount + 1
                    : quantityCount
                )
              }
              className="inc qtybutton">
              +
            </button>
          </div>
          <div className="pro-details-cart">
            <button
              onClick={() => {
                AddToCart(productInfo)}
                }
              className="btn border border-dark addcart-btn title-text-bold"

              // disabled={productCartQty >= productStock}
            >
              {" "}
              Add To Cart{" "}
            </button>
          </div>
          <div className="pro-details-cart">
            <button
              // onClick={() =>
              //   addToCart(
              //     product,
              //     addToast,
              //     quantityCount,
              //     selectedProductColor,
              //     selectedProductSize
              //   )
              // }
              style={{ background: "transparent" }}
              className="btn  border-green title-text title-text-bold"
              onClick={() => OpenCollection()}

              // disabled={productCartQty >= productStock}
            >
              {" "}
              Add To Collection{" "}
            </button>
          </div>
          <div className="pro-details-wishlist">
            <button
              className={wishlist ? "active" : ""}
              // disabled={wishlist!== undefined}
              title={
                wishlist !== undefined ? "Added to wishlist" : "Add to wishlist"
              }
              onClick={() =>
                wishlist
                  ? DeleteFromWishlist(productInfo.DCODE)
                  : AddToWishlist(productInfo)
              }>
              {wishlist ? (
                <FavoriteIcon className="icon-color" />
              ) : (
                <FavoriteBorderOutlinedIcon className="icon-color" />
              )}
            </button>
          </div>
        </div>
        {/* <div className="row">
            <div className="pro-details-cart btn-hover ml-2">
              {productStock && productStock > 0 ? (
                <button
                  onClick={() =>
                    addToCart(
                      product,
                      addToast,
                      quantityCount,
                      selectedProductColor,
                      selectedProductSize
                    )
                  }
                  className="btn border border-dark"

                  disabled={productCartQty >= productStock}
                >
                  {" "}
                  Add To Wishlist{" "}
                </button>
              ) : (
                <button disabled>Out of Stock</button>
              )}
            </div>
            <div className="pro-details-cart btn-hover ml-4">
              {productStock && productStock > 0 ? (
                <button
                  onClick={() =>
                    addToCart(
                      product,
                      addToast,
                      quantityCount,
                      selectedProductColor,
                      selectedProductSize
                    )
                  }
                  disabled={productCartQty >= productStock}
                  className="btn border border-dark"
                >
                  {" "}
                  Add To Collection{" "}
                </button>
              ) : (
                <button disabled>Out of Stock</button>
              )}
            </div>
          </div> */}
      </div>
    </>
  );
};

export default ProductDetailDescription;
