import React, { Fragment, useState, useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./plp.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import MyCollectionModal from "./MyCollectionModal";
import CreateCollection from "./CreateCollection";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import ApiLib from "../../utils/api";
import addToCart from "../../redux/actions/cartActions";
import Loader from "../../helpers/Loader";

// import { Lazy } from "swiper/dist/js/swiper.esm";
const responsive = {
  0: { items: 2 },
  568: { items: 3 },
  1024: { items: 3 },
};

function BottomBar({
  newSelectedProducts,
  selectedProducts,
  setSelectAll,
  setSelectedProducts,
  setSelectCheckbox,
}) {
  console.log("bottombar products-->", selectedProducts);
  const userDetail = useSelector((state) => state.userDetails);
  const [products, setProducts] = useState([]);
  const [newProducts, setNewProducts] = useState([]);
  const [openCollectionModal, setOpenCollectionModal] = useState(false);
  const [createCollection, setCreateCollection] = useState(false);
  const userDetails = useSelector((state) => state.userDetails);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const history = useHistory();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const API = new ApiLib();
  const dispatch = useDispatch();
  const {selectAll}=useSelector(state=>state)

  

  const removeProducts = function (prds, key, value) {
    console.log("remove atr workig");
    let i = prds.length;
    while (i--) {
      if (
        prds[i] &&
        prds[i].hasOwnProperty(key) &&
        arguments.length > 2 &&
        prds[i][key] === value
      ) {
        prds.splice(i, 1);
        console.log("if working");
      }
    }
    console.log("remove atr arr", prds);
    return prds;
  };

  const deleteProduct = (id) => {
    console.log("prdId=>", id);
    const remainingProducts = removeProducts(selectedProducts, "DCODE", id);
    console.log("remaining", remainingProducts);
    // setSelectedProducts(remainingProducts);
    newSelectedProducts(remainingProducts);
    setNewProducts(remainingProducts);
    console.log("productsAfter deletion=>", selectedProducts);
  };

 useEffect(() => {
  setProducts(selectAll.selectedProducts)
 }, [selectAll])
 
  

  /// Product slider

  const [thumbIndex, setThumbIndex] = useState(0);
  const [thumbAnimation, setThumbAnimation] = useState(false);
  const [thumbs, setThumbs] = useState();
  const [morePrd, setMorePrds] = useState([]);
  console.log("thumb", thumbs, thumbIndex);
  
  useEffect(() => {
    if (products.length) {
      let samePrd = 1
        ? products.map((product) => {
            return (
              <div
                className={
                  products.length < 3 ? " col-5 text-center " : "  text-center "
                }>
                <div
                  className={
                    products.length < 3
                      ? "custom_addcollectionclear bg-white ml-2 inc-width "
                      : "custom_addcollectionclear bg-white ml-2 "
                  }>
                  <img
                    src={product.DIMAGE}
                    width="40px"
                    height="40px"
                    className="my-auto py-1 px-1"
                  />
                  <p className="my-auto mx-1 text-center">{product.INAME}</p>
                  <div className=" my-auto mx-1 custom_collection_clear text-center">
                    <ClearIcon
                      style={{ fontSize: 15, color: "#6c6c6c" }}
                      onClick={() => deleteProduct(product.DCODE)}
                    />
                  </div>
                </div>
              </div>
            );
          })
        : null;

      const thumbItems = (samePrd, [setThumbIndex, setThumbAnimation]) => {
        return samePrd.map((item, i) => (
          <div onClick={() => (setThumbIndex(i), setThumbAnimation(true))}>
            {item}
          </div>
        ));
      };
      setThumbs(thumbItems(samePrd, [setThumbIndex, setThumbAnimation]));
      setMorePrds(samePrd);
    }
  }, [products]);


  const slideNext = () => {
    if (thumbIndex < thumbs.length - 3) {
      setThumbAnimation(true);
      setThumbIndex(thumbIndex + 1);
    } else {
      console.log("fetch more prds workimg");
      // fetchMorePrds();
    }
  };

  const slidePrev = () => {
    if (thumbIndex > 0) {
      setThumbAnimation(true);
      if (thumbIndex == thumbs.length - 1) {
        setThumbIndex(thumbIndex - 5);
      } else {
        setThumbIndex(thumbIndex - 1);
      }
    }
  };

  const OpenCollection = () => {
    console.log("open collection", userDetail);
    if (userDetail.user != null) {
      setOpenCollectionModal(true);
      // setSelectAll(false);
      // setSelectedProducts([]);
      // setSelectCheckbox(false);
    } else {
      addToast("Kindly login to view/add collection", {
        appearance: "warning",
        autoDismiss: true,
      });
    }
  };

  const AddItemsToCart = () => {
    if (!userDetail?.user?.ID) {
      addToast("Kindly login to add item to cart ", {
        appearance: "warning",
        autoDismiss: true,
      });
      return;
    }
    if (!products.length) return;
    setLoading(true);
    let items = [];
    products.map((item) => {
      let itemInfo = {
        ser_no: 0,
        userid: userDetails.user.ID,
        dcode: item.DCODE,
        quantity: 1,
        rate: item.SRATE1,
      };
      items.push(itemInfo);
    });
    API.AddToCart(items)
      .then((res) => {
        setLoading(false);
        if (res.data[0].result == 1) {
          addToast("Items added to cart successfully ", {
            appearance: "success",
            autoDismiss: true,
          });
          GetCartDetails();
        } else if (res.data[0].result == 2) {
          addToast("Items already present in cart", {
            appearance: "error",
            autoDismiss: true,
          });
        } else {
          addToast("Something went wrong please try again", {
            appearance: "error",
            autoDismiss: true,
          });
        }
        setSelectAll(false);
        setSelectedProducts([]);
        setSelectCheckbox(false);
      })
      .catch((err) => {
        setLoading(false);
        addToast("Something went wrong please try again", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const GetCartDetails = () => {
    setLoading(true);
    API.GetCart(userDetails.user.ID)
      .then((res) => {
        console.log("get cart", res);
        dispatch(addToCart(res.data));
        setLoading(false);
        newSelectedProducts([]);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <MyCollectionModal
        open={openCollectionModal}
        close={() => setOpenCollectionModal(false)}
        product={products}
        openAddCollection={() => {
          setCreateCollection(true);
          setOpenCollectionModal(false);
        }}
        closeaAddCollection={() => setCreateCollection(false)}
        clearAll={() => newSelectedProducts([])}
        setSelectAll={setSelectAll}
        setSelectedProducts={setSelectedProducts}
        setSelectCheckbox={setSelectCheckbox}
      />
      <CreateCollection
        onClose={() => setCreateCollection(false)}
        open={createCollection}
        openCollection={() => {
          setCreateCollection(false);
          setOpenCollectionModal(true);
        }}
      />
      <div className="row bottom-bar">
        <div className="col-7 my-auto">
          <div className="row ml-1">
            <div className="col-1 my-auto text-right">
              <ArrowBackIosIcon
                style={{ color: "white" }}
                onClick={() => slidePrev()}
              />
            </div>
            <div className="col-10">
              <AliceCarousel
                activeIndex={thumbIndex}
                animationType="fadeout"
                animationDuration={400}
                mouseTracking
                items={morePrd}
                responsive={responsive}
                disableDotsControls
                disableButtonsControls
                // autoWidth
                // touchTracking={!thumbAnimation}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-12">
          <div className="row">
          {/*  */}
            <div className="col-1 my-auto text-left">
              <ArrowForwardIosIcon
                style={{ color: "white" }}
                onClick={() => slideNext()}
              />
            </div>

            {/*  */}
            <div className="col-6 text-right">
              <button
                className="btn px-2 my-3 bottom-btn text-white btn-background"
                onClick={() => OpenCollection()}>
                Add To Collection
              </button>
            </div>
            <div className="col-lg-5 col-6">
              <button
                className="btn px-2 my-3 text-white bottom-btn btn-background"
                onClick={() => AddItemsToCart()}>
                Add To Cart
              </button>{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BottomBar;
