import PropTypes from "prop-types";
import React from "react";

const BrandLogoOneSingle = ({ data, sliderClassName, spaceBottomClass }) => {
  return (
    <div
      className={`single-brand-logo ${sliderClassName ? sliderClassName : ""} ${
        spaceBottomClass ? spaceBottomClass : ""
      }`}
      style={{ display: "flex", justifyContent: "center" }}>
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "30%",
        }}>
        <img
          src={data.image}
          alt=""
          style={{ width: data?.width ? "60%" : "100%" }}
        />
        {/* {data.image2 && (
          <img src={data.image2} alt="" style={{ width: "100%" }} />
        )} */}
      </a>
    </div>
  );
};

BrandLogoOneSingle.propTypes = {
  data: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
};

export default BrandLogoOneSingle;
