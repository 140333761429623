import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import HomeProductsTitle from "../../components/section-title/HomeProductsTitle";
import HomeProductsGrid from "./HomeProductsGrid";
import ApiLib from "../../utils/api";

const HomeProducts = ({
  spaceTopClass,
  spaceBottomClass,
  containerClass,
  extraClass,
  title,
  title2,
  products,
  fetchMorePrds,
  wishlistPrds,
  deletewishlist,
  addwishlist,
}) => {
  return (
    <div className="container">
      <div
        className={`product-area  ${spaceTopClass ? spaceTopClass : ""} ${
          spaceBottomClass ? spaceBottomClass : ""
        } ${extraClass ? extraClass : ""}`}
      >
        <div className={`${containerClass ? containerClass : "container"}`}>
          <Tab.Container defaultActiveKey="men">
            <Nav
              variant="pills"
              className="product-tab-list pt-30  pb-55 text-right "
            >
              <div className="row">
                <div className="col-md-4">
                  <HomeProductsTitle
                    titleText={title}
                    titleText2={title2}
                    positionClass="pull-left"
                  />
                </div>
                {/* <div className="col-md-5 offset-lg-7  prod-list-col">
                  <Nav.Item className="pull-right title-category">
                    <Nav.Link eventKey="men">
                      <h4>Rings</h4>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="pull-right title-category">
                    <Nav.Link eventKey="women">
                      <h4>Earings</h4>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item className="pull-right title-category">
                    <Nav.Link eventKey="saleItems">
                      <h4>Bangles</h4>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="pull-right title-category">
                    <Nav.Link eventKey="saleItems">
                      <h4>Bracelets</h4>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item className="pull-right title-category">
                    <Nav.Link eventKey="saleItems" className="py-0">
                      <button className="btn pt-0 pb-0 rounded-pill btn-color text-white">
                        View All
                      </button>
                    </Nav.Link>
                  </Nav.Item>
                </div> */}
              </div>
            </Nav>

            {/* </div> */}

            <Tab.Content>
              <Tab.Pane eventKey="men">
                <div className="row">
                  <HomeProductsGrid
                    category="men"
                    limit={4}
                    spaceBottomClass="mb-25"
                    products={products}
                    fetchMorePrds={fetchMorePrds}
                    wishlistPrds={wishlistPrds}
                    addwishlist={addwishlist}
                    deletewishlist={deletewishlist}
                  />
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
};

HomeProducts.propTypes = {
  category: PropTypes.string,
  containerClass: PropTypes.string,
  extraClass: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
};

export default HomeProducts;
