import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./account.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useToasts } from "react-toast-notifications";
import Apilib from "../../utils/api";
import Loader from "../../helpers/Loader";
import Radio from "@mui/material/Radio";
import NoProfile from "../../assets/img/no-profile.png";
import Avatar from "../../assets/img/avatar.png";
import { Form } from "react-bootstrap";
import { useRef } from "react";
import { userInformation } from "../../redux/actions/userInformationAction";
import amyraaLogo from "../../assets/img/amyraa_logo.png";
import mixpanel from "mixpanel-browser";
import { Mixpanel } from "../../utils/mixpannel";

export default function BusinessDetails() {
  const logoFormRef = useRef();
  const [userDetails, setUserDetails] = useState();
  const [gst, setGst] = useState(false);
  const [gstNo, setGstNo] = useState();
  const [loading, setLoading] = useState(false);
  const [editField, setEditField] = useState(true);
  const [photocheck, setPhotocheck] = useState({
    loaded: false,
    url: "",
    type: "",
    name: "",
    source: "",
  });
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const API = new Apilib();
  const userInfo = useSelector((state) => state);

  console.log(userInfo?.userInformation?.user, "ChMain");
  console.log("photoCHECK", userDetails);

  console.log("gst", gst);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...userDetails,
    },
    validationSchema: Yup.object({
      REFBY: Yup.string().min(0).label("Reffered by"),
      PAN: Yup.string()
        .required("PAN No. is required")
        .min(0)
        .matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN no.")
        .label("PAN no."),
      BNAME: Yup.string()
        .required()
        .min(0)
        .matches(/^[A-Za-z/' ]+$/, "Invalid business name")
        .label("Business Name"),
      WEB: Yup.string()
        .required("Website is required")
        .matches(
          /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/,
          "Invalid website "
        )
        .label("Website"),
    }),
    onSubmit: () => {
      SubmitBussinessDetails();
    },
  });
  console.log("formikUserDetails", formik.values, userDetails);

  useEffect(() => {
    FetchUserDetails();
    //eslint-disable-next-line
  }, []);

  function FetchUserDetails() {
    setLoading(true);

    API.GetUserDetails(localStorage?.amyra_email)
      .then((res) => {
        console.log("user Details", res.data);
        let userInfo = { ...res.data[0] };
        // dispatch(setUserDetails(userDetails));
        setUserDetails(userInfo);
        setGstNo(userInfo.GSTIN);
        setGst(userInfo.GSTIN ? true : false);
        dispatch(userInformation(userInfo));
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }

  function SubmitBussinessDetails() {
    // event.preventDefault();
    if (!formik.isValid) {
      addToast("Enter all required fields", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    if (gst) {
      if (!gstNo) {
        addToast("Enter all required fields", {
          appearance: "error",
          autoDismiss: true,
        });
        return;
      }
    }
    setLoading(true);
    const details = {
      ...formik.values,
      GSTIN: gst ? gstNo : "",
    };
    console.log("formik----->", details);
    API.UpdateUserDetails(2, JSON.stringify(details))
      .then((res) => {
        console.log("resDataFor", res);

        formik.resetForm();
        FetchUserDetails();
        // uploadMedia(res);
        setLoading(false);
        addToast("User details updated successfully", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }

  // console.log(photocheck, "EventHappen1");
  const handleUpdateImage = (e) => {
    console.log(e.target.files, " checkfordtaa");
    if (e.target.files && e.target.files[0]) {
      let aud = e.target.files[0];

      setPhotocheck({
        ...photocheck,
        loaded: true,
        source: e.target.files[0],
        url: URL.createObjectURL(aud),
        name: e.target.files[0].name,
      });
      console.clear();
      console.log("EventHappen1", photocheck);
    }
  };

  const uploadMedia = (e) => {
    console.log("EventHappen12", photocheck);

    e.preventDefault();
    console.log(photocheck.source, "Form Running");
    const formData = new FormData();
    formData.append("", photocheck.source, userDetails.ID + ".jpg");
    console.log(formData, "Check FormData");

    // file = new

    // const formData = {
    //   name: photocheck.name,
    //   type: photocheck.source.type,
    //   uri: photocheck.url,

    // }

    // console.log("Upload Media Running" ,  JSON.stringify(photocheck) ,"Abhishek" ,photocheck.source  )
    setLoading(true);

    API.UploadLogo(formData)
      .then((res) => {
        console.log("UploadCheck", res.data);
        FetchUserDetails();
        setLoading(false);
        window.location.reload(true);
        mixpanel.track("NewLogoAdded");
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };
  console.log("asdas", window.location);

  const deleteMedia = (e) => {
    e.preventDefault();
    setLoading(true);

    API.DeleteLogo(userDetails.ID)
      .then((res) => {
        FetchUserDetails();
        setLoading(false);
        window.location.reload(true);
        mixpanel.track("LogoDeleted");
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const EditBussinessDetails = () => {
    mixpanel.track("Edit_Business_Details");
  }

  return (
    <div>
      {loading ? <Loader /> : null}
      <div className="ml-1 overflow-hidden">
        <div className="title-text-bold sidebar-title h2">
          Business Details
          <button
            className="btn btn-background border-round text-white edit-btn mx-4 btn-sm"
            onClick={() => {
              EditBussinessDetails()
              setEditField(!editField)
              }}
          >
            {editField ? "Edit" : "Cancel"}
          </button>
        </div>
        <hr />
        <div className="mt-2 mr-2 pr-2">
          <form onSubmit={formik.handleSubmit}>
            <div className="row font-weight-bold text-black ">
              <div className="col-12 mb-2">
                <label>Logo Preview: </label>
                <form ref={logoFormRef} className="row">
                  <div style={{ width: "40%" }}>
                    <div>
                      <img
                        src={
                          photocheck.loaded
                            ? photocheck.url
                            : formik.values.USERIMG
                        }
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = amyraaLogo;
                        }}
                        // width="200"
                        // height="115"
                        style={{ width: "100%" }}
                        alt="Business logo"
                      />
                    </div>
                    <div>
                      <input
                        type="file"
                        // id="logoImage"
                        // name="file"
                        onChange={handleUpdateImage}
                        style={{ paddingLeft: "16px" }}
                      />
                    </div>
                  </div>

                  <div
                    className="ml-2 my-auto flex-column save-delete-logo-btn"
                    style={{ gap: 10 }}
                  >
                    <button
                      className="btn btn-background border-round text-white edit-btn mx-4 btn-sm"
                      type="submit"
                      onClick={uploadMedia}
                    >
                      Save logo
                    </button>

                    <button
                      className="btn btn-background border-round text-white edit-btn mx-4 btn-sm"
                      style={{
                        backgroundColor: "#b90e0a",
                        borderColor: "#b90e0a",
                      }}
                      type="submit"
                      onClick={deleteMedia}
                    >
                      Delete logo
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-lg-4 col-7">
                <label>Business Type</label>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="custom_business_radio">
                      <Radio
                        id="gst"
                        onChange={() => setGst(!gst)}
                        checked={gst}
                        disabled={editField}
                      />
                      {/* <input
                        type="radio"
                        id="gst" 
                        onChange={() => setGst(!gst)}
                        checked={gst}
                        disabled={editField}
                      /> */}
                      <label className="">GST </label>
                      {/* </div>

                  <div className="custom_business_radio"> */}
                      <Radio
                        checked={!gst}
                        onChange={() => setGst(!gst)}
                        disabled={editField}
                      />
                      {/* <input
                        type="radio"
                        checked={!gst}
                        onChange={() => setGst(!gst)}
                        disabled={editField}
                      /> */}
                      <label className="">Non-GST</label>
                    </div>
                  </div>
                </div>
              </div>
              {gst ? (
                <div className="col-lg-4 col-6">
                  <label>GST No. </label>

                  <input
                    type="text"
                    class="form-control "
                    placeholder=" Enter Gst number"
                    disabled={editField}
                    name="Gst"
                    onChange={(e) => setGstNo(e.target.value)}
                    value={gstNo}
                  />
                </div>
              ) : null}
              <div className="col-lg-4 col-6">
                <label>Business Name </label>

                <input
                  type="text"
                  class="form-control "
                  disabled={editField}
                  placeholder="Enter your bussiness name"
                  name="BNAME"
                  onChange={formik.handleChange}
                  value={formik.values.BNAME || ""}
                  onBlur={formik.handleBlur}
                />
                <span className="form-text text-danger login-error">
                  {formik.touched.EMAILID && formik.errors.EMAILID}
                </span>
              </div>

              <div className="col-lg-4 col-6">
                <label>PAN No. </label>
                <span
                  style={{ color: "red", fontWeight: "bold" }}
                  class="required"
                >
                  *
                </span>
                <input
                  type="text"
                  class="form-control "
                  placeholder="Enter your PAN no."
                  name="PAN"
                  disabled={editField}
                  onChange={formik.handleChange}
                  value={formik.values.PAN || ""}
                  onBlur={formik.handleBlur}
                />

                <span className="form-text text-danger login-error">
                  {formik.touched.PAN && formik.errors.PAN}
                </span>
              </div>
              <div className="col-lg-4 col-6">
                <label>Company Website</label>
                <span
                  style={{ color: "red", fontWeight: "bold" }}
                  class="required"
                >
                  *
                </span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter your company website"
                  name="WEB"
                  disabled={editField}
                  onChange={formik.handleChange}
                  value={formik.values.WEB || ""}
                  onBlur={formik.handleBlur}
                />
                <span className="form-text text-danger login-error">
                  {formik.touched.WEB && formik.errors.WEB}
                </span>
              </div>

              <div className="col-lg-4 col-6">
                <label>Reffered by </label>

                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter your reference name"
                  name="REFBY"
                  disabled={editField}
                  onChange={formik.handleChange}
                  value={formik.values.REFBY || ""}
                  onBlur={formik.handleBlur}
                />

                <span className="form-text text-danger login-error">
                  {formik.touched.REFBY && formik.errors.REFBY}
                </span>
              </div>
            </div>
            {!editField ? (
              <div className="row text-center">
                <div className=" mt-5   w-100">
                  <button className="btn btn-background border-round  text-white ">
                    Submit
                  </button>
                </div>
              </div>
            ) : null}
          </form>
        </div>
      </div>
    </div>
  );
}
