import React, { Fragment, useState, useEffect } from "react";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
// import "react-multi-carousel/lib/styles.css";
import "react-alice-carousel/lib/alice-carousel.css";
import { useSelector, useDispatch } from "react-redux";
import ApiLib from "../../utils/api";
import { useToasts } from "react-toast-notifications";
import SweetAlert from "react-bootstrap-sweetalert";
import mixpanel from "mixpanel-browser";
import { Mixpanel } from "../../utils/mixpannel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
  pb: 0,
};

function MyCollectionModal(props) {
  const [loading, setLoading] = useState(false);
  const [collection, setCollection] = useState([]);
  const API = new ApiLib();
  const userDetails = useSelector((state) => state.userDetails);
  const [checked, setChecked] = React.useState([]);
  const { addToast } = useToasts();
  const [showSweetAlert, setShowSweetAlert] = useState(false);

  console.log("collection modal props", props);
  console.log("checked items", checked);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  useEffect(() => {
    if (props.open) {
      FetchCollection();
    }
  }, [props.open]);

  const AddToCollection = () => {
    if (!checked.length) return;
    setLoading(true);
    let itemDetail = checked.map((item) => {
      let newItem = {
        ...item,
        DCODE: [props.product.DCODE],
      };
      delete newItem.CNT;
      return newItem;
    });
    console.log("itemDetails", itemDetail);
    API.UpdateCollection(itemDetail)
      .then((res) => {
        console.log("added to collection", res.data);
        addToast("Item added to collection successfully", {
          appearance: "success",
          autoDismiss: true,
        });
        setChecked([]);
        props.close();
        //
        mixpanel.track("AddToCollection", { ItemDetails: JSON.stringify(itemDetail[0].DCODE)});  // MixPannel ka code
        //
      })
      .catch((err) => {
        console.log(err);
        setChecked([]);
        props.close();
        addToast("Something went wrong please try again", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const FetchCollection = () => {
    setLoading(true);
    API.GetAllCollection(userDetails.user.ID)
      .then((res) => {
        console.log("collection", res.data);
        setCollection(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Modal
      open={props.open}
      onClose={() => props.close()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="custom_pdpcollection_popup"
    >
      <Box sx={style} className="custom_pdpcollection_popup_inside">
        <div className="row">
          <div className="col-6 text-left">
            <div className="row">
              <h3 className="mr-3 sidebar-title title-text-bold">
                {" "}
                My Collection{" "}
              </h3>
              <AddIcon
                className="icon-color mt-1 close-button"
                onClick={() => props.openAddCollection()}
              />
            </div>
          </div>

          <div className=" col-6 text-right mt-n2 ">
            <CloseIcon className="close-button" onClick={() => props.close()} />
          </div>
        </div>
        {/* <div className="font-weight-bold">Add Product to</div> */}
        <List
          dense
          sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "background.paper",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          {collection.map((item) => {
            const labelId = `checkbox-list-secondary-label-${item}`;
            return (
              <ListItem
                className="custom_collection_listed"
                key={item}
                secondaryAction={
                  <Checkbox
                    edge="end"
                    onChange={handleToggle(item)}
                    checked={checked.indexOf(item) !== -1}
                    inputProps={{ "aria-labelledby": labelId }}
                    className="checkbox-color"
                  />
                }
                disablePadding
              >
                <br />
                <br />
                <ListItemButton className="collection-products">
                  <ListItemText
                    id={labelId}
                    primary={
                      <div className="row ml-2 custom_collection_products_list">
                        <div className="h5 sidebar-title">
                          {item.COLL_NAME} <span>({item.CNT})</span>
                        </div>
                        {/* <div className="ml-3">
                          <ShareOutlinedIcon className="share-button icon-color" />
                        </div> */}
                      </div>
                    }
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        <div
          className="row mt-3 mb-3 text-center  custom_row_collection_btn"
          // style={{ marginLeft: "-33px", marginRight: "-33px" }}
          onClick={() => {
            if (checked?.length > 0) {
              AddToCollection();
            } else {
              setShowSweetAlert(true);
            }
          }}
        >
          <div className="col-8 px-3 py-3 text-white close-button  btn-background title-text-bold custom_addtocollectionbtn">
            Add To Collection
          </div>
          {/* <button className="custom_pdpcollection_popup_btn"> Add To Collection</button> */}
        </div>
        <div style={{minHeight:"80px"}} >
        {showSweetAlert ? (
          
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Okay"
            confirmBtnBsStyle="danger"
            title="Please select atleast one collection"
            onConfirm={() => {
              setShowSweetAlert(false);
            }}
            onCancel={() => {
              setShowSweetAlert(false);
            }}
            focusCancelBtn
          ></SweetAlert>
        ) : null}
        </div>
       
      </Box>
    </Modal>
  );
}

export default MyCollectionModal;
