import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import MetaTags from "react-meta-tags";
import LayoutOne from "../../layouts/LayoutOne";
import ProductListingFilters from "./ProductListingFilters";
import ShopTopbar from "./ShopTopbar";
import ProductListingProducts from "./ProductListingProducts";
import "./plp.css";
import BottomBar from "./BottomBar";
import Dropdown from "react-bootstrap/Dropdown";
import removeProducts from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import WhatsAppBotComp from "../../components/WhatsAppBot/YourMainComponent";
import { setSelectAllAction } from "../../redux/actions/selectAllAction";

const ProductListing = ({ location, products }) => {
  const [layout, setLayout] = useState("grid three-column");
  const [offset, setOffset] = useState(0);
  const [currentData, setCurrentData] = useState([]);
  const [selectProduct1, setSelectProduct] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectCheckbox, setSelectCheckbox] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const pageLimit = 15;
  const { pathname } = location;
  const productsList = useSelector((state) => state.allProducts.products);
  const [productsLength, setProductsLength] = useState(30);
  const { SCat } = location;
  const dispatch = useDispatch();
  const { selectAll: reduxSelectAll } = useSelector((state) => state);

  const xyz = useLocation();
  console.log(SCat, "CheckCatID");
  console.log(productsList, "CheckForProducts");

  // Side Bar Code
  const [FilterOpen, setFilterOpen] = useState(true);
  // Side Bar Function
  const toggleFilterbar = () => {
    setFilterOpen(!FilterOpen);
  };

  const getLayout = (layout) => {
    setLayout(layout);
  };
  useEffect(() => {
    if (selectAll) {
      setSelectedProducts([...productsList]);
    } else {
      setSelectedProducts([]);
    }
  }, [productsList, selectAll]);

  const deleteProduct = (id) => {
    console.log("prdId=>", id);
    const remainingProducts = removeProducts(selectedProducts, "DCODE", id);
    console.log("remaining", remainingProducts);
    setSelectedProducts([...remainingProducts]);
    console.log("productsAfter deletion=>", selectedProducts);
  };
  return (
    <Fragment>
      {/* WhatsApp Icon */}
      {/* <a href=" https://wa.me/9320838762">
<img  src={whatsapp_btn} className="whatsapp-img" alt="" />
</a> */}
      <MetaTags>
        <title>Amyraa | Shop Page</title>
        <meta
          name="description"
          content="Shop page of flone react minimalist eCommerce template."
        />
      </MetaTags>

      {/* <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>
        Shop
      </BreadcrumbsItem> */}

      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        {/* <Breadcrumb /> */}

        <div className="shop-area pt-25 pb-25">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 order-2 order-lg-1 custom_sidebar border-filter">
                {/* shop sidebar */}
                <ProductListingFilters
                  toggleFilterbar={toggleFilterbar}
                  FilterOpen={FilterOpen}
                  setFilterOpen={setFilterOpen}
                  products={products}
                  // getSortParams={getSortParams}
                  sideSpaceClass="mr-30"
                />
              </div>
              <div className="col-lg-9 order-1 order-lg-2 scroll-max-products">
                {/* shop topbar default */}
                <ShopTopbar
                  toggleFilterbar={toggleFilterbar}
                  FilterOpen={FilterOpen}
                  getLayout={getLayout}
                  products={products}
                  selectAll={(products, checked) => {
                    console.log("select all", productsList);
                    dispatch(setSelectAllAction([...productsList]));
                    // setSelectedProducts([...productsList]);
                    // setSelectAll(checked);
                  }} // getFilterSortParams={getFilterSortParams}
                  // productCount={products.length}
                  // sortedProductCount={currentData.length}
                  selectCheckbox={selectCheckbox}
                  setSelectCheckbox={setSelectCheckbox}
                  productsLength={productsLength}
                  setProductsLength={setProductsLength}
                />
                <div
                  className="text-left ml-1 display-flex flex-row align-items-center"
                  style={{ justifyContent: "space-between" }}
                >
                  <p style={{ margin: 0 }}>
                    Total products : {productsList[0]?.TOTCNT || 0}
                  </p>
                  <Dropdown>
                    <Dropdown.Toggle
                      style={{
                        background: "transparent",
                        color: "black",
                        border: "none",
                        boxShadow: "none",
                      }}
                    >
                      No of Products: {productsLength}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => setProductsLength(30)}
                        name="sortby"
                      >
                        30
                      </Dropdown.Item>

                      <Dropdown.Item onClick={() => setProductsLength(60)}>
                        60
                      </Dropdown.Item>

                      <Dropdown.Item onClick={() => setProductsLength(90)}>
                        90
                      </Dropdown.Item>

                      <Dropdown.Item onClick={() => setProductsLength(120)}>
                        120
                      </Dropdown.Item>

                      <Dropdown.Item onClick={() => setProductsLength(150)}>
                        150
                      </Dropdown.Item>

                      <Dropdown.Item onClick={() => setProductsLength(180)}>
                        180
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {/* shop page content default */}
                <ProductListingProducts
                  layout={layout}
                  products={currentData}
                  selectedProducts={(products, checked) =>
                    checked
                      ? setSelectedProducts([...selectedProducts, products])
                      : deleteProduct(products.DCODE)
                  }
                  selectedProductsArr={selectedProducts}
                  checkAll={selectAll}
                  productsLength={productsLength}
                  setProductsLength={setProductsLength}
                />
                {reduxSelectAll?.selectedProducts?.length !== 0 &&
                productsList?.length ? (
                  <BottomBar
                    newSelectedProducts={(prdArr) =>
                      setSelectedProducts([...prdArr])
                    }
                    selectedProducts={selectedProducts}
                    setSelectAll={setSelectAll}
                    setSelectedProducts={setSelectedProducts}
                    setSelectCheckbox={setSelectCheckbox}
                  />
                ) : null}{" "}
                {/* shop product pagination */}
                {/* <div className="pro-pagination-style text-center mt-30">
                  <Paginator
                    totalRecords={sortedProducts.length}
                    pageLimit={pageLimit}
                    pageNeighbours={2}
                    setOffset={setOffset}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageContainerClass="mb-0 mt-0"
                    pagePrevText="«"
                    pageNextText="»"
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
      <WhatsAppBotComp />
    </Fragment>
  );
};

export default ProductListing;
