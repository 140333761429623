import React, { useState, useEffect } from "react";
// import Modal from "@mui/material/Modal";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import { useToasts } from "react-toast-notifications";
import Apilib from "../../utils/api";
import Loader from "../../helpers/Loader";

function EditAddress(props) {
  console.log(props);
  const userDetails = useSelector((state) => state.userDetails);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const [address, setAddress] = useState();
  const API = new Apilib();
  useEffect(() => {
    if (props.open) {
      FetchDeliveryAddress();
    }
  }, [props.open]);

  // useEffect(() => {
  //   if (deliveryAddress) {
  //     props.selectedAdd(deliveryAddress);
  //   }
  // }, [deliveryAddress]);

  const FetchDeliveryAddress = () => {
    setLoading(true);
    const userId = userDetails.user.ID;
    API.GetUserDeliveryAddress(userId, props.address.SER_NO)
      .then((res) => {
        console.log(res.data);
        setAddress(res.data[0]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...address,
    },
    validationSchema: Yup.object({
      FNAME: Yup.string()
        .required()
        .min(3)
        .matches(/^([\w]{3,})+\s+([\w\s]{3,})+$/i, "Invalid full name ")
        .label("Full Name"),
      EMAILID: Yup.string()
        .required()
        .min(3)
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Invalid email id"
        )
        .label("Email Id"),
      MOBNO: Yup.string()
        .required()
        .matches(/^[0-9]+$/, "Invalid mobile no.")
        .min(7)
        .max(15)
        .label("Mobile no"),
      ALTMOBNO: Yup.string()
        .matches(/^[0-9]+$/, "Invalid mobile no.")
        .min(7)
        .max(15)
        .label("Alternate no"),
      AREA: Yup.string().required().min(0).label("Area"),
      PIN: Yup.string()
        .required()
        .matches(/^[0-9]+$/, "Invalid pincode")
        .min(0)
        .max(6)
        .label("Pincode"),
      PAR_ADD1: Yup.string().required().min(0).label("Address"),
      PAR_ADD2: Yup.string().min(0).label("ADDRESS 2"),
      PAR_ADD3: Yup.string().min(0).label("ADDRESS 3"),
      CITY: Yup.string().required().min(0).label("City"),
      COUNTRY: Yup.string().required().min(0).label("Country"),
      STATE: Yup.string().required().min(0).label("State"),
    }),
    onSubmit: (values) => {
      Submit();
    },
  });

  const Submit = () => {
    if (!formik.isValid) return;
    setLoading(true);
    const data = { ...formik.values };
    API.UpdateDeliveryAddress(data)
      .then((res) => {
        addToast("Address updated successfully", {
          appearance: "success",
          autoDismiss: true,
        });
        // props.changeView();
        props.Onclose();
        props.fetchAddress();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        addToast("Something went wrong please try again", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };
  return (
    <>
      {loading ? <Loader /> : null}

      <Modal
        show={props.open}
        onHide={() => props.Onclose()}
        className="custom_arrow_bigger"
      // dialogClassName="address-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="sidebar-title">Edit Address </Modal.Title>
        </Modal.Header>

        <Modal.Body className="">
          <div className="mt-4 mx-4 Custom_modalBody_form">
            <form onSubmit={formik.handleSubmit}>
              <div className="row font-weight-bold text-black  ">
                <div className="col-6">
                  <label>Full Name </label>
                  <span
                    style={{ color: "red", fontWeight: "bold" }}
                    class="required"
                  >
                    *
                  </span>
                  <input
                    type="text"
                    placeholder="Enter your full name"
                    class="form-control "
                    name="FNAME"
                    onChange={formik.handleChange}
                    value={formik.values.FNAME || ""}
                    onBlur={formik.handleBlur}
                  />
                  <span className="form-text text-danger login-error">
                    {formik.touched.FNAME && formik.errors.FNAME}
                  </span>
                </div>
                <div className="col-6">
                  <label>Email-ID </label>
                  <span
                    style={{ color: "red", fontWeight: "bold" }}
                    class="required"
                  >
                    *
                  </span>
                  <input
                    type="text"
                    class="form-control "
                    placeholder=" Enter your Email"
                    name="EMAILID"
                    onChange={formik.handleChange}
                    value={formik.values.EMAILID || ""}
                    onBlur={formik.handleBlur}
                  />
                  <span className="form-text text-danger login-error">
                    {formik.touched.EMAILID && formik.errors.EMAILID}
                  </span>
                </div>
                <div className="col-6  ">
                  <label>Mobile No</label>
                  <span
                    style={{ color: "red", fontWeight: "bold" }}
                    class="required"
                  >
                    *
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter your Mobile No."
                    name="MOBNO"
                    onChange={formik.handleChange}
                    value={formik.values.MOBNO || ""}
                    onBlur={formik.handleBlur}
                  />
                  <span className="form-text text-danger login-error">
                    {formik.touched.MOBNO && formik.errors.MOBNO}
                  </span>
                </div>
                <div className="col-6  ">
                  <label>Alternate Phone Number</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter your Alternate Number"
                    name="ALTMOBNO"
                    onChange={formik.handleChange}
                    value={formik.values.ALTMOBNO || ""}
                    onBlur={formik.handleBlur}
                  />
                  <span className="form-text text-danger login-error">
                    {formik.touched.ALTMOBNO && formik.errors.ALTMOBNO}
                  </span>
                </div>
                <div className="col-12  ">
                  <label>Address 1</label>
                  <span
                    style={{ color: "red", fontWeight: "bold" }}
                    class="required"
                  >
                    *
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    name="PAR_ADD1"
                    onChange={formik.handleChange}
                    value={formik.values.PAR_ADD1 || ""}
                    onBlur={formik.handleBlur}
                  />
                  <span className="form-text text-danger login-error">
                    {formik.touched.PAR_ADD1 && formik.errors.PAR_ADD1}
                  </span>
                </div>
                <div className="col-12  ">
                  <label>Address 2</label>
                  <input
                    type="text"
                    class="form-control"
                    name="PAR_ADD2"
                    onChange={formik.handleChange}
                    value={formik.values.PAR_ADD2 || ""}
                    onBlur={formik.handleBlur}
                  />
                  <span className="form-text text-danger login-error">
                    {formik.touched.PAR_ADD2 && formik.errors.PAR_ADD2}
                  </span>
                </div>
                <div className="col-12  ">
                  <label>Address 3</label>
                  <input
                    type="text"
                    class="form-control"
                    name="PAR_ADD3"
                    onChange={formik.handleChange}
                    value={formik.values.PAR_ADD3 || ""}
                    onBlur={formik.handleBlur}
                  />
                  <span className="form-text text-danger login-error">
                    {formik.touched.PAR_ADD3 && formik.errors.PAR_ADD3}
                  </span>
                </div>
                <div className="col-6  ">
                  <label>Area</label>
                  <span
                    style={{ color: "red", fontWeight: "bold" }}
                    class="required"
                  >
                    *
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter your Area"
                    name="AREA"
                    onChange={formik.handleChange}
                    value={formik.values.AREA || ""}
                    onBlur={formik.handleBlur}
                  />
                  <span className="form-text text-danger login-error">
                    {formik.touched.AREA && formik.errors.AREA}
                  </span>
                </div>
                <div className="col-6  ">
                  <label>Pincode</label>
                  <span
                    style={{ color: "red", fontWeight: "bold" }}
                    class="required"
                  >
                    *
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter your Pincode"
                    name="PIN"
                    onChange={formik.handleChange}
                    value={formik.values.PIN || ""}
                    onBlur={formik.handleBlur}
                  />
                  <span className="form-text text-danger login-error">
                    {formik.touched.PIN && formik.errors.PIN}
                  </span>
                </div>
                <div className="col-6  ">
                  <label>Country</label>
                  <span
                    style={{ color: "red", fontWeight: "bold" }}
                    class="required"
                  >
                    *
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter your Enter Country"
                    name="COUNTRY"
                    onChange={formik.handleChange}
                    value={formik.values.COUNTRY || ""}
                    onBlur={formik.handleBlur}
                  />
                  <span className="form-text text-danger login-error">
                    {formik.touched.COUNTRY && formik.errors.COUNTRY}
                  </span>
                </div>

                <div className="col-6  ">
                  <label>State</label>
                  <span
                    style={{ color: "red", fontWeight: "bold" }}
                    class="required"
                  >
                    *
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter your Enter State"
                    name="STATE"
                    onChange={formik.handleChange}
                    value={formik.values.STATE || ""}
                    onBlur={formik.handleBlur}
                  />
                  <span className="form-text text-danger login-error">
                    {formik.touched.STATE && formik.errors.STATE}
                  </span>
                </div>
                <div className="col-6  ">
                  <label>City</label>
                  <span
                    style={{ color: "red", fontWeight: "bold" }}
                    class="required"
                  >
                    *
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter your City"
                    name="CITY"
                    onChange={formik.handleChange}
                    value={formik.values.CITY || ""}
                    onBlur={formik.handleBlur}
                  />
                  <span className="form-text text-danger login-error">
                    {formik.touched.CITY && formik.errors.CITY}
                  </span>
                </div>
              </div>
              <div className="row text-center">
                <div className=" mb-4  w-100">
                  <button
                    className="btn border-round text-white btn-background custom_editFormSubmitBtn"
                    // onClick={() => props.addAddress()}
                    type="submit"
                  >
                    Submit
                  </button>
                  <div
                    className="btn title-text border-round border-green  mx-4"
                    id="btn-cancel"
                    type="text"
                    onClick={() => props.Onclose()}
                  >
                    Close
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditAddress;
