import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
function Alert() {
  const [values, setValues] = React.useState({ alert: false });
  //Listen to your custom event
  window.addEventListener("showAlert", function (e) {
    // console.log('printer state changed', e);
    const { detail } = e;
    // console.log(detail, "detail");
    if (detail) {
      setValues({
        ...values,
        alert: true,
        type: detail.type,
        title: detail.title,
        confirmBtnText: detail.confirmBtnText,
        onConfirm: detail.onConfirm,
        onCancel: detail.onCancel,
      });
    }
  });
  const hideAlert = () => {
    window.removeEventListener("showAlert", function () {}, false);
    setValues({
      ...values,
      alert: false,
    });
  };

  const alertFunc = () => {
    return values.alert ? (
      values.type === "success" ? (
        <SweetAlert
          success
          title="Success"
          onConfirm={hideAlert}
          className="custom-notify"
        >
          {" "}
          {values.title}{" "}
        </SweetAlert>
      ) : values.type === "info" ? (
        <SweetAlert
          info
          onConfirm={hideAlert}
          className="custom-notify"
        >
          {" "}
          {values.title}{" "}
        </SweetAlert>
      ) : values.type === "error" ? (
        <SweetAlert error title="Error" onConfirm={hideAlert}>
          {" "}
          {values.title}{" "}
        </SweetAlert>
      ) : values.type === "warning" ? (
        <SweetAlert
          warning
          title={values.title}
          confirmBtnText={values.confirmBtnText}
          confirmBtnBsStyle="danger"
          onConfirm={values.onConfirm ? values.onConfirm : hideAlert}
          onCancel={hideAlert}
          showCancel={values.onConfirm}
          focusCancelBtn
        ></SweetAlert>
      ) : null
    ) : null;
  };

  return <div>{alertFunc()} </div>;
}

export default Alert;
