import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import Apilib from "../../utils/api";
import Loader from "../../helpers/Loader";

export default function CreditInfo() {
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const API = new Apilib();

  console.log(userDetails.CRDAYS,"CrDaysCrdays")

  useEffect(() => {
    FetchUserDetails();
  }, []);

  function FetchUserDetails() {
    setLoading(true);

    API.GetUserDetails(localStorage?.amyra_email)
      .then((res) => {
        console.log("user Details", res.data);
        let userInfo = { ...res.data[0] };
        setUserDetails(userInfo);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }
  return (
    <div>
      {loading ? <Loader /> : null}
      <div className="ml-1 overflow-hidden">
        <div className="title-text-bold sidebar-title h2 ">Credit Info</div>
        <hr />
        <div className="row mt-4 pt-4 mb-4 ml-2">
          <div className="col-6  font-weight-bold">Credit Limit</div>
          <div className="col-6 text-right">{userDetails.CRDLIMIT|| 0}</div>
        </div>
        <div className="row mb-4 ml-2">
          <div className="col-6  font-weight-bold">Credit Days</div>
          <div className="col-6  text-right">{userDetails.CRDAYS || 0}</div>
        </div>
        <div className="row mb-4 ml-2">
          <div className="col-6  font-weight-bold">Lock Days</div>
          <div className="col-6  text-right">{userDetails.LOCKDAYS || 0}</div>
        </div>
        <div className="row mb-3 ml-2 ml-2">
          <div className="col-6  font-weight-bold">Intrest%</div>
          <div className="col-6   text-right">{userDetails.INTRATE || 0}</div>
        </div>
      </div>
    </div>
  );
}
