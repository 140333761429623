import PropTypes from "prop-types";
import React from "react";
import why1 from "../../assets/img/why1.png";

const FeatureIconTwoSingle = ({
  data,
  title,
  subTitle,
  spaceBottomClass,
  textAlignClass,
}) => {
  return (
    <div className="col-md-4 ">
      <div
        className={`support-wrap-2 support-shape ${
          spaceBottomClass ? spaceBottomClass : ""
        } ${textAlignClass ? textAlignClass : ""}`}
      >
        <div className="why_us_img">
          <img className="" src={why1} alt="" />
          <h5 className="Custom_whyus">{title}</h5>
          <p>{subTitle}</p>
        </div>
      </div>
    </div>
  );
};

FeatureIconTwoSingle.propTypes = {
  data: PropTypes.object,
  spaceBottomClass: PropTypes.string,
  textAlignClass: PropTypes.string,
};

export default FeatureIconTwoSingle;
