import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { find, pullAllWith, isEqual } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import Checkbox from "@mui/material/Checkbox";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { InputAdornment } from "@mui/material";
import Apilib from "../../utils/api";
import { selectedTopFilters } from "../../redux/actions/productAction";
import Loader from "../../helpers/Loader";
import './ProductListingFilter.css'
import { setSelectAllAction } from "../../redux/actions/selectAllAction";

const styleForIcon = {
  fontSize: "30px",
};
const styleforallicon = {
  fontSize: 20,
  marginTop: 5,
  marginRight: 10,
  color: "black",
};

const ProductListingFilters = (props) => {
  const [expanded, setExpanded] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [all, setAll] = useState(true);
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const filters_top = useSelector((state) => state.allProducts.filters_top);
  const API = new Apilib();
  const dispatch = useDispatch();

  const Categories = [
    {
      SCATG: "ACCESSORIES",
      SCATGID: "358",
      CNT: "43",
      IMG: "https://vivahc.azurewebsites.net/AMYRA/IMAGES/SUBCATG/358.jpg",
      catgid: "214",
      scatgid: "358",
      FILTERATT: ["AD-310", "ANTIQUE-311", "KUNDAN-313", "MAZAK-314"],
    },
    {
      SCATG: "BANGLES",
      SCATGID: "359",
      CNT: "100",
      IMG: "https://vivahc.azurewebsites.net/AMYRA/IMAGES/SUBCATG/359.jpg",
      catgid: "214",
      scatgid: "359",
      FILTERATT: [
        "AD-310",
        "ANTIQUE-311",
        "DP KUNDAN-387",
        "KUNDAN-313",
        "MAZAK-314",
        "RAJWADI-315",
        "REVERSE AD MAZAK-393",
      ],
    },
    {
      SCATG: "EARRINGS",
      SCATGID: "361",
      CNT: "423",
      IMG: "https://vivahc.azurewebsites.net/AMYRA/IMAGES/SUBCATG/361.jpg",
      catgid: "214",
      scatgid: "361",
      FILTERATT: ["AD/KUNDAN-475", "AD-310", "ANTIQUE-311", "KUNDAN-313"],
    },
    {
      SCATG: "FINGER RING",
      SCATGID: "362",
      CNT: "149",
      IMG: "https://vivahc.azurewebsites.net/AMYRA/IMAGES/SUBCATG/362.jpg",
      catgid: "214",
      scatgid: "362",
      FILTERATT: [
        "AD/KUNDAN-475",
        "AD-310",
        "ANTIQUE-311",
        "KUNDAN-313",
        "MAZAK-314",
      ],
    },
    {
      SCATG: "HAIR ACCESSORIES",
      SCATGID: "363",
      CNT: "72",
      IMG: "https://vivahc.azurewebsites.net/AMYRA/IMAGES/SUBCATG/363.jpg",
      catgid: "214",
      scatgid: "363",
      FILTERATT: [
        "AD-310",
        " DP KUNDAN-387",
        "ANTIQUE-311",
        "KUNDAN-313",
        "MAZAK-314",
      ],
    },
    {
      SCATG: "KADA/BRACELET",
      SCATGID: "364",
      CNT: "298",
      IMG: "https://vivahc.azurewebsites.net/AMYRA/IMAGES/SUBCATG/364.jpg",
      catgid: "214",
      scatgid: "364",
      FILTERATT: [
        "AD/KUNDAN-475",
        "AD-310",
        "ANTIQUE-311",
        "KUNDAN-313",
        "MAZAK-314",
      ],
    },
    {
      SCATG: "MALA SET",
      SCATGID: "366",
      CNT: "61",
      IMG: "https://vivahc.azurewebsites.net/AMYRA/IMAGES/SUBCATG/366.jpg",
      catgid: "214",
      scatgid: "366",
      FILTERATT: ["AD-310", "ANTIQUE-311", "KUNDAN-313", " RAJWADI-315"],
    },
    {
      SCATG: "MANGALSUTRA",
      SCATGID: "367",
      CNT: "31",
      IMG: "https://vivahc.azurewebsites.net/AMYRA/IMAGES/SUBCATG/367.jpg",
      catgid: "214",
      scatgid: "367",
      FILTERATT: ["AD-310", "ANTIQUE-311", "KUNDAN-313"],
    },
    {
      SCATG: "NECK PIECES",
      SCATGID: "368",
      CNT: "417",
      IMG: "https://vivahc.azurewebsites.net/AMYRA/IMAGES/SUBCATG/368.jpg",
      catgid: "214",
      scatgid: "368",
      FILTERATT: [
        "AD/KUNDAN-475",
        "AD-310",
        "ANTIQUE-311",
        "KUNDAN-313",
        "MAZAK-314",
      ],
    },
    {
      SCATG: "PENDANT SET",
      SCATGID: "369",
      CNT: "140",
      IMG: "https://vivahc.azurewebsites.net/AMYRA/IMAGES/SUBCATG/369.jpg",
      catgid: "214",
      scatgid: "369",
      FILTERATT: ["AD-310", "ANTIQUE-311", "KUNDAN-313", "MAZAK-314"],
    },
    {
      SCATG: "TIKKA",
      SCATGID: "392",
      CNT: "36",
      IMG: "https://vivahc.azurewebsites.net/AMYRA/IMAGES/SUBCATG/392.jpg",
      catgid: "214",
      scatgid: "392",
      FILTERATT: ["AD-310", "ANTIQUE-311", "KUNDAN-313", "MAZAK-314"],
    },
    {
      SCATG: "TOPS",
      SCATGID: "371",
      CNT: "75",
      IMG: "https://vivahc.azurewebsites.net/AMYRA/IMAGES/SUBCATG/371.jpg",
      catgid: "214",
      scatgid: "371",
      FILTERATT: ["AD-310", "ANTIQUE-311", "KUNDAN-313", "MAZAK-314"],
    },
  ];

  console.log("filters data", filterData);
  console.log("filters datata", filters_top);

  const customCategoriesList = (arr) => {
    console.log("temp", arr);
    const list = [
      "NECK PIECES",
      "BANGLES",
      "EARRINGS",
      "TOPS",
      "BRACELET",
      "KADA",
      "KADA/BRACELET",
      "PENDANT SET",
      "ACCESSORIES",
      "FINGER RING",
      "HAIR ACCESSORIES",
      "MALA",
      "MALA SET",
      "MANGALSUTRA",
      // "TIKKA",
    ];
    
    const res = [];

    if (arr.length > 0) {
      for (let i in list) {
        const temp = arr.find((item) => item.SCATG === list[i]);
        if (temp) res.push(temp);
      }
    }

    return res;
  };

  const handleSelectClick = () => {
    setSelected((oldSelected) =>
      oldSelected.length === 0
        ? [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
          ]
        : []
    );
  };

  useEffect(() => {
    FetchAllCategories();
     
    //eslint-disable-next-line
  }, []);
  function handleClearAllFilters() {
    dispatch(
      selectedTopFilters({
        color: [],
        plating: [],
        lowerPrice: "",
        upperPrice: "",
        sortby: "CREATEDTIME desc",
        catgFilter: [],
      })
    );
  }

  useEffect(() => {
    if (!filters_top.catgFilter.length) {
      // setFilterData([]);
      FetchAllCategories();


      console.log("useeffect working");
    }
    //eslint-disable-next-line
  }, [filters_top]);

  useEffect(() => {
    return () => {
      handleClearAllFilters();
    };
    //eslint-disable-next-line
  }, []);

  const FetchAllCategories = () => {
    setLoading(true);
    let modifiedCategories = [];
    API.FetchCategories()
      .then((res) => {
        const categories = res.data;
        console.log(categories, "Categories");
        categories.map((catg) => {
          let split = catg.FILTERATT.replaceAll("`!~", "-");
          console.log("split", split);
          modifiedCategories.push({
            SCATG: catg.SCATG,
            FILTERATT: split.split(","),
            SCATGID: catg.SCATGID,
            CATGID: catg.CATGID,
          });
        });
        console.log(modifiedCategories, "modifiedCategories");
        const filteredArray = modifiedCategories.filter(
          (obj) => obj.CATGID == 214
        );
        console.log(filteredArray, "modifiedCategories2");
        setAllCategories(filteredArray);
        setFilterData(filters_top.catgFilter);
        // dispatch(loading(false));
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };


  console.log("ALLL Categories", customCategoriesList(allCategories));
  console.log("ALLL Categoriesasdabhj", allCategories);
  // const xyz = (filter, category) => {
  //   console.log(filter, category, "FilterIDCheck122");
  //   // [
  //   //   { catgid: "214", scatgid: "358", filterid: "310" },
  //   //   { catgid: "214", scatgid: "358", filterid: "311" },
  //   //   { catgid: "214", scatgid: "358", filterid: "313" },
  //   // ];

  //   let idObj = category.FILTERATT.map((filter) => {
  //     return ({
  //       catgid: category.CATGID,
  //       scatgid: category.SCATGID,
  //       filterid: filter.split("-")[1],
  //     });
  //   });

  //   console.log(idObj, "FilterIDCheck123");

  //   const tempFilters = { ...filters_top };

  //   console.log(tempFilters, "FilterIDCheck2");

  //   if (!find(filterData, ...idObj)) {
  //     setFilterData([...filterData, ...idObj]);
  //     tempFilters.catgFilter = [...filterData, ...idObj];
  //     dispatch(selectedTopFilters(tempFilters));
  //   } else {
  //     let idArr = filterData;
  //     pullAllWith(idArr, [...idObj], isEqual);
  //     setFilterData([...idArr]);
  //     tempFilters.catgFilter = [...idArr];
  //     dispatch(selectedTopFilters(tempFilters));
  //   }

  //   // let id = filter.split("-")[1];
  //   // console.log(id, "FilterIDCheck1")
  //   // const idObj = {
  //   //   catgid: category.CATGID,
  //   //   scatgid: category.SCATGID,
  //   //   filterid: id,
  //   // }
  // };

  const handleFilters = (filterId, category) => {
    console.log(filterId, category, "FilterIDCheck");
    let id = filterId.split("-")[1];
    console.log(id, "FilterIDCheck1");
    const idObj = {
      catgid: category.CATGID,
      scatgid: category.SCATGID,
      filterid: id,
    };

    const tempFilters = { ...filters_top};

    console.log(tempFilters, "FilterIDCheck2");

    if (!find(filterData, idObj)) {
      setFilterData([...filterData, idObj]);
      tempFilters.catgFilter = [...filterData, idObj];
      dispatch(selectedTopFilters(tempFilters));
    } else {
      let idArr = filterData;
      pullAllWith(idArr, [idObj], isEqual);
      setFilterData([...idArr]);
      tempFilters.catgFilter = [...idArr];
      dispatch(selectedTopFilters(tempFilters));
    }
  };

  const handleTreeClick = (e, category) => {
    e.stopPropagation();
    if (e.target instanceof Element) {
      const isIcon = !!e.target.closest(".MuiTreeItem-iconContainer");
      if (isIcon) {
        return;
      }
    }
    console.log("e.target", e);
    dispatch(setSelectAllAction([]))
    handleCategoryClick(category);
  };

  const handleCategoryClick = (category) => {
    const tempFilters = { ...filters_top };

    let tArr = category.FILTERATT.map((item) => ({
      catgid: category.CATGID,
      scatgid: category.SCATGID,
      filterid: item.split("-")[1],
    }));

    setFilterData(tArr);
    tempFilters.catgFilter = tArr;
    dispatch(selectedTopFilters(tempFilters));
  };

  const AllCategoryClick = () => {
    const tempFilters = { ...filters_top };
    // Clear all filters
    tempFilters.catgFilter = [];
    // Dispatch action to update filters
    dispatch(selectedTopFilters(tempFilters));
  }

  return (
    <>
    
      {loading ? (
        <Loader />
      ) : (
        <div className={`sidebar-style custom_linkdiv1 ${props.FilterOpen ? 'sidebar-modal' : ''}`}>
          <Box sx={{ height: "auto", flexGrow: 1, maxWidth: 450 }}>
            <Box
              sx={{
                mb: 1,
                fontSize: 30,
                backgroundColor: "#13706a",
                marginRight: 0,
                marginLeft: 0,
                borderRadius:"0px 12px 0px 0px"
              }}
            >
              {/* <Button onClick={handleExpandClick}>
            {expanded.length === 0 ? "Expand all" : "Collapse all"}
          </Button> */}
          

          <div className="filter-close-btn">
          <i onClick={props.toggleFilterbar} 
                  className="collection-icon"  class="ri-close-line"></i>
          </div>


              <Button onClick={handleSelectClick}>
                <div
                  className="row text-white filter-title"
                  style={{ margin: 15 }}
                >
                  {" "}
                  Filters 
                </div>
              </Button>
            </Box>
            <Box
              sx={{
                mb: 1,
                fontSize: 30,
                // backgroundColor: "#13706a",
                // marginRight: 0,
                marginLeft: "20px !important",
              }}
              className="custom_linkdiv2"
            >
              <Button onClick={() => setAll(!all)}>
                {selected.length === 0 ? (
                  <div className="row">
                    {" "}
                    {!all ? (
                      <KeyboardArrowRightIcon
                        startAdornment={
                          <InputAdornment position="end"> </InputAdornment>
                        }
                        style={styleforallicon}
                      />
                    ) : (
                      <KeyboardArrowDownIcon
                        startAdornment={
                          <InputAdornment position="end"> </InputAdornment>
                        }
                        style={styleforallicon}
                      />
                    )}{" "}
                    <h3 onClick={AllCategoryClick} className=" custom_shopall">All</h3>{" "}
                  </div>
                ) : (
                  <div className=" ">
                    {" "}
                    {/* <KeyboardArrowDownIcon   style={styleForIcon} />{" "} */}
                    <h3
                      className=" custom_shopall"
                      // onClick={() => handleClearAllFilters()}
                    >
                      All
                    </h3>{" "}
                  </div>
                )}
              </Button>
            </Box>
            {all ? (
              <TreeView
                aria-label="controlled"
                defaultCollapseIcon={
                  <KeyboardArrowDownIcon
                    startAdornment={
                      <InputAdornment position="end"> </InputAdornment>
                    }
                    style={styleForIcon}
                  />
                }
                defaultExpandIcon={
                  <KeyboardArrowRightIcon
                    startAdornment={
                      <InputAdornment position="end"> </InputAdornment>
                    }
                    style={styleForIcon}
                  />
                }
                // expanded={expanded}
                // selected={selected}
                // onNodeToggle={handleToggle}
                // onNodeSelect={handleSelect}
                multiSelect
              >
                {customCategoriesList(allCategories).map((category, index) => {
                  return (
                    <div className="border-bottom custom_shop_filter">
                      <TreeItem
                        nodeId={category.SCATG}
                        label={
                          <h3 style={{ marginTop: 5 }}>{category.SCATG}</h3>
                        }
                        onClick={(e) => handleTreeClick(e, category)}
                        // onClick={() => {
                        //   xyz(category.FILTERATT, category);
                        // }}
                      >
                        {category.FILTERATT.map((filter, i) => {
                          return (
                            <TreeItem
                              nodeId={i}
                              label={
                                <div className="row Custom_subitem_row">
                                  {" "}
                                  <Checkbox
                                    edge="start"
                                    checked={find(filterData, {
                                      catgid: category.CATGID,
                                      scatgid: category.SCATGID,
                                      filterid: filter.split("-")[1],
                                    })}
                                    onChange={() =>
                                      handleFilters(filter, category)
                                    }
                                    onClick={() => {
                                      console.log(props.FilterOpen,"Filter Open")
                                      props.setFilterOpen(true)}
                                    }
                                    // tabIndex={-1}
                                    // disableRipple
                                    size="small"
                                    className="checkbox-color"
                                  />
                                  <h4 className="custom_shop_insidefilter mb-0 ml-2">
                                    {filter.split("-")[0]}
                                  </h4>
                                </div>
                              }
                            />
                          );
                        })}
                      </TreeItem>
                    </div>
                  );
                })}
              </TreeView>
            ) : null}
          </Box>

          {/* shop search */}
          {/* <ShopSearch /> */}

          {/* filter by categories */}
          {/* <ShopCategories
        categories={uniqueCategories}
        getSortParams={getSortParams}
      /> */}

          {/* filter by color */}
          {/* <ShopColor colors={uniqueColors} getSortParams={getSortParams} /> */}

          {/* filter by size */}
          {/* <ShopSize sizes={uniqueSizes} getSortParams={getSortParams} /> */}

          {/* filter by tag */}
          {/* <ShopTag tags={uniqueTags} getSortParams={getSortParams} /> */}
        </div>
      )}
    </>
  );
};

ProductListingFilters.propTypes = {
  getSortParams: PropTypes.func,
  products: PropTypes.array,
  sideSpaceClass: PropTypes.string,
};

export default ProductListingFilters;
