import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./account.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useToasts } from "react-toast-notifications";
import Apilib from "../../utils/api";
import Loader from "../../helpers/Loader";

function AdditionalDetails() {
  const [userDetails, setUserDetails] = useState();
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const API = new Apilib();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...userDetails,
    },
    validationSchema: Yup.object({
      // ECOM: Yup.string()
      //   .min(0)
      //   .matches(/^[A-Za-z/' ]+$/, "Invalid ecommerce  name")
      //   .label("Ecommerce Name"),
      // TRANSPORT: Yup.string()
      //   .min(0)
      //   .matches(/^[A-Za-z/' ]+$/, "Invalid transport name")
      //   .label("Transport Name"),
      REMARKS: Yup.string()
        .min(0)
        .matches(/^[A-Za-z/' ]+$/, "Invalid remarks")
        .label("Remarks"),
      REFBY: Yup.string().required().min(0).label("Reffered by"),
    }),
    onSubmit: (values) => {
      SubmitAdditionalDetails();
    },
  });
  console.log("formik", formik.values, userDetails);

  useEffect(() => {
    FetchUserDetails();
  }, []);

  function FetchUserDetails() {
    setLoading(true);

    API.GetUserDetails(localStorage?.amyra_email)
      .then((res) => {
        console.log("user Details", res.data);
        let userInfo = { ...res.data[0] };
        // dispatch(setUserDetails(userDetails));
        setUserDetails(userInfo);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  }

  

  function SubmitAdditionalDetails() {
    if (!formik.isValid) {
      addToast("Enter all required fields", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    setLoading(true);
    const details = {
      ...userDetails,
      ...formik.values,
    };
    console.log("formik----->", details);
    API.UpdateUserDetails(2, JSON.stringify(details))
      .then((res) => {
        console.log("res", res);
        formik.resetForm();
        FetchUserDetails();
        setLoading(false);
        addToast("User details updated successfully", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }
  return (
    <div>
      <div className="ml-1 overflow-hidden">
        <div className="title-text-bold sidebar-title h2 ">
          Additional Details
          <button className="btn btn-background border-round text-white ml-4 edit-btn  btn-sm">
            Edit
          </button>
        </div>
        <hr />
        <div className="mt-2 mr-4 pr-4">
          <form onSubmit={formik.handleSubmit}>
            <div className="row font-weight-bold text-black  ">
              {/* <div className="col-4">
                <label>E-comm Operator </label>

                <input
                  type="text"
                  class="form-control "
                  placeholder="Enter E-comm operator"
                  name="ECOM"
                  onChange={formik.handleChange}
                  value={formik.values.ECOM || ""}
                  onBlur={formik.handleBlur}
                />
                <span className="form-text text-danger login-error">
                  {formik.touched.ECOM && formik.errors.ECOM}
                </span>
              </div>
              <div className="col-4">
                <label>Transport </label>

                <input
                  type="text"
                  class="form-control "
                  placeholder="Enter transport"
                  name="TRANSPORT"
                  onChange={formik.handleChange}
                  value={formik.values.TRANSPORT || ""}
                  onBlur={formik.handleBlur}
                />
                <span className="form-text text-danger login-error">
                  {formik.touched.TRANSPORT && formik.errors.TRANSPORT}
                </span>
              </div> */}
              <div className="col-4">
                <label>Remarks</label>

                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter remarks"
                  name="REMARKS"
                  onChange={formik.handleChange}
                  value={formik.values.REMARKS || ""}
                  onBlur={formik.handleBlur}
                />
                <span className="form-text text-danger login-error">
                  {formik.touched.REMARKS && formik.errors.REMARKS}
                </span>
              </div>
              <div className="col-4 ">
                <label>Reffered by</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Reffered by"
                  name="REFBY"
                  onChange={formik.handleChange}
                  value={formik.values.REFBY || ""}
                  onBlur={formik.handleBlur}
                />
                <span className="form-text text-danger login-error">
                  {formik.touched.REFBY && formik.errors.REFBY}
                </span>{" "}
              </div>
            </div>
            <div className="row text-center">
              <div className=" mt-5   w-100">
                <button className="btn btn-background border-round text-white  ">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AdditionalDetails;
