import ApiLib from "../../utils/api";
import { useToasts } from "react-toast-notifications";
import React, { Fragment, useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
  pb: 0,
};

const CreateCollection = (props) => {
  const [collectionName, setCollectionName] = useState();
  const [loading, setLoading] = useState(false);
  const API = new ApiLib();
  const userDetails = useSelector((state) => state.userDetails);
  const { addToast } = useToasts();

  const CreateCollection = () => {
    if (collectionName) {
      setLoading(true);
      const data = {
        USERID: userDetails.user.ID,
        COLL_NAME: collectionName,
      };
      API.SaveCollection(data)
        .then((res) => {
          console.log(res);
          addToast("New collection created successfully", {
            appearance: "success",
            autoDismiss: true,
          });
          setLoading(false);
          props.onClose();
          props.openCollection();
          // setOpenCollectionModal(true);
        })
        .catch((err) => {
          console.log(err);
          addToast("Something went wrong please try again", {
            appearance: "error",
            autoDismiss: true,
          });
          setLoading(false);
        });
    }
  };
  return (
    <Modal
      open={props.open}
      // onClose={props.onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="row">
          <div className="col-10 text-left">
            <h3 className="mr-3 title-text-bold sidebar-title">
              Create Collection{" "}
            </h3>
          </div>
          <div className=" col-2 text-right mt-n2 ">
            <CloseIcon
              onClick={() => props.onClose()}
              className="close-button"
            />
          </div>{" "}
        </div>

        <div className="row">
          <div className="col-12 px-4 py-4 mb-3">
            <input
              type="text"
              onChange={(e) => setCollectionName(e.target.value)}
 // Bug Solve By Syed
 onKeyDown={(e) => {
        if (e.key === "Enter") {
          CreateCollection();
        }
      }}
      //
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter Collection Name"
              autoFocus
            />
          </div>
        </div>
        <div
          className="row bg-black  text-center save-btn"
          style={{ marginLeft: "-33px", marginRight: "-33px" }}
        >
          <div
            className="col-12 px-3 py-3 text-white btn-background custom_savecollectionbutton"
            onClick={() => {
              CreateCollection();
            }}
          >
            Save
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default CreateCollection;
