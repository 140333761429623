import React, { Fragment, useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CloseIcon from "@mui/icons-material/Close";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import CryptoJS from "crypto-js";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
  pb: 0,
};

const ShareModal = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [shareLink, setShareLink] = useState();
  const userDetails = useSelector((state) => state.userDetails);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (props.selectedCollection) {
      CreateShareLink();
    }
    // eslint-disable-next-line
  }, [props]);

  const CreateShareLink = () => {
    let userId = Buffer.from(`${userDetails.user.ID}`).toString("base64");
    let coll_id = props.selectedCollection.COLL_ID;
    let coll_name = props.selectedCollection.COLL_NAME.replace(/ /g, "_");
    // let link = ;
    let link =
      props.type == 1
        ? `https://catalogcollection.web.app/s/${userDetails.user.BNAME.replace(
            " ",
            "%20"
          )}/${userId}`
        : `https://catalogcollection.web.app/s/${userId}/${coll_name}/${coll_id}`;
    setShareLink(link);
  };

  const handleWaClick = () => {
    let userPhone = userDetails.user.MOBNO;

    if (userPhone === "") return;

    let link = `https://wa.me/${userPhone}`;
    window.open(link, "_blank");
  };

  return (
    <Modal
      open={props.shareCollection}
      onClose={props.shareCollection}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="share-on-whatsApp">
        <div className="row">
          <div className="col-8 text-left">
            <div className="row align-items-center mb-10">
              <h4
                className="mr-3 font-weight-bold share-button title-text"
                onClick={handleWaClick}
                style={{ marginBottom: 0 }}
              >
                {/* {props.type == 1 ? "Share Collection" : "Share Catalog"} */}
                Share on Whatsapp
              </h4>
              <WhatsAppIcon
                className="custom_icon_size icon-color pointer"
                onClick={handleWaClick}
              />
            </div>
          </div>

          <div className=" col-4 text-right mt-n2 ">
            <CloseIcon
              className=" close-button"
              onClick={() => props.onClose()}
            />
          </div>
        </div>
        {/* <div className="row text-center mt-4 mb-4">
          <div className="col-3">
            <InstagramIcon className="custom_icon_size icon-color" />
          </div>
          <div className="col-3">
            <EmailIcon className=" icon-color" />
          </div>
          <div className="col-3">
            <FacebookIcon className="custom_icon_size icon-color" />
          </div>
          <div className="col-3">
            <WhatsAppIcon
              className="custom_icon_size icon-color pointer"
              onClick={handleWaClick}
            />
          </div>
        </div> */}
        <div className="row">
          <div className="col-12 mb-2">
            {" "}
            {props.type == 1 ? "Collection Link" : "Catalog Link"}
          </div>
          <div className="col-10 pr-0 mb-3">
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={shareLink}
              placeholder="https://zjwel.com/ddf/dfdf/swd....."
              disabled={true}
            />
          </div>
          <div className="col-2  mt-2">
            <ContentCopyOutlinedIcon
              aria-describedby={id}
              onClick={(event) => {
                navigator.clipboard.writeText(shareLink);
                handleClick(event);
              }}
              className="pointer"
            />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Typography sx={{ p: 2 }}>
                {props.type == 1
                  ? "Collection link copied"
                  : "Catalog link copied"}
              </Typography>
            </Popover>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
export default ShareModal;
