import PropTypes from "prop-types";
import React from "react";
import featureIconData from "../../data/feature-icons/feature-icon-two.json";
import FeatureIconTwoSingle from "../../components/feature-icon/FeatureIconTwoSingle.js";
import { useSelector } from "react-redux";


const FeatureIconTwo = ({ spaceTopClass, spaceBottomClass }) => {

  const mylanguage = useSelector((state) => state.language);

  const FirstCard = {
    English: {
      title: "Years of skill and know-how",
      subTitle:
        "With close to 2 decades of experienced craftsmanship, Amyraa is revolutionizing the artificial fashion jewelry market. With premium quality and a wide variety of products, you can be sure to find what you are looking for.",
    },
    Hindi: {
      title: "कौशल और ज्ञान के वर्ष",
      subTitle:
        "अनुभवी कौशल के करीब 2 दशकों के साथ, एमिरा कृत्रिम फैशन आभूषण बाजार को क्रांति ला रहा है। प्रीमियम गुणवत्ता और विविधता वाले उत्पादों के साथ, आप जो भी खोज रहे हैं, उसे पा सकते हैं।",
    },
    Tamil: {
      title: "கலை மற்றும் அறிவின் ஆண்டுகள்",
      subTitle:
        "அனுபவமிக்கும் கலை மூன்று பொருள்கள் கொண்டு, அமைரா மேலாண்மை பரிந்துரை மூலம் பணம் வாங்குவது அவசியம் இல்லை. நம்பிக்கையான தர மற்றும் வெற்புவான பொருட்களின் அனைத்து வகைகளுடன், நீங்கள் தேடுகின்ற உத்தியைக் கண்டறிய முடியும்.",
    },
    Telugu: {
      title: "సాన్నివ్వాళ్లు మరియు అరివు సంవత్సరాలు",
      subTitle:
        "అనుభవ సాన్నివ్వాళ్లు తో అరుదుగా 2 దశల కోసం, అమ్య్రా ఆర్టిఫిషియల్ ఫ్యాషన్ ఆభరణం మార్కెట్‌ను క్రాంతి చేస్తోంది. ప్రీమియం గుణము మరియు వివిధ ఉత్పత్తుల తో, మీరు ఏమి కనుగొనున్నారో ఖచ్చితంగా ఉంటుంది.",
    },
    French: {
      title: "Des années de compétence et de savoir-faire",
      subTitle:
        "Avec près de 2 décennies d'expérience artisanale, Amyraa révolutionne le marché des bijoux de mode artificiels. Avec une qualité premium et une grande variété de produits, vous pouvez être sûr de trouver ce que vous cherchez.",
    },
    Spanish: {
      title: "Años de habilidad y conocimiento",
      subTitle:
        "Con casi 2 décadas de artesanía experimentada, Amyraa está revolucionando el mercado de la joyería de moda artificial. Con calidad premium y una amplia variedad de productos, puedes estar seguro de encontrar lo que buscas.",
    },
  }


  const SecondCard = {
    English: {
      titleTwo: "Sell with us",
      subTitleTwo:"A major component of a jewelry business is its inventory. It is capital intensive and requires elaborate resources to manage. Not anymore. With Amyraa, you can run your very own business without the hassle of inventory and delivery.",
    },
    Hindi: {
      titleTwo: "हमारे साथ बेचें",
      subTitleTwo:"आभूषण व्यवसाय का एक प्रमुख घटक इसका इन्वेंटरी है। यह पूंजी निवेशी और प्रबंधन के लिए विस्तृत संसाधनों की आवश्यकता है। अब नहीं। एमिरा के साथ, आप अपना व्यवसाय बिना इन्वेंटरी और वितरण के विचार किए अपने खुद के तरीके से चला सकते हैं।",
    },
    Tamil: {
      titleTwo: "எங்களுடன் விற்க",
      subTitleTwo:"ஆ஭ரண வணிக உத்பாதனையின் முக்கிய பொருள் அதாவது அதன் சரக்குகள் ஆகும். இது மேலாண்மை போலவே செயல்படுத்துவதற்கு பெரிய செலவாக உள்ளது மற்றும் விபரப்படுத்த வேண்டிய வளமையான வளைவுகளை தேவைப்படுகின்றது. இனிமேல் அல்ல. அமைரா உடன், உங்கள் சொத்துக்கும் டெலிவரி விசில்ப்பாக்களும் இல்லாது உங்கள் தனியார் வணிகத்தை நீங்கள் இயக்க முடியும்.",
    },
    Telugu: {
      titleTwo: "మాతో వాణించండి",
      subTitleTwo: "ఆభరణ వ్యాపారం యొక్క ఒక ప్రధాన ఘటకం అది అతని స్టాక్ ఉంది. అది ప్రధాన ధన మునిగితే మరియు నిరాహార నిర్వహణకు విస్తారమైన సాధనాలు అవసరం. ఇప్పుడు కాదు. అమ్య్రాతో, మీరు అంతర్గత స్టాక్ మరియు డెలివరీ ఇలాజు లేక మీ సొంత వ్యాపారం నడవడం సాధ్యం.",
    },
    Spanish: {
      titleTwo: "Vende con nosotros",
      subTitleTwo:"Un componente importante de un negocio de joyería es su inventario. Es intensivo en capital y requiere recursos elaborados para administrarlo. Ya no más. Con Amyraa, puedes dirigir tu propio negocio sin la molestia del inventario y la entrega.",
    },
    French: {
      titleTwo: "Vendez avec nous",
      subTitleTwo:"Un élément majeur d'une entreprise de bijoux est son inventaire. C'est intensif en capital et nécessite des ressources élaborées pour le gérer. Plus maintenant. Avec Amyraa, vous pouvez gérer votre propre entreprise sans les tracas de l'inventaire et de la livraison.",
    },
  };

  const ThirdCard = {
    English:{
      titleThree:"A business of your own",
      SubtitleThree:"Amyraa offers an end-to-end platform that enables you to sell exclusive products of Amyraa on your own terms, just like your own business. Here, you can make a business profile of your own with personalization that best suit you."
    },
    Hindi: {
      titleThree: "अपना व्यवसाय",
      SubtitleThree:
        "एमिरा एक अंत से अंत तक प्लेटफ़ॉर्म प्रदान करता है जो आपको अपने खुद के शर्तों पर एमिरा के विशेष उत्पादों की बिक्री करने की संभावना देता है, जैसे अपना व्यवसाय। यहाँ, आप अपने व्यक्तिगत वितरण के साथ अपना व्यवसाय प्रोफ़ाइल बना सकते हैं जो आपको सबसे अच्छे रूप में अनुकूलित करता है।",
    },
    Tamil: {
      titleThree: "உங்கள் வணிகம்",
      SubtitleThree:
        "ஏமிரா ஒரு முடியும் நெறிமுறை உள்ளடக்கம் வழங்குகிறது, ஏமிராவின் உச்சந்தர உத்பந்தங்களை உங்கள் தனியார் விதிகளில் விற்க அனுமதிக்கும், உங்கள் வணிகம் போல். இங்கே, நீங்கள் உங்களுக்கு சிறந்த அனுகரிப்புடன் ஒரு வணிக சுயவிவரமையம் உருவாக்க முடியும்.",
    },
    Telugu: {
      titleThree: "మీ వ్యాపారం",
      SubtitleThree:
        "అమ్య్రా ఒక అంత నుండి అంత ప్రాంతం ప్లాట్‌ఫారం అందుస్తుంది, అమ్య్రా ప్రత్యేక ఉత్పత్తులు మీ ఆత్మవిశ్వాసంలో అందిస్తుంది, మీ స్వంత వ్యాపారం మరియు శరణాలతో. ఇక, మీరు మీకు ఉత్తమమైన వ్యక్తిగతీకరణతో మీ స్వంత వ్యాపార ప్రొఫైల్‌ను ఉంచుకోవచ్చు.",
    },
    French: {
      titleThree: "Votre propre entreprise",
      SubtitleThree:
        "Amyraa propose une plateforme de bout en bout qui vous permet de vendre les produits exclusifs d'Amyraa selon vos propres termes, tout comme votre propre entreprise. Ici, vous pouvez créer un profil d'entreprise avec une personnalisation qui vous convient le mieux.",
    },
    Spanish: {
      titleThree: "Tu propio negocio",
      SubtitleThree:
        "Amyraa ofrece una plataforma de extremo a extremo que te permite vender productos exclusivos de Amyraa según tus propios términos, al igual que tu propio negocio. Aquí, puedes crear un perfil empresarial con personalización que mejor se adapte a ti.",
    },
  }
  

  const { title, subTitle } = FirstCard[mylanguage] || FirstCard['English']; // Default to English if language not found
  const {titleTwo, subTitleTwo} = SecondCard[mylanguage] || SecondCard["English"];
  const {titleThree, SubtitleThree} = ThirdCard[mylanguage] || ThirdCard["English"];




  return (
    <div
      className={`support-area ${spaceTopClass ? spaceTopClass : ""} ${
        spaceBottomClass ? spaceBottomClass : ""
      }`}
    >
      <div className="container">
        <div className="row feature-icon-two-wrap">
          <FeatureIconTwoSingle
            title={title}
            subTitle={subTitle}
            spaceBottomClass="mb-30"
            textAlignClass="text-center"
          />
          <FeatureIconTwoSingle
            title={titleTwo}
            subTitle={subTitleTwo}
            spaceBottomClass="mb-30"
            textAlignClass="text-center"
          />
          <FeatureIconTwoSingle
            title={titleThree}
            subTitle={SubtitleThree}
            spaceBottomClass="mb-30"
            textAlignClass="text-center"
          />
        </div>
      </div>
    </div>
  );
};

FeatureIconTwo.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
};

export default FeatureIconTwo;
