import { ActionTypes } from "../constants/action-type";
const initialState = {
    selectedLanguage: 'English',
  };
  export const languageReducer = (state = initialState.selectedLanguage, { type, payload }) => {
    console.log("cart payload", type, payload);
    switch (type) {
      case ActionTypes.ENGLISHLANGUAGE:
        return "English";
        case ActionTypes.HINDILANGUAGE:
        return "Hindi";
        case ActionTypes.SPANISHLANGUAGE:
        return "Spanish";
        case ActionTypes.TAMILLANGUAGE:
          return "Tamil";
          case ActionTypes.TELUGULANGUAGE:
          return "Telugu";
          case ActionTypes.FRENCHLANGUAGE:
          return "French";  
      default:
        return state;
    }
  };
