import React, { Fragment, useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import MetaTags from "react-meta-tags";
import LayoutOne from "../../layouts/LayoutOne";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import BlogComment from "../../wrappers/blog/BlogComment";
import SideBar from "./SideBar";
import ApiLib from "../../utils/api";
import { useSelector } from "react-redux";
import EditModal from "./EditCollection";
import ShareModal from "./ShareCollection";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../../helpers/Loader";
import UserMode from "./UserMode";

import "./Collection.css";
import WhatsAppBotComp from "../../components/WhatsAppBot/YourMainComponent";
import mixpanel from "mixpanel-browser";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
  pb: 0,
};
const Collection = ({ location }) => {
  const { pathname } = location;
  const { addToast } = useToasts();
  const userDetails = useSelector((state) => state.userDetails);

  const API = new ApiLib();

  const [deleteCollection, setDeleteCollection] = useState(false);
  const [shareCollection, setShareCollection] = useState(false);
  const [shareCatalog, setShareCatalog] = useState(false);
  const [editCollection, setEditCollection] = useState(false);
  const [showUserModeModal, setShowUserModeModal] = useState(false);
  const [collection, setCollection] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState();
  const [loading, setLoading] = useState(false);
  const [collectionProducts, setCollectionProducts] = useState([]);
  const [shareModal, setShareModal] = useState(false);

  // Side Bar Code
  const [sidebarOpen, setSidebarOpen] = useState(false);
  // Side Bar Function
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleOpenshareCollection = () => {
    setShareCollection(true);
    shareAllCollection();
  }

  const handleOpenshareCatalog = () => setShareCatalog(true);
  const handleOpenUsermodeModal = () => setShowUserModeModal(true);


  const shareAllCollection = () => {
    mixpanel.track("ShareAllCollection")
  }

  useEffect(() => {
    if (userDetails.user != null) {
      GetAllCollection();
    } else {
      addToast("Kindly login to view/add collection", {
        appearance: "warning",
        autoDismiss: true,
      });
      // Notify({
      //   alert: true,
      //   type: "warning",
      //   title: "Kindly login to view/add collection",
      // });
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedCollection) {
      FetchCollectionDetail();
      setSidebarOpen(false);
    }
    //eslint-disable-next-line
  }, [selectedCollection]);

  const FetchCollectionDetail = () => {
    setLoading(true);
    API.GetDetailCollection(userDetails.user.ID, selectedCollection.COLL_ID)
      .then((res) => {
        console.log("DESIGN COLLECTION", res.data);
        if (!res.data.length) {
          // Notify({
          //   alert: true,
          //   type: "warning",
          //   title: "No product available in this collection",
          // });
          addToast("No product available in this collection", {
            appearance: "warning",
            autoDismiss: true,
          });
        }
        setCollectionProducts(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const GetAllCollection = () => {
    setLoading(true);
    API.GetAllCollection(userDetails?.user?.ID)
      .then((res) => {
        if (!res.data.length) {
          // Notify({
          //   alert: true,
          //   type: "warning",
          //   title: "No Collection available yet",
          // });

          setLoading(false);
          setCollection(res.data);

          addToast("No Collection available yet", {
            appearance: "warning",
            autoDismiss: true,
          });
          return;
        }

        setCollection(res.data);
        setSelectedCollection(res.data[0]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const DeleteCollection = () => {
    mixpanel.track("CollectionDeletedSuccessfully");
    setLoading(true);
    let data = {
      USERID: userDetails.user.ID,
      COLL_ID: Number(selectedCollection.COLL_ID),
    };
    API.DeleteCollection(data)
      .then((res) => {
        setDeleteCollection(false);
        GetAllCollection();
        console.log(res.data);
        addToast("Collection deleted successfully ", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((err) => {
        addToast("Something went wrong please try again", {
          appearance: "warning",
          autoDismiss: true,
        });
        console.log(err);
      });
  };

  //type =1 collection modal
  //type =2 catalog modal
  return (
    <>
      {/* WhatsApp Icon */}
      {/* <a href=" https://wa.me/9320838762">
<img  src={whatsapp_btn} className="whatsapp-img" alt="" />
</a> */}
      {loading ? <Loader /> : null}
      <ShareModal
        onClose={() => setShareCollection(false)}
        shareCollection={shareCollection}
        selectedCollection={selectedCollection}
        type={1}
      />
      <ShareModal
        onClose={() => setShareCatalog(false)}
        shareCollection={shareCatalog}
        selectedCollection={selectedCollection}
        type={2}
      />
      <EditModal
        onClose={() => setEditCollection(false)}
        editCollection={editCollection}
        selectedCollection={selectedCollection}
        GetAllCollection={() => GetAllCollection()}
      />
      <UserMode
        onClose={() => setShowUserModeModal(false)}
        show={showUserModeModal}
        selectedCollection={selectedCollection}
        type={2}
      />
      {deleteCollection ? (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Confirm"
          confirmBtnBsStyle="danger"
          title="Are you sure you want to remove entire collection?"
          onConfirm={DeleteCollection}
          onCancel={() => setDeleteCollection(false)}
          focusCancelBtn></SweetAlert>
      ) : null}
      <Fragment>
        <MetaTags>
          <title>Amyraa | Collection</title>
          <meta
            name="description"
            content="Compare page of flone react minimalist eCommerce template."
          />
        </MetaTags>
        {/* <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>
          Home
        </BreadcrumbsItem>
        <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>
          My Collection
        </BreadcrumbsItem> */}
        <LayoutOne headerTop="visible">
          {/* breadcrumb */}
          {/* <Breadcrumb /> */}

          {userDetails?.user?.ID && collection.length ? (
            <div className="compare-main-area pt-90 pb-100">
              <div className="container col-par">
                {collection.length ? (
                  <div className="row">
                    <div className="col-3 side-col">
                      <SideBar
                        toggleSidebar={toggleSidebar}
                        sidebarOpen={sidebarOpen}
                        collection={collection}
                        selectedCollection={selectedCollection}
                        updateSelectedCollection={(collection) =>
                          setSelectedCollection(collection)
                        }
                      />
                    </div>
                    <div className="col-9 side-col-2">
                      <div className="row custom_need_in_oneline">
                        <div className="row">
                          <div className="mt-2">
                            <h2 className="collect-head">
                              {selectedCollection?.COLL_NAME}
                            </h2>
                          </div>
                          {/* <div
                            className="my-auto ml-4 mr-2"
                            onClick={() => {
                              console.log("button clicked");
                              setShowUserModeModal(true);
                            }}
                          >
                            <div className="custom_shareAllCollection">
                              <h6>Share collection </h6>
                              <ShareOutlinedIcon className="share-button icon-color" />
                            </div>
                          </div> */}
                          <div className="my-auto ml-4 mr-2">
                            <ShareOutlinedIcon
                              className="share-button icon-color"
                              onClick={() => handleOpenUsermodeModal()}
                            />
                          </div>
                          <div className="my-auto mx-2">
                            <ModeEditOutlineOutlinedIcon
                              className=" icon-color share-button"
                              onClick={() => setEditCollection(true)}
                            />
                          </div>
                          <div className="my-auto mx-2">
                            <DeleteOutlineOutlinedIcon
                              className=" icon-color share-button"
                              onClick={() => setDeleteCollection(true)}
                            />
                          </div>
                        </div>

                        <div
                          className=" "
                          onClick={handleOpenshareCollection}>
                          <div className="custom_shareAllCollection">
                            <h6>Share All collection </h6>
                            <ShareOutlinedIcon className="share-button icon-color" />
                          </div>
                        </div>
                      </div>

                      {/* Collection Button */}
                      <div className="btn-div">
                        <button
                          className="collection-btn"
                          onClick={toggleSidebar}>
                          View Collection
                        </button>
                      </div>

                      <div className="row">
                        {/* </div> */}
                        <div className="col-lg-12">
                          {/* </div> */}
                          <div className="row">
                            {/* </div> */}
                            {collectionProducts.length ? (
                              collectionProducts.map((item) => {
                                return (
                                  <div className=" col-lg-4 custom_marginBottom_col-lg-4">
                                    {/* <div className="col-lg-12"> */}

                                    <BlogComment
                                      className="collection-products"
                                      item={item}
                                      FetchCollectionDesigns={() =>
                                        FetchCollectionDetail()
                                      }
                                    />
                                  </div>
                                  // </div>
                                );
                              })
                            ) : (
                              <div className="container text-center">
                                <h4
                                  style={{
                                    marginBottom: "20rem",
                                    marginTop: "4rem",
                                  }}>
                                  No product available in this collection{" "}
                                </h4>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <div className="container text-center">
              <h4 style={{ marginBottom: "20rem", marginTop: "4rem" }}>
                {/* No Collection available */}
                <Loader /> 
              </h4>
            </div>
          )}
        </LayoutOne>
        <WhatsAppBotComp/>
      </Fragment>
    </>
  );
};

// Collection.propTypes = {
//   addToCart: PropTypes.func,
//   cartItems: PropTypes.array,
//   compareItems: PropTypes.array,
//   currency: PropTypes.object,
//   location: PropTypes.object,
//   deleteFromCompare: PropTypes.func,
// };

export default Collection;
