import PropTypes from "prop-types";
import React from "react";

const HomeProductsTitle = ({
  titleText,
  titleText2,
  positionClass,
  spaceClass,
  colorClass
}) => {
  return (
    <div
      className={`section-title-3 ${positionClass ? positionClass : "left"} ${
        spaceClass ? spaceClass : ""
      }display-flex`}
    >
      <h2 className="text-title">{titleText}</h2>
      <h2 className="text-green">{titleText2}</h2>
    </div>
  );
};

HomeProductsTitle.propTypes = {
  positionClass: PropTypes.string,
  spaceClass: PropTypes.string,
  titleText: PropTypes.string
};

export default HomeProductsTitle;
