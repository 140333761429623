import React, { useState } from "react";
// import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import WhatsAppImage from '../../assets/img/chatbotimage/WhatsApp.png'
import AmyraaSmallLogo from '../../assets/img/chatbotimage/AmyraaSmallLogo.png'
import "./YourMainComponent.css"; // Import your main component styles
import YoutubeLogo from '../../assets/img/YoutubeLogo.png'
import VideoCallLogo from '../../assets/img/VideoCallLogo.png'
import mixpanel from "mixpanel-browser";
import { Mixpanel } from "../../utils/mixpannel";


const WhatsAppBotComp = () => {   
  //
  const trackWhatsAppClick = () => {
    mixpanel.track("ClickWhatsappBtn");  // MixPannel ka code
  }
  const trackVideoCallClick = () => {
    mixpanel.track("ClickVideoCallBtn");  // MixPannel ka code

  }
  const trackYoutubeClick = () => {
    mixpanel.track("ClickYoutubeBtn");  // MixPannel ka code
  }
  //
   
  return (
    <>
    <div className="communication">
    <a href="https://www.youtube.com/@AdminAmyraa"  target="_blank" onClick={trackYoutubeClick} >
    <img src={YoutubeLogo} className="how-to-use-youtube" alt="" />
    </a>
    <a href="https://api.whatsapp.com/send?phone=9320838762&text=Hello%20!%20i%20want%20a%20video%20call%21" target="_blank"  onClick={trackVideoCallClick} >
   <img src={VideoCallLogo} className="video-call-logo" alt="" />
   </a>
   <a href="https://api.whatsapp.com/send?phone=9320838762&text=Hello" target="_blank" onClick={trackWhatsAppClick} >
   <img src={WhatsAppImage} alt="" className="whatsApp-icon" />
   </a>
    </div>
    </>
  );
};


export default WhatsAppBotComp;