import { ActionTypes } from "../constants/action-type";

function setUser(user) {
  console.log("user" , user)
  return {
    type: ActionTypes.SET_USER,
    payload: user,
  };
}

export default setUser;
