import React from "react";

function BankDetails() {
  return (
    <div>
      <div className="ml-5 overflow-hidden">
      <div className="title-text-bold sidebar-title h2 ">
          Bank Details 
          <button className="btn btn-background border-round text-white  edit-btn mx-4 btn-sm">Edit</button>

        </div>
        <hr/>
        <div className="mt-2 mr-4 pr-4">
          <form>
            <div className="row font-weight-bold text-black  ">
              <div className="col-4 mt-3 pt-3">
                <label>Bank Name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter your details"
                />
              </div>
              <div className="col-4 mt-3 pt-3">
                <label>Branch Name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter your area"
                />
              </div>
              <div className="col-4 mt-3 pt-3">
                <label>IFSC Code</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter your pincode"
                />
              </div>
              <div className="col-4 mt-3 pt-3">
                <label>Account Number</label>
                <input type="text" class="form-control" placeholder="City" />
              </div>
              <div className="col-4 mt-3 pt-3">
                <label>MSME Number</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter State"
                />
              </div>
            </div>
            <div className="row text-center">
              <div className=" mt-5   w-100">
                <button className="btn btn-background border-round text-white ">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default BankDetails;
