import { combineReducers } from "redux";
import { ProductReducer } from "./productReducer";
import { UserReducer } from "./userReducer";
import { UserInformationReducer } from "./userInformationReducer";
import { CartReducer } from "./cartReducer";
import { CategoryReducer } from "./CategoryReducer";
import { WishlistReducer } from "./wishlistReducer";
import {PriceCurrencyReducer} from './priceCurrencyReducer'
import {languageReducer} from './languageReducer'
import { SelectAllReducer } from "./selectAllReducer";

export const reducers = combineReducers({
  allProducts: ProductReducer,
  userDetails: UserReducer,
  cart: CartReducer,
  userInformation: UserInformationReducer,
  CategoryReducer: CategoryReducer,
  wishlist: WishlistReducer,
  price:PriceCurrencyReducer,
  language:languageReducer,
  selectAll:SelectAllReducer
});
