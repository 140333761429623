import React, { useState, useEffect } from "react";
// import Modal from "@mui/material/Modal";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import "./account.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Country, State, City } from "country-state-city";
import mixpanel from "mixpanel-browser";

import Select from "react-select";
import { useToasts } from "react-toast-notifications";
import Apilib from "../../utils/api";
import Loader from "../../helpers/Loader";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
const customStyles = (width = 100, height = 80) => { };

export default function PersonalDetails() {
  const [shareCollection, setShareCollection] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [state, setState] = useState();
  const [country, setCountry] = useState();
  const [city, setCity] = useState();
  const [loading, setLoading] = useState(false);
  const [editField, setEditField] = useState(true);
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const handleOpenshareCollection = () => setShareCollection(true);
  const handleCloseshareCollection = () => setShareCollection(false);
  const API = new Apilib();

  useEffect(() => {
    FetchUserDetails();
  }, []);

  useEffect(() => {
    const countryList = Country.getAllCountries();
    let arr = countryList.map((country) => {
      return {
        value: country.isoCode,
        label: country.name,
      };
    });
    console.log(arr);

    setCountries(arr);
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      FNAME: userDetails?.FNAME,
      BNAME: userDetails?.BNAME,
      EMAILID: userDetails?.EMAILID,
      MOBNO: userDetails?.MOBNO,
      OTEL1: userDetails?.OTEL1,
      PIN: userDetails?.PIN,
      AREA: userDetails?.AREA,
      PAR_ADD1: userDetails?.PAR_ADD1,
      PAR_ADD2: userDetails?.PAR_ADD2,
      PAR_ADD3: userDetails?.PAR_ADD3,
      CITY: userDetails?.CITY,
      COUNTRY: userDetails?.COUNTRY,
      STATE: userDetails?.STATE
    },
    validationSchema: Yup.object({
      FNAME: Yup.string()
        .required('Full name is required')
        .min(3)
        .matches(/^([\w]{3,})+(\s+([\w\s]{3,})+)?$/, "Invalid Full Name ")
        .label("Full Name"),
      BNAME: Yup.string()
        .required()
        .min(0)
        .matches(/^[A-Za-z/' ]+$/, "Invalid business name")
        .label("Business Name"),
      EMAILID: Yup.string()
        .required()
        .min(3)
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Invalid email id"
        )
        .label("Email Id"),
      MOBNO: Yup.string()
        .required("Mobile number is required")
        .matches(/^[0-9]+$/, "Invalid Mobile Number")
        .min(7, "Invalid Mobile Number")
        .max(15, "Invalid Mobile Number")
        .label("Contact no"),
      OTEL1: Yup.string()
        .matches(/^[0-9]+$/, "Invalid mobile no.")
        .min(7)
        .max(15)
        .label("Alternate no"),
      AREA: Yup.string().required("Area is required").min(0).label("Area"),
      PIN: Yup.string()
        .required("Pincode is required")
        .matches(/^[0-9]+$/, "Invalid pincode")
        .min(0)
        .max(6)
        .label("Pincode"),
      PAR_ADD1: Yup.string()
        .required("Address is required")
        .min(0)
        .label("ADDRESS"),
      PAR_ADD2: Yup.string().min(0).label("ADDRESS 2"),
      PAR_ADD3: Yup.string().min(0).label("ADDRESS 3"),
      CITY: Yup.string().required("City required").min(0).label("City"),
      COUNTRY: Yup.string()
        .required("Country required")
        .min(0)
        .label("Country"),
      STATE: Yup.string().required("State required").min(0).label("State"),
    }),
    onSubmit: (values) => {
      Submit();
    },
  });

  const clickOnEditPersonalDetails = () => {
    mixpanel.track("Edit Personal Details");
  }


  function FetchUserDetails() {
    API.GetUserDetails(localStorage?.amyra_email)
      .then((res) => {
        console.log("user Details", res.data);
        let userInfo = { ...res.data[0] };
        // dispatch(setUserDetails(userDetails));
        setUserDetails(userInfo);

        //set country state and city

        // let countryobj = userInfo.COUNTRY.split(",");
        // let stateobj = userInfo.STATE.split(",");
        // let cityobj = userInfo.CITY.split(",");
        // console.log("countryobj", countryobj);

        // if (countryobj.length == 2) {
        //   setCountry({
        //     label: countryobj[0],
        //     value: countryobj[1],
        //   });
        // }
        // if (stateobj.length == 2) {
        //   setState({ label: stateobj[0], value: stateobj[1] });
        // }
        // if (cityobj.length == 2) {
        //   setCity({ label: cityobj[0], value: cityobj[1] });
        // }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function handleCountryChange(e) {
    console.log("state---", e);
    setCountry(e);
    const statelist = State.getStatesOfCountry(e.value);
    let arr = statelist.map((state) => {
      return {
        value: state.isoCode,
        label: state.name,
      };
    });
    setStates(arr);
  }

  function handleStateChange(e) {
    console.log("state---", e);
    setState(e);
    const citylist = City.getCitiesOfState(country.value, e.value);
    const arr = citylist.map((city) => {
      return {
        value: city.stateCode,
        label: city.name,
      };
    });
    setCities(arr);
  }

  function Submit() {
    if (!formik.isValid) {
      addToast("Enter all required fields", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    setLoading(true);
    const details = {
      ...userDetails,
      ...formik.values,
      // COUNTRY: `${country.label},${country.value}`,
      // STATE: `${state.label},${state.value}`,
      // CITY: `${city.label},${city.value}`,
    };
    console.log("formik", details);

    API.UpdateUserDetails(1, JSON.stringify(details))
      .then((res) => {
        console.log("res", res);
        formik.resetForm();
        FetchUserDetails();
        setEditField(true);
        setLoading(false);
        addToast("User details updated successfully", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }
  useEffect(() => {
    if (editField) {
      formik.resetForm();
    }
  }, [editField]);

  const PasswordModal = () => {
    const [confirm_pass, setConfirm_pass] = useState(false);
    const [newPass, setNewPass] = useState(false);

    const ChangePassword = useFormik({
      initialValues: {
        old: "",
        new: "",
        type: userDetails?.USERTYPE,
        emailid: userDetails?.EMAILID,
        confirm_password: "",
      },
      validationSchema: Yup.object({
        old: Yup.string()
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
            "Password length must be 6 and contain 1 Uppercase, 1 Lowercase, 1 Special Character, 1 Number."
          )
          .required("Old password is required"),
        // .oneOf([Yup.ref(!"new"), null], "Old Password and New should not match"),

        new: Yup.string()
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
            "Password length must be 6 and contain 1 Uppercase, 1 Lowercase, 1 Special Character, 1 Number."
          )
          .required("New password is required"),
        confirm_password: Yup.string()
          .required("Confirm Password is required")
          .oneOf([Yup.ref("new"), null], "Passwords must match"),
      }),
      onSubmit: (values) => {
        // alert(JSON.stringify(values, null, 2));
        console.log(values);
        changeUserPassword();
        // userRegistration();
      },
    });

    function changeUserPassword() {
      if (ChangePassword.values.old === ChangePassword.values.new) {
        return alert("New Password should not match old Password")
      }


      if (!formik.isValid) {
        addToast("Enter all required fields", {
          appearance: "error",
          autoDismiss: true,
        });
        return;
      }

      setLoading(true);
      let data = {
        ...ChangePassword.values,
      };
      delete data.confirm_password;
      console.log(data);
      API.ChangePassword(data)
        .then((res) => {
          console.log(res);
          setLoading(false);
          handleCloseshareCollection();
          ChangePassword.resetForm();
          addToast("User password updated successfully", {
            appearance: "success",
            autoDismiss: true,
          });
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
    return (
      <Modal
        show={shareCollection}
        onHide={handleCloseshareCollection}
        dialogClassName="my-modal-password"
        size="sm"
        className="custom_arrow_bigger"
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="mt-4 mx-4">
            <form onSubmit={ChangePassword.handleSubmit}>
              <div className="row title-text-bold title-text  ">
                <div className="col-12 ">
                  <input
                    type="password"
                    name="old"
                    onChange={ChangePassword.handleChange}
                    value={ChangePassword.values.old || ""}
                    onBlur={ChangePassword.handleBlur}
                    class="form-control"
                    placeholder="Enter old password"
                  />

                  <span className="form-text text-danger login-error">
                    {ChangePassword.touched.old && ChangePassword.errors.old}
                  </span>
                </div>
                <div className="col-12 ">
                  {newPass ? (
                    <VisibilityIcon
                      onClick={() => setNewPass(!newPass)}
                      className="new-pass custom_new_pass"
                    />
                  ) : (
                    <VisibilityOffIcon
                      className="new-pass custom_new_pass"
                      onClick={() => setNewPass(!newPass)}
                    />
                  )}
                  <input
                    type={!newPass ? "password" : "text"}
                    name="new"
                    onChange={ChangePassword.handleChange}
                    value={ChangePassword.values.new || ""}
                    onBlur={ChangePassword.handleBlur}
                    class="form-control"
                    placeholder="Enter new password"
                  />

                  <span className="form-text text-danger login-error">
                    {ChangePassword.touched.new && ChangePassword.errors.new}
                  </span>
                </div>
                <div className="col-12 ">
                  {confirm_pass ? (
                    <VisibilityIcon
                      className="confirm-pass custom_new_pass"
                      onClick={() => setConfirm_pass(!confirm_pass)}
                    />
                  ) : (
                    <VisibilityOffIcon
                      className="confirm-pass custom_new_pass"
                      onClick={() => setConfirm_pass(!confirm_pass)}
                    />
                  )}
                  <input
                    type={!confirm_pass ? "password" : "text"}
                    name="confirm_password"
                    onChange={ChangePassword.handleChange}
                    value={ChangePassword.values.confirm_password || ""}
                    onBlur={ChangePassword.handleBlur}
                    class="form-control"
                    placeholder="Enter confirm password"
                  />

                  <span className="form-text text-danger login-error">
                    {ChangePassword.touched.confirm_password &&
                      ChangePassword.errors.confirm_password}
                  </span>
                </div>
              </div>
              <div className="row text-center">
                <div className="mb-4 w-100">
                  <button
                    className="btn btn-background border-round text-white custom_all_bootstrap_btn"
                    type="submit"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  return (
    <div>
      {loading ? <Loader /> : null}
      <PasswordModal />
      <div className="ml-1 overflow-hidden">
        <div className="title-text-bold sidebar-title  h2 ">
          Personal Details
          <button
            className="btn  title-text border-round border-green edit-btn mx-4 btn-sm customEditbtn"
            onClick={() => {
              clickOnEditPersonalDetails()
              setEditField(!editField)}}
          >
            {editField ? "Edit" : "Cancel"}
          </button>
          <button
            className="btn btn-background border-round text-white  edit-btn  btn-sm custom_changepassword"
            onClick={handleOpenshareCollection}
          >
            Change Password
          </button>
        </div>
        <hr />
        <div className="mt-2 mr-2 pr-4">
          <form onSubmit={formik.handleSubmit}>
            <div className="row font-weight-bold text-black  ">
              <div className="col-lg-4 col-12">
                <label>Full Name </label>
                <span
                  style={{ color: "red", fontWeight: "bold" }}
                  class="required"
                >
                  *
                </span>
                <input
                  type="text"
                  name="FNAME"
                  disabled={editField}
                  onChange={formik.handleChange}
                  value={formik.values.FNAME || ""}
                  onBlur={formik.handleBlur}
                  class="form-control"
                  placeholder="Enter your Full name"
                />

                <span className="form-text text-danger login-error">
                  {formik.touched.FNAME && formik.errors.FNAME}
                </span>
              </div>
              <div className="col-lg-4 col-12">
                <label>Email-ID </label>
                <span
                  style={{ color: "red", fontWeight: "bold" }}
                  class="required"
                >
                  *
                </span>
                <input
                  type="email"
                  disabled
                  name="EMAILID"
                  onChange={formik.handleChange}
                  value={formik.values.EMAILID || ""}
                  onBlur={formik.handleBlur}
                  class="form-control"
                  placeholder="Enter your email"
                />

                <span className="form-text text-danger login-error">
                  {formik.touched.EMAILID && formik.errors.EMAILID}
                </span>
              </div>
              <div className="col-lg-4 col-12">
                <label>Mobile No</label>
                <span
                  style={{ color: "red", fontWeight: "bold" }}
                  class="required"
                >
                  *
                </span>
                <input
                  type="text"
                  disabled
                  name="MOBNO"
                  onChange={formik.handleChange}
                  value={formik.values.MOBNO || ""}
                  onBlur={formik.handleBlur}
                  class="form-control"
                  placeholder="Enter your mobile no"
                />

                <span className="form-text text-danger login-error">
                  {formik.touched.MOBNO && formik.errors.MOBNO}
                </span>
              </div>
              <div className="col-lg-4 col-12 mt-2">
                <label>Alternate Phone Number</label>
                <input
                  type="text"
                  disabled={editField}
                  name="OTEL1"
                  onChange={formik.handleChange}
                  value={formik.values.OTEL1 || ""}
                  onBlur={formik.handleBlur}
                  class="form-control"
                  placeholder="Enter your alternate number"
                />

                <span className="form-text text-danger login-error">
                  {formik.touched.OTEL1 && formik.errors.OTEL1}
                </span>
              </div>
              <div className="col-lg-4 col-12 mt-2">
                <label>Address 1</label>
                <span
                  style={{ color: "red", fontWeight: "bold" }}
                  class="required"
                >
                  *
                </span>
                <input
                  type="text"
                  name="PAR_ADD1"
                  onChange={formik.handleChange}
                  value={formik.values.PAR_ADD1 || ""}
                  onBlur={formik.handleBlur}
                  class="form-control"
                  placeholder="Enter your address"
                  disabled={editField}
                />

                <span className="form-text text-danger login-error">
                  {formik.touched.PAR_ADD1 && formik.errors.PAR_ADD1}
                </span>
              </div>
              <div className="col-lg-4 col-12 mt-2">
                <label>Address 2</label>
                <input
                  type="text"
                  name="PAR_ADD2"
                  onChange={formik.handleChange}
                  value={formik.values.PAR_ADD2 || ""}
                  onBlur={formik.handleBlur}
                  class="form-control"
                  placeholder="Enter your address"
                  disabled={editField}
                />

                <span className="form-text text-danger login-error">
                  {formik.touched.PAR_ADD2 && formik.errors.PAR_ADD2}
                </span>
              </div>
              <div className="col-lg-4 col-12 mt-2">
                <label>Address 3</label>
                <input
                  type="text"
                  name="PAR_ADD3"
                  onChange={formik.handleChange}
                  value={formik.values.PAR_ADD3 || ""}
                  onBlur={formik.handleBlur}
                  class="form-control"
                  placeholder="Enter your address"
                  disabled={editField}
                />

                <span className="form-text text-danger login-error">
                  {formik.touched.PAR_ADD3 && formik.errors.PAR_ADD3}
                </span>
              </div>
              <div className="col-lg-4 col-12 mt-2">
                <label>Area</label>
                <span
                  style={{ color: "red", fontWeight: "bold" }}
                  class="required"
                >
                  *
                </span>
                <input
                  type="text"
                  name="AREA"
                  onChange={formik.handleChange}
                  value={formik.values.AREA || ""}
                  onBlur={formik.handleBlur}
                  class="form-control"
                  placeholder="Enter your area"
                  disabled={editField}
                />

                <span className="form-text text-danger login-error">
                  {formik.touched.AREA && formik.errors.AREA}
                </span>
              </div>
              <div className="col-lg-4 col-12 mt-2">
                <label>Pincode</label>
                <span
                  style={{ color: "red", fontWeight: "bold" }}
                  class="required"
                >
                  *
                </span>
                <input
                  type="text"
                  name="PIN"
                  onChange={formik.handleChange}
                  value={formik.values.PIN || ""}
                  onBlur={formik.handleBlur}
                  class="form-control"
                  placeholder="Enter your pincode"
                  disabled={editField}
                />

                <span className="form-text text-danger login-error">
                  {formik.touched.PIN && formik.errors.PIN}
                </span>
              </div>
              <div className="col-lg-4 col-12 mt-2">
                <label>Country</label>
                <input
                  type="text"
                  name="COUNTRY"
                  onChange={formik.handleChange}
                  value={formik.values.COUNTRY || ""}
                  onBlur={formik.handleBlur}
                  class="form-control"
                  placeholder="Enter your Country"
                  disabled={editField}
                />

                {/* <Select
                  placeholder="Select your country"
                  options={countries}
                  style={customStyles}
                  isDisabled={editField}
                  name="COUNTRY"
                  onChange={(e) => {
                    handleCountryChange(e);
                  }}
                  value={country}
                /> */}
              </div>
              <div className="col-lg-4 col-12 mt-2">
                <label>State</label>
                <input
                  type="text"
                  name="STATE"
                  onChange={formik.handleChange}
                  value={formik.values.STATE || ""}
                  onBlur={formik.handleBlur}
                  class="form-control"
                  placeholder="Enter your State"
                  disabled={editField}
                />
                {/* <Select
                  name="STATE"
                  onChange={(e) => {
                    handleStateChange(e);
                  }}
                  value={state}
                  options={states}
                  style={customStyles}
                  placeholder="Select your state"
                  isDisabled={editField}
                ></Select> */}

                {/* <span className="form-text text-danger login-error">
                  {formik.touched.STATE && formik.errors.STATE}
                </span> */}
              </div>

              <div className="col-lg-4 col-12 mt-2">
                <label>City</label>
                <input
                  type="text"
                  name="CITY"
                  onChange={formik.handleChange}
                  value={formik.values.CITY || ""}
                  onBlur={formik.handleBlur}
                  class="form-control"
                  placeholder="Enter your City"
                  disabled={editField}
                />
                {/* <Select
                  required
                  onChange={(e) => {
                    console.log("state---", e);
                    setCity(e);
                  }}
                  value={city}
                  options={cities}
                  // style={customStyles}
                  placeholder="Select your city"
                  isDisabled={editField}
                ></Select> */}

                {/* <span className="form-text text-danger login-error">
                  {formik.touched.CITY && formik.errors.CITY}
                </span> */}
              </div>
            </div>
            {editField ? null : (
              <div className="row text-center">
                <div className=" mt-5   w-100">
                  <button className="btn btn-background border-round text-white custom_all_bootstrap_btn">
                    Submit
                  </button>
                </div>
              </div>)
            }
          </form>
        </div>
      </div>
    </div>
  );
}
