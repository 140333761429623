import React, { useEffect, useState } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { Box, Typography } from "@material-ui/core";
import cashIcon from "../../assets/icons/icons8-cash-64.png";
import orderConfirm from "../../assets/icons/order_confirm.png";
import enrouteIcon from "../../assets/icons/delivery.png";

const CustomStepper = ({ status }) => {
  const steps = [
    {
      id: 1,
      label1: "Order",
      label2: "Processed",
      icon: "https://i.imgur.com/9nnc9Et.png",
      status: "",
    },
    {
      id: 2,
      label1: "Order",
      label2: "Confirmed",
      icon: orderConfirm,
      status: "",
    },
    {
      id: 3,
      label1: "Payment",
      label2: "Complete",
      icon: cashIcon,
      status: "",
    },
    {
      id: 4,
      label1: "Order",
      label2: "Enroute",
      icon: enrouteIcon,
      status: "",
    },
    {
      id: 5,
      label1: "Order",
      label2: "Shipped",
      icon: "https://i.imgur.com/TkPm63y.png",
      status: "",
    },
  ];

  const [activeStep, setActiveStep] = useState(1);

  useEffect(() => {
    switch (status) {
      case "Pending":
        setActiveStep(1);
        break;
      case "Order Booked":
        setActiveStep(2);
        break;
      case "Payment Completed":
        setActiveStep(3);
        break;
      case "In Transit":
        setActiveStep(4);
        break;
      case "Delivered":
        setActiveStep(5);
        break;
      default:
        break;
    }
  }, [status]);

  console.log("asdhaskas", status, activeStep);

  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      style={{
        overflowX: "scroll",
        padding: "24px 0px",
      }}
    >
      {steps.map((item) => (
        <Step>
          <StepLabel>
            <Box style={{ display: "flex", alignItems: "center", gap: 10 }}>
              <img
                style={{ height: 40, width: 40 }}
                src={item.icon}
                alt="icon"
              />
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography style={{ fontSize: "14px" }}>
                  {item.label1}
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  {item.label2}
                </Typography>
              </Box>
            </Box>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default CustomStepper;
